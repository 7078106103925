/*
 * StreamBank Monitoring v2.0.21
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import {quantTools} from "../Constants/tools";
import {
    PHOTO_POINT_DOWNSTREAM,
    PHOTO_POINT_INSTREAM_SHADE, PHOTO_POINT_TYPE_END,
    PHOTO_POINT_TYPE_ORIGIN,
    PHOTO_POINT_UPSTREAM
} from "../Constants/photos";
import {useSelector} from "react-redux";
import {photoPointWasActiveDuringSampleEvent} from "./PhotoHooks";
import {selectTransectsWithPlots} from "../Redux/Selectors/transectSelectors";
import {
    invasiveCoverCategories,
    lengthOfCoverCategories,
    stemTallyCategories,
    treeDBHCategories
} from "../Constants/lengthCategories";
import {useRepeaterParameters, useValidAnswer} from "./SurveyHooks";
import {
    TRANSECT_REVIEW_CODE_CANOPY,
    TRANSECT_REVIEW_CODE_DBH,
    TRANSECT_REVIEW_CODE_INSTREAM,
    TRANSECT_REVIEW_CODE_INVASIVE_COVER,
    TRANSECT_REVIEW_CODE_NARRATIVE,
    TRANSECT_REVIEW_CODE_PHOTOS,
    TRANSECT_REVIEW_CODE_STEM_LENGTH
} from "../Constants/transectReview";
import {selectCameraPointsWithPhotoPointHistory} from "../Redux/Selectors/photoSelectors";

export const useProgressColor = () => {
    return (progress = null, isComplete = null) => {
        if (progress === 100 && isComplete) {
            return 'success';
        }

        if (progress > 0) {
            return 'primary';
        }

        return '';
    }
};

export const useSampleEventsWithProgress = () => {
    const transectsWithProgress = useTransectsWithProgress();
    const cameraPointsWithProgress = useCameraPointsWithProgress();
    const surveysWithProgress = useSurveysWithProgress();

    const transects = useSelector(state => selectTransectsWithPlots(state));
    const cameraPoints = useSelector(state => selectCameraPointsWithPhotoPointHistory(state));
    const {surveys} = useSelector(state => state.offlineDataState);

    return (sampleEvents) => {
        return sampleEvents.map(event => {
            let progressNumerator, progressDenominator, dataWithProgress = [];

            switch (event.procedureCode) {
                case 'quant-veg':
                    const eventTransects = transects.filter(transect => String(transect.sampleEventId) === String(event.sampleEventId));
                    dataWithProgress = transectsWithProgress(eventTransects);
                    break;
                case 'qual-veg':
                    const eventSurveys = surveys.filter(survey => String(survey.programId) === String(event.subProgramId));
                    dataWithProgress = surveysWithProgress(eventSurveys, event.sampleEventId);
                    break;
                case 'photo':
                    const eventCameraPoints = cameraPoints.filter(point => String(point.projectId) === String(event.projectId));
                    dataWithProgress = cameraPointsWithProgress(eventCameraPoints, event);
                    break;
            }

            progressNumerator = dataWithProgress.reduce((acc, curr) => acc + curr.progressNumerator, 0);
            progressDenominator = dataWithProgress.reduce((acc, curr) => acc + curr.progressDenominator, 0);

            let progress = progressNumerator / progressDenominator * 100;

            if(event.isComplete) {
                progressNumerator = progressDenominator;
                progress = 100;
            }

            return {
                ...event,
                progress,
                progressNumerator,
                progressDenominator,
                progressComplete: progress === 100,
                isCompleted: event.isComplete
            }
        })
    }
};

export const usePhotoPointIsComplete = () => {
    const photos = useSelector(state => state.offlineDataState.photos);

    return (photoPoint, sampleEvent) => {

        // all deactivated photo points count as complete
        if (!photoPointWasActiveDuringSampleEvent(photoPoint, sampleEvent)) {
            return true;
        }

        // otherwise the photoPoint is complete if it has atleast one photo
        return photos.some(photo => (
            String(photo.photoPointId) === String(photoPoint.photoPointId) &&
            String(sampleEvent.sampleEventId) === String(photo.sampleEventId) &&
            !(photo.deleted ?? null)
        ));
    }
};

export const useCameraPointsWithProgress = () => {
    //const photos = useSelector(state => state.offlineDataState.photos);
    const photoPointIsComplete = usePhotoPointIsComplete();

    return (cameraPoints, sampleEvent) => {
        return cameraPoints.map(point => {
            
            const activePhotoPoints = (point.PhotoPoints ?? [])
                .filter(photoPoint => !!photoPoint.discontinued);
            
            const photoPointIds = activePhotoPoints
                .map(photoPoint => photoPoint.photoPointId);

            const completedPhotoPointIds = activePhotoPoints
                .filter(photoPoint => photoPointIsComplete(photoPoint, sampleEvent))
                .map(photoPoint => photoPoint.photoPointId);

            return {
                ...point,
                title: `Point ${point.name}`,
                progress: photoPointIds.length === 0 ? 0 : (completedPhotoPointIds.length / photoPointIds.length * 100),
                progressNumerator: completedPhotoPointIds.length,
                progressDenominator: photoPointIds.length,
            }
        })
    }
};

export const useSurveysWithProgress = () => {
    const repeaterParameters = useRepeaterParameters();
    const validAnswer = useValidAnswer();

    const qualitativeVegetationMonitorings = useSelector(state => state.offlineDataState.qualitativeVegetationMonitorings);
    const hasValue = (answer) => answer !== '' && answer !== null;

    return (surveys, eventId) => {
        return surveys.map(survey => {
            const answers = qualitativeVegetationMonitorings.find(monitoring => String(monitoring.sampleEventId) === String(eventId));
            let progressNumerator = 0, progressDenominator = 0, progress = 0;

            if(answers) {
                survey.SurveySections.forEach(section => {
                    section.SurveyQuestions.forEach(question => {
                        if (question?.isRequired ?? true) {
                            const answer = answers[question.code];
                            const values = question.options?.values;
                            const valueObject = values && values.length ? values.find(({value}) => String(value) === String(answer)) : null;

                            switch (question.surveyQuestionDataTypeId) {
                                // Checkbox list
                                case 15:
                                    progressDenominator++;
                                    let isChecked = false;

                                    values.forEach(value => {
                                        const checkboxAnswer = !!answers[value.code];
                                        if (hasValue(checkboxAnswer) && checkboxAnswer === true) {
                                            isChecked = true;
                                        }
                                    });

                                    if (isChecked) {
                                        progressNumerator++;
                                    }
                                    break;
                                // Repeater
                                case 17:
                                    progressDenominator++;
                                    const repeaterAnswers = answers.QualitativeVegetationMonitoringRepeaters.filter(repeater => repeater.code === question.code);
                                    const repeaterParams = repeaterParameters(question.code);

                                    if (validAnswer(repeaterAnswers, repeaterParams.fields)) {
                                        progressNumerator++;
                                    }
                                    break;
                                // Radio
                                case 13:
                                    if (valueObject?.secondaryFields) {
                                        valueObject.secondaryFields.forEach(field => {
                                            progressDenominator++;

                                            switch (field.type) {
                                                case 'monitoring-repeater':
                                                    const repeaterAnswers = answers.QualitativeVegetationMonitoringRepeaters.filter(repeater => repeater.code === field.code);
                                                    const repeaterParams = repeaterParameters(field.code);

                                                    if (validAnswer(repeaterAnswers, repeaterParams.fields)) {
                                                        progressNumerator++;
                                                    }
                                                    break;
                                                case 'monitoring-recommendations':
                                                    if (answers.QualitativeVegetationMonitoringRecommendations.find(recommendation => recommendation.code === field.code)) {
                                                        progressNumerator++;
                                                    }
                                                    break;
                                                default:
                                                    const secondaryAnswer = answers[field.code];
                                                    if (hasValue(secondaryAnswer)) {
                                                        progressNumerator++;
                                                    }
                                            }
                                        });
                                        break;
                                    }
                                //fallthrough
                                default:
                                    progressDenominator++;

                                    if (hasValue(answer)) {
                                        progressNumerator++;
                                    }
                            }
                        }
                    })
                });

                progress = progressNumerator / progressDenominator * 100;
            }

            return {
                ...survey,
                progress,
                progressNumerator,
                progressDenominator,
                progressComplete: progress === 100,
            }
        })
        .sort((a, b) => a.order > b.order ? 1: -1);
    }
};

export const useTransectReviewProgress = () => {
    return (review) => {
        const confirmed = review.filter(item => item.progressComplete);

        return confirmed.length / review.length;
    }
};

export const useTransectsWithProgress = () => {
    const transectToolProgress = useTransectToolProgress();

    return (transects) => {
        return transects.map(transect => {
            if (!transect.isActive) {
                return {
                    ...transect,
                    progress: 0,
                    progressNumerator: 0,
                    progressDenominator: 0,
                    isCompleted: false,
                }
            }

            const toolProgress = transectToolProgress(transect);
            const progressNumerator = toolProgress.reduce((acc, curr) => acc + curr.progressNumerator, 0);
            const progressDenominator = toolProgress.reduce((acc, curr) => acc + curr.progressDenominator, 0);
            const isCompleted = toolProgress.every(progress => progress.isCompleted);

            return {
                ...transect,
                progress: progressNumerator / progressDenominator * 100,
                progressNumerator,
                progressDenominator,
                isCompleted
            }
        })
    }
};



const hydrozonePlotSpeciesHasStemTallyData = (hydrozonePlotSpecies) => {
    return stemTallyCategories.some(category => (
        Number(hydrozonePlotSpecies?.[category.code]) > 0
    ));
};

const hydrozonePlotSpeciesHasLengthOfCoverData = (hydrozonePlotSpecies) => {
    return lengthOfCoverCategories.some(category => (
        Number(hydrozonePlotSpecies?.[category.code]) > 0
    ));
};

const hydrozonePlotSpeciesHasDBHData = (hydrozonePlotSpecies) => {
    return treeDBHCategories.some(category => (
        Number(hydrozonePlotSpecies?.[category.code]) > 0
    ));
};

const hydrozoneSpeciesIsCompleteData = (hydrozone) => {
    return hydrozoneGroundCoverSpeciesSum(hydrozone, 'leftCover') >= 100
        && hydrozoneGroundCoverSpeciesSum(hydrozone, 'rightCover') >= 100;
};

const hydrozoneGroundCoverSpeciesSum = (hydrozone, categoryCode) => {
    return hydrozone.HydrozoneGroundCoverSpecies.reduce((acc, hydrozoneSpecies) => (
        acc + Number(hydrozoneSpecies?.[categoryCode] ?? 0)
    ), 0);
};

const countHydrozoneScreensComplete = (hydrozones, plotTypeId) => {
    const hydrozoneScreensComplete = hydrozones.reduce((acc, hydrozone) => {
        let noStems = (plotTypeId === 1 && (hydrozone?.hasNoStemsPlot1 ?? hydrozone?.hasNoPlantsPlot1)) ||
                      (plotTypeId === 2 && (hydrozone?.hasNoStemsPlot2 ?? hydrozone?.hasNoPlantsPlot2));

        let noLengthOfCover = (plotTypeId === 1 && (hydrozone.hasNoLengthOfCoverPlot1 ?? hydrozone?.hasNoPlantsPlot1)) ||
                              (plotTypeId === 2 && (hydrozone.hasNoLengthOfCoverPlot2 ?? hydrozone?.hasNoPlantsPlot2));

        let noDBH = (plotTypeId === 1 && hydrozone?.hasNoDBHPlot1) ||
                    (plotTypeId === 2 && hydrozone?.hasNoDBHPlot2);

        return {
            ...acc,
            ...hydrozone.HydrozonePlotSpecies.reduce(
                (acc, hydrozonePlotSpecies) => {
                    if (Number(hydrozonePlotSpecies.plotTypeId) === plotTypeId) {
                        let hydrozoneKey = String(hydrozone.hydrozoneTypeId);

                        // complete hydrozone has no plants, or if one of the plotSpecies has data
                        // note: this logic appears a bit overcomplicated because we used to count
                        // each of these screens separately... instead of refactoring that away, I'm
                        // leaving the complexity to allow changes in the future when we might want
                        // to count screens (DBH for instance) separately.
                        acc[`${hydrozoneKey}-stem`] = acc[`${hydrozoneKey}-stem`] || noStems || hydrozonePlotSpeciesHasStemTallyData(hydrozonePlotSpecies);
                        acc[`${hydrozoneKey}-loc`] = acc[`${hydrozoneKey}-loc`] || noLengthOfCover || hydrozonePlotSpeciesHasLengthOfCoverData(hydrozonePlotSpecies);

                        // dbh is not required, so always count as complete
                        //acc[`${hydrozoneKey}-dbh`] = true; //acc[`${hydrozoneKey}-dbh`] || noDBH || hydrozonePlotSpeciesHasDBHData(hydrozonePlotSpecies);
                    }
                    return acc;
                }, {})
        };
    }, {});
    // count the number of non 0 keys
    return Object.keys(hydrozoneScreensComplete).reduce((acc, k) => acc + Number(hydrozoneScreensComplete[k]), 0);
};

export const useTransectToolProgress = (displayAll = false) => {
    const photos = useSelector(state => state.offlineDataState.photos);

    return (transect, tools = []) => {
        const toolIsComplete = (code) => transect.TransectReviews.find(review => review.code === code)?.isConfirmed ?? false;

        return quantTools.filter(tool => {
                if (tools.length) {
                    return tools.includes(tool.code);
                }

                return (displayAll || !tool.hide) && !(transect.hasNoPlot2 && tool.code === 'plot2')
            }).map(tool => {
                let progressNumerator = 0,
                    progressDenominator = 0,
                    fields = [],
                    dataKey,
                    plotTypeId,
                    hydrozonePlots,
                    hydrozonesWithPlots,
                    completedHydrozones,
                    photoPointTypes = [],
                    isCompleted = false;

                switch (tool.code) {
                    case 'transect-narrative':
                        isCompleted = toolIsComplete(TRANSECT_REVIEW_CODE_NARRATIVE);

                        if (!transect?.TransectNarrative) {
                            progressNumerator = 0;
                            progressDenominator = 1;
                            break;
                        }

                        fields = [
                            'speciesProtected',
                            'competingVegetationNotes',
                            'wildlifeSightings',
                            'managementRecommendations',
                            'TransectNarrativeIrrigation',
                            'TransectNarrativeBrowse',
                            'TransectNarrativeVigor'
                        ];

                        dataKey = 'TransectNarrative';
                        break;
                    case 'plot1':
                        plotTypeId = 1;
                        // fallthrough
                    case 'plot2':
                        plotTypeId = plotTypeId ?? 2;

                        isCompleted = toolIsComplete(TRANSECT_REVIEW_CODE_STEM_LENGTH) && toolIsComplete(TRANSECT_REVIEW_CODE_DBH);

                        hydrozonePlots = transect.HydrozonePlots.filter(hydrozonePlot =>
                            Number(hydrozonePlot.plotTypeId) === plotTypeId
                        );

                        // not all hydrozones have plots, and we should only
                        // count the ones with plots in the number
                        hydrozonesWithPlots = transect.Hydrozones.filter( hydrozone =>
                            hydrozonePlots.some( hydrozonePlot => hydrozonePlot.hydrozoneId === hydrozone.hydrozoneId)
                        );

                        progressNumerator = countHydrozoneScreensComplete(hydrozonesWithPlots, plotTypeId);
                        progressDenominator = hydrozonesWithPlots.length * 2; // stem and length, dbh excluded because it is not required
                        break;
                    case 'invasive-cover':
                        isCompleted = toolIsComplete(TRANSECT_REVIEW_CODE_INVASIVE_COVER);

                        completedHydrozones = transect.Hydrozones.filter(hydrozone => (
                            hydrozone.hasNoRoomForQuadrat ||
                            hydrozoneSpeciesIsCompleteData(hydrozone)
                        ));

                        progressNumerator = completedHydrozones.length;
                        progressDenominator = transect.Hydrozones.length;
                        break;
                    case 'canopy':
                        isCompleted = toolIsComplete(TRANSECT_REVIEW_CODE_CANOPY);

                        if (!transect?.TransectCanopyClosure) {
                            progressNumerator = 0;
                            progressDenominator = 1;
                            break;
                        }

                        fields = [
                            'canopyClosure',
                            'measurementLocation',
                            'north',
                            'east',
                            'south',
                            'west',
                        ];

                        dataKey = 'TransectCanopyClosure';
                        break;
                    case 'origin-end-photo':
                        isCompleted = toolIsComplete(TRANSECT_REVIEW_CODE_PHOTOS);
                        photoPointTypes = [PHOTO_POINT_TYPE_ORIGIN, PHOTO_POINT_TYPE_END];
                        break;
                    case 'instream-shade':
                        isCompleted = toolIsComplete(TRANSECT_REVIEW_CODE_INSTREAM);
                        photoPointTypes = [PHOTO_POINT_INSTREAM_SHADE, PHOTO_POINT_UPSTREAM, PHOTO_POINT_DOWNSTREAM];
                        break;
                }

                if (fields.length) {
                    fields.forEach(field => {
                        const data = transect[dataKey][field];
                        if (data?.length || (data !== '' && data !== null && data !== undefined)) {
                            progressNumerator++;
                        } else if(dataKey === 'TransectNarrative' && data === '') {
                            progressNumerator++;
                        }
                    });

                    progressDenominator = fields.length;
                }

                if (photoPointTypes.length) {
                    const photoPointIds = transect.PhotoPoints
                        .filter(point => photoPointTypes.includes(point.name))
                        .map(point => point.photoPointId);

                    progressNumerator = photos.filter(photo => (
                        photoPointIds.includes(photo.photoPointId) && String(photo.sampleEventId) === String(transect.sampleEventId)
                    )).length;
                    progressDenominator = photoPointIds.length;
                }

                let progress = progressNumerator / progressDenominator * 100;

                if (isCompleted) {
                    progressNumerator = progressDenominator;
                    progress = 100;
                }

                return {
                    ...tool,
                    progress,
                    progressNumerator,
                    progressDenominator,
                    progressComplete: progress === 100,
                    isCompleted,
                }
        })
    }
};
