import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SafetyNoteIcon = props => {
    return (
        <span className="fa-layers fa-fw danger" onClick={() => props.onClick ? props.onClick() : null}>
            <FontAwesomeIcon icon={['fas', 'circle']} transform={`grow-${props.circleSize}`} />
            <FontAwesomeIcon icon={['fas', 'exclamation']} transform="shrink-10" />
            <FontAwesomeIcon icon={['fal', 'sticky-note']} transform="shrink-3" />
        </span>
    );
};

export default SafetyNoteIcon;
