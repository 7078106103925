import {useSelector} from "react-redux";

export const useExpandedNodes = () => {
    const hierarchyNodes = useSelector(state => state.hierarchyNodeState.hierarchyNodes);

    return (hierarchyIds) => {
        const allIds = new Set();

        hierarchyIds.forEach(id => {
            const hierarchyNode = hierarchyNodes.find(node => String(node.hierarchyId) === String(id));

            if (hierarchyNode) {
                allIds.add(hierarchyNode.projectPhaseHierarchyId);
                allIds.add(hierarchyNode.projectContextNodeId);
                allIds.add(hierarchyNode.subProgramContextNodeId);
            }
        });

        return [...allIds];
    }
};
