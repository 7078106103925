import React, { Fragment } from 'react';
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Row, Col, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import { useContextTools } from '../../../Hooks/ToolHooks';
import { CONTEXT_TOOL_ACCOUNT } from '../../../Constants/tools';
import {HIERARCHY_TYPE_PROGRAM} from "../../../Constants/hierarchy";

import PageContent from '../../Layout/Page/PageContent';
import Sidebar from './Sidebar';
import Title from '../../Layout/TitleComponent';
import PageContainer from '../../Layout/Page/PageContainer';
import TileList from '../../Common/TileList';
import PageFooter from "../../Layout/Page/PageFooter";
import PageHeader from "../../Layout/Page/PageHeader";
import {selectProjects} from "../../../Redux/Selectors/nodeSelectors";
import {useShortenProjectName} from "../../../Hooks/TextHooks";

const DashboardPage = (props) => {
    useContextTools([CONTEXT_TOOL_ACCOUNT]);
    const shortenProjectname = useShortenProjectName();

    const getProjectsByProgram = (hierarchyId) => {
        return projects.filter(project => String(project.programContextNodeId) === String(hierarchyId))
            .map(project => ({
                ...project,
                title: shortenProjectname(project.name),
                subtitle: project.subProgramName,
            }))
            .sort((a, b) => a.title > b.title ? 1 : -1);
    };

    const programs = useSelector(state => state.offlineDataState.programs);
    const projects = useSelector(state => selectProjects(state));

    const topLevelPrograms = programs
        .filter(program => program.hierarchyTypeId === HIERARCHY_TYPE_PROGRAM && getProjectsByProgram(program.hierarchyId).length > 0)
        .sort((a, b) => a.name > b.name ? 1 : -1);

    return (
        <PageContainer className="dashboard-page" sidebar={<Sidebar />}>
            <PageHeader>
                <Title title="On-Device Projects" />
            </PageHeader>
            <PageContent>
                <Row xs={12}>
                    <Col>
                        {
                            topLevelPrograms.length ?
                                topLevelPrograms.map(program => (
                                    <Fragment key={program.hierarchyId}>
                                        <Title className="program-title" title={program.name} />
                                        <TileList
                                            className="tile-list-dashboard"
                                            data={getProjectsByProgram(program.hierarchyId)}
                                            tileUrl={(item) => `/project/${item.projectId}`}
                                            primaryKey="projectId"
                                            xs={12}
                                            sm={6}
                                            lg={4}
                                            ellipsis
                                        />
                                    </Fragment>
                                )) :
                                <div className="no-events-message">
                                    <span>You have no projects synced to this device</span>
                                    <Link to="/offline">
                                        <Button variant="complete">Get Started</Button>
                                    </Link>
                                </div>
                        }
                    </Col>
                </Row>
            </PageContent>
            {
                !!topLevelPrograms.length &&
                    <PageFooter>
                        <Row xs={3}>
                            <Col>
                                <Link to="/offline">
                                    <Button variant="action">
                                        <FontAwesomeIcon icon={['fal', 'cloud']} />
                                        Manage On-Device Data
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </PageFooter>
            }
        </PageContainer>
    );
};

export default DashboardPage;
