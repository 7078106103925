import {PLOT_TYPE_2} from "../Constants/hydrozones";

export default class HydrozoneUtils {
    static ComputeHydrozoneEnds(transect) {
        // reverse sort
        transect.Hydrozones.sort((a,b) => a.hydrozoneTypeId > b.hydrozoneTypeId ? -1 : 1);

        let lastEnd = transect.length;
        for (let hydrozone of transect.Hydrozones) {
            hydrozone.end = lastEnd;
            lastEnd = hydrozone.start;
        }

        return transect.Hydrozones.sort((a,b) => a.hydrozoneTypeId > b.hydrozoneTypeId ? 1 : -1);
    }

    static ComputeHydrozonePlots(transect) {
        let hydrozonePlots = [];

        // note if you don't have `hydrozone.end` below you might have
        // to compute it with something like this by looking at the
        // start of the next hydrozone.
        transect.Hydrozones = this.ComputeHydrozoneEnds(transect);

        for (let hydrozone of transect.Hydrozones) {
            for (let plot of transect.TransectPlots) {

                // skip plot2 if the transect doesn't have it
                if (plot.plotTypeId === PLOT_TYPE_2 && transect.hasNoPlot2) {
                    continue;
                }

                let hydrozonePlot = {
                    hydrozoneId: hydrozone.hydrozoneId,
                    hydrozoneTypeId: hydrozone.hydrozoneTypeId,
                    plotTypeId: plot.plotTypeId,
                    start: Math.max(plot.start, hydrozone.start),
                    // note: this will compute a 0 value for hydrozoneplots that don't intersect a hydrozone
                    length: Math.max(Math.min(hydrozone.end, (plot.start + plot.length)) - Math.max(plot.start, hydrozone.start), 0),
                    width: plot.width
                };

                // compute end from length computed above
                hydrozonePlot.end = hydrozonePlot.start + hydrozonePlot.length;

                // if you have hydrozone.end precalculated you could use that below instead
                if (hydrozonePlot.length > 0 && hydrozonePlot.width > 0) {
                    hydrozonePlots.push(hydrozonePlot);
                }
            }
        }

        return hydrozonePlots.sort((a, b) => a.start > b.start ? 1 : -1);
    }
}
