import BaseService from './BaseService';

import config from '../../Config';
import {USER_LOGOUT} from "../../Redux/Actions/Types/userActionTypes";

class AuthService extends BaseService {
    constructor() {
        super();
    }

    get Url() { return super.Url + "/auth"; }

    Auth(xhr) {
        //Override to send without auth.
    }

    ForgotPassword(username) {
        return this.SendRequest(BaseService.GET, {
            username: username
        });
    }

    Login(username, password, saveSession) {
        let result = this.SendRequest(BaseService.POST, {
            username: username,
            password: password
        });

        result.then((x) => {
            if(x.success) {
                if(saveSession) {
                    BaseService.refreshAuthTokenFromAPIResponseData(result);
                }
            }
        });

        return result;
    }

    Logout() {
        let result = this.SendRequest(BaseService.POST, {}, null, '/logout');

        return result.then((x) => {
            if(x.success) {
                BaseService.AUTH_TOKEN = '';
                BaseService.PERMISSIONS = [];

                config.getStore().dispatch({type: USER_LOGOUT});
            }

            return result;
        });
    }
}

let service = new AuthService();
export default service;
