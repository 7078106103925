
export const OFFLINE_STATUS_DO_NOT_SYNC = 1;
export const OFFLINE_STATUS_SYNC = 2;
export const OFFLINE_STATUS_OUT_OF_SYNC = 3;
export const OFFLINE_STATUS_READY_FOR_UPLOAD = 4;

export const STATUS_LABEL_UP_TO_DATE = 'Up to Date';
export const STATUS_LABEL_QUEUED_DOWNLOAD = 'Queued to Download';
export const STATUS_LABEL_QUEUED_UPLOAD = 'Queued to Upload';
export const STATUS_LABEL_READY_TO_UPLOAD = 'Ready to Upload';
export const STATUS_LABEL_OUT_OF_DATE = 'Out of Date';
export const STATUS_LABEL_NOT_DOWNLOADED = '';
