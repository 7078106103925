import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch} from "react-redux";
import {CLOSE_QUICK_NAV} from "../../../Redux/Actions/Types/appStatusTypes";
import {Button, ProgressBar} from "react-bootstrap";

const PillLink = props => {
    const dispatch = useDispatch();

    const hasProgress = props.progress > 0 && !props?.complete;

    const onClick = () => {
        if (props.onClick) {
            props.onClick();
        }

        dispatch({type: CLOSE_QUICK_NAV});
    };

    const getClassType = () => {
        let classNames = [];

        if (props.current) {
            classNames.push('current');
        }

        if (props.complete) {
            classNames.push('complete');
        }

        if (props.child) {
            classNames.push('child');
        }

        if (hasProgress) {
            classNames.push('has-progress')
        }

        return classNames.join(' ');
    };

    const component = () => {
        return (
            <Fragment>
                <Button className={`pill-link ${getClassType()} ${props.className || ''}`} onClick={() => onClick()}>
                    { props.complete && <FontAwesomeIcon icon={['fal', 'check-circle']} /> }
                    <span>{props.label}</span>
                    {
                        hasProgress &&
                        <div className="pill-progress">
                            <ProgressBar now={props.progress} />
                        </div>
                    }
                </Button>
            </Fragment>
        );
    };

    if (props.to) {
        return (
            <Link to={props.to}>
                {component()}
            </Link>
        )
    }

    return component();
};

export default PillLink;
