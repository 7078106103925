import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as PropTypes from "prop-types";
import React, {Fragment} from "react";
import {useSelector} from "react-redux";
import {selectVisiblePhotoById} from "../../../Redux/Selectors/photoSelectors";
import {Link} from "react-router-dom";

export const ReviewedPhotoThumbnail = (props) => {
	const {photoId} = props;
	const photo = useSelector(state => selectVisiblePhotoById(state, photoId));
	return <div className="photo">
		{
			photo ?
				<Fragment>
					<Link to={`/photo/${photo.photoId}`}>
						<img src={photo?.thumbnailSrc ?? photo?.src}/>
						<div className="caption">
							<div className="caption-group">
								<FontAwesomeIcon icon={['fal', 'check-circle']}/>
							</div>
						</div>
					</Link>
				</Fragment> :
				<div className="no-image">
					<FontAwesomeIcon icon={['fal', 'file-image']}/>
					No Photo
				</div>
		}
	</div>;
}

ReviewedPhotoThumbnail.propTypes = {photoId: PropTypes.string};