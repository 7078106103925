import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CloudIcon = props => {
    return (
        <span className={`fa-layers fa-fw ${props.className || ''}`}>
            <FontAwesomeIcon icon={['fas', 'cloud']} />
            <FontAwesomeIcon icon={props.icon} transform="shrink-9 down-1" inverse />
        </span>
    );
};

export default CloudIcon;
