import React, {useEffect, useState} from 'react';
import { ModalBody, ModalFooter, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Modal from '../../../Common/Modal';
import FormInput from "../../../Common/FormInput";
import DateUtils from "../../../../Utils/DateUtils";
import {useDispatch, useSelector} from "react-redux";
import {UPDATE_PHOTO_POINT} from "../../../../Redux/Actions/Types/offlineDataActionTypes";
import {useHasInvalidField} from "../../../../Hooks/FormHooks";

const DeactivatePhotoPointModal = props => {
    const dispatch = useDispatch();
    const hasInvalidField = useHasInvalidField();
    
    const user = useSelector(state => state.userState.user);

    const [date, setDate] = useState(DateUtils.GetCurrentDate());
    const [notes, setNotes] = useState('');
    
    useEffect(() => {
        setDate(DateUtils.GetCurrentDate());
        setNotes('');
    }, [props.show]);

    const disableSave = () => {
        const requiredFields = [date];
        const validatedFields = [];

        return hasInvalidField(requiredFields, validatedFields);
    };

    const deactivate = () => {
        const photoPoint = {
            ...props.point,
            PhotoPointHistory: props.point?.PhotoPointHistory.map((history, index) => {
                if (props.point?.PhotoPointHistory.length === index + 1) {
                    return {
                        ...history,
                        activeTo: DateUtils.FormatDate(date, "yyyy-MM-dd"),
                        deactivateUserId: user.userId,
                        notes,
                    }
                }

                return history;
            })
        };

        dispatch({type: UPDATE_PHOTO_POINT, photoPoint});
        onHide();
    };

    const onHide = () => {
        props.onHide();
    };

    return (
        <Modal
            title={`Discontinue Photo Point ${props.point?.name}`}
            size="md"
            show={props.show}
            className="deactivate-photo-point-modal"
            onHide={() => onHide()}
        >
            <ModalBody>
                <Row xs={12}>
                    <Col xs={12}>
                        <FormInput
                            label="Discontinue Date"
                            type="date"
                            value={DateUtils.FormatDate(date, 'yyyy-MM-dd')}
                            onChange={value => setDate(DateUtils.FormatDate(value, 'yyyy-MM-dd'))}
                            required
                        />
                    </Col>
                    <Col xs={12}>
                        <FormInput
                            label="Discontinue Notes"
                            type="textarea"
                            value={notes}
                            onChange={value => setNotes(value)}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex flex-column">
                    <Button variant="danger" onClick={() => deactivate()} disabled={disableSave()}>
                        <FontAwesomeIcon icon={['fal', 'calendar-times']} />
                        Confirm Deactivation
                    </Button>
                    <Button variant="link" className="mt-2" onClick={() => onHide()}>
                        Cancel
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
};

export default DeactivatePhotoPointModal;

