import React from 'react';
import {useSelector} from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {selectAvailableSampleEvents} from "../../../Redux/Selectors/nodeSelectors";
import DateUtils from "../../../Utils/DateUtils";

import TileList from '../../Common/TileList';
import Title from "../../Layout/TitleComponent";
import SidebarContent from "../../Layout/Sidebar/SidebarContent";
import CollapsibleSidebar from "../../Layout/Sidebar/CollapsibleSidebar";
import {useSampleEventsWithProgress} from "../../../Hooks/ProgressHooks";
import ArrayUtils from "../../../Utils/ArrayUtils";
import {useShortenProjectName} from "../../../Hooks/TextHooks";

const Sidebar = props => {
    const sampleEventsWithProgress = useSampleEventsWithProgress();
    const shortenProjectname = useShortenProjectName();

    const sampleEvents = useSelector(state => selectAvailableSampleEvents(state));

    const hideSidebar = () => {
        return !!(props.hide || !sampleEvents.length);
    };

    const getSampleEvents = () => {
        const allSampleEvents = sampleEvents
            .filter(event => !(event.isComplete && !event.offlineModifiedDate))
            .map(event => ({
                ...event,
                title: shortenProjectname(event.projectName),
                subtitle: event.sampleEventPhaseName,
                caption: DateUtils.FormatToLocalDate(event.startDate),
                leftIcon: <FontAwesomeIcon icon={event.procedureIcon} />,
            }));

        const progressSort = (a,b) => {

            if(a.progress === b.progress) {
                return 0;
            }

            if(isNaN(a.progress)) {
                return 1;
            }

            if(a.progress === 0 || b.progress === 100) {
                return -1;
            }

            if(a.progress === 100 || b.progress === 0) {
                return 1;
            }

            // this means they are both in progress
            return 0;
        };

        const result = ArrayUtils.SortByMultipleCriteria(sampleEventsWithProgress(allSampleEvents), [
                progressSort,
                // (a,b) => (a.progress === b.progress ? 0 : a.priority === b.priority ? 0 : (a.priority > b.priority ? 1 : -1)),
                (a,b) => (a.sampleEventPhaseId === b.sampleEventPhaseId ? 0 : (a.sampleEventPhaseId > b.sampleEventPhaseId ? -1 : 1)),
                (a,b) => DateUtils.CompareAsc(a.startDate, b.startDate),
            ]);

        return result;
    };

    return (
        <CollapsibleSidebar className="dashboard-sidebar" hide={hideSidebar()}>
            <Title title="Scheduled Events" />
            <SidebarContent>
                <TileList
                    className="tile-list-dashboard-sidebar"
                    colSize="12"
                    data={getSampleEvents()}
                    primaryKey="sampleEventId"
                    tileUrl={(item) => `/event/${item.sampleEventId}/${item.procedureCode}`}
                    ellipsis
                />
            </SidebarContent>
        </CollapsibleSidebar>
    )
};

export default Sidebar;
