import React, {Fragment, useState} from 'react';
import {useDispatch} from "react-redux";
import storage from 'localforage';
import {format} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ModalBody, ModalFooter, Button, Row, Col, FormCheck} from 'react-bootstrap';

import FileUtils from "../../../Utils/FileUtils";
import {ENVIRONMENT} from "../../../Constants";
import {restoreBackup} from "../../../Redux/Actions/backupActions";

import Modal from '../../Common/Modal';
import PhotoUtils from "../../../Utils/PhotoUtils";

const BackupModal = props => {
    const dispatch = useDispatch();

    const [includePhotos, setIncludePhotos] = useState(true);
    const [isRestoring, setIsRestoring] = useState(false);

    const backup = async () => {
        let store = await storage.getItem('persist:root');
        let dataStr = null;
        if (includePhotos) {
            const json = JSON.parse(String(store));
            delete json.hierarchyNodeState;
            let offlineDataState = JSON.parse(json.offlineDataState);
            for (const photo of offlineDataState.photos) {
                if (String(photo.photoId).includes('tmp')) {
                    const photoData = await storage.getItem(PhotoUtils.getPhotoApiPath(photo.photoId));
                    if(photoData) {
                        const blob = FileUtils.EnsureBlob(photoData, 'image/jpeg');
                        photo.src = await FileUtils.BlobToBase64(blob);
                    } else {
                        photo.src = null;
                    }
                }
            }
            json.offlineDataState = JSON.stringify(offlineDataState);
            offlineDataState = null;
            dataStr = JSON.stringify(json);
        } else {
            dataStr = store;
        }
        const downloadAnchorNode = document.createElement('a');
        const dateTime = format(new Date, 'yyyy-MM-dd-HHmmss');
        const blob = new Blob([dataStr], {type: "application/json;charset=utf-8"});
        const objectUrl = window.URL.createObjectURL(blob);
        downloadAnchorNode.setAttribute("href", objectUrl);
        downloadAnchorNode.setAttribute("download", `sb-monitoring-backup-${dateTime}.json`);
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();

        setTimeout(() => {
            downloadAnchorNode.remove();
            URL.revokeObjectURL(objectUrl);
        }, 10000);

        props.onHide();
    };

    return (
        <Modal
            title="Backup Data"
            size="md"
            show={props.show}
            onHide={() => props.onHide()}
        >
            <ModalBody>
                <Row xs={12}>
                    <Col xs={12}>
                        {
                            isRestoring ? <p>Restoring... Please wait.</p> :
                            <Fragment>
                                <p>
                                    This feature allows you to export the current state of your on-device data and save
                                    it as a file on your device. This is useful for making a backup of changes that have
                                    not been uploaded or for troubleshooting problems.
                                </p>
                                <p>
                                    On an iPad, after clicking the download button below you can click the "Share" icon
                                    to either "Save to Device..." or share with someone to assist in troubleshooting.
                                </p>
                                <FormCheck
                                    id="complete-checkbox"
                                    label="Include New Photo Content"
                                    type="checkbox"
                                    value={includePhotos}
                                    checked={includePhotos}
                                    onChange={() => setIncludePhotos(!includePhotos)}
                                />
                            </Fragment>
                        }
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {ENVIRONMENT === 'local' &&
                <Button variant="warning" className="ml-3" disabled={isRestoring} onClick={async () => {
                    setIsRestoring(true);
                    await dispatch(restoreBackup());
                    setIsRestoring(false);
                    props.onHide();
                } 
                }>
                    <FontAwesomeIcon icon={['fal', 'arrow-to-top']}/>
                    Restore
                </Button>
                }
                <Button variant="complete" className="ml-3" disabled={isRestoring} onClick={() => backup()}>
                    <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />
                    Download
                </Button>
                <Button variant="link" className="ml-3" disabled={isRestoring} onClick={() => props.onHide()}>
                    <FontAwesomeIcon icon={['fal', 'times']} />
                    Cancel
                </Button>
                
            </ModalFooter>
        </Modal>
    )
};

export default BackupModal;
