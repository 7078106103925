import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import {OPEN_TERMS, SET_OFFLINE_MODE, SET_PARTIAL_LOGIN} from "../../../Redux/Actions/Types/appStatusTypes";
import {login} from "../../../Redux/Actions/loginActions";
import LoginComponent from "../../Auth/LoginComponent"
import ForgotPasswordPopup from "../../Auth/ForgotPasswordPopup"
import VerificationComponent from "../../Auth/VerificationComponent"
import {UPDATE_VERIFICATION_FIELD_VALUE} from "../../../Redux/Actions/Types/loginActionTypes";
import BackgroundImage from "./BackgroundImage";

const LoginPage = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const loginState = useSelector(state => state.loginState);
    const {termsAccepted, offlineMode, partialLogin} = useSelector(state => state.appStatusState);

    const [state, setState] = useState({ displayForgotPassword: false });

    const attemptRedirect = () => {
        if (!termsAccepted) {
            dispatch({type: OPEN_TERMS});
        }
        dispatch({type: SET_OFFLINE_MODE, offlineMode: false});
        dispatch({type: SET_PARTIAL_LOGIN, partialLogin: false});
        dispatch({type: UPDATE_VERIFICATION_FIELD_VALUE, field: 'password', value: ''});
        history.push('/dashboard');
    };

    const goOffline = () => {
        dispatch({type: SET_OFFLINE_MODE, offlineMode: true});
        history.push('/dashboard');
    };

    return (
        <div className="login-page">
            <BackgroundImage/>
            <ForgotPasswordPopup
                SuggestedEmail={loginState.fields.email}
                show={state.displayForgotPassword}
                onHide={() => setState({ displayForgotPassword: false })}
            />
            <div className="login-modal">
                <Container>
                    <Row key={1}>
                        <Col xs={12} md={12} className="login-header">
                            <img src="/images/sbm-logo-color.svg" alt="logo" className="logo" />
                            <h2>{loginState.title || 'Login'}</h2>
                            <hr />
                        </Col>
                    </Row>
                    <Row key={2}>
                        <Col xs={12} className="mb-2">
                            {
                                partialLogin &&
                                    <span>Your StreamBank credentials are no longer valid. Please login to continue or switch to Offline Mode.</span>
                            }
                        </Col>
                        <Col xs={12} md={3} />
                        <Col xs={12} md={6}>
                            {
                                !loginState.isVerifying ?
                                    <LoginComponent
                                        Login={() => {
                                            dispatch(login(attemptRedirect, true))
                                        }}
                                        ForgotPassword={() => setState({ displayForgotPassword: true })}
                                        GoOffline={() => goOffline()}
                                        offlineMode={partialLogin}
                                    /> :
                                    <VerificationComponent OnSuccess={attemptRedirect} />
                            }
                        </Col>
                        <Col xs={12} md={3} />
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default LoginPage;
