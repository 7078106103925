import React, {Fragment, useState} from 'react';
import {Button, FormGroup, FormLabel} from 'react-bootstrap';

import Title from '../../Layout/TitleComponent';
import CollapsibleSidebar from '../../Layout/Sidebar/CollapsibleSidebar';
import SidebarContent from '../../Layout/Sidebar/SidebarContent';
import SidebarFooter from '../../Layout/Sidebar/SidebarFooter';
import TransectNotesModal from "./TransectNotesModal";
import {useFinalized} from "../../../Hooks/DataHooks";
import {formatNotesLabel} from "../../../Hooks/LabelHooks";

const Sidebar = props => {
    const [showNotes, setShowNotes] = useState(false);

    const {transect} = props;
    const finalized = useFinalized(transect.sampleEventId);

    return (
        <Fragment>
            <TransectNotesModal
                show={showNotes}
                transect={transect}
                onHide={() => setShowNotes(false)}
                readOnly={finalized}
            />
            <CollapsibleSidebar>
                <Title title="Transect Info" />
                <SidebarContent>
                    <FormGroup>
                        <FormLabel>Origin Location Notes :</FormLabel>
                        <div>{formatNotesLabel(transect.originNotes)}</div>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Safety Notes</FormLabel>
                        <div>{formatNotesLabel(transect.safetyNotes)}</div>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Other Notes</FormLabel>
                        <div>{formatNotesLabel(transect.notes)}</div>
                    </FormGroup>
                </SidebarContent>
                <SidebarFooter>
                    <Button variant="action" onClick={() => setShowNotes(true)}>
                        Edit Transect Notes
                    </Button>
                </SidebarFooter>
            </CollapsibleSidebar>
        </Fragment>
    )
};

export default Sidebar;
