import React, {useState} from 'react';
import FormInput from "../FormInput";
import {updateSurveyValue} from "../../../Redux/Actions/surveyActions";
import {useDispatch} from "react-redux";

const SurveyTextInput = props => {
    const dispatch = useDispatch();

    const { options, value, answerId } = props;
    const [text, setText] = useState(value);

    return (
        <div className="survey-text-input">
            <FormInput
                type={options?.type ?? "text"}
                placeholder={options?.placeholder ?? ""}
                value={text ?? ''}
                onChange={(result) => setText(result)}
                onBlur={() => dispatch(updateSurveyValue(answerId, props.code, text))}
                readOnly={props.readOnly}
            />
        </div>
    )
};

export default SurveyTextInput;
