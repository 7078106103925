import React from 'react';
import {Button, Col, ModalBody, ModalFooter, Row} from "react-bootstrap";
import Modal from "../../Common/Modal";

const SoftwareLicenseModal = props => {
    return (
        <Modal
            title="Software Licenses"
            size="lg"
            show={props.show}
            onHide={() => props.onHide()}
            hideCloseButton
            static
        >
            <ModalBody>
                <Row xs={12}>
                    <Col xs={12}>
                        Coming Soon.
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button variant="danger" className="ml-3" onClick={() => props.onHide()}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default SoftwareLicenseModal;
