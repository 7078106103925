import React from 'react';
import { Button, FormGroup, FormLabel } from 'react-bootstrap';

import Title from '../../Layout/TitleComponent';
import CollapsibleSidebar from '../../Layout/Sidebar/CollapsibleSidebar';
import SidebarContent from '../../Layout/Sidebar/SidebarContent';
import SidebarFooter from '../../Layout/Sidebar/SidebarFooter';
import {formatNotesLabel} from "../../../Hooks/LabelHooks";

const Sidebar = ({item, showNotes}) => {
    return (
        <CollapsibleSidebar>
            <Title title="Project Notes" />
            <SidebarContent>
                <FormGroup>
                    <FormLabel>Landowner Notification Required</FormLabel>
                    <div>{formatNotesLabel(item.landownerNotificationRequired)}</div>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Special Equipment Needs</FormLabel>
                    <div>{formatNotesLabel(item.specialEquipment)}</div>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Project</FormLabel>
                    <div>{formatNotesLabel(item.notes)}</div>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Directions</FormLabel>
                    <div>{formatNotesLabel(item.directions)}</div>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Access Stipulations / Parking</FormLabel>
                    <div>{formatNotesLabel(item.accessStipulations)}</div>
                </FormGroup>
            </SidebarContent>
            <SidebarFooter>
                <Button variant="action" onClick={() => showNotes()}>
                    Edit Notes
                </Button>
            </SidebarFooter>
        </CollapsibleSidebar>
    )
};

export default Sidebar;
