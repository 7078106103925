import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function SpeciesTypeIcon(props) {
    let path;
    let viewBox = "0 0 8.45 10.02";
    switch (props.speciesTypeId) {
        case 1:
            // tree
            path = <path
                d="M6.18 8L4.84 5.72h.81a.25.25 0 00.2-.39L4.24 3.12h.85a.24.24 0 00.19-.39L3.3.1a.24.24 0 00-.39 0l-2 2.63a.25.25 0 00.2.39H2L.36 5.33a.25.25 0 00.2.39h.81L0 8a.21.21 0 00.18.32h2.1v1.35a.23.23 0 00.23.24H3.7a.23.23 0 00.23-.24V8.28H6A.21.21 0 006.18 8z"
                data-name="Layer 2"
                fill="currentColor"
            />;
            viewBox = "0 0 6 10.02";
            break;
        case 2:
            // shrub
            path = <path
                d="M10.24 4.1a1.64 1.64 0 00-2.05.46 5.51 5.51 0 00-.72 1.31c-.08-1.53 0-4.28 1.41-5.38a.21.21 0 000-.34c-.7-.48-1.82.27-2.5.76A5.38 5.38 0 005 2.36c-.09-.41-.2-.7-.3-1C4.5.6 4.05-.18 3.46 0a.12.12 0 000 .15A4.13 4.13 0 013.6 2a2.94 2.94 0 000 .73C2.93 1.8 1.79.75.82 1.29a.16.16 0 000 .26C1.66 2.25 1.92 4 2 5.3a8.79 8.79 0 00-.54-1.24C1.16 3.39.59 2.69 0 3a.12.12 0 000 .15A4.29 4.29 0 01.49 4.9c.09 1.12.73 2 1.53 2h6.87a.76.76 0 00.76-.79 2.21 2.21 0 01.59-1.92.07.07 0 000-.09z"
                data-name="Layer 2"
                fill="currentColor"
                transform="translate(0 2)"
            />;
            viewBox="0 0 10.45 10.02";
            break;
        case 3:
            // vine
            path = <g data-name="Layer 2">
                <path fill="currentColor" d="M2.95 4.23a.19.19 0 01-.18.24A2.76 2.76 0 010 1.66a.19.19 0 01.19-.19 2.76 2.76 0 012.76 2.76zM2.95 9.11a.19.19 0 01-.19.24A2.76 2.76 0 010 6.54a.19.19 0 01.18-.19 2.76 2.76 0 012.77 2.76zM7.71 4.03a2.76 2.76 0 01-2.77 2.76.19.19 0 01-.19-.19 2.76 2.76 0 012.8-2.76.19.19 0 01.19.19z" />
                <path fill="currentColor"d="M5.18.24a.44.44 0 00-.59-.19 2 2 0 00-.73.6 2.07 2.07 0 00-.74-.6.44.44 0 00-.59.19.44.44 0 00.2.59 1.23 1.23 0 01.69 1.1v8.27a.43.43 0 00.44.43.44.44 0 00.44-.43V1.93A1.22 1.22 0 015 .83a.43.43 0 00.18-.59z" />
            </g>;
            viewBox = "0 0 6 10.02";
            break;
        case 4:
            // herb
            path = <path
                d="M8.45 9v1h-.58L6.54 7.55l-.09-.09A7.55 7.55 0 010 .35.34.34 0 01.35 0C3.8.19 6.88 1.87 7.06 5.4L3.28 2.48a.15.15 0 00-.22.18L6.78 7z"
                data-name="Layer 2"
                fill="currentColor"
            />;
            break;
        case 5:
            return <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className={props.className} />;
        default:
            return null;
    }

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox}
            width="1em"
            height="1em"
            className={props.className}
        >
            {path}
        </svg>
    );
}

export default SpeciesTypeIcon;
