export default class ArrayUtils {
    static SortByMultipleCriteria(array, sortFunctions) {
        return array.sort((a,b) => {
            for(const f of sortFunctions) {
                const r = f(a,b);
                if(r !== 0) {
                    return r;
                }
            }
            return 0;
        });
    }
    
    static GroupBy(array, groupKeyLogic, sortLogic) {
        let result = array.reduce((accumulator, x) =>
            {
                const groupKey = groupKeyLogic(x);

                // find an existing group for this key
                // from within our accumulated results
                let group = accumulator.find(x => x.key === groupKey);

                // if no existing group exists
                // create one with an empty value set
                if(!group) {
                    group = { key: groupKey, value: [] };
                    accumulator.push(group);
                }

                // add this value to the group in our accumulator
                // note that group is a reference to an
                // element in the accumulator array
                group.value.push(x);

                return accumulator;
            },
            []
        );

        if(sortLogic) {
            result = result.sort(sortLogic);
        }

        return result;
    }

    static Sum(array, logic) {
        let total = 0;

        if(array && array.length) {
            array.forEach((x) => {
                total += logic(x);
            });
        }

        return total;
    }

    static SortToBottom(logic) {
        return (a,b) => {
            if (logic(a)) {
                return 1;
            } else if (logic(b)) {
                return -1;
            } else {
                return 0;
            }
        }
    }
    
    // works with React components
    // and supports a final separator for cases like: "a, b, c and d"
    // adapted from: https://stackoverflow.com/a/40788571/385979
    static arrayJoin(parts, separator = ',', finalSeparator = undefined) {
        if(finalSeparator === undefined) {
            finalSeparator = separator;
        }
        return parts.reduce(
            (prev, curr, index, array) =>
                [prev, ((index+1 === array.length) ? finalSeparator : separator), curr]
        );
    }
    
    // returns only unique elements of an array of objects
    // where unique-ness is determined by the value of a single (primary) key
    // from https://stackoverflow.com/a/56757215/385979
    static uniqueByKey(arr, key) {
        return arr.filter((v,i,a)=>a.findIndex(t=>(t[key] === v[key]))===i)
    }
}
