/*
 * StreamBank Monitoring v2.0.21
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import React, {useState, Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Form, Button, Col, FormControl, Row, Spinner, InputGroup} from 'react-bootstrap';

import {SB_ENTER_KEY_CODE} from "../../Constants";
import Conditional from "../Conditional";
import KeyboardUtils from "../../Utils/KeyboardUtils";
import {UPDATE_VERIFICATION_FIELD_VALUE} from "../../Redux/Actions/Types/loginActionTypes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const LoginComponent = (props) => {
    const dispatch = useDispatch();
    const {fields, loading} = useSelector(state => state.loginState);
    const {user} =  useSelector(state => state.userState);    

    const [showPassword, setShowPassword] = useState(false);

    const updateField = (field, value) => {
        dispatch({type: UPDATE_VERIFICATION_FIELD_VALUE, field, value})
    };

    useEffect(() => {
        if(user && props.offlineMode && fields.email !== user.username) {
            updateField('email', user.username);
        }
    });

    return (
        <Form>
            <Row key={1} className="top10">
                <Col md={3}>
                    <span className="login-form-label">Email</span>
                </Col>
                <Col md={9}>
                    <FormControl
                        autoComplete="username"
                        type="email"
                        name="email"
                        value={fields.email}
                        onChange={(e) => updateField('email', e.target.value)}
                        onKeyPress={(x) => KeyboardUtils.handleKeyPress(SB_ENTER_KEY_CODE, x, () => props.Login())}
                        readOnly={props.offlineMode}
                        autoFocus
                    />
                </Col>
            </Row>
            <Row key={2} className="top10">
                <Col md={3}>
                    <span className="login-form-label">Password</span>
                </Col>
                <Col md={9}>
                    <div className="form-group">
                        <InputGroup className={!props.offlineMode ? 'has-suffix' : ''}>
                            <FormControl
                                autoComplete="current-password"
                                type={ (!props.offlineMode && showPassword) ? 'text' : 'password'}
                                name="password"
                                value={fields.password}
                                onChange={(e) => updateField('password', e.target.value)}
                                onKeyPress={(x) => KeyboardUtils.handleKeyPress(SB_ENTER_KEY_CODE, x, () => props.Login())}
                            />
                            {
                                !props.offlineMode &&
                                <InputGroup.Append>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon
                                            icon={['fas', showPassword ? 'eye-slash' : 'eye']}
                                            onClick={() => setShowPassword(!showPassword)}
                                        />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            }
                            
                        </InputGroup>
                    </div>
                </Col>
            </Row>
            <Conditional Condition={!props.HideButtons}>
                <Row key={3} className="top10 login-btn">
                    <Col md={3} />
                    <Col md={9}>
                        <Button onClick={() => props.Login()} variant="primary">
                            <FontAwesomeIcon icon={['fal', 'user-hard-hat']} />
                            Login
                            {
                                loading && <Spinner animation="border" size="sm" />
                            }
                        </Button>
                    </Col>
                    {
                        props.offlineMode &&
                        <Fragment>
                            <Col md={3} />
                            <Col md={9} className="top10">
                                <Button onClick={() => props.GoOffline()} variant="secondary">Use Offline Mode</Button>
                            </Col>
                        </Fragment>
                    }
                </Row>
                <Row key={4} className="top10 forgot-password">
                    <Col md={3} />
                    <Col md={9}>
                        <a onClick={() => props.ForgotPassword()}>Forgot Password?</a>
                    </Col>
                </Row>
            </Conditional>
        </Form>
    );
};

export default LoginComponent;
