import React from 'react';
import {useSelector} from "react-redux";
import {useParams} from 'react-router-dom'

import PageHeader from "../../Layout/Page/PageHeader";
import Title from "../../Layout/TitleComponent";
import PageContent from "../../Layout/Page/PageContent";
import PageFooter from "../../Layout/Page/PageFooter";
import PageContainer from "../../Layout/Page/PageContainer";
import FooterNav from "../../Layout/Page/FooterNav";
import {useQualVegNav} from "../../../Hooks/QuickNavHooks";
import {
    selectQualitativeEventBySampleEventId, selectSampleEventById,
    selectSurveysBySampleEventId
} from "../../../Redux/Selectors/nodeSelectors";
import SurveyField from "../../Common/Survey/SurveyField";
import {qualTools} from "../../../Constants/tools";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import SpeciesPickerModal from "../../Common/SpeciesPickerModal";
import {useContextToolsBySampleEventId} from "../../../Hooks/ToolHooks";
import {useReadOnly} from "../../../Hooks/FormHooks";
import {useFinalized} from "../../../Hooks/DataHooks";

const QualVegSurveyPage = props => {
    const params = useParams();

    useQualVegNav(params.eventId);

    const event = useSelector(state => selectSampleEventById(state, params.eventId));
    const surveys = useSelector(state => selectSurveysBySampleEventId(state, params.eventId));
    const answers = useSelector(state => selectQualitativeEventBySampleEventId(state, params.eventId));
    const survey = surveys.find(survey => String(survey.surveyId) === String(params.surveyId));
    const surveySection = survey.SurveySections.find(section => section.code === params.sectionCode);
    const tool = qualTools.find(tool => tool.id === survey.surveyTypeId);

    useBreadcrumbs(surveySection.name, event.projectId, event.sampleEventId);
    useContextToolsBySampleEventId(event.sampleEventId);

    const finalized = useFinalized(event.sampleEventId);
    useReadOnly(finalized);

    return (
        <PageContainer className="survey-page">
            <SpeciesPickerModal projectId={event.projectId} />
            <PageHeader>
                <Title
                    title={surveySection.name}
                    subtitle={tool?.title}
                    readOnly={finalized}
                />
            </PageHeader>
            <PageContent>
                {
                    surveySection.SurveyQuestions
                        .sort((a,b) => Number(a.order) === Number(b.order) ? 0 : (Number(a.order) > Number(b.order) ? 1 : -1))
                        .map(question => (
                        <SurveyField
                            question={question}
                            answers={answers}
                            key={question.surveyQuestionId}
                            readOnly={finalized}
                        />
                    ))
                }
            </PageContent>
            <PageFooter>
                <FooterNav />
            </PageFooter>
        </PageContainer>
    )
};

export default QualVegSurveyPage;
