export const SET_CURRENT_DATA = 'transect/SET_CURRENT_DATA';
export const SET_SELECTED_INPUT = 'transect/SET_SELECTED_INPUT';
export const CLEAR_SELECTED_INPUT = 'transect/CLEAR_SELECTED_INPUT';
export const SET_SELECTED_SPECIES = 'transect/SET_SELECTED_SPECIES';

export const OPEN_SPECIES_PICKER = 'transect/OPEN_SPECIES_PICKER';
export const CLOSE_SPECIES_PICKER = 'transect/CLOSE_SPECIES_PICKER';

export const SET = 'keyboard/SET';
export const BACKSPACE = 'keyboard/BACKSPACE';
export const UNDO = 'keyboard/UNDO';
export const INCREMENT = 'keyboard/INCREMENT';
export const SET_REPLACE = 'keyboard/SET_REPLACE';
