import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useReturnLink} from "../../Hooks/RouteHooks";

const ReturnLink = (props) => {
    const returnLink = useReturnLink();

    const getReturnTitle = () => {
        if (props.returnTitle) {
            return props.returnTitle;
        }

        if (props.isTransectReview) {
            return 'Transect Review';
        }

        return 'Review & Finalize';
    };

    const action = () => {
        if (props.onClick) {
            props.onClick();
        } else {
            returnLink(props.path, getReturnTitle());
        }
    };

    return (
        <span className="return-link" onClick={() => action()}>
            {props.children}
            <FontAwesomeIcon icon={['fas', 'external-link']} />
        </span>
    )
};

export default ReturnLink;
