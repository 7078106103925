import {
    TIMER_TICK,
    TIMER_START,
    SET_PHONE_NUMBER,
    SET_LOGIN_ERROR,
    VERIFICATION_SUCCESS,
    VERIFICATION_START, SET_LOADING
} from "./Types/loginActionTypes";
import AuthService from "../../Services/Core/AuthService";
import TwoFactorAuthService from "../../Services/TwoFactorAuthService";

export const login = (callback, shouldAlert = false) => (dispatch, getState) => {
    const { loginState, appStatusState } = getState();

    if (!appStatusState.online) {
        dispatch(handleError('login', 'Please check your internet connection before logging in.', shouldAlert));

        return;
    }

    dispatch({type: SET_LOADING, loading: true});

    AuthService.Login(loginState.fields.email, loginState.fields.password, true)
        .then((x) => {
            if(x.success) {
                const { userState } = getState();

                console.log(userState, getState());

                if(userState.user?.userId && !userState.user?.twoFactorRequired) {
                    callback();
                } else {
                    dispatch({type: VERIFICATION_START});
                }
            } else {
                dispatch(handleError('login', x.message || 'invalid login', shouldAlert));
            }
            dispatch({type: SET_LOADING, loading: false});
        })
        .catch((x) => {
            if(x.data && x.data.message) {
                dispatch(handleError('login', x.data.message, shouldAlert));
            } else {
                dispatch(handleError('login', x.message || 'Unexpected error', shouldAlert));
            }
            dispatch({type: SET_LOADING, loading: false});
        });
};

let timerInterval = null;

export const sendCode = () => (dispatch, getState) => {
    const { loginState } = getState();

    dispatch({type: TIMER_START});
    timerInterval = setInterval(() => {
        // Refreshed timer after dispatching TIMER_START
        const timer = getState().loginState.timer;

        if (timer > 0) {
            dispatch({type: TIMER_TICK});
        } else {
            clearInterval(timerInterval);
        }
    }, 1000);

    TwoFactorAuthService.SendVerificationCode({
        mobileNumber: loginState.fields.mobileNumber,
    })
        .then(x => {

            if (loginState.displayPhoneNumberForm) {
                const number = loginState.fields.mobileNumber;
                const maskedNumber = loginState.fields.maskedNumber || '(***) ***-' + number.substring(number.length - 4, number.length);

                dispatch({type: SET_PHONE_NUMBER, maskedNumber});
            }
        })
        .catch(x => {
            dispatch(handleError('sendCode', x.message));
        });
};

export const verifyCode = (callback) => (dispatch, getState) => {
    const { loginState } = getState();

    TwoFactorAuthService.VerifyCode({
        code: loginState.fields.code,
        mobileNumber: loginState.fields.mobileNumber,
        rememberMe: loginState.fields.rememberMe,
    })
        .then(x => {
            dispatch({type: VERIFICATION_SUCCESS});
        })
        .then(x => {
            callback();
            dispatch({type: SET_LOADING, loading: false});
        })
        .catch(x => {
            console.log(x);
            dispatch(handleError('verify', x?.data?.message || x?.message || 'Unknown Error'));
        });
};

const handleError = (field, errorMessage, shouldAlert = false) => (dispatch) => {
    if (shouldAlert) {
        alert(errorMessage);
    } else {
        dispatch({type: SET_LOGIN_ERROR, field, errorMessage});
    }
};
