import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import {Button, ModalBody} from 'react-bootstrap';
import Title from "../TitleComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MenuLink from "./MenuLink";

import {CLOSE_ACCOUNT_MENU, CLOSE_BACKUP, OPEN_BACKUP, OPEN_TERMS} from "../../../Redux/Actions/Types/appStatusTypes";
import BackupModal from "../Modal/BackupModal";
import SidebarWithBackdrop from "../Sidebar/SidebarWithBackdrop";
import TermsOfServiceModal from "../Modal/TermsOfServiceModal";
import {selectUploadCount} from "../../../Redux/Selectors/hierarchySelectors";
import CircledTextIcon from "../../CompositeIcons/CircledTextIcon";
import SoftwareLicenseModal from "../Modal/SoftwareLicenseModal";
import PrivacyPolicyModal from "../Modal/PrivacyPolicyModal";
import ProcedureModal from "../Modal/ProcedureModal";
import MapModal from "../Modal/MapModal";
import {SBM_VERSION} from "../../../Constants";
import ApiUtils from "../../../Utils/ApiUtils";
import Config from "../../../Config";

const AccountMenu = props => {
    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector(state => state.userState.user);
    const {termsOpen, backupOpen, offlineMode} = useSelector(state => state.appStatusState);
    const {email: offlineModeEmail} = useSelector(state => state.loginState.fields);
    const uploadCount = useSelector(state => selectUploadCount(state));
    const {procedures, projectMap} = useSelector(state => state.appStatusState);

    const [showSoftwareModal, setShowSoftwareModal] = useState(false);
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);
    const [showProcedureModal, setShowProcedureModal] = useState(false);
    const [showMapModal, setShowMapModal] = useState(false);

    const redirectToLibrary = () => {
        ApiUtils.SBAxios().post('library').then(({data}) => {
            const path = `${Config.getLibraryUrl()}/authorize?token=${data.result.authToken}`;
            window.open(path, '_blank') || window.location.replace(path);
        })
    };

    const logoutAction = () => {
        dispatch({type: CLOSE_ACCOUNT_MENU});
        history.push('/logout');
    };

    const menuTitle = () => {
        return (
            <span className="account-menu-title">
                <FontAwesomeIcon className={offlineMode ? 'warning' : props.isOnline ? 'success' : ''} icon={['fas', 'circle']} />
                {offlineMode ? 'Offline Mode' : props.isOnline ? 'Online' : 'Offline'}
            </span>
        );
    };

    const actionButtons = () => {
        return (
            <Fragment>
                {/*<Button variant="menu">*/}
                {/*    <FontAwesomeIcon icon={['fal', 'camera']} />*/}
                {/*    Photos*/}
                {/*</Button>*/}
                    <Button variant="menu" disabled={!projectMap} onClick={() => {
                        dispatch({type: CLOSE_ACCOUNT_MENU});
                        setShowMapModal(true);
                    }}>
                        <FontAwesomeIcon icon={['fal', 'globe']} />
                        Project Map
                    </Button>
                    <Button variant="menu" disabled={!procedures.length}
                            onClick={() => {
                        dispatch({type: CLOSE_ACCOUNT_MENU});
                        setShowProcedureModal(true);
                    }}>
                        <FontAwesomeIcon icon={['fal', 'book']} />
                        Procedures
                    </Button>
            </Fragment>
        )
    };

    const accountData = () => {
        return (
            <div>
                <Title title="Account / Data" />
                <MenuLink label="On-Device Data" to="/offline" badge={
                    !!uploadCount &&
                        <CircledTextIcon
                            circleSize={3}
                            text={uploadCount}
                            className="danger"
                        />
                } />
                <MenuLink label="Backup" onClick={() => dispatch({type: OPEN_BACKUP})} />
            </div>
        )
    };

    const help = () => {
        return (
            <div className="mt-3">
                <Title title="Help" />
                <MenuLink label="StreamBank Library" onClick={() => redirectToLibrary()} />
                <MenuLink label="Terms and Conditions" onClick={() => dispatch({type: OPEN_TERMS})} />
                {/*<MenuLink label="Privacy Policy" onClick={() => setShowPrivacyModal(true)} />*/}
                {/*<MenuLink label="Software Licenses" onClick={() => setShowSoftwareModal(true)} />*/}
                <MenuLink label="The Freshwater Trust" external="https://thefreshwatertrust.org" />
            </div>
        )
    };

    return (
        <Fragment>
            <BackupModal show={backupOpen} onHide={() => dispatch({type: CLOSE_BACKUP})} />
            <TermsOfServiceModal show={termsOpen} />
            <SoftwareLicenseModal show={showSoftwareModal} onHide={() => setShowSoftwareModal(false)} />
            <PrivacyPolicyModal show={showPrivacyModal} onHide={() => setShowPrivacyModal(false)} />
            {
                !!procedures.length &&
                    <ProcedureModal procedures={procedures} show={showProcedureModal} onHide={() => setShowProcedureModal(false)} />
            }
            {
                !!projectMap &&
                    <MapModal src={projectMap} show={showMapModal} onHide={() => setShowMapModal(false)} />
            }
            <SidebarWithBackdrop
                show={props.show}
                className="account-menu"
                hideMenu={() => dispatch({type: CLOSE_ACCOUNT_MENU})}
            >
                <div className="menu-content">
                    <Title title={menuTitle()} />
                    {actionButtons()}
                    {accountData()}
                    {help()}
                </div>
                <div className="menu-footer">
                    <span className="current-user">{offlineMode ? 'Offline mode' : 'Logged in'}: {user?.username ?? offlineModeEmail }</span>
                    {
                        offlineMode ?
                            <Button variant="secondary" className="mb-2" onClick={() => history.push('/login')}>Go
                                Online</Button> : null
                    }
                    <Button variant="complete" className="mb-3" onClick={() => logoutAction()}>Logout</Button>
                    <img className="logo" src="/images/sbm-logo-color.svg" />
                    <span className="copyright">
                        version {SBM_VERSION}
                    </span>
                    <span className="copyright">
                        &#169;Copyright 2021 - The Freshwater Trust
                    </span>
                </div>
            </SidebarWithBackdrop>
        </Fragment>
    )
};

export default AccountMenu;
