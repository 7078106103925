export default class FileUtils {
    static GetFileSize(bytes, si = false) {
        const thresh = si ? 1000 : 1024;
        if(Math.abs(bytes) < thresh) {
            return Math.abs(bytes) + ' B';
        }
        const units = si
            ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
            : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
        let u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while(Math.abs(bytes) >= thresh && u < units.length - 1);

        return Math.abs(bytes).toFixed(1)+' '+units[u];
    }

    static DownloadArrayBufferAsFile(arrayBuffer, contentType, filename) {
        let blob = new Blob( [arrayBuffer], { type: contentType });
        this.DownloadBlobAsFile(blob, filename);
    }

    static DownloadBlobAsFile(blob, filename) {
        const objectUrl = URL.createObjectURL(blob);
        this.DownloadUrlAsFile(objectUrl, filename);
        URL.revokeObjectURL(objectUrl);
    }

    static DownloadUrlAsFile(url, filename) {
        const link = document.createElement('a');
        link.href = url
        link.download = filename;
        link.dispatchEvent(new MouseEvent('click'));
    }

    // this is real dumb logic but it works for our 90% case
    // jpeg, png, gif, tif, tiff, pdf
    // failure cases: svg+xml, etc
    static FileExtensionFromContentType(contentType) {
        return contentType === 'image/jpeg' ? 'jpg' : contentType.substring(contentType.indexOf('/')+1)
    }

    // used to ensure that data from the legacy versions of the app
    // are converted to Blobs with a default contentType instead of
    // ArrayBuffer
    static EnsureBlob(arrayBufferOrBlob, defaultContentType) {
        if(arrayBufferOrBlob instanceof ArrayBuffer) {
            return new Blob([arrayBufferOrBlob], { type: defaultContentType});
        }
        return arrayBufferOrBlob;
    }

    static BlobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result); // it seems like this is the place that throws an out of memory error
            reader.readAsDataURL(blob);
        });
    }

    static IndexedDBKeyFromPhotoURL(url) {
        // remove hostname and /api/ route
        const path = url.split('/api/').pop();
        // remove any query params
        const cleanPath = path.split('?')[0];
        return '/api/' + cleanPath;
    }

    static ParseHTTPRangeHeader(headerValue) {
        let start = null, end = null;
        if(headerValue && (typeof headerValue === 'string' || headerValue instanceof String)) {
            const beginningOfTheEnd = headerValue.lastIndexOf('-');
            start = Number(headerValue.substring('bytes='.length, beginningOfTheEnd));
            end = Number(headerValue.substring(beginningOfTheEnd + 1));
        }
        return {start, end};
    }
}
