import React, {Fragment} from "react";

export const useShortenProjectName = () => {
    return (projectName) => {
        const [namePortion, riverMilePortion = false] = projectName.split(' RM ');

        // Not really a fan of &nbsp; here but can't think of another way atm
        return <div className="project-name-short">
            <div>
                { namePortion }
                { riverMilePortion && <Fragment>&nbsp;RM</Fragment>}
            </div>
            { riverMilePortion &&<Fragment>&nbsp;<div>{riverMilePortion}</div></Fragment> }
        </div>
    };
};
