/*
 * StreamBank Monitoring v2.0.21
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import React, {Fragment, useEffect, useState} from 'react';
import {ModalBody, ModalFooter, Row, Col, FormControl, Form, Button} from 'react-bootstrap';

import Modal from '../Common/Modal';
import Conditional from "../Conditional";
import Config from "../../Config";
import KeyboardUtils from "../../Utils/KeyboardUtils";
import {SB_ENTER_KEY_CODE} from "../../Constants";
import AuthService from "../../Services/Core/AuthService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ForgotPasswordPopup = props => {

    const [email, setEmail] = useState(props.SuggestedEmail ?? '');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        setEmail(props.SuggestedEmail ?? '')
    }, [props.show]);

    const submit = (e) => {
        e.preventDefault();
        if(email) {
            AuthService.ForgotPassword(email)
                .then((x) => {
                    if(x.success) {
                        setError('');
                        setSuccess(x?.message ?? "An email was sent to " + email);
                    } else {
                        setError( x.message || "Unknown error");
                    }
                })
                .catch((x) => {
                    setError(x?.data?.message ?? x?.message ?? "Unknown error");
                });
        }
    };

    const handleSuccessConfirmation = () => {
        setSuccess('');
        props.onHide();
    }

    return <Fragment>
        <Modal
            title="Success"
            size="md"
            body={<p>{success}</p>}
            footer={<Button variant="complete" onClick={handleSuccessConfirmation}>OK</Button>}
            show={!!success}
            onHide={() => handleSuccessConfirmation()}
        />
        <Modal
            title="Error"
            body={<p>{error}</p>}
            footer={<Button variant="complete" onClick={() => setError('')}>OK</Button>}
            show={!!error}
            onHide={() => setError('')}
        />
        <Modal
            title="Forgot Password"
            size="md"
            show={props.show && !error && !success}
            onHide={() => props.onHide()}
        >
            <ModalBody>
                <Form>
                    <Row key={1}>
                        <Col xs={12}>
                            <p>
                                {
                                    email ?
                                        ("Click 'Send Reset Password Email' to send a unique reset password link to " + email + "."):
                                        "Please enter your email and press 'Send Reset Password Email' to get a unique reset password link."
                                }
                            </p>
                        </Col>
                    </Row>
                    <Conditional Condition={Config.getEnvironment() !== 'prod'}>
                        <Row key={3}>
                            <Col xs={12}>
                                <p className="sb-login-popup-container-error-message">This feature is disabled on this server. Please contact <a href="support@streambank.com">support@streambank.com</a> for assistance.</p>
                            </Col>
                        </Row>
                    </Conditional>
                    <Conditional Condition={!props.DefaultEmail}>
                        <Row key={2}>
                            <Col md={3}>
                                <span>Email</span>
                            </Col>
                            <Col md={9}>
                                <FormControl
                                    autoComplete="username"
                                    type="text"
                                    name="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onKeyPress={(e) => KeyboardUtils.handleKeyPress(SB_ENTER_KEY_CODE, e, (e) => submit(e))}
                                    // inputRef={(ref) => {this.input = ref}}
                                />
                            </Col>
                        </Row>
                    </Conditional>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button variant="complete" onClick={() => submit()}>
                    Send Reset Password Email
                </Button>
                <Button variant="link" className="ml-3" onClick={() => props.onHide()}>
                    <FontAwesomeIcon icon={['fal', 'times']} />
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    </Fragment>;
};

export default ForgotPasswordPopup;
