import React from 'react';
import { ModalBody, ModalFooter, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Modal from './Modal';

const ConfirmSyncModal = ({onConfirm, onHide, show}) => {
    return (
        <Modal
            title={`Upload New Event?`}
            size="md"
            show={show}
            hideCloseButton
            static
        >
            <ModalBody>
                <Row xs={12}>
                    <Col xs={12}>
                        <span>
                            You must upload this new event before other users can use it. Do you want to upload it now?
                        </span>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button variant="link" className="ml-3" onClick={() => onHide()}>
                    <FontAwesomeIcon icon={['fal', 'times']} />
                    Upload Later
                </Button>
                <Button variant="primary" className="w-25" onClick={() => onConfirm()}>
                    {
                        !onConfirm &&
                        <FontAwesomeIcon icon={['fal', 'upload']} />
                    }
                    Upload Now
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default ConfirmSyncModal;
