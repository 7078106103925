import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {Row, Col, Button, OverlayTrigger, Popover} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {UPSERT_SAMPLE_EVENT} from "../../../Redux/Actions/Types/offlineDataActionTypes";
import {SET_AUTO_UPLOAD_ID} from "../../../Redux/Actions/Types/syncStatusTypes";

import {
    selectProcedures,
    selectProjectById,
    selectSampleEventById,
    selectSampleEventPhaseTypes,
    selectUsers
} from "../../../Redux/Selectors/nodeSelectors";
import {HIERARCHY_TYPE_SAMPLE_EVENT} from "../../../Constants/hierarchy";

import {useContextTools, useContextToolsByProjectId} from '../../../Hooks/ToolHooks';
import {useNextTempId} from "../../../Hooks/TempIdHooks";
import {useHasInvalidField, useValidateBetween} from "../../../Hooks/FormHooks";
import DateUtils from "../../../Utils/DateUtils";

import PageContent from '../../Layout/Page/PageContent';
import PageFooter from '../../Layout/Page/PageFooter';
import Title from '../../Layout/TitleComponent';
import PageContainer from '../../Layout/Page/PageContainer';
import FormInput from '../../Common/FormInput';
import CheckboxList from '../../Common/CheckboxList';
import PageHeader from "../../Layout/Page/PageHeader";
import RouteLeavingGuard from "../../Common/RouteLeavingGuard";

import {useCopyEventData} from "../../../Hooks/DataCopyHooks";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import {useUpdateEffect} from "../../../Hooks/EffectHooks";
import SaveButtonWithHelpIcon from "../../Common/SaveButtonWithHelpIcon";
import {useQueryParams} from "../../../Hooks/RouteHooks";
import {TEMP_ID_PREFIX} from "../../../Constants/misc";


const NewEventPage = (props) => {
    useContextTools([]);
    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();
    const nextId = useNextTempId();
    const copyEventData = useCopyEventData();
    const validateBetween = useValidateBetween();
    const hasInvalidField = useHasInvalidField();
    const {projectId, autoUpload} = useQueryParams();

    const sampleEvent = useSelector(state => selectSampleEventById(state, params.eventId));
    const project = useSelector(state => selectProjectById(state, sampleEvent?.projectId ?? projectId));
    const users = useSelector(state => selectUsers(state, project.projectId));
    const procedures = useSelector(state => selectProcedures(state, project.parentHierarchyId));
    const sampleEventPhaseTypes = useSelector(state => selectSampleEventPhaseTypes(state, project.parentHierarchyId));

    const breadcrumbTitle = params.eventId ? 'Edit Monitoring Event' : 'New Monitoring Event';
    useBreadcrumbs(breadcrumbTitle, project.projectId);
    useContextToolsByProjectId(project.projectId);

    const [startDate, setStartDate] = useState(DateUtils.GetCurrentDateTime());
    const [sampleEventPhaseId, setSampleEventPhaseId] = useState('');
    const [procedureId, setProcedureId] = useState('');
    const [otherPurpose, setOtherPurpose] = useState('');
    const [compassDeclination, setCompassDeclination] = useState('');
    const [notes, setNotes] = useState('');
    const [safetyNotes, setSafetyNotes] = useState('');
    const [otherStaffMembers, setOtherStaffMembers] = useState('');
    const [isPerformanceMonitoring, setIsPerformanceMonitoring] = useState(0);
    const [crewMembers, setCrewMembers] = useState([]);
    const [hasChanges, setHasChanges] = useState(false);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (sampleEvent) {
            setStartDate(sampleEvent.startDate ?? '');
            setSampleEventPhaseId(sampleEvent.sampleEventPhaseId ?? 1);
            setProcedureId(sampleEvent.procedureId ?? 1);
            setOtherPurpose(sampleEvent.otherPurpose ?? '');
            setCompassDeclination(sampleEvent.compassDeclination ?? project.compassDeclination ?? '');
            setNotes(sampleEvent.notes ?? '');
            setSafetyNotes(sampleEvent.safetyNotes ?? '');
            setCrewMembers(sampleEvent.SampleEventStaff ?? []);
            setOtherStaffMembers(sampleEvent.otherStaffMembers ?? '');
            setIsPerformanceMonitoring(sampleEvent.isPerformanceMonitoring ?? 0);
        }
    }, []);

    useUpdateEffect(() => {
        if (initialized) {
            setHasChanges(true);
        }

        // Ignore first iteration because data is being set by other useEffect
        setInitialized(true);
    }, [startDate, sampleEventPhaseId, procedureId, otherPurpose, compassDeclination, notes, safetyNotes, crewMembers, otherStaffMembers]);

    const save = () => {
        const projectPhaseHierarchyId = `${project.hierarchyId}/${sampleEventPhaseId}`;
        if (sampleEvent) {
            const updatedSampleEvent = {
                ...sampleEvent,
                startDate,
                parentHierarchyId: projectPhaseHierarchyId,
                projectPhaseHierarchyId,
                sampleEventPhaseId: Number(sampleEventPhaseId),
                procedureId: Number(procedureId),
                otherPurpose,
                compassDeclination,
                notes,
                safetyNotes,
                otherStaffMembers,
                isPerformanceMonitoring,
                SampleEventStaff: crewMembers,
            };

            dispatch({type: UPSERT_SAMPLE_EVENT, sampleEvent: updatedSampleEvent});
        } else {
            const hierarchyId = nextId();

            const newSampleEvent = {
                hierarchyId,
                hierarchyTypeId: HIERARCHY_TYPE_SAMPLE_EVENT,
                sampleEventId: nextId(),
                programContextNodeId: project.programContextNodeId,
                projectContextNodeId: project.contextNodeId,
                parentHierarchyId: projectPhaseHierarchyId,
                projectPhaseHierarchyId,
                projectId: project.projectId,
                startDate,
                sampleEventTypeId: 1,
                sampleEventPhaseId: Number(sampleEventPhaseId),
                procedureId: Number(procedureId),
                otherPurpose,
                compassDeclination,
                notes,
                safetyNotes,
                otherStaffMembers,
                isPerformanceMonitoring,
                SampleEventStaff: crewMembers,
                bytes: 0,
            };

            dispatch({type: UPSERT_SAMPLE_EVENT, sampleEvent: newSampleEvent});
            copyEventData(newSampleEvent);

            if (autoUpload) {
                dispatch({type: SET_AUTO_UPLOAD_ID, autoUploadId: hierarchyId});
            }
        }

        setHasChanges(false);
        history.goBack();
    };

    const validateSampleEventPhaseId = () => {
        if (
            (!sampleEvent || String(sampleEvent?.sampleEventId).startsWith(TEMP_ID_PREFIX)) ||
            Number(sampleEventPhaseId) === Number(sampleEvent?.sampleEventPhaseId)
        ) {
            return null;
        }
        return "Cannot be modified after event has been uploaded.";
    }

    const disableSave = () => {
        const requiredMembers = otherStaffMembers ? [...crewMembers, otherStaffMembers] : crewMembers;
        const requiredFields = [startDate, sampleEventPhaseId, compassDeclination, requiredMembers, procedureId];
        const validatedFields = [
            validateSampleEventPhaseId(),
            validateBetween(compassDeclination, 0, 360)
        ];

        return hasInvalidField(requiredFields, validatedFields);
    };

    return (
        <Fragment>
            <RouteLeavingGuard when={hasChanges} />
            <PageContainer className="new-event-page">
                <PageHeader>
                    <Title title={sampleEvent ? `Monitoring Event - ${sampleEvent.fullName}` : 'New Monitoring Event'} />
                </PageHeader>
                <PageContent>
                    <Row xs={12}>
                        <Col xs={6}>
                            <Row xs={12}>
                                <Col xs={6}>
                                    <FormInput
                                        label="Phase"
                                        type="select"
                                        primaryKey="sampleEventPhaseId"
                                        placeholder="Select Phase"
                                        options={sampleEventPhaseTypes}
                                        value={sampleEventPhaseId}
                                        onChange={value => setSampleEventPhaseId(value)}
                                        validationMessage={validateSampleEventPhaseId()}
                                        required
                                    />
                                </Col>
                                <Col xs={6}>
                                    <FormInput
                                        label="Procedure"
                                        type="select"
                                        primaryKey="procedureId"
                                        placeholder="Select Procedure"
                                        options={procedures}
                                        value={procedureId}
                                        onChange={value => setProcedureId(value)}
                                        disabled={!!sampleEvent}
                                        required
                                    />
                                </Col>
                                <Col xs={6}>
                                    <FormInput
                                        label="Purpose"
                                        overlayIcon={
                                            <OverlayTrigger
                                                trigger="click"
                                                key="right"
                                                placement="right"
                                                overlay={
                                                    <Popover>
                                                        <Popover.Content>
                                                            Only use this field when you need to describe an atypical purpose of monitoring, such as "Academic Research Project for Intern".
                                                        </Popover.Content>
                                                    </Popover>
                                                }
                                                rootClose
                                            >
                                                <FontAwesomeIcon icon={['fas', 'info-circle']} className="info-popover" />
                                            </OverlayTrigger>
                                        }
                                        value={otherPurpose}
                                        onChange={value => setOtherPurpose(value)}
                                        placeholder="Research"
                                    />
                                </Col>
                                <Col xs={6}>
                                    <FormInput
                                        label="Start Date"
                                        type="datetime-local"
                                        value={DateUtils.FormatToLocalDateTime(startDate)}
                                        onChange={value => setStartDate(DateUtils.FormatToUTCDateTime(value))}
                                        required
                                    />
                                </Col>
                                <Col xs={6}>
                                    <FormInput
                                        label="Compass Declination"
                                        type="number"
                                        value={compassDeclination}
                                        onChange={value => setCompassDeclination(value)}
                                        validationMessage={validateBetween(compassDeclination, 0, 360)}
                                        suffix="&#176;E"
                                        required
                                    />
                                </Col>
                                <Col xs={6}>
                                    <FormInput
                                        label="Performance Monitoring"
                                        type="select"
                                        options={[
                                            { id: 1, name: 'Yes' },
                                            { id: 0, name: 'No' },
                                        ]}
                                        value={isPerformanceMonitoring}
                                        onChange={value => setIsPerformanceMonitoring(value)}
                                        overlayIcon={
                                            <OverlayTrigger
                                                trigger="click"
                                                key="right"
                                                placement="right"
                                                overlay={
                                                    <Popover>
                                                        <Popover.Content>
                                                            Check this box if the data collected at this event will be reported on for performance monitoring. Leave this box unchecked if the event is another type of site visit such assessing maintenance needs.
                                                        </Popover.Content>
                                                    </Popover>
                                                }
                                                rootClose
                                            >
                                                <FontAwesomeIcon icon={['fas', 'info-circle']} className="info-popover" />
                                            </OverlayTrigger>
                                        }
                                    />
                                </Col>
                                <Col xs={12}>
                                    <FormInput
                                        label="Notes"
                                        type="textarea"
                                        value={notes}
                                        onChange={value => setNotes(value)}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <FormInput
                                        label="Safety Notes"
                                        type="textarea"
                                        value={safetyNotes}
                                        onChange={value => setSafetyNotes(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={6}>
                            <CheckboxList
                                label={
                                    <Fragment>
                                        <span>Field Crew</span>
                                        <OverlayTrigger
                                            trigger="click"
                                            key="bottom"
                                            placement="bottom"
                                            overlay={
                                                <Popover>
                                                    <Popover.Content>
                                                        Program and Project Managers can add Users to this list by assigning the User to this Project in StreamBank Admin.
                                                    </Popover.Content>
                                                </Popover>
                                            }
                                            rootClose
                                        >
                                            <FontAwesomeIcon icon={['fas', 'info-circle']} className="info-popover" />
                                        </OverlayTrigger>
                                    </Fragment>
                                }
                                data={users}
                                primaryKey="userId"
                                checked={crewMembers}
                                onChange={(value) => setCrewMembers(value)}
                                required
                            />
                            <FormInput
                                label="Other Staff"
                                value={otherStaffMembers}
                                onChange={value => setOtherStaffMembers(value)}
                            />
                        </Col>
                    </Row>
                </PageContent>
                <PageFooter>
                    <SaveButtonWithHelpIcon
                        type="Event"
                        hasData={!!sampleEvent}
                        save={save}
                        disable={disableSave()}
                    />
                    <Button variant="link" className="ml-3" onClick={() => history.goBack()}>
                        <FontAwesomeIcon icon={['fal', 'times']} />
                        Cancel
                    </Button>
                </PageFooter>
            </PageContainer>
        </Fragment>
    );
};

export default NewEventPage;
