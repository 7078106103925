import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {Col, Row, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectCameraPointsWithPhotoPointHistory, selectVisiblePhotos} from "../../../Redux/Selectors/photoSelectors";
import ReturnButton from "../../Common/ReturnButton";
import DateUtils from "../../../Utils/DateUtils";

const PhotoPointReviewTable = (props) => {
    const {event} = props;
    
    const photos = useSelector(state => selectVisiblePhotos(state));
    const cameraPoints = useSelector(state => selectCameraPointsWithPhotoPointHistory(state));

    const getCameraPointsAndPhotos = (discontinued) => {
        return cameraPoints
            .filter(cameraPoint => String(cameraPoint.projectId) === String(event.projectId))
            .map(cameraPoint => ({
                ...cameraPoint,
                PhotoPoints: cameraPoint.PhotoPoints
                    .filter(photoPoint => photoPoint.discontinued === discontinued)
                    .map(photoPoint => ({
                        ...photoPoint,
                        Photo: photos.find(photo => (
                            photo.photoPointId === photoPoint.photoPointId &&
                            photo.sampleEventId === event.sampleEventId
                        ))
                    }))
            }))
            .filter(cameraPoint => !!cameraPoint.PhotoPoints.length)
            .sort((a, b) => Number(a.name) > Number(b.name) ? 1 : -1);
    };

    const renderDiscontinuedTable = (cameraPoint) => {
        return (
            <Table borderless responsive>
                <thead>
                    <tr key='header'>
                        <th key={1}>Photo Point Name</th>
                        <th key={2}>Direction</th>
                        <th key={3}>Compass Bearing</th>
                        <th key={4}>Deactivation Date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        cameraPoint.PhotoPoints
                            .map(photoPoint => {
                                const latestDeactivationDate = photoPoint.PhotoPointHistory.sort((a, b) => DateUtils.CompareDesc(a.activeTo, b.activeTo))[0];
    
                                return (
                                    <tr key={photoPoint.photoPointId} >
                                        <td key={1}>{photoPoint.name}</td>
                                        <td key={2}>{photoPoint.photoDirection}</td>
                                        <td key={3}>{photoPoint.compassBearing}</td>
                                        <td key={4}>{DateUtils.FormatDate(latestDeactivationDate.activeTo)}</td>
                                    </tr>
                                )
                            })
                    }
                </tbody>
            </Table>
        );
    };

    return (
        <div>
            {
                getCameraPointsAndPhotos(false).map(cameraPoint => (
                    <div className="review-photos photo-point-row" key={cameraPoint.sampleLocationId}>
                        <div className="review-title">
                            <span>
                                Camera Point: {cameraPoint.name} ({cameraPoint.latitude}&#176;, {cameraPoint.longitude}&#176;) Bank: {cameraPoint.bank}
                            </span>
                            <ReturnButton path={`/event/${event.sampleEventId}/photo/${cameraPoint.sampleLocationId}/point`} readOnly={event.isComplete} />
                        </div>
                        <Row xs={12} className="photo-point-content">
                            {cameraPoint.PhotoPoints.map(photoPoint => (
                                <Col xs={3} key={photoPoint.photoPointId}>
                                    {
                                        photoPoint.Photo ?
                                            <div className="photo">
                                                {
                                                    <Link to={`/photo/${photoPoint.Photo.photoId}`}>
                                                        <img src={photoPoint?.Photo?.thumbnailSrc ?? photoPoint?.Photo?.src} />
                                                        <div className="caption">
                                                            <div className="caption-group">
                                                                <FontAwesomeIcon icon={['fal', 'camera']} />
                                                                <span>{photoPoint.name}</span>
                                                            </div>
                                                            <div className="caption-group">
                                                                <FontAwesomeIcon icon={['fal', 'compass']} />
                                                                <span>{photoPoint.photoDirection} {photoPoint.compassBearing}&#176;</span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                }
                                            </div> :
                                            <div className="photo no-photo">
                                                <div className="no-image">
                                                    <FontAwesomeIcon icon={['fal', 'file-image']} />
                                                    No Photo
                                                </div>
                                                <div className="caption">
                                                    <div className="caption-group">
                                                        <FontAwesomeIcon icon={['fal', 'camera']} />
                                                        <span>{photoPoint.name}</span>
                                                    </div>
                                                    <div className="caption-group">
                                                        <FontAwesomeIcon icon={['fal', 'compass']} />
                                                        <span>{photoPoint.photoDirection} {photoPoint.compassBearing}&#176;</span>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </Col>
                            ))}
                        </Row>
                    </div>
                ))
            }
            {
                !!getCameraPointsAndPhotos(true).length &&
                    <Fragment>
                        <div className="review-title">Discontinued Photo Points</div>
                        <Table borderless responsive className="streambank-table">
                            <tbody>
                                {
                                    getCameraPointsAndPhotos(true).map(cameraPoint => (
                                        <tr key={cameraPoint.sampleLocationId}>
                                            <td key={1}>Camera Point {cameraPoint.name}</td>
                                            <td key={2}>{renderDiscontinuedTable(cameraPoint)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Fragment>
            }
        </div>
    );
};

export default PhotoPointReviewTable;
