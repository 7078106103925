import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectTransectsWithPlots} from "../../../Redux/Selectors/transectSelectors";
import {Table} from "react-bootstrap";
import {useGetUserFacingSpeciesName} from "../../../Hooks/LabelHooks";
import {
    lengthOfCoverCategories,
    speciesNativityTypes,
    speciesTypes,
    stemTallyCategories,
    treeDBHCategories,
    TYPE_INVASIVE_COVER,
    TYPE_LENGTH_OF_COVER,
    TYPE_STEM_TALLY,
    TYPE_TREE_DBH
} from '../../../Constants/lengthCategories';
import {selectProjectById} from "../../../Redux/Selectors/nodeSelectors";
import {PHOTO_POINT_INSTREAM_SHADE} from "../../../Constants/photos";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectVisiblePhotos} from "../../../Redux/Selectors/photoSelectors";
import ReturnLink from "../../Common/ReturnLink";
import TransectModal from "./TransectModal";
import ArrayUtils from "../../../Utils/ArrayUtils";
import PhotoUtils from "../../../Utils/PhotoUtils";
import {ReviewedPhotoThumbnail} from "./ReviewedPhotoThumbnail";

const QuantitativeVegMonitoringReviewTable = (props) => {
    const {event} = props;

    const getUserFacingSpeciesName = useGetUserFacingSpeciesName();

    const transects = useSelector(state => selectTransectsWithPlots(state));
    const project = useSelector(state => selectProjectById(state, event.projectId));
    const photos = useSelector(state => selectVisiblePhotos(state));

    const [sampleEventTransects, setSampleEventTransects] = useState([]);
    const [hydrozonePlotSpecies, setHydrozonePlotSpecies] = useState([]);
    const [hydrozoneGroundSpecies, setHydrozoneGroundSpecies] = useState([]);
    const [invasiveSpeciesData, setInvasiveSpeciesData] = useState([]);
    const [plotSpeciesData, setPlotSpeciesData] = useState([]);
    const [totalAcres, setTotalAcres] = useState(0);
    const [totalLength, setTotalLength] = useState(0);
    const [transectList, setTransectList] = useState({data: [], invasive: false});

    const ft2PerAcre = 43560;

    useEffect(() => {
        const treeShrubVineSpeciesList = new Set();
        const invasiveHerbaceousSpeciesList = new Set();
        const hydrozonePlotSpeciesData = [];
        const invasiveHerbaceousSpeciesData = [];

        const calculatedTransects = transects.filter(transect => transect.sampleEventId === event.sampleEventId && transect.isActive)
            .map(transect => {
                const transectSqft = transect.TransectPlots.reduce((acc, curr) => (acc + (curr.width * curr.length)), 0);
                const transectLength = transect.TransectPlots.reduce((acc, curr) => (acc + curr.length), 0);
                const transectHydrozonePlotSpecies = [];

                transect?.Hydrozones.forEach(hydrozone => {
                    const hydrozonePlotSpecies = hydrozone?.HydrozonePlotSpecies ?? [];
                    const hydrozoneGroundSpecies = hydrozone?.HydrozoneGroundCoverSpecies ?? [];

                    hydrozonePlotSpecies.forEach(species => {
                        const speciesWithTransect = {
                            ...species,
                            transectId: transect.transectId,
                            transectName: transect.name,
                            hydrozoneTypeId: hydrozone.hydrozoneTypeId,
                        };

                        treeShrubVineSpeciesList.add(species.speciesId);
                        hydrozonePlotSpeciesData.push(speciesWithTransect);
                        transectHydrozonePlotSpecies.push(speciesWithTransect);
                    });

                    hydrozoneGroundSpecies.forEach(species => {
                        if (species.leftCover > 0 || species.rightCover > 0) {
                            const speciesWithTransect = {
                                ...species,
                                transectId: transect.transectId,
                                transectName: transect.name,
                                hydrozoneTypeId: hydrozone.hydrozoneTypeId,
                            };

                            invasiveHerbaceousSpeciesList.add(species.speciesId);
                            invasiveHerbaceousSpeciesData.push(speciesWithTransect);
                        }
                    });
                });

                return {
                    ...transect,
                    transectSqft: transectSqft,
                    transectAcre: transectSqft/ft2PerAcre,
                    transectLength,
                    transectHydrozonePlotSpecies
                };
            })
            .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }));

        setSampleEventTransects(calculatedTransects);
        setHydrozonePlotSpecies(project.Species.filter(species => [...treeShrubVineSpeciesList].includes(species.speciesId)));
        setHydrozoneGroundSpecies(project.Species.filter(species => [...invasiveHerbaceousSpeciesList].includes(species.speciesId)));
        setInvasiveSpeciesData(invasiveHerbaceousSpeciesData);
        setPlotSpeciesData(hydrozonePlotSpeciesData);
        setTotalAcres(calculatedTransects.reduce((acc, curr) => (acc + curr.transectAcre), 0));
        setTotalLength(calculatedTransects.reduce((acc, curr) => (acc + curr.transectLength), 0));
    }, []);

    const roundValue = (value) => {
        if (value === '' || value == null) {
            return null;
        }

        if (Number(value) === 0) {
            return '0';
        }

        if (value < 0.5) {
            return '<1';
        }

        return Math.round(Number(value));
    };

    const getTotalStemCount = (data) => {
        return data.reduce((acc, curr) => {
            return acc + stemTallyCategories.reduce((stemAcc, stemCurr) => {
                const value = curr[stemCurr.code] ?? 0;

                return stemAcc + value;
            }, 0)
        }, 0);
    };

    const getTotalLengthOfCover = (data) => {
        return data.reduce((acc, curr) => {
            return acc + lengthOfCoverCategories.reduce((coverAcc, coverCurr) => {
                const value = curr[coverCurr.code] ?? 0;

                return coverAcc + value;
            }, 0)
        }, 0);
    };

    const getAverageDBHStems = (data, category) => {
        return data.reduce((acc, curr) => {
            const value = curr[category.code] ?? 0;

            return acc + value;
        }, 0);
    };

    const getTransectData = (transect) => {
        const averageStemsPerAcre = (getTotalStemCount(transect.transectHydrozonePlotSpecies) / (transect.transectAcre));
        const averageCover = (getTotalLengthOfCover(transect.transectHydrozonePlotSpecies) / transect.transectLength) * 100;
        return {
            averageStemsPerAcre,
            averageCover
        };
    };

    const getInstreamShadePhoto = (transect) => {
        const photoPoint = transect.PhotoPoints.find(photoPoint => photoPoint.name === PHOTO_POINT_INSTREAM_SHADE);
        const photo = photos.find(photo => photo.photoPointId === photoPoint.photoPointId && photo.sampleEventId === transect.sampleEventId);

        return photo ?? null;
    };

    const getUniqueTransectList = (data) => {
        // Get first hydrozoneTypeId and plotTypeId of data
        const sortedData = ArrayUtils.SortByMultipleCriteria(
            data,
            [
                (a, b) => a.hydrozoneTypeId - b.hydrozoneTypeId,
                (a, b) => a?.plotTypeId - b?.plotTypeId
            ]
        );

        return [...new Set(data.map(({transectId}) => transectId))]
            .map(id => {
                const {transectId, transectName, hydrozoneTypeId, plotTypeId} = sortedData.find(({transectId}) => transectId === id);

                return {
                    transectId,
                    transectName,
                    hydrozoneTypeId,
                    plotTypeId: plotTypeId ?? null, // Invasive ground cover doesn't have plotTypeId
                }
            })
            .sort((a, b) => a.transectName.localeCompare(b.transectName, 'en', { numeric: true }));
    };

    const renderSpeciesTable = (typeId) => {
        return (
            <Table borderless responsive>
                <thead>
                    <tr key="header">
                        <th key={1} className="species-row">Species Name</th>
                        <th key={2}>Avg. stems/acre</th>
                        <th key={3}>Avg. % cover</th>
                    </tr>
                </thead>
                <tbody>
                    {renderSpeciesData(typeId)}
                </tbody>
            </Table>
        );
    };

    const renderSpeciesData = (typeId) => {
        return hydrozonePlotSpecies.filter(species => species.speciesTypeId === typeId)
            .map(species => {
                const data = plotSpeciesData.filter(({speciesId}) => speciesId === species.speciesId);

                // Get unique transect list
                const transectList = getUniqueTransectList(data);
                
                const totalStems = getTotalStemCount(data);
                const totalLengthOfCover = getTotalLengthOfCover(data);

                return {
                    ...species,
                    totalStems,
                    totalLengthOfCover,
                    averageStemsPerAcre: (totalStems / totalAcres),
                    averageLengthOfCover: (totalLengthOfCover / totalLength) * 100,
                    transectList,
                }
            })
            .filter(species => species.averageStemsPerAcre > 0 || species.averageLengthOfCover > 0)
            .map(species => {
                return (
                    <tr key={species.speciesId}>
                        <td key="name" className="species-row">{getUserFacingSpeciesName(species)}</td>
                        <td key="stemsPerAcre">
                            <ReturnLink onClick={() => setTransectList({data: species.transectList, type: TYPE_STEM_TALLY})}>{roundValue(species.averageStemsPerAcre)}</ReturnLink>
                        </td>
                        <td key="averageLengthOfCover">
                            <ReturnLink onClick={() => setTransectList({data: species.transectList, type: TYPE_LENGTH_OF_COVER})}>{roundValue(species.averageLengthOfCover)}</ReturnLink>
                        </td>
                    </tr>
                )
            })
    };

    const renderDBHSpeciesData = () => {
        return hydrozonePlotSpecies.filter(species => {
            const data = plotSpeciesData.filter(data => data.speciesId === species.speciesId);

            // Sum of all categories need to be greater than 0, or else we hide that row
            return data.reduce((acc, curr) => {
                return acc + treeDBHCategories.reduce((dbhAcc, dbhCurr) => {
                    const value = curr[dbhCurr.code] ?? 0;
                    return dbhAcc + value;
                }, 0)
            }, 0) > 0;
        })
        .map(species => {
            const speciesNativity = speciesNativityTypes.find(type => species.speciesNativityId === type.speciesNativityId);
            const data = plotSpeciesData.filter(data => data.speciesId === species.speciesId);

            const transectList = getUniqueTransectList(data);

            return (
                <tr key={species.speciesId}>
                    <td key="name">
                        <ReturnLink onClick={() => setTransectList({data: transectList, type: TYPE_TREE_DBH})}>
                            {getUserFacingSpeciesName(species)}
                        </ReturnLink>
                    </td>
                    <td key="nativity">{speciesNativity?.name[0] ?? "Unknown"}</td>
                    {
                        treeDBHCategories.map(category => (
                            <td key={category.name}>{roundValue(getAverageDBHStems(data, category) / totalAcres)}</td>
                        ))
                    }
                </tr>
            )
        })
    };

    return (
        <div className="mt-3">
            <TransectModal
                transects={transectList.data}
                show={!!transectList.data.length}
                onHide={() => setTransectList({data: [], type: null})}
                type={transectList?.type}
            />
            {
                !!sampleEventTransects.length &&
                    <Fragment>
                        <div className="review-title">Transect Notes</div>
                        <Table borderless responsive className="streambank-table">
                            <thead>
                                <tr key={'header'}>
                                    <th className="transect-name">Transect Name</th>
                                    <th>Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sampleEventTransects.map(transect => (
                                    <tr key={transect.transectId}>
                                        <td className="transect-name">
                                            <ReturnLink path={`/transect/${transect.transectId}/quant-veg`}>{transect.name}</ReturnLink>
                                        </td>
                                        <td>{!!transect.notes ? transect.notes : 'N/A'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="review-title">Tree/Shrub/Woody Vine Species</div>
                        <Table borderless responsive className="streambank-table">
                            <thead>
                                <tr key={'header'}>
                                    <th>Transect Name</th>
                                    <th>Avg. stems/acre</th>
                                    <th>Avg. % cover</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sampleEventTransects.map(transect => (
                                    <tr key={transect.transectId}>
                                        <td>{transect.name}</td>
                                        <td>{roundValue(getTransectData(transect).averageStemsPerAcre)}</td>
                                        <td>{roundValue(getTransectData(transect).averageCover)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Table borderless responsive className="streambank-table">
                            <tbody>
                                {speciesTypes.filter(type => !!renderSpeciesData(type.speciesTypeId).length).map(type => (
                                    <tr key={type.speciesTypeId}>
                                        <td>{type.icon && <FontAwesomeIcon icon={type.icon} />} {type.name}</td>
                                        <td>{renderSpeciesTable(type.speciesTypeId)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="review-title">Tree DBH Size Class Distribution</div>
                        <Table borderless responsive className="streambank-table">
                            <thead>
                                <tr key={'header'}>
                                    <th>Species Name</th>
                                    <th>Nativity</th>
                                    {treeDBHCategories.map(category => (
                                        <th key={category.name}>{category.name} stems/acre</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    renderDBHSpeciesData().length ?
                                        renderDBHSpeciesData() :
                                        <tr key={'none'}>
                                            <td colSpan={treeDBHCategories.length + 2}>No Data</td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                        <div className="review-title">Invasive and Herbaceous Cover</div>
                        <Table borderless responsive className="streambank-table">
                            <thead>
                                <tr key={'header'}>
                                    <th>Species</th>
                                </tr>
                            </thead>
                            <tbody>
                                {hydrozoneGroundSpecies.map(species => {
                                    const speciesData = invasiveSpeciesData.filter(data => data.speciesId === species.speciesId);
                                    const data = getUniqueTransectList(speciesData);

                                    return (
                                        <tr key={species.speciesId}>
                                            <td>
                                                <ReturnLink onClick={() => setTransectList({data, type: TYPE_INVASIVE_COVER})}>{getUserFacingSpeciesName(species)}</ReturnLink>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        <div className="review-title">Canopy Closure</div>
                        <Table borderless responsive className="streambank-table">
                            <thead>
                                <tr key={'header'}>
                                    <th className="transect-name">Transect Name</th>
                                    <th>% canopy closure</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sampleEventTransects.map(transect => (
                                    <tr key={transect.transectId}>
                                        <td className="transect-name">{transect.name}</td>
                                        <td>
                                            <ReturnLink path={`/transect/${transect.transectId}/canopy`}>
                                                {roundValue(transect?.TransectCanopyClosure?.canopyClosure) ?? 'N/A'}
                                            </ReturnLink>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="review-title">Instream Shade</div>
                        <Table borderless responsive className="streambank-table photo-table">
                            <thead>
                                <tr key={'header'}>
                                    <th key={1}>Photo Collected</th>
                                    <th key={2}>Transect Name</th>
                                    <th key={3}>Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sampleEventTransects.map(transect => {
                                        const photo = getInstreamShadePhoto(transect);

                                        return (
                                            <tr key={transect.transectId}>
                                                <td className="review-photos photo-point-row">
                                                    <div className="photo-point-content">
                                                        <ReviewedPhotoThumbnail photoId={photo?.photoId}/>
                                                    </div>
                                                </td>
                                                <td className="transect-name">
                                                    <ReturnLink path={`/transect/${transect.transectId}/instream-shade`}>
                                                        {transect.name}
                                                    </ReturnLink>
                                                </td>
                                                <td>{photo && !!photo.notes ? photo.notes : 'N/A'}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Fragment>
            }
        </div>
    );
};

export default QuantitativeVegMonitoringReviewTable;
