//import React from 'react';
import {default as store} from './Redux/store'
import ApiUtils from "./Utils/ApiUtils";

class initialStore {
    dispatch() {
        console.log('fake dispatch')
    }
    subscribe() {}
}

let _store = store;
let _domain = '';
let _environment = 'prod';
let _axiosInstances = {
    default: null,
    cancellable: null,
    external: null,
};

const config = {
    setValues: ({store, domain, environment}) => {
        _environment = environment;
        _domain = domain;
        _store = store;
    },
    setDomain: domain => _domain = domain,
    getDomain: () => {
        return _domain;
    },
    setEnvironment: environment => _environment = environment,
    getEnvironment: () => {
        return _environment;
    },
    setStore: store => _store = store,
    getStore: () => {
        return _store;
    },
    getUrl: (subdomain, protocol = 'https://') => {
        return protocol + subdomain + '.' + _domain;
    },
    getAdminUrl: (protocol = 'https://') => {
        return config.getUrl('admin', protocol);
    },
    getApiUrl: (protocol = 'https://') => {
        return config.getUrl('api', protocol);
    },
    getLibraryUrl: (protocol = 'https://') => {
        return config.getUrl('library', protocol);
    },
    getMonitoringUrl: (protocol = 'https://') => {
        return config.getUrl('monitoring', protocol);
    },
    setAxiosInstances: (axiosInstances) => {
        _axiosInstances = axiosInstances;
    },
    getAxiosInstances: () => {
        return _axiosInstances;
    }
};

Object.freeze(config);
export default config;
