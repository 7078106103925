import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import {SET_RETURN_PATH} from "../Redux/Actions/Types/appStatusTypes";
import {useMemo} from "react";

export const useReturnLink = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    return (link, title) => {
        dispatch({type: SET_RETURN_PATH, path: window.location.pathname, title});
        history.push(link);
    }
};

export const useReturnToReview = () => {
    const returnLink = useReturnLink();

    return (link) => returnLink(link, 'Review & Finalize');
};

// A custom hook that builds on useLocation to parse
// the query string for you.
// WARNING: BE CAREFUL PASSING BOOLEANS AS QUERY PARAMETERS
// All query params come through as strings and "0" and "false"
// both evaluate to true!
export function useQueryParams() {
    const { search } = useLocation();
    return useMemo(() => Object.fromEntries(new URLSearchParams(search)), [search]);
}

export function useSetQueryParams() {
    const history = useHistory();
    return (newParams) => {
        newParams = Object.fromEntries(
            Object.entries(newParams).filter(
                ([key, value]) => value != null
            )
        );
        history.replace({
            search: (new URLSearchParams(newParams)).toString()
        });
    }
}

export function usePatchQueryParams() {
    const setQueryParams = useSetQueryParams();
    const existingParams = useQueryParams();
    return (newParams) => {
        return setQueryParams({
            ...existingParams,
            ...newParams,
        });
    }
}
