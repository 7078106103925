import {SET_CONTEXT_TOOLS} from "../Actions/Types/toolActionTypes";
import {USER_LOGOUT} from "../Actions/Types/userActionTypes";
import {RESTORE_BACKUP} from "../Actions/Types/offlineDataActionTypes";

const initialState = {
    contextTools: [],
};

const toolReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONTEXT_TOOLS:
            return {
                ...state,
                contextTools: action.tools,
            };
        case USER_LOGOUT:
            return initialState;
        case RESTORE_BACKUP:
            return action.data.toolState;
        default:
            return state;
    }
};

export default toolReducer;
