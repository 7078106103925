import React from 'react';
import {Container, Modal as BootstrapModal, ModalTitle} from 'react-bootstrap';

const Modal = props => {
    return (
        <BootstrapModal
            show={props.show}
            size={props.size || 'sm'}
            onHide={() => props.onHide()}
            onExited={() => props.onExited ? props.onExited() : null}
            className={`streambank-modal ${props.className || ''}`}
            backdrop={props.static ? 'static' : (props.backdrop ?? true)}
            centered={props.centered !== undefined ? props.centered : true}
        >
            <Container>
                {
                    props.title &&
                    <BootstrapModal.Header closeButton={!props.hideCloseButton}>
                        <ModalTitle>
                            {props.title}
                        </ModalTitle>
                    </BootstrapModal.Header>
                }
                {
                    props.body &&
                    <BootstrapModal.Body>
                        {props.body}
                    </BootstrapModal.Body>
                }
                {props.children}
                {
                    props.footer &&
                    <BootstrapModal.Footer>
                        {props.footer}
                    </BootstrapModal.Footer>
                }
            </Container>
        </BootstrapModal>
    )
};

export default Modal;
