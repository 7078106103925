import {CLEAR_ERRORS, SET_CONFIRM, SET_ERROR, SET_UNKNOWN_ERROR} from "../Actions/Types/errorActionTypes";
import {USER_LOGOUT} from "../Actions/Types/userActionTypes";
import {RESTORE_BACKUP} from "../Actions/Types/offlineDataActionTypes";

const initialState = {
    error: null,
    unknownError: false,
    confirm: null,
};

const errorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case SET_UNKNOWN_ERROR:
            return {
                ...state,
                unknownError: true,
                error: action.error,
            };
        case SET_CONFIRM:
            return {
                ...state,
                confirm: action.confirm,
            };
        case USER_LOGOUT:
        case CLEAR_ERRORS:
            return initialState;
        case RESTORE_BACKUP:
            return action.data.errorState;
        default:
            return state;
    }
};

export default errorReducer;
