import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ReactivateIcon = props => {
    return (
        <span className="fa-stack">
            <FontAwesomeIcon icon={['fal', 'certificate']} className="fa-stack-2x" transform="shrink-1" />
            <FontAwesomeIcon icon={['fal', 'fist-raised']} className="fa-stack-2x" transform="shrink-6 left-7 rotate-90 flip-v"/>
            <FontAwesomeIcon icon={['fal', 'fist-raised']} className="fa-stack-2x" transform="shrink-6 right-7 rotate-270"/>
        </span>
    );
};

export default ReactivateIcon;
