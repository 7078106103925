import React, {useEffect, useState} from 'react';
import storage from "localforage";

import {Button, Col, ModalBody, ModalFooter, Row} from "react-bootstrap";
import Modal from "../../Common/Modal";
import FormInput from "../../Common/FormInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Base64Utils from "../../../Utils/Base64Utils";
import FileUtils from "../../../Utils/FileUtils";

const ProcedureModal = props => {
    const [selectedProcedureId, setSelectedProcedureId] = useState('');

    const selectedProcedure = props.procedures.find(procedure => procedure.procedureId === Number(selectedProcedureId));

    useEffect(() => {
        setSelectedProcedureId(getDefaultProcedureId());
    }, []);


    const getDefaultProcedureId = () => {
        if (props.procedures.length) {
            const procedure = props.procedures.find(procedure => procedure.selected);

            if (procedure) {
                return procedure.procedureId;
            }

            return props.procedures[0].procedureId;
        }

        return '';
    };

    const downloadPdf = async () => {
        const path = selectedProcedure.documentURL.split('/api/').pop();
        const data = await storage.getItem(`/api/${path}`);
        const blob = FileUtils.EnsureBlob(data, 'application/pdf');
        FileUtils.DownloadBlobAsFile(blob, `${selectedProcedure.name}`);
    };

    return (
        <Modal
            title="Procedure"
            className="procedure-modal"
            size="md"
            show={props.show}
            onHide={() => props.onHide()}
        >
            <ModalBody>
                <Row xs={12}>
                    <Col xs={12}>
                        <FormInput
                            label="Choose"
                            type="select"
                            placeholder=""
                            value={selectedProcedureId}
                            options={props.procedures.map(({procedureId, name}) => ({id: procedureId, name}))}
                            onChange={(value) => setSelectedProcedureId(value)}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {
                    selectedProcedure?.documentURL ?
                    <Button key={1} variant="complete" className="ml-3" onClick={() => downloadPdf()}>
                        <FontAwesomeIcon icon={['fas', 'external-link']}/>
                        View Procedure (PDF)
                    </Button> :
                    <Button key={2} variant="secondary" className="ml-3" disabled>
                        Document Not Available
                    </Button>
                }
            </ModalFooter>
        </Modal>
    )
};

export default ProcedureModal;
