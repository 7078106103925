import {UPSERT_QUAL_VEG_MONITORING} from "./Types/offlineDataActionTypes";

// this is one of the few places to call UPSERT_QUAL_VEG_MONITORING action
// without the use of upsertQualitativeVegetationMonitoring because this
// method is specifically used to process downloaded data, and we don't want
// to trigger an update to modified dates or duplicate photos in this case
export const upsertQualitativeVegetationMonitorings = (qualitativeVegetationMonitorings, download) => (dispatch, getState) => {
	if (qualitativeVegetationMonitorings?.length) {
		qualitativeVegetationMonitorings.forEach(qualitativeVegetationMonitoring => {
			dispatch({type: UPSERT_QUAL_VEG_MONITORING, qualitativeVegetationMonitoring, download});
		})
	}
}
