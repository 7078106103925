import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom'

import PageHeader from "../../Layout/Page/PageHeader";
import Title from "../../Layout/TitleComponent";
import PageContent from "../../Layout/Page/PageContent";
import {FormCheck, Table} from "react-bootstrap";
import PageFooter from "../../Layout/Page/PageFooter";
import PageContainer from "../../Layout/Page/PageContainer";
import FooterNav from "../../Layout/Page/FooterNav";
import {useTransectReviewNav} from "../../../Hooks/QuickNavHooks";
import {selectTransect} from "../../../Redux/Selectors/transectSelectors";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import {useContextToolsBySampleEventId} from "../../../Hooks/ToolHooks";
import {updateTransectReview} from "../../../Redux/Actions/transectActions";
import {useTransectToolProgress} from "../../../Hooks/ProgressHooks";
import {useFinalized} from "../../../Hooks/DataHooks";
import ReturnButton from "../../Common/ReturnButton";
import {selectSampleEventById} from "../../../Redux/Selectors/nodeSelectors";
import {TRANSECT_REVIEW_CODE_NARRATIVE} from "../../../Constants/transectReview";

const TransectNarrativeReviewPage = props => {
    const dispatch = useDispatch();
    const params = useParams();
    const transectToolProgress = useTransectToolProgress();

    useTransectReviewNav(params.transectId);

    const transect = useSelector(state => selectTransect(state, params.transectId));
    const sampleEvent = useSelector(state => selectSampleEventById(state, transect.sampleEventId));
    const user = useSelector(state => state.userState.user);

    useBreadcrumbs('Transect Review', transect.projectId, transect.sampleEventId, transect.transectId);
    useContextToolsBySampleEventId(transect.sampleEventId);
    const finalized = useFinalized(transect.sampleEventId);

    const {TransectNarrativeIrrigation, TransectNarrativeBrowse, TransectNarrativeVigor, speciesProtected, competingVegetationNotes, wildlifeSightings, managementRecommendations} = transect?.TransectNarrative ?? {};
    const toolCode = 'transect-narrative';
    const reviewCode = TRANSECT_REVIEW_CODE_NARRATIVE;
    const toolProgress = transectToolProgress(transect, [toolCode])[0];

    const isConfirmed = () => {
        return transect.TransectReviews.find(review => review.code === reviewCode)?.isConfirmed ?? false;
    };

    const renderTable = () => {
        if (!transect.TransectNarrative) {
            return (
                <Table borderless responsive className="streambank-table half-table">
                    <tbody>
                        <tr>
                            <td>TransectNarrative has not been started.</td>
                        </tr>
                    </tbody>
                </Table>
            );
        }

        return <Table borderless responsive className="streambank-table half-table">
            <tbody>
                <tr>
                    <td>Transect Name</td>
                    <td>{transect.name}</td>
                </tr>
                <tr>
                    <td>Current Management and Species Protected</td>
                    <td>{speciesProtected}</td>
                </tr>
                <tr>
                    <td>Browse</td>
                    <td><ol>{TransectNarrativeBrowse.map((item, i) => <li key={i}>{item.animalSpecies} - {item.plantSpecies} (severity: {item.browseSeverity})</li>)}</ol></td>
                </tr>
                <tr>
                    <td>Irrigation</td>
                    <td><ol>{TransectNarrativeIrrigation.map((item, i) => <li key={i}>{item.irrigationMethod} ({item.notes})</li>)}</ol></td>
                </tr>
                <tr>
                    <td>Plant Vigor</td>
                    <td><ol>{TransectNarrativeVigor.map((item, i) => <li key={i}>{item.plantSpecies} - {item.overallVigor} ({item.suggestedCauses})</li>)}</ol></td>
                </tr>
                <tr>
                    <td>Competing Veg Notes</td>
                    <td>{competingVegetationNotes}</td>
                </tr>
                <tr>
                    <td>Wildlife Sightings</td>
                    <td>{wildlifeSightings}</td>
                </tr>
                <tr>
                    <td>Transect Notes</td>
                    <td>{transect.notes}</td>
                </tr>
                <tr>
                    <td>Site Notes</td>
                    <td>{sampleEvent.siteNotes}</td>
                </tr>
                <tr>
                    <td>Management Recommendations</td>
                    <td>{managementRecommendations}</td>
                </tr>
            </tbody>
        </Table>;
    };

    return (
        <PageContainer className="transect-narrative-review-page">
            <PageHeader>
                <Title
                    title="Transect Review"
                    subtitle="Transect Narrative Details"
                    action={<ReturnButton path={`/transect/${transect.transectId}/transect-narrative`} isTransectReview readOnly={finalized} />}
                />
            </PageHeader>
            <PageContent>
                {renderTable()}
            </PageContent>
            <PageFooter>
                <FooterNav>
                    <div className="complete-checkbox">
                        <FormCheck
                            id="complete-checkbox"
                            label="Confirm"
                            type="checkbox"
                            value={isConfirmed()}
                            checked={isConfirmed()}
                            onChange={() => dispatch(updateTransectReview(transect, reviewCode, !isConfirmed(), user.userId))}
                            disabled={finalized}
                        />
                    </div>
                </FooterNav>
            </PageFooter>
        </PageContainer>
    )
};

export default TransectNarrativeReviewPage;
