import React from 'react';
import {Col, Row} from "react-bootstrap";
import HydrozoneSpeciesRow from "./HydrozoneSpeciesRow";
import {TYPE_INVASIVE_COVER} from "../../../Constants/lengthCategories";

const HydrozoneSpeciesList = props => {
    const typeClass = `type-${props.type}`;
    const isInvasiveCover = props.type === TYPE_INVASIVE_COVER;

    return <div className={`hydrozone-species-list ${typeClass}`}>
        <Row xs={12} className="species-labels">
            <Col xs={isInvasiveCover ? 6 : 3}>
                <span>Species</span>
            </Col>
            <Col>
                <Row xs={12}>
                    {
                        props.categories.map(category => (
                            <Col key={category.id}>
                                <span>{category.name}</span>
                            </Col>
                        ))
                    }
                </Row>
            </Col>
        </Row>
        {
            props.hydrozoneSpecies.map(item => (
                <HydrozoneSpeciesRow
                    key={item.speciesName ?? item.speciesId}
                    item={item}
                    species={props.species}
                    type={props.type}
                    categories={props.categories}
                    plotLength={props.plotLength}
                    disabled={props.disabled}
                />
            ))
        }
    </div>;
};

export default HydrozoneSpeciesList;
