export default class IconUtils {
    static GetIcon(code, type = 'fal', defaultIcon = null) {
        switch (code) {
            case 'quant-veg':
                return [type, "tree-alt"];
            case 'qual-veg':
                return [type, "clipboard-list"];
            case 'photo':
                return [type, "camera"];
            case 'instream-shade':
                return [type, "sun"];
            default:
                return defaultIcon;
        }
    }
}
