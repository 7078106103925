import {
    UPDATE_VERIFICATION_FIELD_VALUE,
    TIMER_TICK,
    TIMER_START,
    SET_LOGIN_ERROR,
    SET_PHONE_NUMBER,
    VERIFICATION_SUCCESS,
    SET_TITLE,
    VERIFICATION_START,
    VERIFICATION_CANCEL,
    SET_SESSION_TIMEOUT, SET_LOADING
} from "../Actions/Types/loginActionTypes";
import {USER_LOGOUT} from "../Actions/Types/userActionTypes";
import {RESTORE_BACKUP} from "../Actions/Types/offlineDataActionTypes";

const initialState = {
    isVerifying: false,
    displayPhoneNumberForm: true,
    timer: 0,
    title: '',
    displayTimeout: false,
    fields: {
        email: '',
        password: '',
        mobileNumber: '',
        code: '',
        rememberMe: false,
        maskedNumber: '',
    },
    errors: {
        login: '',
        sendCode: '',
        verify: '',
    },
    loading: false,
};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_VERIFICATION_FIELD_VALUE:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.field]: action.value,
                },
            };
        case SET_TITLE:
            return {
                ...state,
                title: action.title,
            };
        case SET_PHONE_NUMBER:
            return {
                ...state,
                displayPhoneNumberForm: false,
                title: 'Confirmation',
                fields: {
                    ...state.fields,
                    maskedNumber: action.maskedNumber,
                },
                errors: initialState.errors,
            };
        case SET_SESSION_TIMEOUT:
            return {
                ...state,
                displayTimeout: action.value,
            };
        case VERIFICATION_START:
            return {
                ...state,
                isVerifying: true,
            };
        case TIMER_START:
            return {
                ...state,
                timer: 30,
            };
        case TIMER_TICK:
            return {
                ...state,
                timer: state.timer - 1,
            };
        case SET_LOGIN_ERROR:
            // Clear other errors and set new error
            return {
                ...state,
                errors: {
                    ...initialState.errors,
                    [action.field]: action.errorMessage,
                },
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        case VERIFICATION_CANCEL:
        case VERIFICATION_SUCCESS:
        case USER_LOGOUT:
            return {
                ...initialState,
                fields: {
                    ...initialState.fields,
                    email: state.fields.email
                }
            };
        case RESTORE_BACKUP:
            return action.data.loginState;
        default:
            return state;
    }
};

export default loginReducer;
