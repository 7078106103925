import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom'

import PageHeader from "../../Layout/Page/PageHeader";
import Title from "../../Layout/TitleComponent";
import PageContent from "../../Layout/Page/PageContent";
import {Col, OverlayTrigger, Popover, Row} from "react-bootstrap";
import PageFooter from "../../Layout/Page/PageFooter";
import PageContainer from "../../Layout/Page/PageContainer";
import FooterNav from "../../Layout/Page/FooterNav";
import {useQuantVegNav} from "../../../Hooks/QuickNavHooks";
import {selectTransect} from "../../../Redux/Selectors/transectSelectors";
import RepeatableSection from "../../Common/RepeatableSection";
import {useCreateTransectNarrative} from "../../../Hooks/DataCreationHooks";
import {
    addTransectNarrativeItem,
    deleteTransectNarrativeItem,
    updateTransectNarrativeItem,
    updateTransectNarrativeValue
} from "../../../Redux/Actions/transectActions";
import FormInput from "../../Common/FormInput";
import {UPSERT_SAMPLE_EVENT, UPSERT_TRANSECT} from "../../../Redux/Actions/Types/offlineDataActionTypes";
import {selectSampleEventById} from "../../../Redux/Selectors/nodeSelectors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import {useContextToolsBySampleEventId} from "../../../Hooks/ToolHooks";
import {useReadOnly} from "../../../Hooks/FormHooks";
import {useFinalized} from "../../../Hooks/DataHooks";


const TransectNarrativePage = props => {
    const params = useParams();
    const dispatch = useDispatch();

    useQuantVegNav(params.transectId);
    useCreateTransectNarrative(params.transectId);

    const transect = useSelector(state => selectTransect(state, params.transectId));
    const sampleEvent = useSelector(state => selectSampleEventById(state, transect.sampleEventId));

    useBreadcrumbs(`Transect Narrative`, transect.projectId, transect.sampleEventId, transect.transectId);
    useContextToolsBySampleEventId(transect.sampleEventId);

    const finalized = useFinalized(transect.sampleEventId);
    useReadOnly(finalized);

    return (
        <PageContainer className="transect-narrative-page">
            <PageHeader>
                <Title title="Transect Narrative" readOnly={finalized} />
            </PageHeader>
            <PageContent>
                <Row xs={12}>
                    <Col xs={12}>
                        <h2 className="first-header">Browse</h2>
                    </Col>
                    <Col xs={5}>
                        <FormInput
                            label="Current Management and Species Protected"
                            value={transect?.TransectNarrative?.speciesProtected ?? ''}
                            onChange={value => dispatch(updateTransectNarrativeValue(transect.transectId, 'speciesProtected', value))}
                        />
                    </Col>
                    <Col xs={12}>
                        <RepeatableSection
                            name="Browse"
                            data={transect?.TransectNarrative?.TransectNarrativeBrowse}
                            fields={[
                                {
                                    label: 'Browse Animal(s)',
                                    code: 'animalSpecies',
                                    type: 'select',
                                    options: ['deer', 'elk', 'cow', 'unk. ungulate', 'beaver', 'insect', 'other rodent', 'unknown']
                                        .map(option => ({id: option, name: option}))
                                },
                                {
                                    label: 'Plant Species Targeted',
                                    code: 'plantSpecies',
                                    type: 'text',
                                },
                                {
                                    label: 'Overall Browse Severity',
                                    code: 'browseSeverity',
                                    type: 'select',
                                    options: ['Severe', 'Medium', 'Low'].map(option => ({id: option, name: option})),
                                    overlayIcon: (
                                        <OverlayTrigger
                                            trigger="click"
                                            key="top"
                                            placement="top"
                                            overlay={
                                                <Popover>
                                                    <Popover.Content>
                                                        <li>Low: &lt;1/3 plant missing, unlikely to impact future growth.</li>
                                                        <li>Med: &gt;1/3 plant missing, future growth likely retarded.</li>
                                                        <li>Severe: &gt;1/3 plant missing, severe impact/fatal.</li>
                                                    </Popover.Content>
                                                </Popover>
                                            }
                                            rootClose
                                        >
                                            <FontAwesomeIcon icon={['fas', 'info-circle']} />
                                        </OverlayTrigger>
                                    )
                                }
                            ]}
                            colSize={4}
                            addItem={() => dispatch(addTransectNarrativeItem(transect.transectId, 'TransectNarrativeBrowse'))}
                            updateItem={(index, column, value) => dispatch(updateTransectNarrativeItem(transect.transectId, 'TransectNarrativeBrowse', index, column, value))}
                            deleteItem={(index) => dispatch(deleteTransectNarrativeItem(transect.transectId, 'TransectNarrativeBrowse', index))}
                            readOnly={finalized}
                        />
                    </Col>
                    <Col xs={12}>
                        <h2>Irrigation</h2>
                        <RepeatableSection
                            name="Irrigation"
                            data={transect?.TransectNarrative?.TransectNarrativeIrrigation}
                            fields={[
                                {
                                    label: 'Current Irrigation Method',
                                    code: 'irrigationMethod',
                                    type: 'select',
                                    options: ['Overhead', 'Drip', 'Flood', 'None', 'Other (See notes)'].map(option => ({id: option, name: option}))
                                },
                                {
                                    label: 'Notes',
                                    code: 'notes',
                                    type: 'textarea'
                                }
                            ]}
                            colSize={6}
                            addItem={() => dispatch(addTransectNarrativeItem(transect.transectId, 'TransectNarrativeIrrigation'))}
                            updateItem={(index, column, value) => dispatch(updateTransectNarrativeItem(transect.transectId, 'TransectNarrativeIrrigation', index, column, value))}
                            deleteItem={(index) => dispatch(deleteTransectNarrativeItem(transect.transectId, 'TransectNarrativeIrrigation', index))}
                            readOnly={finalized}
                        />
                    </Col>
                    <Col xs={12}>
                        <h2>Plant Vigor</h2>
                        <RepeatableSection
                            name="Plant Vigor"
                            data={transect?.TransectNarrative?.TransectNarrativeVigor}
                            fields={[
                                {
                                    label: 'Plant Species',
                                    code: 'plantSpecies',
                                    type: 'text',
                                },
                                {
                                    label: 'Overall Vigor',
                                    code: 'overallVigor',
                                    type: 'select',
                                    options: ['Excellent', 'Satisfactory', 'Poor'].map(option => ({id: option, name: option}))
                                },
                                {
                                    label: 'Suspected Causes of Vigor',
                                    code: 'suggestedCauses',
                                    type: 'text',
                                }
                            ]}
                            colSize={4}
                            addItem={() => dispatch(addTransectNarrativeItem(transect.transectId, 'TransectNarrativeVigor'))}
                            updateItem={(index, column, value) => dispatch(updateTransectNarrativeItem(transect.transectId, 'TransectNarrativeVigor', index, column, value))}
                            deleteItem={(index) => dispatch(deleteTransectNarrativeItem(transect.transectId, 'TransectNarrativeVigor', index))}
                            readOnly={finalized}
                        />
                    </Col>
                    <Col xs={6}>
                        <h2>Competing Vegetation</h2>
                        <FormInput
                            label="Notes"
                            type="textarea"
                            value={transect?.TransectNarrative?.competingVegetationNotes ?? ''}
                            onChange={value => dispatch(updateTransectNarrativeValue(transect.transectId, 'competingVegetationNotes', value))}
                        />
                    </Col>
                    <Col xs={6}>
                        <h2>Wildlife Sightings</h2>
                        <FormInput
                            label="Notes"
                            type="textarea"
                            value={transect?.TransectNarrative?.wildlifeSightings ?? ''}
                            onChange={value => dispatch(updateTransectNarrativeValue(transect.transectId, 'wildlifeSightings', value))}
                        />
                    </Col>
                    <Col xs={6}>
                        <h2>Transect Notes</h2>
                        <FormInput
                            label="Notes"
                            type="textarea"
                            value={transect?.notes ?? ''}
                            onChange={value => dispatch({type: UPSERT_TRANSECT, transect: {...transect, notes: value}})}
                        />
                    </Col>
                    <Col xs={6}>
                        <h2>Site Notes</h2>
                        <FormInput
                            label="Notes"
                            type="textarea"
                            value={sampleEvent.siteNotes ?? ''}
                            onChange={value => dispatch({type: UPSERT_SAMPLE_EVENT, sampleEvent: {...sampleEvent, siteNotes: value}})}
                        />
                    </Col>
                    <Col xs={6}>
                        <h2>Management Recommendations</h2>
                        <FormInput
                            label="Notes"
                            type="textarea"
                            value={transect?.TransectNarrative?.managementRecommendations ?? ''}
                            onChange={value => dispatch(updateTransectNarrativeValue(transect.transectId, 'managementRecommendations', value))}
                        />
                    </Col>
                </Row>
            </PageContent>
            <PageFooter>
                <FooterNav />
            </PageFooter>
        </PageContainer>
    )
};

export default TransectNarrativePage;
