export const photoDirections = [
    { id: '', name: 'None' },
    { id: 'Upstream', name: 'Upstream' },
    { id: 'Downstream', name: 'Downstream' },
    { id: 'Across', name: 'Across' },
    { id: 'Inland', name: 'Inland' },
    { id: 'Vernal Pool', name: 'Vernal Pool' },
    { id: 'Irrigation Upgrade', name: 'Irrigation Upgrade' },
    { id: 'Other', name: 'Other' },
];

export const PHOTO_POINT_TYPE_ORIGIN = 'A';
export const PHOTO_POINT_TYPE_END = 'B';
export const PHOTO_POINT_INSTREAM_SHADE = 'C';
export const PHOTO_POINT_UPSTREAM = 'D';
export const PHOTO_POINT_DOWNSTREAM = 'E';

export const transectPhotoPoints = [
    {name: PHOTO_POINT_TYPE_ORIGIN, photoDirection: 'Origin'},
    {name: PHOTO_POINT_TYPE_END, photoDirection: 'End'},
    {name: PHOTO_POINT_INSTREAM_SHADE, photoDirection: 'Instream Shade'},
    {name: PHOTO_POINT_UPSTREAM, photoDirection: 'Upstream'},
    {name: PHOTO_POINT_DOWNSTREAM, photoDirection: 'Downstream'},
];
