import {
    HYDROZONE_TYPE_BANK,
    HYDROZONE_TYPE_OVERBANK, HYDROZONE_TYPE_TOE,
    HYDROZONE_TYPE_TRANSITION,
    HYDROZONE_TYPE_UPLAND
} from "../Constants/hydrozones";
import {TYPE_LENGTH_OF_COVER, TYPE_STEM_TALLY, TYPE_TREE_DBH} from "../Constants/lengthCategories";

export const useHydrozoneName = () => {
    return (hydrozoneId) => {
        switch (Number(hydrozoneId)) {
            case HYDROZONE_TYPE_UPLAND:
                return 'Upland';
            case HYDROZONE_TYPE_TRANSITION:
                return 'Transition';
            case HYDROZONE_TYPE_OVERBANK:
                return 'Overbank';
            case HYDROZONE_TYPE_BANK:
                return 'Bank';
            case HYDROZONE_TYPE_TOE:
                return 'Toe';
            default:
                return '';
        }
    }
};

export const useHydrozoneSpeciesTypeName = () => {
    return (hydrozoneSpeciesType) => {
        switch (hydrozoneSpeciesType) {
            case TYPE_STEM_TALLY:
                return 'Stem Tally';
            case TYPE_LENGTH_OF_COVER:
                return 'Length of Cover';
            case TYPE_TREE_DBH:
                return 'Tree DBH';
        }
    }
};

export const useGetUserFacingSpeciesName = () => {
    return (species) => {
        if (!species) {
            return null;
        }

        const commonName = species?.alternativeCommonName ?? species.commonName;
        const latinName = species?.alternativeLatinName ?? species.latinName;

        if (commonName === latinName) {
            return species.commonName;
        }

        return `${commonName}${latinName ? ` (${latinName})` : ''}`;
    };
};

export const useGetSpeciesById = () => {
    return (speciesList, speciesId) => {
        if (!speciesList || !speciesId) {
            return null;
        }

        const species = speciesList.find(x => String(x.speciesId) === String(speciesId));
        return species;
    };
}

export const useGetUserFacingSpeciesNameById = () => {
    const getSpeciesById = useGetSpeciesById();
    const getUserFacingSpeciesName = useGetUserFacingSpeciesName();
    return (speciesList, speciesId) => {
        const species = getSpeciesById(speciesList, speciesId);
        return getUserFacingSpeciesName(species);
    };
};

export const useSpeciesIdByUserFacingName = () => {
    const getUserFacingSpeciesName = useGetUserFacingSpeciesName();
    return (speciesList, speciesName) => {
        if (!speciesList || !speciesName) {
            return null;
        }
        
        const species = speciesList.find(x => String(getUserFacingSpeciesName(x)) === speciesName);
        return species?.speciesId;
    };
};

export const formatNotesLabel = (notesValue) => {
    return ((notesValue ?? '') === '') ? 'N/A' : notesValue;
}
