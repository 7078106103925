import React, {Fragment} from 'react';
import {Col, FormGroup, FormLabel, Row} from "react-bootstrap";

const CameraPointRow = ({item, thin}) => {
    return (
        <div className="camera-point-details">
            <Row xs={12}>
                {
                    thin ?
                        <Fragment>
                            <Col xs={3}>
                                <div>
                                    <span className="font-weight-bold">Bank: </span>
                                    <span>{item.bank || 'N/A'}</span>
                                </div>
                                <div>
                                    <span className="font-weight-bold">Physical Marker: </span>
                                    <span>{item.physicalMarker}</span>
                                </div>
                            </Col>
                            <Col xs={3}>
                                <div>
                                    <span className="font-weight-bold">Latitude: </span>
                                    <span>{item.latitude}&#176; N</span>
                                </div>
                                <div>
                                    <span className="font-weight-bold">Longitude: </span>
                                    <span>{item.longitude}&#176; E</span>
                                </div>
                            </Col>
                        </Fragment> :
                        <Col xs={6}>
                            <Row xs={12}>
                                <Col xs={6}>
                                    <div>
                                        <FormGroup>
                                            <FormLabel>Bank</FormLabel>
                                            <div>{item.bank || 'N/A'}</div>
                                        </FormGroup>
                                    </div>
                                    <div>
                                        <FormGroup>
                                            <FormLabel>Physical Marker</FormLabel>
                                            <div>{item.physicalMarker}</div>
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div>
                                        <FormGroup>
                                            <FormLabel>Latitude</FormLabel>
                                            <div>{item.latitude}&#176; N</div>
                                        </FormGroup>
                                    </div>
                                    <div>
                                        <FormGroup>
                                            <FormLabel>Longitude</FormLabel>
                                            <div>{item.longitude}&#176; E</div>
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                }
                <Col xs={6}>
                    <div>
                        <FormGroup>
                            <FormLabel>Notes</FormLabel>
                            <div className="camera-point-notes">{item.notes}</div>
                        </FormGroup>
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default CameraPointRow;
