import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SET_CONNECTION} from "../Redux/Actions/Types/appStatusTypes";

export const useConnectionStatus = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        updateStatus();
        window.addEventListener('online', updateStatus);
        window.addEventListener('offline', updateStatus);
        return () => {
            window.removeEventListener('online', updateStatus);
            window.removeEventListener('offline', updateStatus);
        }
    }, []);

    const updateStatus = () => {
        // TODO: Also check if there are any changes
        if (navigator.onLine) {
            dispatch({type: SET_CONNECTION, online: true});
        } else {
            dispatch({type: SET_CONNECTION, online: false});
        }
    };
};
