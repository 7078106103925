import React from 'react';
import {Button} from "react-bootstrap";
import {useFooterNavLinks} from "../../../Hooks/QuickNavHooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

const FooterNav = props => {
    const {previous, next} = useFooterNavLinks();

    const renderPreviousButton = () => {
        if (!previous) {
            return (
                <Button variant="secondary" disabled>
                    <FontAwesomeIcon icon={['fal', 'angle-left']} transform="grow-4" />
                    Previous
                </Button>
            )
        }

        return (
            <Link to={previous.path}>
                <Button variant="action" className={previous.className || ''}>
                    <FontAwesomeIcon icon={['fal', 'angle-left']} transform="grow-4" />
                    {previous?.footerNavTitle || previous?.shortName || previous?.title}
                </Button>
            </Link>
        )
    };

    const renderNextButton = () => {
        if (!next) {
            return (
                <Button variant="secondary" disabled>
                    Next
                    <FontAwesomeIcon icon={['fal', 'angle-right']} transform="grow-4" />
                </Button>
            )
        }

        return (
            <Link to={next.path}>
                <Button variant="action" className={next.className || ''}>
                    {next?.footerNavTitle || next?.shortName || next?.title}
                    <FontAwesomeIcon icon={['fal', 'angle-right']} transform="grow-4" />
                </Button>
            </Link>
        )
    };

    return (
        <div className="footer-nav">
            <div className="footer-item">
                {renderPreviousButton()}
            </div>
            <div className="footer-item">
                {props.children}
            </div>
            <div className="footer-item">
                {renderNextButton()}
            </div>
        </div>
    )
};

export default FooterNav;
