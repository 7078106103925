import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import NoSleep from "@uriopass/nosleep.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {ModalBody, ModalFooter, Button, Row, Col, ProgressBar, Spinner} from 'react-bootstrap';
import Modal from '../../Common/Modal';
import SizeUtils from "../../../Utils/SizeUtils";
import {
    ABORT_SYNC, DISABLE_NO_SLEEP,
    ENABLE_NO_SLEEP,
    HIDE_SYNC_DIALOG,
    RESET_PROGRESS
} from "../../../Redux/Actions/Types/syncStatusTypes";

const noSleep = new NoSleep();

const SyncModal = props => {
    const dispatch = useDispatch();

    const {show, enableNoSleep, checkingForData, step, process, uploadProgress, downloadProgress, completedProgress, totalProgress, syncErrors, failedPhotoUploads, failedPhotoDeletes} = useSelector(state => state.syncState);

    const [progress, setProgress] = useState(0);
    const [progressText, setProgressText] = useState('');
    const [total, setTotal] = useState(0);

    useEffect(() => {
        hide();
    }, []);

    useEffect(() => {
        // disable noSleep when the sync
        // dialog is dismissed
        if(enableNoSleep) {
            noSleep.enable();
        } else if(noSleep.isEnabled) {
            noSleep.disable();
        }
    }, [enableNoSleep]);

    // this effect feels like a gross hack to reset the current step
    // progress when the step changes. It could probably be improved
    // by moving this progress out of local state and into the redux
    // syncState and resetting it in the reducer when steps change
    useEffect(() => {
        setProgress(0);
    }, [completedProgress, totalProgress]);

    useEffect(() => {
        const calculatedProgress = ((completedProgress+(progress/100)) / totalProgress) * 100;
        setTotal(Math.min(calculatedProgress, 100));
    }, [progress]);
    
    const syncHadErrors = (syncErrors.length || failedPhotoDeletes || failedPhotoUploads);

    const title = () => {
        const syncingTitle = (
            <span>
                <Spinner animation="border" size="sm" />
                Syncing...
            </span>
        );

        if (total < 100) {
            return syncingTitle;
        }

        if (checkingForData) {
            return syncingTitle;
        }

        if (syncHadErrors) {
            return <span>
                {checkingForData && <Spinner animation="border" size="sm" />}
                Sync Completed with Errors
            </span>
        }

        return <span>
            <FontAwesomeIcon icon={['fal', 'check-circle']} className="success" />
            Sync Successful!
        </span>;
    };

    const renderErrors = (errors) => {
        return errors.map((error, i) => (
            <li key={i}>
                {error.title}
                {
                    error?.children?.length ? <ol>{renderErrors(error.children)}</ol> : null
                }
            </li>
        ))
    };

    useEffect(() => {
        if (process === 'Finished') {
            setProgressText('');
            setProgress(100);
        } else if (step === 'Uploading') {
            switch (process) {
                case 'Projects':
                    const projectProgress = (uploadProgress.completedProjects / uploadProgress.totalProjects) * 100;
                    setProgressText(`${uploadProgress.completedProjects} / ${uploadProgress.totalProjects}`);
                    setProgress(projectProgress);
                    break;
                case 'Monitoring Events':
                    const eventProgress = (uploadProgress.completedEvents / uploadProgress.totalEvents) * 100;
                    setProgressText(`${uploadProgress.completedEvents} / ${uploadProgress.totalEvents}`);
                    setProgress(eventProgress);
                    break;
                case 'Photos':
                    const photoProgress = (uploadProgress.completedPhotos / uploadProgress.totalPhotos) * 100;
                    setProgressText(`${uploadProgress.completedPhotos} / ${uploadProgress.totalPhotos}`);
                    setProgress(photoProgress);
                    break;
            }
        } else {
            switch (process) {
                case 'Projects and Events':
                    const nodeProgress = (downloadProgress.completedNodes / downloadProgress.totalNodes) * 100;
                    setProgressText(`${downloadProgress.completedNodes} / ${downloadProgress.totalNodes}`);
                    setProgress(nodeProgress);
                    break;
                case 'Photos':
                    const photoProgress = (downloadProgress.completedPhotosSize / downloadProgress.totalPhotosSize) * 100;
                    setProgressText(`${SizeUtils.GetFileSize(downloadProgress.completedPhotosSize, 2, true)} / ${SizeUtils.GetFileSize(downloadProgress.totalPhotosSize)}`);
                    setProgress(photoProgress);
                    break;
            }
        }
    }, [step, process, downloadProgress, uploadProgress]);

    const itemsSyncedMessage = () => {
        const pluralizedNoun = (itemCount, noun) => {
            noun = itemCount > 1 ? `${noun}s` : noun;
            return `${itemCount} ${noun}`;
        }

        const totalItems = Math.max(uploadProgress.completedProjects + uploadProgress.completedEvents, downloadProgress.completedNodes);
        const totalPhotos = Math.max(uploadProgress.completedPhotos, downloadProgress.downloadedPhotos);
        const totalItemsMessage = totalItems > 0 ? pluralizedNoun(totalItems, 'item') : null;
        const totalPhotosMessage = totalPhotos > 0 ? pluralizedNoun(totalPhotos, 'photo') : null;
        const andClause = totalItems && totalPhotos ? ' and ' : '';

        return <span> - {totalItemsMessage}{andClause}{totalPhotosMessage} synced</span>;
    };

	const hide = () => {
        dispatch({type: HIDE_SYNC_DIALOG});
        dispatch({type: DISABLE_NO_SLEEP});
        dispatch({type: RESET_PROGRESS});
    };

	const renderSyncContent = () => {
	    if (checkingForData) {
            return <Col xs={12} className="progress-container step-progress mb-0">
                <div className="progress-text pt-0">
                    Checking for new data...
                </div>
            </Col>
        }
	    
	    if (total === 100 && syncHadErrors) {
            return <Col xs={12} className="sync-errors">
                {renderErrors(syncErrors)}
                {!!failedPhotoUploads && <li key='upload'>Failed to upload {failedPhotoUploads} photos.</li>}
                {!!failedPhotoDeletes && <li key='delete'>Failed to delete {failedPhotoDeletes} photos.</li>}
            </Col>
        }

        if (total === 100) {
            return <Col xs={12} className="progress-container step-progress mb-0">
                <div className="progress-text pt-0">
                    <span className="font-weight-bold">Completed!</span>
                    {itemsSyncedMessage()}
                </div>
            </Col>
        }

        return <Fragment>
            <Col xs={12} className="progress-container step-progress">
                <ProgressBar key={`${step}-${process}`} now={progress === 0 ? 1 : progress} />
                <div className="progress-text">
                    <span className="font-weight-bold">{step}</span>
                    <span> - {process} ({progressText})</span>
                </div>
            </Col>
            <Col xs={12} className="progress-container">
                <ProgressBar now={total} />
                <div className="progress-text">
                    <span className="font-weight-bold">Total Progress</span>
                </div>
            </Col>
        </Fragment>
    };

    return (
        <Modal
            title={title()}
            size="md"
            show={show}
            className="sync-dialog"
            hideCloseButton
            static
        >
            <ModalBody>
                <Row xs={12} className="pt-2">
                    {renderSyncContent()}
                </Row>
            </ModalBody>
            <ModalFooter>
                {
                    total < 100 || checkingForData ?
                        <Button variant="link" onClick={() => {
                            hide();
                            dispatch({type: ABORT_SYNC});
                        }}>
                            Cancel Sync
                        </Button> :
                        <Row xs={12}>
                            {
                                !syncHadErrors &&
                                <Col xs={12}>
                                    <Link to="/dashboard">
                                        <Button variant="complete" onClick={() => hide()}>
                                            <FontAwesomeIcon icon={['fal', 'border-all']}/>
                                            Dashboard
                                        </Button>
                                    </Link>
                                </Col>
                            }
                            <Col xs={12}>
                                <Button variant="link" onClick={() => hide()}>
                                    Close
                                </Button>
                            </Col>
                        </Row>
                }
            </ModalFooter>
        </Modal>
    )
};

export default SyncModal;
