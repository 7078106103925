import Base64Utils from "./Base64Utils";

export default class SizeUtils {
    static GetFileSize(bytes, decimals = 2, hideUnit = false, prefix = '', minimum = 0) {
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        if(isNaN(bytes)) {
            bytes = 0;
        }
        
        if(bytes < minimum) {
            bytes = minimum;
            prefix = '<';
        }
        
        let i = 0, num = 0;
        if(bytes > 0) {
            i = Math.floor(Math.log(bytes) / Math.log(k));
            num = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
        }
        const suffix = hideUnit ? '' : ' ' + sizes[i];

        return `${prefix}${num}${suffix}`;
    }

    static GetBase64ByteLength(base64String) {
        const buffer = Buffer.from(Base64Utils.GetBase64Content(base64String));

        return buffer.length;
    }
}
