import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useReturnLink} from "../../Hooks/RouteHooks";
import {Button} from "react-bootstrap";

const ReturnButton = (props) => {
    const returnLink = useReturnLink();
    const defaultLabel = (props.readOnly ?? false) ? 'View' : 'Edit';

    const getReturnTitle = () => {
        if (props.returnTitle) {
            return props.returnTitle;
        }

        if (props.isTransectReview) {
            return 'Transect Review';
        }

        return 'Review & Finalize';
    };

    return (
        <Button className="btn-return" variant="action" onClick={() => returnLink(props.path, getReturnTitle())}>
            {props.children || defaultLabel}
            <FontAwesomeIcon icon={['fas', 'external-link']} />
        </Button>
    )
};

export default ReturnButton;
