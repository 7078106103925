import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";
import {RepeatableSectionItem} from "./RepeatableSectionItem";

const RepeatableSection = props => {
    const {answerId, sampleEventId, fields, updateItem, deleteItem, colSize, readOnly} = props;
    const primaryKey = props.primaryKey ? props.primaryKey : 'index';

    return (
        <div className="repeatable-section">
            {
                !!props?.data?.length &&
                props.data.map((datum) => (
                    <RepeatableSectionItem
                        repeaterData={datum}
                        key={datum[primaryKey]}
                        primaryKey={primaryKey}
                        answerId={answerId}
                        sampleEventId={sampleEventId}
                        updateItem={updateItem}
                        deleteItem={deleteItem}
                        fields={fields}
                        colSize={colSize}
                        readOnly={readOnly}
                    />
                    ))
            }
            {
                !props.readOnly &&
                    <Button className="w-100 mt-2" variant="secondary" onClick={() => props.addItem()}>
                        <FontAwesomeIcon icon={["fal", "plus"]} />
                        Add {props.name}
                    </Button>
            }
        </div>
    )
};

export default RepeatableSection;
