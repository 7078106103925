import React, {Fragment, useEffect, useState} from 'react';
import {Switch, Route, Redirect, withRouter} from 'react-router-dom';

import {useConnectionStatus} from "./Hooks/ConnectionHooks";
import {useCheckAuth} from "./Hooks/AuthHooks";

import HeaderComponent from './Components/Layout/Header/HeaderComponent';
import LoginPage from "./Components/Pages/Login/LoginPage";
import DashboardPage from "./Components/Pages/Dashboard/DashboardPage";
import ProjectDetailsPage from './Components/Pages/Projects/ProjectDetailsPage';
import NewEventPage from './Components/Pages/Events/NewEventPage';
import DownloadPage from './Components/Pages/OfflineData/DownloadPage';
import PhotoToolPage from "./Components/Pages/PhotoPoints/PhotoToolPage";
import CameraPointsPage from "./Components/Pages/CameraPoints/CameraPointsPage";
import PhotoPointsPage from "./Components/Pages/PhotoPoints/PhotoPointsPage";
import PhotoPointDetailPage from "./Components/Pages/PhotoPoints/PhotoPointDetailPage";
import CameraPointDetailPage from "./Components/Pages/CameraPoints/CameraPointDetailPage";
import TransectDetailPage from "./Components/Pages/Transects/TransectDetailPage";
import QuantVegToolPage from "./Components/Pages/Transects/QuantVegToolPage";
import TransectsPage from "./Components/Pages/Transects/TransectsPage";
import HydrozoneSpeciesPage from "./Components/Pages/QuantVegTools/HydrozoneSpeciesPage";
import InvasiveGroundCoverPage from "./Components/Pages/QuantVegTools/InvasiveGroundCoverPage";
import HydrozoneSpeciesReviewPage from "./Components/Pages/TransectReview/HydrozoneSpeciesReviewPage";
import TransectPhotoPointsPage from "./Components/Pages/QuantVegTools/TransectPhotoPointsPage";
import TransectPhotoReviewPage from "./Components/Pages/TransectReview/TransectPhotoReviewPage";
import TransectNarrativePage from "./Components/Pages/QuantVegTools/TransectNarrativePage";
import CanopyClosurePage from "./Components/Pages/QuantVegTools/CanopyClosurePage";
import TransectNarrativeReviewPage from "./Components/Pages/TransectReview/TransectNarrativeReviewPage";
import CanopyClosureReviewPage from "./Components/Pages/TransectReview/CanopyClosureReviewPage";
import QualVegSurveyPage from "./Components/Pages/Surveys/QualVegSurveyPage";
import QualVegToolPage from "./Components/Pages/Surveys/QualVegToolPage";
import ReviewAndFinalizePage from "./Components/Pages/Events/ReviewAndFinalizePage";
import LogoutPage from "./Components/Pages/Login/LogoutPage";
import {useErrorBoundary} from "use-error-boundary";
import ErrorComponent from "./Components/Layout/ErrorComponent";
import UpdateModal from "./Components/Common/UpdateModal";
import {useSelector} from "react-redux";

const MonitoringApp = React.memo(props => {
    useCheckAuth();
    useConnectionStatus();
    const { ErrorBoundary, didCatch } = useErrorBoundary();
    const updateOpen = useSelector(state => state.appStatusState.updateOpen);

    const excludeHeader = ['/login', '/logout', '/photo/']
        .find(excludePath => props.location.pathname.startsWith(excludePath));

    const renderComponent = (component) => {
        return <ErrorBoundary
            renderError={({error}) => <ErrorComponent error={error} />}
            render={() => component}
        />
    };

    return (
        <Fragment>
            <UpdateModal show={updateOpen} />
            {!excludeHeader && !didCatch && <HeaderComponent />}
            <Switch>
                <Route exact path="/">
                    <Redirect to="/dashboard" />
                </Route>
                <Route path="/login">
                    {renderComponent(<LoginPage />)}
                </Route>
                <Route path="/logout">
                    {renderComponent(<LogoutPage />)}
                </Route>
                <Route path="/dashboard">
                    {renderComponent(<DashboardPage />)}
                </Route>
                <Route path="/event/:eventId/photo/:cameraPointId/point">
                    {renderComponent(<PhotoPointsPage />)}
                </Route>
                <Route path="/event/:eventId/photo/create">
                    {renderComponent(<CameraPointDetailPage />)}
                </Route>
                <Route path="/event/:eventId/photo/:cameraPointId">
                    {renderComponent(<CameraPointDetailPage />)}
                </Route>
                <Route path="/event/:eventId/photo/">
                    {renderComponent(<CameraPointsPage />)}
                </Route>
                <Route path="/event/:eventId/transect/create">
                    {renderComponent(<TransectDetailPage />)}
                </Route>
                <Route path="/event/:eventId/quant-veg">
                    {renderComponent(<TransectsPage />)}
                </Route>
                <Route path="/event/create">
                    {renderComponent(<NewEventPage />)}
                </Route>
                <Route path="/event/:eventId/edit">
                    {renderComponent(<NewEventPage />)}
                </Route>
                <Route path="/event/:eventId/qual-veg/:surveyId/:sectionCode">
                    {renderComponent(<QualVegSurveyPage />)}
                </Route>
                <Route path="/event/:eventId/qual-veg">
                    {renderComponent(<QualVegToolPage />)}
                </Route>
                <Route path="/event/:eventId/review">
                    {renderComponent(<ReviewAndFinalizePage />)}
                </Route>
                <Route path="/project/:projectId">
                    {renderComponent(<ProjectDetailsPage />)}
                </Route>
                <Route path="/offline">
                    {renderComponent(<DownloadPage />)}
                </Route>
                <Route path="/photo/tool">
                    {renderComponent(<PhotoToolPage />)}
                </Route>
                <Route path="/photo/:photoId">
                    {renderComponent(<PhotoPointDetailPage />)}
                </Route>
                <Route path="/transect/:transectId/quant-veg">
                    {renderComponent(<QuantVegToolPage />)}
                </Route>
                <Route path="/transect/:transectId/transect-narrative">
                    {renderComponent(<TransectNarrativePage />)}
                </Route>
                <Route path="/transect/:transectId/plot:plotTypeId/:type/:hydrozoneTypeId">
                    {renderComponent(<HydrozoneSpeciesPage />)}
                </Route>
                <Route path="/transect/:transectId/invasive-cover/:hydrozoneTypeId">
                    {renderComponent(<InvasiveGroundCoverPage />)}
                </Route>
                <Route path="/transect/:transectId/canopy">
                    {renderComponent(<CanopyClosurePage />)}
                </Route>
                <Route path="/transect/:transectId/instream-shade">
                    {renderComponent(<TransectPhotoPointsPage />)}
                </Route>
                <Route path="/transect/:transectId/origin-end-photo">
                    {renderComponent(<TransectPhotoPointsPage />)}
                </Route>
                <Route path="/transect/:transectId/instream-photo">
                    {renderComponent(<HydrozoneSpeciesPage />)}
                </Route>
                <Route path="/transect/:transectId">
                    {renderComponent(<TransectDetailPage />)}
                </Route>
                <Route path="/transect-review/:transectId/stem-length">
                    {renderComponent(<HydrozoneSpeciesReviewPage />)}
                </Route>
                <Route path="/transect-review/:transectId/transect-narrative">
                    {renderComponent(<TransectNarrativeReviewPage />)}
                </Route>
                <Route path="/transect-review/:transectId/dbh">
                    {renderComponent(<HydrozoneSpeciesReviewPage />)}
                </Route>
                <Route path="/transect-review/:transectId/invasive-cover">
                    {renderComponent(<HydrozoneSpeciesReviewPage />)}
                </Route>
                <Route path="/transect-review/:transectId/instream-shade">
                    {renderComponent(<TransectPhotoReviewPage />)}
                </Route>
                <Route path="/transect-review/:transectId/canopy">
                    {renderComponent(<CanopyClosureReviewPage />)}
                </Route>
                <Route path="/transect-review/:transectId/origin-end-photo">
                    {renderComponent(<TransectPhotoReviewPage />)}
                </Route>
            </Switch>
        </Fragment>
    )
});

export default withRouter(MonitoringApp);
