import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from 'react-router-dom'

import PageHeader from "../../Layout/Page/PageHeader";
import Title from "../../Layout/TitleComponent";
import PageContent from "../../Layout/Page/PageContent";
import {Col, FormCheck, Row} from "react-bootstrap";
import PageFooter from "../../Layout/Page/PageFooter";
import PageContainer from "../../Layout/Page/PageContainer";
import FooterNav from "../../Layout/Page/FooterNav";
import {useTransectReviewNav} from "../../../Hooks/QuickNavHooks";
import {selectTransect} from "../../../Redux/Selectors/transectSelectors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    PHOTO_POINT_DOWNSTREAM,
    PHOTO_POINT_INSTREAM_SHADE, PHOTO_POINT_TYPE_END,
    PHOTO_POINT_TYPE_ORIGIN,
    PHOTO_POINT_UPSTREAM
} from "../../../Constants/photos";
import {selectVisiblePhotos} from "../../../Redux/Selectors/photoSelectors";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import {useContextToolsBySampleEventId} from "../../../Hooks/ToolHooks";
import {updateTransectReview} from "../../../Redux/Actions/transectActions";
import {useTransectToolProgress} from "../../../Hooks/ProgressHooks";
import {useFinalized} from "../../../Hooks/DataHooks";
import ReturnButton from "../../Common/ReturnButton";
import {TRANSECT_REVIEW_CODE_INSTREAM, TRANSECT_REVIEW_CODE_PHOTOS} from "../../../Constants/transectReview";


const TransectPhotoReviewPage = props => {
    const dispatch = useDispatch();
    const params = useParams();
    const transectToolProgress = useTransectToolProgress();

    useTransectReviewNav(params.transectId);

    const transect = useSelector(state => selectTransect(state, params.transectId));
    const photos = useSelector(state => selectVisiblePhotos(state));
    const user = useSelector(state => state.userState.user);

    useBreadcrumbs('Transect Review', transect.projectId, transect.sampleEventId, transect.transectId);
    useContextToolsBySampleEventId(transect.sampleEventId);
    const finalized = useFinalized(transect.sampleEventId);

    const [subtitle, setSubtitle] = useState(null);
    const [photoPoints, setPhotoPoints] = useState([]);
    const [toolCode, setToolCode] = useState('');
    const [reviewCode, setReviewCode] = useState('');
    const [toolProgress, setToolProgress] = useState(null);

    useEffect(() => {
        const type = location.pathname.split('/').pop(); // last segment of url
        let photoPointTypes, pageTitle, reviewCode;

        if (type === 'instream-shade') {
            pageTitle = 'Instream Photos and Shade';
            photoPointTypes = [PHOTO_POINT_INSTREAM_SHADE, PHOTO_POINT_UPSTREAM, PHOTO_POINT_DOWNSTREAM];
            reviewCode = TRANSECT_REVIEW_CODE_INSTREAM;
        } else {
            pageTitle = 'Origin & End Photos';
            photoPointTypes = [PHOTO_POINT_TYPE_ORIGIN, PHOTO_POINT_TYPE_END];
            reviewCode = TRANSECT_REVIEW_CODE_PHOTOS;
        }

        setSubtitle(pageTitle);
        setPhotoPoints(transect.PhotoPoints.filter(point => photoPointTypes.includes(point.name)));
        setToolCode(type);
        setReviewCode(reviewCode);
        setToolProgress(transectToolProgress(transect, [type])[0]);
    }, [location.pathname]);

    const isConfirmed = () => {
        return transect.TransectReviews.find(review => review.code === reviewCode)?.isConfirmed ?? false;
    };

    return (
        <PageContainer className="transect-photo-review-page">
            <PageHeader>
                <Title
                    title="Transect Review"
                    subtitle={subtitle}
                    action={<ReturnButton path={`/transect/${transect.transectId}/${toolCode}`} isTransectReview  readOnly={finalized} />}
                />
            </PageHeader>
            <PageContent>
                <div className="photo-point-row ">
                    <Row xs={12} className="photo-point-content">
                        {
                            photoPoints.map(photoPoint => {
                                const photo = photos.find(photo => photo.photoPointId === photoPoint.photoPointId && photo.sampleEventId === transect.sampleEventId);

                                return (
                                    <Col key={photoPoint.photoPointId} xs={4}>
                                        <div className="photo">
                                            {
                                                photo ?
                                                    <Link to={`/photo/${photo.photoId}`}>
                                                        <img src={photo?.thumbnailSrc ?? photo?.src} />
                                                        <div className="caption">
                                                            <div className="caption-group">
                                                                <span>{photoPoint.photoDirection}</span>
                                                            </div>
                                                        </div>
                                                    </Link> :
                                                    <div className="no-image">
                                                        <FontAwesomeIcon icon={['fal', 'file-image']} />
                                                        No Photo
                                                    </div>
                                            }
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>
            </PageContent>
            <PageFooter>
                <FooterNav>
                    <div className="complete-checkbox">
                        <FormCheck
                            id="complete-checkbox"
                            label="Confirm"
                            type="checkbox"
                            value={isConfirmed()}
                            checked={isConfirmed()}
                            onChange={() => dispatch(updateTransectReview(transect, reviewCode, !isConfirmed(), user.userId))}
                            disabled={finalized}
                        />
                    </div>
                </FooterNav>
            </PageFooter>
        </PageContainer>
    )
};

export default TransectPhotoReviewPage;
