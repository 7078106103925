import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from 'react-router-dom'
import {Row, Col, FormLabel, FormGroup, Button, FormCheck, OverlayTrigger, Popover} from 'react-bootstrap';

import {selectSampleEventById, selectSurveysBySampleEventId} from "../../../Redux/Selectors/nodeSelectors";

import PageContent from '../../Layout/Page/PageContent';
import PageFooter from '../../Layout/Page/PageFooter';
import Title from '../../Layout/TitleComponent';
import PageContainer from '../../Layout/Page/PageContainer';
import PageHeader from "../../Layout/Page/PageHeader";
import DateUtils from "../../../Utils/DateUtils";
import FormInput from "../../Common/FormInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UPSERT_SAMPLE_EVENT} from "../../../Redux/Actions/Types/offlineDataActionTypes";
import PhotoPointReviewTable from "./PhotoPointReviewTable";
import QualitativeVegMonitoringReviewTable from "./QualitativeVegMonitoringReviewTable";
import {useSampleEventUsers} from "../../../Hooks/DataHooks";
import QuantitativeVegMonitoringReviewTable from "./QuantitativeVegMonitoringReviewTable";
import {useSampleEventsWithProgress} from "../../../Hooks/ProgressHooks";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import {qualTools} from "../../../Constants/tools";

const ReviewAndFinalizePage = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const staff = useSampleEventUsers(params.eventId);
    const eventsWithProgress = useSampleEventsWithProgress();

    const event = useSelector(state => selectSampleEventById(state, params.eventId));
    const user = useSelector(state => state.userState.user);
    const surveys = useSelector(state => selectSurveysBySampleEventId(state, params.eventId));
    const currentEventWithProgress = eventsWithProgress([event])[0];

    useBreadcrumbs('Review & Finalize', event.projectId, event.sampleEventId);

    const renderMonitoringEventTable = () => {
        switch (event.procedureCode) {
            case 'photo':
                return <PhotoPointReviewTable event={event} />;
            case 'qual-veg':
                return <QualitativeVegMonitoringReviewTable event={event} />;
            case 'quant-veg':
                return <QuantitativeVegMonitoringReviewTable event={event} />;
            default:
                return null;
        }
    };

    const renderPreviousButton = () => {
        switch (event.procedureCode) {
            case 'photo':
                return (
                    <Link to={`/event/${event.sampleEventId}/photo`}>
                        <Button variant="action">
                            <FontAwesomeIcon icon={['fal', 'angle-left']} />
                            Camera Points
                        </Button>
                    </Link>
                );
            case 'quant-veg':
                return (
                    <Link to={`/event/${event.sampleEventId}/quant-veg`}>
                        <Button variant="action">
                            <FontAwesomeIcon icon={['fal', 'angle-left']} />
                            Transects
                        </Button>
                    </Link>
                );
            case 'qual-veg':
                const qualTool = qualTools.find(tool => tool.code === 'management-survey');
                const survey = surveys.find(survey => survey.surveyTypeId === qualTool.id);

                return (
                    <Link to={`/event/${event.sampleEventId}/qual-veg/${survey.surveyId}/management`}>
                        <Button variant="action">
                            <FontAwesomeIcon icon={['fal', 'angle-left']} />
                            Management
                        </Button>
                    </Link>
                );
            default:
                return null;
        }
    };

    return (
        <PageContainer className="review-and-finalize-page">
            <PageHeader>
                <Title title="Review & Finalize" subtitle={`${event.projectName} - ${event.procedureName}`} />
                <div className="sample-event-details">
                    <Row xs={12}>
                        <Col xs={3}>
                            <FormGroup>
                                <FormLabel>Phase</FormLabel>
                                <div>{event.sampleEventPhaseName}</div>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Purpose</FormLabel>
                                <div>{event.otherPurpose || 'N/A'}</div>
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <FormLabel>Sample Event Start Date</FormLabel>
                                <div>{DateUtils.FormatToLocalDate(event.startDate)}</div>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Procedure</FormLabel>
                                <div>{event.procedureName}</div>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <FormLabel>Field Crew</FormLabel>
                                <div>{staff || 'N/A'}</div>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Notes</FormLabel>
                                <div>{event.notes}</div>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </PageHeader>
            <PageContent>
                <Row xs={12}>
                    <Col xs={12}>
                        {renderMonitoringEventTable()}
                    </Col>
                </Row>
            </PageContent>
            <PageFooter>
                <Row xs={12}>
                    <Col xs={2} className="action-buttons justify-content-start">
                        {renderPreviousButton()}
                    </Col>
                    <Col xs={3}>
                        <FormInput
                            label="Sample Event End Date"
                            type="datetime-local"
                            value={DateUtils.FormatToLocalDateTime(event.endDate)}
                            onChange={value => dispatch({
                                type: UPSERT_SAMPLE_EVENT,
                                sampleEvent: {...event, endDate: DateUtils.FormatToUTCDateTime(value)}
                            })}
                            disabled={event.isComplete}
                        />
                    </Col>
                    <Col xs={3}>
                        <FormInput
                            label="Total Staff Hours"
                            type="number"
                            value={event.estimatedHours ?? ''}
                            onChange={value => dispatch({
                                type: UPSERT_SAMPLE_EVENT,
                                sampleEvent: {...event, estimatedHours: value}
                            })}
                            overlayIcon={
                                <OverlayTrigger
                                    trigger="click"
                                    key="right"
                                    placement="right"
                                    overlay={
                                        <Popover>
                                            <Popover.Content>
                                                Estimate the total number of staff hours spent on this monitoring event.
                                            </Popover.Content>
                                        </Popover>
                                    }
                                    rootClose
                                >
                                    <FontAwesomeIcon icon={['fas', 'info-circle']} className="info-popover" />
                                </OverlayTrigger>
                            }
                            disabled={event.isComplete}
                        />
                    </Col>
                    <Col xs={4} className="action-buttons">
                        <div className="complete-checkbox"
                             onClick={() => dispatch({
                                 type: UPSERT_SAMPLE_EVENT,
                                 sampleEvent: {
                                     ...event,
                                     isComplete: !event.isComplete,
                                     finalizedDate: DateUtils.GetCurrentDateTime(),
                                     finalizedBy: user.userId
                                 }
                             })}
                        >
                            <FormCheck
                                id="complete-checkbox"
                                label="Finalize"
                                type="checkbox"
                                value={event.isComplete ?? false}
                                checked={event.isComplete}
                                readOnly
                            />
                        </div>
                        <Link to={`/project/${event.projectId}`}>
                            <Button variant="action">
                                Project Details
                                <FontAwesomeIcon icon={['fal', 'angle-right']} />
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </PageFooter>
        </PageContainer>
    );
};

export default ReviewAndFinalizePage;
