import {SET_USER, SET_PERMISSIONS, USER_LOGOUT} from "../Actions/Types/userActionTypes";
import {VERIFICATION_CANCEL} from '../Actions/Types/loginActionTypes';
import {RESTORE_BACKUP} from "../Actions/Types/offlineDataActionTypes";

const initialState = {
    user: null,
    permissions: [],
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.user,
            };
        case SET_PERMISSIONS:
            return {
                ...state,
                permissions: action.permissions,
            };
        case VERIFICATION_CANCEL:
        case USER_LOGOUT:
            return initialState;
        case RESTORE_BACKUP:
            return action.data.userState;
        default:
            return state;
    }
};

export default userReducer;
