import React, {Fragment, useEffect, useState} from 'react';
import {Prompt, useHistory} from "react-router-dom";
import {Button, Col, ModalBody, ModalFooter, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "./Modal";

const RouteLeavingGuard = props => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [lastLocation, setLastLocation] = useState(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            // Navigate to the previous blocked location with your navigate function
            history.push(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation]);

    const handleConfirmNavigationClick = () => {
        setShowModal(false);
        setConfirmedNavigation(true);
    };

    const handleBlockedNavigation = (nextLocation) => {
        if (!confirmedNavigation) {
            setShowModal(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    return (
        <Fragment>
            <Prompt when={props.when} message={handleBlockedNavigation} />
            <Modal
                title={'Unsaved Changes'}
                size="md"
                show={showModal}
                onHide={() => setShowModal(false)}
            >
                <ModalBody>
                    <Row xs={12}>
                        <Col xs={12}>
                            <span>You are about to navigate away from this page with unsaved changes, are you sure?</span>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button variant="link" className="ml-3" onClick={() => setShowModal(false)}>
                        <FontAwesomeIcon icon={['fal', 'times']} />
                        Cancel
                    </Button>
                    <Button variant="danger" className="w-25" onClick={() => handleConfirmNavigationClick()}>
                        Discard Changes
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
};

export default RouteLeavingGuard;
