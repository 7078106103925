import {useEffect} from "react";
import {useSelector} from "react-redux";
import {selectSampleEventById} from "../Redux/Selectors/nodeSelectors";

export const useReadOnly = (readOnly) => {
    useEffect(() => {
        if (readOnly) {
            document.querySelectorAll('input, textarea').forEach(input => {
                input.readOnly = true;
                input.classList.add('sb-finalized');
            });

            document.querySelectorAll('select, .streambank-body .btn-danger, .streambank-body .btn-secondary, .streambank-body .btn-action, .streambank-header .btn-secondary').forEach(input => {
                input.disabled = true;
                input.classList.add('sb-finalized');
            });
        }
    }, []);
};

export const useFieldIsDisabled = () => {
    return (field, data) => {
        return field.enabled && !data[field.enabled];
    }
};

export const useHasInvalidField = () => {
    return (requiredValues, validationMessages = []) => {
        // If field is an empty array or not filled in
        const hasEmptyRequiredField = requiredValues.some(field => {
            if (Array.isArray(field)) {
                return !field.length;
            }

            return field === undefined || field === null || field === '';
        });

        // If validatedField has error message
        const hasInvalidField = validationMessages.some(field => !!field);

        return hasEmptyRequiredField || hasInvalidField;
    }
};

export const useValidateRegex = () => {
    return (value, regex, fieldName = 'value') => {
        if (!regex.test(value) && value) {
            return `${fieldName} is incorrectly formatted.`;
        }
    }
};

export const useValidateEqualTo = () => {
    return (value, equalToNumber, fieldName = 'Value') => {
        const boolean = !(Number(value) === Number(equalToNumber));
        const message = `${fieldName} must be ${equalToNumber}.`;

        return comparisonValidation(value, equalToNumber, boolean, message);
    }
};

export const useValidateLatitude = () => {
    return (value) => {
        if (value === undefined || value === '' || value === null) {
            return null;
        }

        const [digits, decimalPlaces] = value.toString().split('.');

        if (value < 0) {
            return 'Latitude must be positive';
        }

        if (digits && digits.length !== 2) {
            return 'Latitude must have 2 digits.'
        }

        if (!decimalPlaces || (decimalPlaces &&decimalPlaces.length !== 5)) {
            return 'Latitude must have 5 decimal places.'
        }

        return null;
    }
};

export const useValidateLongitude = () => {
    return (value) => {
        if (value === undefined || value === '' || value === null) {
            return null;
        }

        const [digits, decimalPlaces] = value.toString().split('.');

        if (value > 0) {
            return 'Longitude must be negative';
        }

        // This is compared with 4 because of the negative sign
        if (digits && digits.length !== 4) {
            return 'Longitude must have 3 digits.'
        }

        if (!decimalPlaces || (decimalPlaces && decimalPlaces.length !== 5)) {
            return 'Longitude must have 5 decimal places.'
        }

        return null;
    }
};

export const useValidateInteger = () => {
    return (value, fieldName = 'Value') => {
        const number = Number(value);

        if (!Number.isInteger(number)) {
            return `${fieldName} must be an integer.`;
        }
        
        return null;
    }
}

export const useValidatePositiveValue = () => {
    return (value, fieldName = 'Value') => {
        if (value <= 0) {
            return `${fieldName} must be greater than 0.`;
        }

        return null;
    }
};

export const useValidateDuplicate = () => {
    return (value, field, array, originalField) => {
        if (originalField && String(value) === String(originalField[field])) {
            return null;
        }

        if (array.find(item => String(item[field]) === String(value))) {
            return `${value} already exists.`;
        }

        return null;
    }
};

export const useValidateGreaterThan = () => {
    return (value, greaterThanNumber, fieldName = 'Value') => {
        const boolean = !(Number(value) > Number(greaterThanNumber));
        const message = `${fieldName} must be greater than ${greaterThanNumber}.`;

        return comparisonValidation(value, greaterThanNumber, boolean, message);
    }
};

export const useValidateLessThan = () => {
    return (value, lessThanNumber, fieldName = 'Value') => {
        const boolean = !(Number(value) < Number(lessThanNumber));
        const message = `${fieldName} must be less than ${lessThanNumber}.`;

        return comparisonValidation(value, lessThanNumber, boolean, message);
    }
};

export const useValidateGreaterThanOrEquals = () => {
    return (value, greaterThanNumber, fieldName = 'Value') => {
        const boolean = !(Number(value) >= Number(greaterThanNumber));
        const message = `${fieldName} must be greater than or equal to ${greaterThanNumber}.`;

        return comparisonValidation(value, greaterThanNumber, boolean, message);
    }
};

export const useValidateLessThanOrEquals = () => {
    return (value, lessThanNumber, fieldName = 'Value') => {
        const boolean = !(Number(value) <= Number(lessThanNumber));
        const message = `${fieldName} must be less than or equal to ${lessThanNumber}.`;

        return comparisonValidation(value, lessThanNumber, boolean, message);
    }
};

export const useValidateBetween = () => {
    return (value, start, end, fieldName = 'Value') => {
        const boolean = !(Number(value) >= Number(start) && Number(value) <= Number(end));
        const message = `${fieldName} must be between ${start} and ${end}.`;

        return comparisonValidation(value, start, boolean, message);
    }
};

export const useValidateIsNotEmpty = () => {
    return (value, fieldName = 'Value') => {
        if (value === null || value === '' || value === undefined) {
            return `${fieldName} is a required field.`;
        }
        
        return null;
    }
};

const comparisonValidation = (value, comparisonNumber, boolean, message) => {
    if (
        comparisonNumber === null || comparisonNumber === '' || comparisonNumber === undefined ||
        value === null || value === '' || value === undefined
    ) {
        return null;
    }

    if (boolean) {
        return message;
    }

    return null;
};
