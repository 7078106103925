import {SET_LOADING} from "../Actions/Types/ajaxActionTypes";
import {USER_LOGOUT} from "../Actions/Types/userActionTypes";
import {RESTORE_BACKUP} from "../Actions/Types/offlineDataActionTypes";

const initialState = {
    loading: false,
};

const ajaxReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loading: action.value,
            };
        case USER_LOGOUT:
            return initialState;
        case RESTORE_BACKUP:
            return action.data.ajaxState;
        default:
            return state;
    }
};

export default ajaxReducer;
