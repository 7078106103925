import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

import {
    OFFLINE_STATUS_DO_NOT_SYNC,
    OFFLINE_STATUS_OUT_OF_SYNC,
    OFFLINE_STATUS_READY_FOR_UPLOAD,
    OFFLINE_STATUS_SYNC,
} from "../../../Constants/status";
import {HIERARCHY_TYPE_PROJECT} from "../../../Constants/hierarchy";
import {SET_DELETED_NODE} from "../../../Redux/Actions/Types/hierarchyActionTypes";
import {ENABLE_NO_SLEEP} from "../../../Redux/Actions/Types/syncStatusTypes";
import {createMonitoringEvent} from "../../../Redux/Actions/downloadActions";

import SizeUtils from "../../../Utils/SizeUtils";
import IconUtils from "../../../Utils/IconUtils";

import CloudIcon from '../../CompositeIcons/CloudIcon';
import CircledIcon from '../../CompositeIcons/CircledIcon';

const TreeNode = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const {online, offlineMode} = useSelector(state => state.appStatusState);

    const {
        name,
        shortName,
        bytes,
        statusLabel,
        offlineState,
        procedureCode,
        sampleEventId,
        hierarchyId,
        hierarchyTypeId,
    } = props.node;

    const renderIcon = () => {
        if (props.parentIcon?.hasMultipleStates) {
            return <CloudIcon icon={['fas', 'ellipsis-h']} className={props.parentIcon?.iconColor} />;
        }

        switch (props.parentIcon?.maxPriority || offlineState) {
            case OFFLINE_STATUS_SYNC:
                return <CloudIcon icon={['fas', 'check']} className="success" />;
            case OFFLINE_STATUS_OUT_OF_SYNC:
                return <CloudIcon icon={['fas', 'arrow-down']} className="danger inverse" />;
            case OFFLINE_STATUS_READY_FOR_UPLOAD:
                return <CloudIcon icon={['fas', 'arrow-up']} className="danger" />;
            default:
                return <FontAwesomeIcon icon={['fal', 'cloud-download']} />;
        }
    };

    return (
        <div className="tree-node">
            <div className="tree-prefix">
                {renderIcon()}
            </div>
            <div className="tree-node-content">
                {
                    sampleEventId ?
                        <span>
                            <FontAwesomeIcon icon={IconUtils.GetIcon(procedureCode, 'fas')} />
                            <span>{shortName}</span>
                        </span> :
                        <span>{name}</span>
                }
                <div className="tree-status">
                    <span>{statusLabel}</span>
                    {
                        hierarchyTypeId === HIERARCHY_TYPE_PROJECT &&
                            <Button
                                variant="action"
                                className="btn-event"
                                onClick={() => {
                                    dispatch({type: ENABLE_NO_SLEEP});
                                    dispatch(createMonitoringEvent(hierarchyId, history))
                                }}
                                disabled={offlineMode || !online}
                            >
                                <FontAwesomeIcon icon={['fal', 'plus']} />
                                New Event
                            </Button>
                    }
                </div>
            </div>
            <div className="tree-suffix">
                <div className="tree-suffix-container">
                    <span className="tree-node-file-size">({SizeUtils.GetFileSize(bytes, 0, false, '~', 1024)})</span>
                    <span className="tree-node-action">
                    <Button variant="link"
                            disabled={offlineState === OFFLINE_STATUS_DO_NOT_SYNC}
                            onClick={() => dispatch({type: SET_DELETED_NODE, deletedNode: props.node})}
                    >
                        <CircledIcon
                            icon={['fal', 'trash-alt']}
                            circleSize="8"
                            iconTransform="shrink-2"
                            className="node-remove-icon"
                        />
                    </Button>
                </span>
                </div>
            </div>
        </div>
    )
};

export default TreeNode;
