export default class Base64Utils {
    static GetKeyStr() {
        return "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    }

    static Base64ToArrayBuffer(input) {
        const base64 = this.GetBase64Content(input);
        const bytes = (base64.length/4) * 3;
        const ab = new ArrayBuffer(bytes);
        this.Decode(base64, ab);

        return ab;
    }

    static ArrayBufferToBase64(buffer, contentType = 'image/png') {
        let binary = '';
        const bytes = new Uint8Array( buffer );
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return `data:${contentType};base64,${window.btoa(binary)}`;
    }

    static RemovePaddingChars(input) {
        const lkey = this.GetKeyStr().indexOf(input.charAt(input.length - 1));
        if(lkey == 64) {
            return input.substring(0,input.length - 1);
        }
        return input;
    }

    static Decode(input, arrayBuffer) {
        //get last chars to see if are valid
        input = this.RemovePaddingChars(input);
        input = this.RemovePaddingChars(input);

        const bytes = parseInt((input.length / 4) * 3, 10);

        let uarray;
        let chr1, chr2, chr3;
        let enc1, enc2, enc3, enc4;
        let i = 0;
        let j = 0;

        if (arrayBuffer) {
            uarray = new Uint8Array(arrayBuffer);
        } else {
            uarray = new Uint8Array(bytes);
        }

        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

        for (i=0; i<bytes; i+=3) {
            //get the 3 octects in 4 ascii chars
            enc1 = this.GetKeyStr().indexOf(input.charAt(j++));
            enc2 = this.GetKeyStr().indexOf(input.charAt(j++));
            enc3 = this.GetKeyStr().indexOf(input.charAt(j++));
            enc4 = this.GetKeyStr().indexOf(input.charAt(j++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            uarray[i] = chr1;
            if (enc3 != 64) uarray[i+1] = chr2;
            if (enc4 != 64) uarray[i+2] = chr3;
        }

        return uarray;
    }

    static async Base64ToHash(input) {
        const arrayBuffer = this.Base64ToArrayBuffer(this.GetBase64Content(input));

        return await this.ArrayBufferToHash(arrayBuffer);
    }

    static async ArrayBufferToHash(arrayBuffer) {
        const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

        return hashHex;
    }

    static GetBase64Content(base64String) {
        return base64String.substring(base64String.indexOf(',') + 1);
    }
    
    static GetBase64ContentType(base64String) {
        return base64String.substring(base64String.indexOf(":")+1, base64String.indexOf(";"));
    }
}
