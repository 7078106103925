import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { ModalBody, ModalFooter, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {ADD_PHOTO_POINT, UPDATE_PHOTO_POINT} from "../../../../Redux/Actions/Types/offlineDataActionTypes";
import {useNextAlphaId, useNextTempId} from "../../../../Hooks/TempIdHooks";
import {useHasInvalidField, useValidateBetween} from "../../../../Hooks/FormHooks";
import DateUtils from "../../../../Utils/DateUtils";

import Modal from '../../../Common/Modal';
import FormInput from "../../../Common/FormInput";

import {photoDirections} from "../../../../Constants/photos";
import ConfirmSaveModal from "../../../Common/ConfirmSaveModal";
import {TEMP_ID_PREFIX} from "../../../../Constants/misc";

const PhotoPointModal = props => {
    const dispatch = useDispatch();
    const nextId = useNextTempId();
    const nextAlphaId = useNextAlphaId();
    const validateBetween = useValidateBetween();
    const hasInvalidField = useHasInvalidField();

    const user = useSelector(state => state.userState.user);

    const [direction, setDirection] = useState('');
    const [otherDirection, setOtherDirection] = useState('');
    const [azimuth, setAzimuth] = useState('');
    const [showSaveWarning, setShowSaveWarning] = useState(false);

    const lastAlphaId = [...props.allPoints || []]
        .sort((a, b) => (a.name > b.name) ? -1 : 1)
        .map(point => point.name)
        .find(point => !!point);

    useEffect(() => {
        initData();
    }, [props.point]);

    useEffect(() => {
        // Clear otherDirection when direction changes
        if (direction !== 'Other') {
            setOtherDirection('');
        }
    }, [direction]);

    const initData = () => {
        if (props.point) {
            const photoDirection = props.point.photoDirection;
            if (photoDirections.find(({id}) => id === photoDirection)) {
                setDirection(photoDirection);
            } else {
                setDirection('Other');
                setOtherDirection(photoDirection);
            }
            setAzimuth(props.point.compassBearing);
        }
    };

    const confirmSave = () => {
        if (!props.point?.photoPointId || (String(props.point.photoPointId).startsWith(TEMP_ID_PREFIX))) {
            save();
        } else {
            const photoDirection = direction !== 'Other' ? direction : otherDirection;

            if (props.point.photoDirection === photoDirection && props.point.compassBearing === azimuth) {
                save();
            } else {
                setShowSaveWarning(true);
            }
        }
    };

    const save = () => {
        const photoDirection = direction !== 'Other' ? direction : otherDirection;

        if (!props.point?.photoPointId) {
            const photoPointId = nextId();

            const newPhotoPoint = {
                photoPointId,
                sampleLocationId: props.sampleLocationId,
                name: nextAlphaId(lastAlphaId),
                photoDirection,
                compassBearing: azimuth,
                PhotoPointHistory: [{
                    photoPointHistoryId: nextId(),
                    photoPointId,
                    activeFrom: DateUtils.GetCurrentDate(),
                    activeTo: null,
                    activateUserId: user.userId,
                    deactivateUserId: null,
                    notes: null,
                }],
            };

            dispatch({type: ADD_PHOTO_POINT, photoPoint: newPhotoPoint});
        } else {
            const updatedPhotoPoint = {
                ...props.point,
                photoDirection,
                compassBearing: azimuth,
            };

            dispatch({type: UPDATE_PHOTO_POINT, photoPoint: updatedPhotoPoint});
        }

        onHide();
    };

    const reactivate = () => {

        const today = DateUtils.GetCurrentDate();
        const existingHistory = props.point.PhotoPointHistory.find(
            photoPointHistory => (photoPointHistory?.activeFrom === today)
        );

        // erase any not-yet-saved, or potentially duplicative history objects
        // to avoid a PhotoPointHistory.unq_PhotoPointHistory_ident duplicate
        // key constraint error
        const photoPointWrittenHistory = props.point.PhotoPointHistory.filter(
            photoPointHistory =>
                !(String(photoPointHistory?.photoPointHistoryId).startsWith(TEMP_ID_PREFIX)) &&
                !(String(photoPointHistory?.photoPointHistoryId) === String(existingHistory?.photoPointHistoryId))
        );

        const photoPoint = {
            ...props.point,
            PhotoPointHistory: [
                ...photoPointWrittenHistory,
                {
                    ...existingHistory,
                    photoPointHistoryId: existingHistory?.photoPointHistoryId ?? nextId(),
                    photoPointId: props.point.photoPointId,
                    activeFrom: today,
                    activeTo: null,
                    activateUserId: user.userId,
                    deactivateUserId: null,
                    notes: null,
                }
            ],
        };

        dispatch({type: UPDATE_PHOTO_POINT, photoPoint});
        props.onHide();
    };

    const onHide = () => {
        props.onHide();
    };

    const disableSave = () => {
        const requiredFields = [azimuth, nextAlphaId(lastAlphaId)];
        const validatedFields = [validateBetween(azimuth, 0, 360)];

        return hasInvalidField(requiredFields, validatedFields);
    };

    const renderSaveOrClose = () => {
        if (props.readOnly) {
            return <Button variant="secondary" onClick={() => props.onHide()}>
                Close
            </Button>
        }

        return <Button variant="complete" onClick={() => confirmSave()} disabled={disableSave()}>
            <FontAwesomeIcon icon={['fal', 'save']} />
            Save
        </Button>
    };

    return (
        <Fragment>
            <ConfirmSaveModal
                show={showSaveWarning}
                size="md"
                title="Confirm Photo Point Changes"
                message="Warning: You are about to make changes to an established Photo Point. Doing so will also change these values for all historic data. Are you sure you want to continue?"
                save={() => {
                    save();
                    setShowSaveWarning(false);
                }}
                onHide={() => {
                    initData();
                    setShowSaveWarning(false);
                }}
            />
            {
                !showSaveWarning &&
                    <Modal
                        title={props.point?.name ? `Point ${props.point.name}` : "Add Photo Point"}
                        size="md"
                        show={props.show}
                        className="photo-point-modal"
                        onHide={() => onHide()}
                    >
                        <ModalBody>
                            <Row xs={12}>
                                <Col xs={6}>
                                    <FormInput
                                        label="Photo Direction"
                                        type="select"
                                        options={photoDirections}
                                        value={direction}
                                        onChange={value => setDirection(value)}
                                        disabled={props.readOnly}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <FormInput
                                        label="Compass Azimuth"
                                        type="number"
                                        value={azimuth}
                                        onChange={value => setAzimuth(value)}
                                        validationMessage={validateBetween(azimuth, 0, 360)}
                                        suffix="&#176;"
                                        readOnly={props.readOnly}
                                        required
                                    />
                                </Col>
                                {
                                    direction === 'Other' &&
                                    <Col xs={6}>
                                        <FormInput
                                            label="Other Direction"
                                            value={otherDirection}
                                            onChange={value => setOtherDirection(value)}
                                            disabled={props.readOnly}
                                        />
                                    </Col>
                                }
                                {
                                    !nextAlphaId(lastAlphaId) &&
                                    <Col xs={12}>
                                        <span className="error-msg">CameraPoints are limited to 26 photo points. Please create a new CameraPoint with the same coordinates.</span>
                                    </Col>
                                }
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            {
                                props.point?.photoPointId ?
                                    <div className="w-100 d-flex justify-content-between">
                                        {renderSaveOrClose()}
                                        {
                                            props.point.discontinued ?
                                                <Button variant="action" onClick={() => reactivate()} disabled={props.readOnly}>
                                                    <FontAwesomeIcon icon={['fal', 'calendar-check']} />
                                                    Recontinue Photo Point
                                                </Button> :
                                                <Button variant="danger" onClick={() => props.deactivate()} disabled={props.readOnly}>
                                                    <FontAwesomeIcon icon={['fal', 'calendar-times']} />
                                                    Discontinue Photo Point
                                                </Button>
                                        }

                                    </div> : renderSaveOrClose()
                            }

                        </ModalFooter>
                    </Modal>
            }
        </Fragment>
    )
};

export default PhotoPointModal;
