export default class GeoPositionUtils {
    static defaultErrorHandler(error) { 
        switch (error.code) { 
            case error.PERMISSION_DENIED:
                alert('This feature requires the user to grant location permission(s).');
                break;
                
            case error.POSITION_UNAVAILABLE: 
                console.error( "The current geolocation is unavailable." ); 
                break;
                
            case error.TIMEOUT: 
                console.error( "The geolocation request timed out." ); 
                break;
            default:
                console.error( "An unknown error occurred while retrieving the geolocation." ); 
                break; 
        }
    }
    
    static getCurrentPosition(success, failure = null, options = {}) {
        options = {
            maximumAge: 3000,
            enableHighAccuracy: true,
            timeout: 5000,
            ...options
        }
        
        failure = failure ?? this.defaultErrorHandler;

        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(success, failure, options);
        } else {
            alert('Location services are unavailable on this device')
        }
    }
}
