/*
 * StreamBank Monitoring v2.0.21
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import React from 'react';
import { createSelector } from 'reselect';
import TreeNode from '../../Components/Pages/OfflineData/TreeNode';
import StatusUtils from '../../Utils/StatusUtils';
import {
    programSelector,
    projectSelector,
    sampleEventSelector,
    selectAvailableSampleEvents,
} from "./nodeSelectors";
import {OFFLINE_STATUS_READY_FOR_UPLOAD, STATUS_LABEL_UP_TO_DATE} from "../../Constants/status";
import {HIERARCHY_TYPE_PROJECT, HIERARCHY_TYPE_SAMPLE_EVENT_PHASE} from "../../Constants/hierarchy";
import {selectNotYetUploadedPhotos} from "./photoSelectors";
import {sortHierarchyNodes} from "../../Utils/SortUtils";
import DateUtils from "../../Utils/DateUtils";

const selectHierarchyNodes = state => state.hierarchyNodeState.hierarchyNodes;
const selectSelectedHierarchyNodes = state => state.hierarchyNodeState.selected;
const selectProjects = state => state.offlineDataState.projects;
const selectSampleEventPhases = state => selectImaginarySampleEventPhases(state);

const selectHierarchyNodeIds = createSelector(
    [selectHierarchyNodes],
    (hierarchyNodes) => {
        const hierarchyNodeIds = hierarchyNodes.map(node => node.hierarchyId);
        return hierarchyNodeIds;
    }
)

const selectModifiedProjectHierarchyIds = createSelector(
    [selectHierarchyNodeIds, selectProjects],
    (hierarchyNodeIds, projects) => {
        const updatedProjectIds = projects.filter(project => (
            hierarchyNodeIds.includes(project.hierarchyId) && !!project.offlineModifiedDate
        )).map(project => project.hierarchyId);
        return updatedProjectIds;
    }
)

const selectModifiedSampleEventHierarchyIds = createSelector(
    [selectHierarchyNodeIds, selectAvailableSampleEvents, selectNotYetUploadedPhotos],
    (hierarchyNodeIds, sampleEvents, notYetUploadedPhotos) => {
        const updatedSampleEventIds = sampleEvents.filter(sampleEvent => (
            hierarchyNodeIds.includes(sampleEvent.hierarchyId) && (
                !!sampleEvent.offlineModifiedDate
                || notYetUploadedPhotos.filter(photo => photo.sampleEventId === sampleEvent.sampleEventId).length > 0
            )
        )).map(sampleEvent => sampleEvent.hierarchyId);
        return updatedSampleEventIds;
    }
)

export const buildProjectPhaseHierarchyNode = (projectHierarchyId, sampleEventPhase) => {

    const hierarchyId = `${projectHierarchyId}/${sampleEventPhase.sampleEventPhaseId}`;

    return {
        hierarchyId,
        hierarchyTypeId: HIERARCHY_TYPE_SAMPLE_EVENT_PHASE,
        parentHierarchyId: projectHierarchyId,
        sampleEventPhaseId: sampleEventPhase.sampleEventPhaseId,
        name: sampleEventPhase.name,
        shortName: sampleEventPhase.shortName,
        bytes: 0,
        version: null,
    };
}


export const selectImaginarySampleEventPhases = createSelector(
    [
        sampleEventSelector,
        programSelector,
        projectSelector,
    ],
    (sampleEvents, programs, projects) => {
        const projectPhaseHierarchyNodes = programs.map( program => {
            return projects.map( project => {
                const sampleEventsKeyedByHierarchyNode = sampleEvents.reduce((carry, sampleEvent, index) => {

                    if( !!sampleEvent?.readOnly ) {
                        return carry;
                    } 
                    
                    // only add phases for the appropriate project and program
                    if( String(project.projectId)   !== String(sampleEvent.projectId     ) ||
                        // String(program.programId)   !== String(sampleEvent.programId     ) ||
                        String(program.hierarchyId) !== String(project.parentHierarchyId ) 
                    ) {
                        return carry;
                    }
                    
                    const sampleEventPhase = program?.SampleEventPhases?.find(type => String(type.sampleEventPhaseId) === String(sampleEvent.sampleEventPhaseId));
                    
                    const hierarchyId = `${project.hierarchyId}/${sampleEventPhase.sampleEventPhaseId}`;
                    
                    const projectPhaseHierarchyNode = carry[hierarchyId] ?? buildProjectPhaseHierarchyNode(
                        project.hierarchyId,
                        sampleEventPhase,
                    );

                    projectPhaseHierarchyNode.bytes += sampleEvent.bytes;
                    projectPhaseHierarchyNode.version = DateUtils.GreatestDate(
                        projectPhaseHierarchyNode.version,
                        sampleEvent.version
                    );
                    
                    carry[hierarchyId] = projectPhaseHierarchyNode

                    return carry;
                }, {});
                
                return Object.values(sampleEventsKeyedByHierarchyNode);
            })
                .filter(phases => phases.length > 0)
                .flat();
        });

        return projectPhaseHierarchyNodes
            .filter(phases => phases.length > 0)
            .flat();;
    }
);

const selectNewHierarchyNodes = createSelector(
    [selectHierarchyNodeIds, selectProjects, selectSampleEventPhases, selectAvailableSampleEvents],
    (hierarchyNodeIds, projects, sampleEventPhases, sampleEvents) => {
        const newNodes = [
            ...sampleEventPhases.filter(phase => !hierarchyNodeIds.includes(phase.hierarchyId)),
            ...projects.filter(project => !hierarchyNodeIds.includes(project.hierarchyId)),
            ...sampleEvents.filter(sampleEvent => !hierarchyNodeIds.includes(sampleEvent.hierarchyId))
        ].map(node => ({...node, offlineState: OFFLINE_STATUS_READY_FOR_UPLOAD}));
        return newNodes;
    }
)

export const selectUnsortedHierarchyNodes = createSelector(
    [selectHierarchyNodes, selectNewHierarchyNodes, selectModifiedProjectHierarchyIds, selectModifiedSampleEventHierarchyIds],
    (hierarchyNodes, newNodes, updatedProjectIds, updatedSampleEventIds) => {

        const allHierarchyNodes = [...hierarchyNodes, ...newNodes];

        return allHierarchyNodes.map(node => {
            if (updatedProjectIds.includes(node.hierarchyId) || updatedSampleEventIds.includes(node.hierarchyId)) {
                node.offlineState = OFFLINE_STATUS_READY_FOR_UPLOAD;
            }

            return node;
        });
    }
);

export const selectHierarchyNodesWithStatus = createSelector(
    [selectUnsortedHierarchyNodes, selectSelectedHierarchyNodes],
    (hierarchyNodes, selectedHierarchyNodes) => {
        const selectedNodeIds = getAllSelectedHierarchyNodeIds(hierarchyNodes, selectedHierarchyNodes);

        return hierarchyNodes.map(node => {
            const selected = !!selectedNodeIds.includes(String(node.hierarchyId));

            return {
                ...node,
                selected,
                statusLabel: StatusUtils.GetStatusLabel(node, selected),
            }
        })
    }
);

export const selectSortedHierarchyNodesWithStatus = createSelector(
    [selectHierarchyNodesWithStatus],
    sortHierarchyNodes
);

export const selectHierarchyNodesAsTree = createSelector(
    [selectSortedHierarchyNodesWithStatus],
    (hierarchyNodes) => {
        const treeList = listToTree(hierarchyNodes);

        return treeList.map(node => {
            return {
                ...node,
                children: convertData(node.children)
            }
        })
    }
);

export const selectTotalDownloadSize = createSelector(
    [selectUnsortedHierarchyNodes, selectSelectedHierarchyNodes],
    (hierarchyNodes, selectedHierarchyNodes) => {
        const flatSelectedNodeIds = getFlatHierarchyNodeIds(selectedHierarchyNodes);

        return flatSelectedNodeIds.reduce((acc, curr) => {
            const hierarchyNode = hierarchyNodes.find(node => String(node.hierarchyId) === String(curr));
            const bytes = hierarchyNode ? hierarchyNode.bytes : 0;

            return acc + bytes;
        }, 0);
    }
);

const listToTree = (list) => {
    const treeList = [];
    const lookup = {};

    list.forEach((obj) => {
        lookup[obj.hierarchyId] = obj;
        obj.children = [];
    });

    list.forEach((currentNode) => {
        const parentNode = lookup[currentNode.parentHierarchyId];

        if (!currentNode.parentHierarchyId) {
            treeList.push(currentNode);
        } else if (!parentNode) { 
            console.log(currentNode.hierarchyId + " is not a top-level parent but we couldn't find parent id " + currentNode.parentHierarchyId);
        } else {
            parentNode.bytes += currentNode.bytes;

            if (parentNode.parentHierarchyId) {
                if(!lookup[parentNode.parentHierarchyId]) {
                    // I think this happens if we have offline changes from another organization
                    // and it isn't visible on our current one? this should be fixed if we
                    // clear the data when changing orgs
                    console.log("couldn't find parent with hierarchyId:", parentNode.parentHierarchyId);

                } else {
                    lookup[parentNode.parentHierarchyId].bytes += currentNode.bytes;
                }
            }

            parentNode.children.push(currentNode);
        }
    });

    return treeList;
};

const convertData = (nodes) => {
    return nodes.map((node) => {
        let className = null;
        let parentIcon = null;

        // Hide programs that don't have projects
        if (node.hierarchyTypeId < HIERARCHY_TYPE_PROJECT && !node.children.length) {
            return null;
        }

        if (node.children.length) {
            parentIcon = StatusUtils.GetComputedParentIcon(node);
        }

        if (
            StatusUtils.GetStatusLabel(node, node.selected) === STATUS_LABEL_UP_TO_DATE &&
            (!node.children.length || (parentIcon.iconColor === 'success' && !parentIcon.hasMultipleStates))
        ) {
            // Disabled node if synced leaf node
            className = 'disabled-node';
        }

        return {
            ...node,
            value: node.hierarchyId,
            label: <TreeNode node={node} parentIcon={parentIcon} />,
            title: node.name ?? 'New Event',
            children: node.children.length && convertData(node.children),
            className,
        }
    }).filter(node => !!node);
};

const getAllSelectedHierarchyNodeIds = (hierarchyNodes, selectedHierarchyNodes) => {
    const selected = new Set(
        getFlatHierarchyNodeIds(selectedHierarchyNodes)
    );

    selected.forEach(selectedNode => {
        const selectedHierarchyNode = hierarchyNodes.find(node => String(node.hierarchyId) === String(selectedNode));

        selectedHierarchyNode?.hierarchyId && selected.add(String(selectedHierarchyNode.hierarchyId));
        selectedHierarchyNode?.parentHierarchyId && selected.add(String(selectedHierarchyNode.parentHierarchyId));
        selectedHierarchyNode?.programContextNodeId && selected.add(String(selectedHierarchyNode.programContextNodeId));
        selectedHierarchyNode?.subProgramContextNodeId && selected.add(String(selectedHierarchyNode.subProgramContextNodeId));
        selectedHierarchyNode?.projectContextNodeId && selected.add(String(selectedHierarchyNode.projectContextNodeId));
    });

    return [...selected];
};

const getFlatHierarchyNodeIds = (nodes) => {
    return Object.values(nodes).flat().map(node => node)
};

export const selectUploadCount = createSelector(
    [selectUnsortedHierarchyNodes],
    (unsortedHierarchyNodes) => {
        return unsortedHierarchyNodes.filter(node => node.offlineState === OFFLINE_STATUS_READY_FOR_UPLOAD).length;
    }
);
