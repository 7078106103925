import {UPSERT_TRANSECT} from "./Types/offlineDataActionTypes";
import {BACKSPACE, INCREMENT, SET, SET_REPLACE, UNDO} from "./Types/transectActionTypes";
import {
    invasiveCoverCategories,
    lengthOfCoverCategories,
    stemTallyCategories,
    treeDBHCategories,
    TYPE_INVASIVE_COVER
} from '../../Constants/lengthCategories';
import LengthCategoryUtils from "../../Utils/LengthCategoryUtils";


export const updateTransectReview = (transect, code, value, userId) => (dispatch) => {
    dispatch({
        type: UPSERT_TRANSECT,
        transect: {
            ...transect,
            TransectReviews: [...transect.TransectReviews].map(review => {
                if (review.code === code) {
                    return {
                        ...review,
                        isConfirmed: value,
                        confirmedBy: userId,
                    };
                }

                return review;
            })
        }
    });
};

export const updateValue = (action, type, number = null) => (dispatch, getState) => {
    const {currentTransect, selectedInput, selectedField, setReplace} = getState().transectState;
    const transects = getState().offlineDataState.transects;
    const transect = transects.find(transect => String(transect.transectId) === String(currentTransect));
    const hydrozone = transect.Hydrozones?.find(hydrozone => String(hydrozone.hydrozoneId) === String(selectedInput.hydrozoneId));
    const hydrozoneSpeciesType = type === TYPE_INVASIVE_COVER ? 'HydrozoneGroundCoverSpecies' : 'HydrozonePlotSpecies';
    const hydrozoneSpecies = hydrozone[hydrozoneSpeciesType].find(species =>
        String(species.speciesId) === String(selectedInput?.speciesId) &&
        String(species?.plotTypeId) === String(selectedInput?.plotTypeId)
    );

    const currentValue = hydrozoneSpecies ? hydrozoneSpecies[selectedField] || 0 : hydrozone[selectedField];
    let value;

    switch(action) {
        case SET:
            value = Number(currentValue) === 0 || setReplace ? number : `${currentValue}${number}`;
            break;
        case BACKSPACE:
            value = String(currentValue).slice(0, -1);
            if (!value) {
                value = 0
            }
            break;
        case INCREMENT:
            value = Number(currentValue) + number;
            break;
        case UNDO:
            value = selectedInput[selectedField] || 0;
            break;
    }

    switch (selectedField) {
        case 'leftQuadratStart':
        case 'rightQuadratStart':
            value = Math.round(value);
            if(value >= 0 && value <= 255) {
                dispatch(updateHydrozone(selectedField, Number(value)));
            }
            break;
        default:
            dispatch({
                type: UPSERT_TRANSECT,
                transect: {
                    ...transect,
                    Hydrozones: transect.Hydrozones.map(hydrozone => {
                        if (String(hydrozone.hydrozoneId) === String(selectedInput.hydrozoneId)) {
                            return {
                                ...hydrozone,
                                [hydrozoneSpeciesType]: hydrozone[hydrozoneSpeciesType].map(species => {
                                    if (String(species.speciesId) === String(selectedInput.speciesId) &&
                                        String(species?.plotTypeId) === String(selectedInput?.plotTypeId)) {
                                        return {
                                            ...species,
                                            [selectedField]: Number(value),
                                        }
                                    }

                                    return species;
                                })
                            }
                        }

                        return hydrozone;
                    })
                }
            });
    }

    dispatch({type: SET_REPLACE, setReplace: false});
};

export const addSpecies = (speciesId, speciesName, type) => (dispatch, getState) => {
    const {currentTransect, currentHydrozone, currentPlot} = getState().transectState;
    const transects = getState().offlineDataState.transects;
    const transect = transects.find(transect => String(transect.transectId) === String(currentTransect));

    let hydrozoneSpeciesType, dataKeys;
    const addedSpecies = {speciesId, speciesName, hydrozoneId: currentHydrozone};

    if (type === TYPE_INVASIVE_COVER) {
        hydrozoneSpeciesType = 'HydrozoneGroundCoverSpecies';
        dataKeys = invasiveCoverCategories;
    } else {
        hydrozoneSpeciesType = 'HydrozonePlotSpecies';
        dataKeys = [...stemTallyCategories, ...lengthOfCoverCategories, ...treeDBHCategories];
        addedSpecies.hydrozonePlotSpeciesId = `${currentHydrozone}-${currentPlot}-${speciesId}`;
        addedSpecies.plotTypeId = currentPlot;
    }

    dataKeys.forEach(data => {
        addedSpecies[data.code] = 0;
    });

    dispatch({
        type: UPSERT_TRANSECT,
        transect: {
            ...transect,
            Hydrozones: transect.Hydrozones.map(hydrozone => {
                if (String(hydrozone.hydrozoneId) === String(currentHydrozone)) {
                    return {
                        ...hydrozone,
                        [hydrozoneSpeciesType]: [
                            ...hydrozone[hydrozoneSpeciesType],
                            addedSpecies,
                        ]
                    }
                }

                return hydrozone;
            })
        }
    })
};

export const updateSpecies = (type, speciesId, speciesName, notes = null) => (dispatch, getState) => {
    const {currentTransect, selectedInput} = getState().transectState;

    const transects = getState().offlineDataState.transects;
    const transect = transects.find(transect => String(transect.transectId) === String(currentTransect));
    const hydrozoneSpeciesType = type === TYPE_INVASIVE_COVER ? 'HydrozoneGroundCoverSpecies' : 'HydrozonePlotSpecies';

    dispatch({
        type: UPSERT_TRANSECT,
        transect: {
            ...transect,
            Hydrozones: transect.Hydrozones.map(hydrozone => {
                if (String(hydrozone.hydrozoneId) === String(selectedInput.hydrozoneId)) {
                    return {
                        ...hydrozone,
                        [hydrozoneSpeciesType]: hydrozone[hydrozoneSpeciesType].map(species => {
                            if (String(species.speciesId) === String(selectedInput.speciesId) &&
                                String(species?.plotTypeId) === String(selectedInput?.plotTypeId)) {
                                let speciesObject = {...species, speciesId, speciesName};

                                if (notes != null) {
                                    speciesObject.notes = notes;
                                }

                                return speciesObject;
                            }

                            return species;
                        })
                    }
                }

                return hydrozone;
            })
        }
    })
};

export const updateHydrozone = (fieldName, value) => (dispatch, getState) => {
    const {currentTransect, currentHydrozone} = getState().transectState;
    const transects = getState().offlineDataState.transects;
    const transect = transects.find(transect => String(transect.transectId) === String(currentTransect));

    dispatch({
        type: UPSERT_TRANSECT,
        transect: {
            ...transect,
            Hydrozones: transect.Hydrozones.map(hydrozone => {
                if (String(hydrozone.hydrozoneId) === String(currentHydrozone)) {
                    return {
                        ...hydrozone,
                        [fieldName]: value,
                    }
                }

                return hydrozone;
            })
        }
    })
};

export const updateNoPlants = (value) => (dispatch, getState) => {
    const {currentTransect, currentHydrozone, currentPlot, currentSpeciesPage} = getState().transectState;
    const transects = getState().offlineDataState.transects;    
    const transect = transects.find(transect => String(transect.transectId) === String(currentTransect));
    const fieldName = LengthCategoryUtils.GetNoPlantsFieldName(currentSpeciesPage, currentPlot);

    dispatch({
        type: UPSERT_TRANSECT,
        transect: {
            ...transect,
            Hydrozones: transect.Hydrozones.map(hydrozone => {
                if (String(hydrozone.hydrozoneId) === String(currentHydrozone)) {
                    return {
                        ...hydrozone,
                        [fieldName]: value,
                    }
                }

                return hydrozone;
            })
        }
    })
};

export const updateCanopyClosureValue = (transectId, fieldName, value, download = false) => (dispatch, getState) => {
    const transects = getState().offlineDataState.transects;
    const transect = transects.find(transect => String(transect.transectId) === String(transectId));
    let {north, south, east, west, canopyClosure} = transect.TransectCanopyClosure ?? {};
    const directions = {north, south, east, west};

    // Auto-populate canopyClosure value
    switch (fieldName) {
        case 'north':
        case 'south':
        case 'east':
        case 'west':
            const values = Object.values({...directions, [fieldName]: value});

            canopyClosure = values.reduce((acc, curr) => {
                return acc + Number(curr === '' ? undefined : curr) / 0.96;
            }, 0);

            canopyClosure = isNaN(canopyClosure) ? null : (100 - (canopyClosure / values.length).toFixed(2));
            break;
    }

    dispatch({
        type: UPSERT_TRANSECT,
        transect: {
            ...transect,
            TransectCanopyClosure: {
                ...transect.TransectCanopyClosure,
                [fieldName]: value,
                canopyClosure,
            }
        },
        download,
    })
};

export const updateTransectNarrativeValue = (transectId, fieldName, value) => (dispatch, getState) => {
    const transects = getState().offlineDataState.transects;
    const transect = transects.find(transect => String(transect.transectId) === String(transectId));

    dispatch({
        type: UPSERT_TRANSECT,
        transect: {
            ...transect,
            TransectNarrative: {
                ...transect.TransectNarrative,
                [fieldName]: value
            }
        }
    })
};

export const addTransectNarrativeItem = (transectId, fieldName) => (dispatch, getState) => {
    const transects = getState().offlineDataState.transects;
    const transect = transects.find(transect => String(transect.transectId) === String(transectId));
    const index = transect.TransectNarrative[fieldName].length ? Math.max(...transect.TransectNarrative[fieldName].map(field => field.index)) + 1 : 0;
    let newItem;

    switch (fieldName) {
        case 'TransectNarrativeIrrigation':
            newItem = {index, irrigationMethod: '', notes: ''};
            break;
        case 'TransectNarrativeBrowse':
            newItem = {index, animalSpecies: '', plantSpecies: '', browseSeverity: ''};
            break;
        case 'TransectNarrativeVigor':
            newItem = {index, plantSpecies: '', overallVigor: '', suggestedCauses: ''};
            break;
    }

    dispatch({
        type: UPSERT_TRANSECT,
        transect: {
            ...transect,
            TransectNarrative: {
                ...transect.TransectNarrative,
                [fieldName]: [...transect.TransectNarrative[fieldName], newItem]
            }
        }
    })
};

export const updateTransectNarrativeItem = (transectId, fieldName, index, column, value) => (dispatch, getState) => {
    const transects = getState().offlineDataState.transects;
    const transect = transects.find(transect => String(transect.transectId) === String(transectId));


    dispatch({
        type: UPSERT_TRANSECT,
        transect: {
            ...transect,
            TransectNarrative: {
                ...transect.TransectNarrative,
                [fieldName]: transect.TransectNarrative[fieldName].map(field => {
                    if (field.index === index) {
                        return {...field, [column]: value};
                    }

                    return field;
                })
            }
        }
    })
};

export const deleteTransectNarrativeItem = (transectId, fieldName, index) => (dispatch, getState) => {
    const transects = getState().offlineDataState.transects;
    const transect = transects.find(transect => String(transect.transectId) === String(transectId));


    dispatch({
        type: UPSERT_TRANSECT,
        transect: {
            ...transect,
            TransectNarrative: {
                ...transect.TransectNarrative,
                [fieldName]: transect.TransectNarrative[fieldName].filter(field => field.index !== index)
            }
        }
    })
};

export const upsertTransects = (transects, download) => (dispatch, getState) => {
    if (transects?.length) {
        transects.forEach(transect => {
            dispatch({type: UPSERT_TRANSECT, transect, download});
        });
    }
}
