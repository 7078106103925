import {useCallback, useEffect, useRef} from "react";

export const useUpdateEffect = (fn, dependency = []) => {
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            return fn();
        }
    }, dependency)
};

export const useDebouncedEffect = (fn, dependency, delay = 500) => {
    const callback = useCallback(fn, dependency);

    useUpdateEffect(() => {
        const handler = setTimeout(() => {
            callback();
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [callback, delay]);
};
