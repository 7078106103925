import React, {useState} from 'react';
import RepeatableSection from "../RepeatableSection";
import {useDispatch, useSelector} from "react-redux";
import {selectSampleEventById} from "../../../Redux/Selectors/nodeSelectors";
import {
    addRepeaterItem,
    deleteRepeaterItem,
    updateRepeaterItem
} from "../../../Redux/Actions/surveyActions";
import {useNextTempId} from "../../../Hooks/TempIdHooks";
import {LIST_PROBLEMATIC} from "../../../Constants/species";
import {competitionLifeStageOptions} from "../../../Constants/survey";
import {useRepeaterParameters} from "../../../Hooks/SurveyHooks";

const CompetitionComponent = props => {
    const dispatch = useDispatch();
    const nextId = useNextTempId();
    const repeaterParameters = useRepeaterParameters();

    const sampleEvent = useSelector(state => selectSampleEventById(state, props.answers.sampleEventId));
    const user = useSelector(state => state.userState.user);

    const answerId = props.answers.qualitativeVegetationMonitoringId;
    const data = props.answers.QualitativeVegetationMonitoringRepeaters.filter(repeater => repeater.code === props.code);
    const parameters = repeaterParameters(props.code);

    return (
        <div className="competition-component">
            <RepeatableSection
                name={parameters.name}
                data={data}
                primaryKey="qualitativeVegetationMonitoringRepeaterId"
                fields={parameters.fields}
                colSize={4}
                addItem={() => dispatch(addRepeaterItem(answerId, props.code, user.userId, nextId()))}
                updateItem={(index, column, value) => dispatch(updateRepeaterItem(answerId, index, column, value))}
                deleteItem={(index) => dispatch(deleteRepeaterItem(answerId, index))}

                // for species input
                answerId={answerId}
                sampleEventId={sampleEvent.sampleEventId}
                readOnly={props.readOnly}
            />
        </div>
    )
};

export default CompetitionComponent;
