import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams, useLocation} from 'react-router-dom'

import PageHeader from "../../Layout/Page/PageHeader";
import Title from "../../Layout/TitleComponent";
import PageContent from "../../Layout/Page/PageContent";
import {Col, FormCheck, Row} from "react-bootstrap";
import PageFooter from "../../Layout/Page/PageFooter";
import PageContainer from "../../Layout/Page/PageContainer";
import FooterNav from "../../Layout/Page/FooterNav";
import {useTransectReviewNav} from "../../../Hooks/QuickNavHooks";
import {selectTransect} from "../../../Redux/Selectors/transectSelectors";
import SpeciesTable from "./SpeciesTable";
import {
    TYPE_LENGTH_OF_COVER,
    TYPE_STEM_TALLY,
} from "../../../Constants/lengthCategories";
import {selectProjectById} from "../../../Redux/Selectors/nodeSelectors";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import {useContextToolsBySampleEventId} from "../../../Hooks/ToolHooks";
import {updateTransectReview} from "../../../Redux/Actions/transectActions";
import {useTransectToolProgress} from "../../../Hooks/ProgressHooks";
import {useFinalized} from "../../../Hooks/DataHooks";
import {PLOT_TYPE_1, PLOT_TYPE_2} from "../../../Constants/hydrozones";
import {useHydrozoneSpeciesTypeName} from "../../../Hooks/LabelHooks";
import {
    TRANSECT_REVIEW_CODE_DBH,
    TRANSECT_REVIEW_CODE_INVASIVE_COVER,
    TRANSECT_REVIEW_CODE_STEM_LENGTH
} from "../../../Constants/transectReview";


const HydrozoneSpeciesReviewPage = props => {
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    const transectToolProgress = useTransectToolProgress();
    const getHydrozoneTypeName = useHydrozoneSpeciesTypeName();

    useTransectReviewNav(params.transectId);
    const user = useSelector(state => state.userState.user);

    const transect = useSelector(state => selectTransect(state, params.transectId));
    const project = useSelector(state => selectProjectById(state, transect.projectId));

    useBreadcrumbs('Transect Review', transect.projectId, transect.sampleEventId, transect.transectId);
    useContextToolsBySampleEventId(transect.sampleEventId);
    const finalized = useFinalized(transect.sampleEventId);

    const [group, setGroup] = useState(false);
    const [type, setType] = useState(null);
    const [subtitle, setSubtitle] = useState(null);
    const [toolProgress, setToolProgress] = useState(null);

    const data = (type === TRANSECT_REVIEW_CODE_INVASIVE_COVER ? transect.Hydrozones : transect.Hydrozones.filter(hydrozone => !!hydrozone.HydrozonePlotSpecies.length));

    useEffect(() => {
        const pageType = location.pathname.split('/').pop(); // last segment of url

        switch (pageType) {
            case TRANSECT_REVIEW_CODE_STEM_LENGTH:
                setSubtitle('Plot 1 & Plot 2');
                setGroup(0);
                break;
            case TRANSECT_REVIEW_CODE_DBH:
                setSubtitle('Tree DBH');
                setGroup(1);
                break;
            case TRANSECT_REVIEW_CODE_INVASIVE_COVER:
                setSubtitle('Invasive + Ground Cover');
                setGroup(0);
                break;
        }

        setType(pageType);
        setToolProgress(transectToolProgress(transect, ['plot1'])[0]);
    }, [location.pathname]);

    const filterHydrozonesByPlot = (plotTypeId) => {
        return data.map(hydrozone => ({
            ...hydrozone,
            HydrozonePlotSpecies: hydrozone.HydrozonePlotSpecies.filter(species => Number(species.plotTypeId) === Number(plotTypeId))
        }));
    };

    const isConfirmed = () => {
        return transect.TransectReviews.find(review => review.code === type)?.isConfirmed ?? false;
    };

    const renderTable = () => {
        switch (type) {
            case 'stem-length':
                return (
                    <Fragment>
                        <Col xs={12}>
                            <SpeciesTable
                                species={project.Species}
                                data={filterHydrozonesByPlot(PLOT_TYPE_1)}
                                transectId={transect.transectId}
                                type={TYPE_STEM_TALLY}
                                group={group}
                                title={`${getHydrozoneTypeName(TYPE_STEM_TALLY)} - Plot ${PLOT_TYPE_1}`}
                            />
                        </Col>
                        <Col xs={12}>
                            <SpeciesTable
                                species={project.Species}
                                data={filterHydrozonesByPlot(PLOT_TYPE_1)}
                                transectId={transect.transectId}
                                type={TYPE_LENGTH_OF_COVER}
                                group={group}
                                title={`${getHydrozoneTypeName(TYPE_LENGTH_OF_COVER)} - Plot ${PLOT_TYPE_1}`}
                            />
                        </Col>
                        <Col xs={12}>
                            <SpeciesTable
                                species={project.Species}
                                data={filterHydrozonesByPlot(PLOT_TYPE_2)}
                                transectId={transect.transectId}
                                type={TYPE_STEM_TALLY}
                                group={group}
                                title={`${getHydrozoneTypeName(TYPE_STEM_TALLY)} - Plot ${PLOT_TYPE_2}`}
                            />
                        </Col>
                        <Col xs={12}>
                            <SpeciesTable
                                species={project.Species}
                                data={filterHydrozonesByPlot(PLOT_TYPE_2)}
                                transectId={transect.transectId}
                                type={TYPE_LENGTH_OF_COVER}
                                group={group}
                                title={`${getHydrozoneTypeName(TYPE_LENGTH_OF_COVER)} - Plot ${PLOT_TYPE_2}`}
                            />
                        </Col>
                    </Fragment>
                );
            case 'dbh':
                return (
                    <Fragment>
                        <Col xs={12}>
                            <SpeciesTable
                                species={project.Species}
                                data={filterHydrozonesByPlot(PLOT_TYPE_1)}
                                transectId={transect.transectId}
                                type={type}
                                group={group}
                                title={`Plot ${PLOT_TYPE_1}`}
                            />
                        </Col>
                        <Col xs={12}>
                            <SpeciesTable
                                species={project.Species}
                                data={filterHydrozonesByPlot(PLOT_TYPE_2)}
                                transectId={transect.transectId}
                                type={type}
                                group={group}
                                title={`Plot ${PLOT_TYPE_2}`}
                            />
                        </Col>
                    </Fragment>
                );
            default:
                return (
                    <Col xs={12}>
                        <SpeciesTable
                            species={project.Species}
                            data={data}
                            transectId={transect.transectId}
                            type={type}
                            group={group}
                        />
                    </Col>
                );
        }
    };

    return (
        <PageContainer className="plot-review-page">
            <PageHeader>
                <Title
                    title="Transect Review"
                    subtitle={subtitle}
                    action={
                        <div className="group-by-hydrozone-field">
                            <FormCheck
                                id="group-by-hydrozone"
                                label="Group All Hydrozones"
                                type="checkbox"
                                value={String(group)}
                                checked={group}
                                onChange={() => setGroup(!group)}
                            />
                        </div>
                    }
                />
            </PageHeader>
            <PageContent>
                <Row xs={12}>
                    {renderTable()}
                </Row>
            </PageContent>
            <PageFooter>
                <FooterNav>
                    <div className="complete-checkbox">
                        <FormCheck
                            id="complete-checkbox"
                            label="Confirm"
                            type="checkbox"
                            value={isConfirmed()}
                            checked={isConfirmed()}
                            onChange={() => dispatch(updateTransectReview(transect, type, !isConfirmed(), user.userId))}
                            disabled={finalized}
                        />
                    </div>
                </FooterNav>
            </PageFooter>
        </PageContainer>
    )
};

export default HydrozoneSpeciesReviewPage;
