export default class ObjectUtils {
    static Clone(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    static DeepClone(obj) {
        if(obj === null) {
            return obj;
        }

        if(Array.isArray(obj)) {
            return obj.map((x) => ObjectUtils.DeepClone(x));
        } else if (typeof obj === "function") {
            return obj;
        } else if (typeof obj === 'object' && obj !== null) {
            let clone = Object.create(obj.constructor.prototype);

            for(let i in obj) {
                if(obj[i] != null && typeof(obj[i]) == "object") {
                    clone[i] = ObjectUtils.DeepClone(obj[i]);
                } else {
                    clone[i] = obj[i];
                }
            }

            return clone;
        } else {
            return obj;
        }
    }

    static Merge(a, b) {
        return Object.assign(a, b);
    }

    static ReactElementReplacer(key, value) {
        if(key[0] === '_') {
            // React.element has these stupid
            // things that contain circular references
            return;
        }
        return value;
    }

    // deep compare returns false if the objects are different
    static Compare(a, b, replacer) {
        if(
            (a === null && b !== null) ||
            (a !== null && b === null)
        ) {
            return false;
        } else if (a === null && b === null) {
            return true;
        } else {
            return JSON.stringify(a, replacer) === JSON.stringify(b, replacer);
        }
    }

    static BuildQueryString(obj) {
        let ret = [];
        for(let propertyName in obj) {
            if(Array.isArray(obj[propertyName])) {
                for (const value of obj[propertyName]) {
                    ret.push(encodeURIComponent(propertyName) + "[]=" + encodeURIComponent(value));
                }
            } else if(obj[propertyName] != null) {
                ret.push(encodeURIComponent(propertyName) + "=" + encodeURIComponent(obj[propertyName]));
            }
        }
        return ret.join("&");
    }

    static IsString(obj) {
        return (typeof obj === 'string' || obj instanceof String);
    }
}
