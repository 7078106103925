import React from 'react';

const PageHeader = props => {
    return (
        <div className="streambank-header">
            {props.children}
        </div>
    )
};

export default PageHeader;
