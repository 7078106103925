import { Base64 } from 'js-base64';

import ObjectUtils from '../../Utils/ObjectUtils';
import autobind from "autobind-decorator";
import config from '../../Config';
import { SET_USER, SET_PERMISSIONS } from "../../Redux/Actions/Types/userActionTypes";
import {SET_LOADING} from "../../Redux/Actions/Types/ajaxActionTypes";
import {SET_SESSION_TIMEOUT} from "../../Redux/Actions/Types/loginActionTypes";
import DateUtils from "../../Utils/DateUtils";

let auth_token = '';
let token_data = {};
let permissions = [];
let pendingAjax = 0;

function setTokenData() {
    let new_token_data, userChannelPayload;
    if(auth_token && auth_token != '') {
        new_token_data = JSON.parse(Base64.decode(auth_token.split('.')[1]));
        userChannelPayload = new_token_data;
    } else {
        new_token_data = {};
        userChannelPayload = null;
    }

    if(!ObjectUtils.Compare(token_data, new_token_data)) {
        token_data = new_token_data;
        config.getStore().dispatch({
            type: SET_USER,
            user: userChannelPayload,
        });
    }
}

function setPermissions() {
    config.getStore().dispatch({
        type: SET_PERMISSIONS,
        permissions,
    });
}

if (localStorage.getItem("storedAuthToken") != null) {
    auth_token = localStorage.getItem("storedAuthToken");
    setTokenData();
}

if (localStorage.getItem("storedPermissions") != null) {
    let savedData = localStorage.getItem("storedPermissions");
    permissions = JSON.parse(savedData);
    setPermissions();
}

//if the authtoken / permissions changes on another tab/window let's load those changes here.
window.addEventListener('storage', (e) => {
    if(e.key == "storedAuthToken") {
        let shouldReloadPage = false;

        if(auth_token != e.newValue) {
            auth_token = e.newValue;
            setTokenData();
        }
    } else if (e.key == "storedPermissions") {
        let savedData = e.newValue;
        let parsedData = JSON.parse(savedData);

        if(!ObjectUtils.Compare(parsedData, permissions)) {
            permissions = parsedData;
            setPermissions();
        }
    }
});

class BaseService {
    constructor() {}

    static get GET() { return 'GET'; }
    static get POST() { return 'POST'; }
    static get PUT() { return 'PUT'; }
    static get DELETE() { return 'DELETE'; }

    static get AUTH_TOKEN() {
        return auth_token;
    }
    static set AUTH_TOKEN(value) {
        auth_token = value;
        setTokenData();
        
        if(value) {
            localStorage.setItem("storedAuthToken", value);
        } else {
            localStorage.removeItem("storedAuthToken");
        }
    }
    static get TOKEN_DATA() {
        return token_data;
    }

    static get PERMISSIONS() {
        return permissions;
    }
    static set PERMISSIONS(value) {
        permissions = value;
        setPermissions();
        
        if((value ?? []).length) {
            localStorage.setItem("storedPermissions", JSON.stringify(value));
        } else {
            localStorage.removeItem("storedPermissions");
        }
    }

    static get IS_AUTHENTICATED() {
        if (auth_token === '') {
            return false;
        } else if (token_data.twoFactorRequired) {
            return false;
        } else if (token_data.publicContext) {
            return false;
        } else if (token_data.activeUntil) {
            let activeUntil = new Date(token_data.activeUntil);
            console.log(token_data.activeUntil, activeUntil);
            if (DateUtils.IsAfter(activeUntil, new Date())) {
                return false;
            }
        }
        return true;
    }

    get Url() { return config.getApiUrl() + "/api"; }
    get ModelName() { console.log("Not implemented."); return "Model Name"; }
    get ModelNamePlural() { console.log("Not implemented."); return "items"; }
    get PrimaryKeyProperty() { console.log("Not implemented."); return null; }
    get TitleProperty() { return "name"; }

    AuthLogic(xhr, overrideJwt = null) {
        let jwt = overrideJwt;

        if(!jwt) {
            jwt = BaseService.AUTH_TOKEN;
        }

        xhr.setRequestHeader('Authorization', jwt);
    }

    SetLoading(loading = true) {
        config.getStore().dispatch({type: SET_LOADING, value: loading});
    }

    SendRequest(method, postData = null, overrideJwt = null, urlSuffix = "", inBackground = false) {
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            let url = this.Url + urlSuffix;

            switch(method) {
                case BaseService.GET:
                    if(postData) {
                        if(typeof postData === 'object') {
                            url = url + "?" + ObjectUtils.BuildQueryString(postData);
                        } else {
                            url = url + "/" + postData;
                        }
                    }
                    break;
                case BaseService.POST:
                    //xhr.setRequestHeader('Content-type', 'application/json');
                    break;
                case BaseService.PUT:
                    //xhr.setRequestHeader('Content-type', 'application/json');
                    break;
                case BaseService.DELETE:
                    if(postData) {
                        url = url + "/" + postData;
                    }
                    break;
            }

            xhr.open(method, url);
            xhr.withCredentials = true;

            this.AuthLogic(xhr, overrideJwt);

            xhr.setRequestHeader('Content-type', 'application/json');

            xhr.onload = () => {

                if(!inBackground) {
                    pendingAjax--;
                    if (pendingAjax === 0) {
                        this.SetLoading(false);
                    }
                }

                let result;

                try {
                    result = JSON.parse(xhr.responseText);
                } catch(e) {
                    result = { status: xhr?.status, message: xhr?.statusText ?? 'Invalid response' }
                }

                if (xhr.status === 200) {
                    BaseService.refreshAuthTokenFromAPIResponseData(result);
                    resolve(result);
                } else if (xhr.status === 401) {
                    config.getStore().dispatch({type: SET_SESSION_TIMEOUT, value: true});

                    reject({ message: result?.message ?? xhr?.statusText ?? 'Session timeout.', xhr: xhr, data: { success: false, sessionTimeout: true }});
                } else {
                    reject({ message: result?.message ?? 'Invalid response.', xhr: xhr, data: xhr.responseText });
                }
            };
            
            xhr.onerror = (error) => {
                reject(error);
            };

            if(!inBackground) {
                pendingAjax++;
                if(pendingAjax === 1) {
                    this.SetLoading();
                }
            }

            //TODO: This is ugly. Make less ugly
            if(method === BaseService.DELETE || method === BaseService.GET) {
                xhr.send();
            } else {
                xhr.send(JSON.stringify(this._CleanData(postData)));
            }
        });
    }

    _CleanData(data) {
        //clone the data so we don't goof up anything with the ui... or anything else dependant on this data.
        let clone = ObjectUtils.Clone(data);

        //remove all _tempId entries on the data.
        clone = this._RemoveTempId(clone);

        //return cleaned copy.
        return clone;
    }

    _RemoveTempId(data) {
        //only work on objects. If anything else lets skip this logic and just return what we're passed.
        if(data instanceof Object) {
            //drop _tempId if it exists on this object.
            if(data._tempId) {
                delete data._tempId;
            }

            //for each property on the object.
            Object.keys(data).forEach((x) => {
                if(data[x] instanceof Object) {
                    //if this property is an object run this logic recursively.
                    data[x] = this._RemoveTempId(data[x]);
                } else if (Array.isArray(data[x])) {
                    //if this is an array run this logic for each entry.
                    for(let i = 0; i < data[x].length; i++) {
                        data[x][i] = this._RemoveTempId(data[x][i]);
                    }
                }
            });
        }

        return data;
    }


    // default implementations
    // which can be overridden at the
    // service level -- I feel like
    // these should come from the model :/
    //
    GetTitle(model) {
        return model != null ? model[this.TitleProperty] : "";
    }

    GetSubtitle(model) {
        return null;
    }

    GetPrimaryKey(model) {
        return model[this.PrimaryKeyProperty];
    }

    @autobind
    SortModels(a, b, value) {
        const titleA = this.GetTitle(a);
        const titleB = this.GetTitle(b);
        let result = 0;

        // primary sort
        if (titleA && titleB == null) {
            result =  -1;
        } else if (titleA == null && titleB) {
            result = 1;
        } else {
            result = titleA.localeCompare(titleB);
        }

        // secondary sort
        if (result === 0) {
            result = (this.GetPrimaryKey(a) < this.GetPrimaryKey(b) ? 1 : -1);
        }

        return result;
    }

    static refreshAuthTokenFromAPIResponseData(result) {
        if(result?.jwt) {
            BaseService.AUTH_TOKEN = result.jwt;
            if(result?.permissions) {
                BaseService.PERMISSIONS = result.permissions;
            }
        }
    }
}

export default BaseService;
