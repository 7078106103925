import React, {Fragment, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {sendCode} from "../../Redux/Actions/loginActions";
import {SET_PHONE_NUMBER, SET_TITLE} from "../../Redux/Actions/Types/loginActionTypes";

import PhoneNumberForm from "./PhoneNumberForm";
import VerificationCodeForm from "./VerificationCodeForm";

const VerificationComponent = (props) => {
    const {displayPhoneNumberForm} = useSelector(state => state.loginState);
    const user = useSelector(state => state.userState.user);
    const dispatch = useDispatch();

    useEffect(() => {
        const maskedNumber = user?.maskedNumber;

        if (maskedNumber) {
            dispatch({type: SET_PHONE_NUMBER, maskedNumber});
            dispatch(sendCode());
        } else {
            dispatch({type: SET_TITLE, title: 'Set up Two-Factor Authentication'});
        }
    }, []);

    return (
        <Fragment>
            {
                displayPhoneNumberForm ?
                    <PhoneNumberForm HideButtons={props.HideButtons} /> :
                    <VerificationCodeForm
                        OnSuccess={props.OnSuccess}
                        HideButtons={props.HideButtons}
                    />
            }
        </Fragment>
    );
};

export default VerificationComponent;
