import React from 'react';

import FormInput from "../FormInput";
import MonitoringRecommendationComponent from "./MonitoringRecommendationComponent";
import SurveySpeciesInput from "./SurveySpeciesInput";
import SurveyPhotoInput from "./SurveyPhotoInput";
import {addRepeaterItem, deleteRepeaterItem, updateRepeaterItem} from "../../../Redux/Actions/surveyActions";
import RepeatableSection from "../RepeatableSection";
import {useDispatch, useSelector} from "react-redux";
import {useNextTempId} from "../../../Hooks/TempIdHooks";
import {useRepeaterParameters} from "../../../Hooks/SurveyHooks";

const SurveySecondaryInput = props => {
    const dispatch = useDispatch();
    const nextId = useNextTempId();
    const repeaterParameters = useRepeaterParameters();

    const user = useSelector(state => state.userState.user);

    const { fieldDefinition, data, answerId, repeaterId, value, disabled, sampleEventId, validationMessage, required } = props;

    switch (fieldDefinition.type) {
        case 'monitoring-recommendations':
            const recommendationData = data?.filter(recommendation => recommendation.code === fieldDefinition.code);

            return <MonitoringRecommendationComponent
                data={recommendationData}
                answerId={answerId}
                code={fieldDefinition.code}
                readOnly={props.readOnly}
            />;
        case 'monitoring-repeater':
            let repeaterData = data.filter(repeater => repeater.code === fieldDefinition.code);
            const parameters = repeaterParameters(fieldDefinition.code);

            if (!parameters) {
                return null;
            }

            return <RepeatableSection
                name={parameters.name}
                data={repeaterData}
                primaryKey="qualitativeVegetationMonitoringRepeaterId"
                fields={parameters.fields}
                colSize={parameters.colSize ?? 4}
                addItem={() => dispatch(addRepeaterItem(answerId, fieldDefinition.code, user.userId, nextId()))}
                updateItem={(index, column, value) => dispatch(updateRepeaterItem(answerId, index, column, value))}
                deleteItem={(index) => dispatch(deleteRepeaterItem(answerId, index))}

                // for species input
                answerId={answerId}
                sampleEventId={sampleEventId}
                readOnly={props.readOnly}
            />;
        case 'species':
            return <SurveySpeciesInput
                    label={fieldDefinition.label || ''}
                    placeholder={fieldDefinition.type === 'select' ? `Select ${fieldDefinition.label}` : ''}
                    column={fieldDefinition.code}
                    value={value}
                    listType={fieldDefinition.listType}
                    disabled={disabled}
                    answerId={answerId}
                    repeaterId={repeaterId}
                    sampleEventId={sampleEventId}
                    readOnly={props.readOnly}
                    required={required}
                />;
        case 'photo':
            return <SurveyPhotoInput
                    label={fieldDefinition?.label}
                    answerId={answerId}
                    repeaterId={repeaterId}
                    sampleEventId={sampleEventId}
                    required={required}
                />;
        default:
            const fieldOptions = fieldDefinition?.options ?? fieldDefinition?.values?.map(({value}) => ({id: (value?.value ?? value), name: (value?.label ?? value?.value ?? value)})) ?? [];

            return <FormInput
                id={`${fieldDefinition.code}-${(repeaterId ?? '')}`}
                key={`${fieldDefinition.code}-${(repeaterId ?? '')}`}
                label={fieldDefinition.label || ''}
                type={fieldDefinition.type}
                placeholder={fieldDefinition.type === 'select' ? `Select ${fieldDefinition.label}` : ''}
                value={value}
                options={fieldOptions}
                overlayIcon={props.overlayIcon}
                onClick={props.onClick}
                onChange={props.onChange}
                onBlur={props.onBlur}
                disabled={disabled}
                required={required}
                validationMessage={validationMessage ? validationMessage(value) : ''}
                readOnly={props.readOnly}
            />
    }

};

export default SurveySecondaryInput;
