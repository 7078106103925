import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from 'react-router-dom';
import {useHydrozoneName} from "./LabelHooks";

import {selectTransect, selectTransectsWithPlots} from "../Redux/Selectors/transectSelectors";

import {PLOT_TYPE_1, PLOT_TYPE_2} from "../Constants/hydrozones";
import {qualTools} from "../Constants/tools";
import {TYPE_LENGTH_OF_COVER, TYPE_STEM_TALLY, TYPE_TREE_DBH} from "../Constants/lengthCategories";
import {
    CLOSE_ACCOUNT_MENU,
    CLOSE_QUICK_NAV,
    SET_BREADCRUMBS, SET_CONTEXT_TABS, SET_PROCEDURES, SET_PROJECT_MAP,
    SET_QUICK_NAV_LINKS
} from "../Redux/Actions/Types/appStatusTypes";
import {selectSurveysBySampleEventId} from "../Redux/Selectors/nodeSelectors";
import {useSurveysWithProgress, useTransectToolProgress} from "./ProgressHooks";
import {
    TRANSECT_REVIEW_CODE_INSTREAM,
    TRANSECT_REVIEW_CODE_NARRATIVE,
    TRANSECT_REVIEW_CODE_PHOTOS,
} from "../Constants/transectReview";

export const useClearNav = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        // Clear links when path name changes
        dispatch({type: SET_QUICK_NAV_LINKS, quickNavLinks: []});
        dispatch({type: SET_BREADCRUMBS, breadcrumbs: []});
        dispatch({type: SET_CONTEXT_TABS, contextTabs: []});
        dispatch({type: SET_PROCEDURES, procedures: []});
        dispatch({type: SET_PROJECT_MAP, projectMap: null});
        dispatch({type: CLOSE_QUICK_NAV});
        dispatch({type: CLOSE_ACCOUNT_MENU});
    }, [location.pathname]);
};

export const useFooterNavLinks = () => {
    const quickNavLinks = useSelector(state => state.appStatusState.quickNavLinks);
    const links = quickNavLinks.filter(link => !link.hideFooter);
    const index = links.findIndex(link => link.current);
    const previousIndex = index - 1;
    const nextIndex = index + 1;

    return {
        previous: previousIndex < 0 ? null : links[previousIndex],
        next: nextIndex > quickNavLinks.length ? null : links[nextIndex]
    };
};

export const useQuantVegNav = (transectId) => {
    const transect = useSelector(state => selectTransect(state, transectId));

    const dispatch = useDispatch();
    const location = useLocation();
    const transectToolProgress = useTransectToolProgress(true);
    const getHydrozoneTypes = useHydrozoneTypesForPlot();
    const invasiveHydrozoneTypes = useInvasiveHydrozoneTypes();
    const plot1HydrozoneTypes = getHydrozoneTypes(transectId, PLOT_TYPE_1);
    const plot2HydrozoneTypes = getHydrozoneTypes(transectId, PLOT_TYPE_2);

    useEffect(() => {
        const links = transectToolProgress(transect)
            .filter(tool => {
                if (!plot1HydrozoneTypes.length && tool.code === 'plot1') {
                    return false;
                }

                if (!plot2HydrozoneTypes.length && tool.code === 'plot2') {
                    return false;
                }

                return true;
            })
            .map(tool => {
                switch (tool.code) {
                    case 'plot1':
                        tool.children = plot1HydrozoneTypes;

                        return tool;
                    case 'plot2':
                        tool.children = plot2HydrozoneTypes;

                        return tool;
                    case 'invasive-cover':
                        tool.children = invasiveHydrozoneTypes(transectId);

                        return tool;
                    case 'transect-review':
                        return {
                            ...tool,
                            path: `/transect-review/${transectId}/transect-narrative`,
                        };
                    default:
                        return {
                            ...tool,
                            path: `/transect/${transectId}/${tool.code}`,
                        };
                }
            });

        dispatch({type: SET_QUICK_NAV_LINKS, quickNavLinks: flattenLinks(links, location.pathname)});
    }, [location.pathname, transect]);
};

export const useTransectReviewNav = (transectId) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const transect = useSelector(state => selectTransect(state, transectId));

    const toolIsComplete = (code) => {
        // TODO: Api code is different from local code
        switch (code) {
            case 'transect-narrative':
                code = TRANSECT_REVIEW_CODE_NARRATIVE;
                break;
            case 'origin-end-photo':
                code = TRANSECT_REVIEW_CODE_PHOTOS;
                break;
            case 'instream-shade':
                code = TRANSECT_REVIEW_CODE_INSTREAM;
                break;
        }

        return transect.TransectReviews.find(review => review.code === code)?.isConfirmed ?? false;
    };

    useEffect(() => {
        const links = [
            {title: 'Transect Narrative', code: 'transect-narrative'},
            {title: 'Plot 1 & Plot 2', code: 'stem-length'},
            {title: 'Tree DBH', code: 'dbh'},
            {title: 'Invasive / Ground', code: 'invasive-cover'},
            {title: 'Photos', code: 'origin-end-photo'},
            {title: 'Canopy Closure', code: 'canopy'},
            {title: 'Instream Shade', code: 'instream-shade'}
        ].map(link => ({
            ...link,
            path: `/transect-review/${transectId}/${link.code}`,
            isCompleted: toolIsComplete(link.code)
        }));

        const previousLink = {
            title: 'Instream Shade',
            code: 'insteam-shade',
            className: 'rust',
            path: `/transect/${transectId}/instream-shade`,
            hideNav: true,
        };

        const nextLink = {
            title: 'Transect List',
            code: 'quant-veg',
            className: 'rust',
            path: `/event/${transect.sampleEventId}/quant-veg`,
            hideNav: true,
        };

        dispatch({type: SET_QUICK_NAV_LINKS, quickNavLinks: flattenLinks([previousLink, ...links, nextLink], location.pathname)});
    }, [location.pathname, transect]);
};

export const useQualVegNav = (sampleEventId) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const surveysWithProgress = useSurveysWithProgress();
    const surveys = useSelector(state => selectSurveysBySampleEventId(state, sampleEventId));

    const qualitativeVegetationMonitorings = useSelector(state => state.offlineDataState.qualitativeVegetationMonitorings);

    useEffect(() => {
        const links = qualTools.map(tool => {
            const survey = surveysWithProgress(surveys, sampleEventId).find(survey => survey.surveyTypeId === tool.id);

            if (survey) {
                tool.children = survey.SurveySections.map(section => ({
                    title: section.name,
                    code: section.code
                })).map(child => ({
                    ...child,
                    path: `/event/${sampleEventId}/qual-veg/${survey.surveyId}/${child.code}`,
                    child: true,
                }));

                return {
                    ...tool,
                    progress: survey.progress,
                    hideFooter: true
                };
            } else {
                return {
                    ...tool,
                    path: `/event/${sampleEventId}/${tool.code}`,
                };
            }
        });

        dispatch({type: SET_QUICK_NAV_LINKS, quickNavLinks: flattenLinks(links, location.pathname)});
    }, [location.pathname, qualitativeVegetationMonitorings]);
};

export const useHydrozoneTypesForPlot = () => {
    const getHydrozoneName = useHydrozoneName();
    const transects = useSelector(state => selectTransectsWithPlots(state));

    const plotTools = [
        {
            title: "Stem Tally",
            code: TYPE_STEM_TALLY,
            order: 0,
        },
        {
            title: "Length of Cover",
            code: TYPE_LENGTH_OF_COVER,
            order: 1,
        },
        {
            title: "DBH",
            code: TYPE_TREE_DBH,
            order: 2,
        },
    ];

    return (transectId, plotType) => {
        const transect = transects.find(transect => String(transect.transectId) === String(transectId));

        return transect.HydrozonePlots
            .filter(plot => plot.plotTypeId === plotType)
            .reduce((acc, plot) => {
                const hydrozoneTitle = getHydrozoneName(plot.hydrozoneTypeId);
                return acc.concat(plotTools.map(plotTool => (
                    {
                        hydrozoneTypeId: plot.hydrozoneTypeId,
                        title: `${plotTool.title} - ${hydrozoneTitle}`,
                        footerNavTitle: `Plot ${plotType}: ${plotTool.title} - ${hydrozoneTitle}`,
                        code: `${plotType}-${hydrozoneTitle.toLowerCase()}-${plotTool.code}`,
                        path: `/transect/${transectId}/plot${plotType}/${plotTool.code}/${plot.hydrozoneTypeId}`,
                        child: true,
                        order: Number(`${plotTool.order}.${plot.hydrozoneTypeId}`)
                    }
                )));
            }, [])
            .sort((a, b) => a.order > b.order ? 1: -1);
    }
};

export const useInvasiveHydrozoneTypes = () => {
    const getHydrozoneName = useHydrozoneName();
    const transects = useSelector(state => selectTransectsWithPlots(state));


    return (transectId) => {
        const transect = transects.find(transect => String(transect.transectId) === String(transectId));

        return transect.Hydrozones
            .map(({hydrozoneTypeId}) => {
                const title = getHydrozoneName(hydrozoneTypeId);

                return {
                    hydrozoneTypeId,
                    title,
                    footerNavTitle: `Invasive / Ground - ${title}`,
                    code: `invasive-ground-${title.toLowerCase()}`,
                    path: `/transect/${transectId}/invasive-cover/${hydrozoneTypeId}`,
                    child: true,
                }
            })
            .sort((a, b) => a.hydrozoneTypeId > b.hydrozoneTypeId ? 1: -1);
    }
};

const flattenLinks = (links, currentPath, flatLinks = []) => {
    links.forEach(link => {
        if (link.children && link.children.length) {
            flatLinks = [...flatLinks, {
                ...link,
                path: link.children[0]?.path,
            }];

            const children = link.children.map(child => ({...child, child: true}));

            flatLinks = flattenLinks(children, currentPath, flatLinks);
        } else {
            if (link.path === currentPath) {
                link.current = true;
            }

            flatLinks = [...flatLinks, link];
        }
    });

    return flatLinks;
};
