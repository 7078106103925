import React from 'react';

const PageFooter = props => {
    return (
        <div className="page-footer">
            {props.children}
        </div>
    )
};

export default PageFooter;