import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HalfCheck = props => {
    return (
        <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={['fas', 'square']} />
            <FontAwesomeIcon icon={['fas', 'circle']} transform="shrink-9 left-1" inverse />
        </span>
    );
};

export default HalfCheck;