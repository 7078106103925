import { createBrowserHistory } from 'history';

import config from '../Config';
import ObjectUtils from './ObjectUtils';
import {SET_URL} from "../Redux/Actions/Types/contextActionTypes";

let history = createBrowserHistory();
config.getStore().dispatch({type: SET_URL, url: history.location.pathname});

let staticConstructorRun = false;
class HistoryUtils {
    static StaticConstructor() {
        if(!staticConstructorRun) {
            staticConstructorRun = true;
            history.listen((location, action) => {
                config.getStore().dispatch({type: SET_URL, url: location.pathname});
            });
        }
    }

    static GetHistory() {
        return history;
    }

    static Push(url) {
        history.push(url);
    }

    static PushQueryStringUpdate(query) {
        history.push({
            pathname: window.location.pathname,
            search: '?' + ObjectUtils.BuildQueryString(query)
        })
    }
    
    static ReplaceHostName(url, newHostName) {
	    const urlObject = new URL(url);
        urlObject.hostname = newHostName;
        return urlObject.href
    }
}
HistoryUtils.StaticConstructor();

export default HistoryUtils;
