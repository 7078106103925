import React, { Fragment } from 'react';
import ReadOnlyHelpIcon from "../Common/ReadOnlyHelpIcon";

const PageTitle = props => {
    return (
        <Fragment>
            <div className={`page-title-component ${props.className || ''}`}>
                <h1 className={`page-title ${props.size || ''}`}>
                    {props.title}
                    {
                        props.subtitle &&
                            <span className="page-subtitle"> / {props.subtitle}</span>
                    }
                    {
                        (props.readOnly || !!props.readOnlyReason) &&
                            <ReadOnlyHelpIcon reason={props.readOnlyReason} />
                    }
                </h1>
                <div>
                    {props.action || null}
                </div>
            </div>
            <hr />
        </Fragment>
    )
};

export default PageTitle;
