import React from 'react';
import { ModalBody, ModalFooter, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Modal from './Modal';

const ConfirmSaveModal = props => {
    return (
        <Modal
            title={props.title ?? 'Confirm Save'}
            size="md"
            show={props.show}
            onHide={() => props.onHide()}
        >
            <ModalBody>
                <Row xs={12}>
                    <Col xs={12}>
                        <span>
                            {props.message}
                        </span>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button variant="link" className="ml-3" onClick={() => props.onHide()}>
                    <FontAwesomeIcon icon={['fal', 'times']} />
                    {props.hideTitle || 'Discard Changes'}
                </Button>
                <Button variant="complete" className="w-25" onClick={() => props.save()}>
                    {props.saveTitle || 'Save Anyway'}
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default ConfirmSaveModal;
