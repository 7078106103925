import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CircledTextIcon = props => {
    return (
        <span className={`fa-layers fa-fw ${props.className || ''}`} onClick={() => props.onClick ? props.onClick() : null}>
            <FontAwesomeIcon icon={['fas', 'circle']} transform={`grow-${props.circleSize}`} />
            <span className="fa-layers-text">{props.text}</span>
        </span>
    );
};

export default CircledTextIcon;
