import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from 'react-router-dom'
import {Row, Col, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {selectSampleEventById} from "../../../Redux/Selectors/nodeSelectors";
import {useContextTools, useContextToolsBySampleEventId} from '../../../Hooks/ToolHooks';
import {useNextTempId} from "../../../Hooks/TempIdHooks";
import {
    useHasInvalidField,
    useReadOnly,
    useValidateDuplicate,
    useValidateIsNotEmpty,
    useValidateLatitude,
    useValidateLongitude,
    useValidatePositiveValue,
    useValidateInteger
} from "../../../Hooks/FormHooks";

import {CONTEXT_TOOL_BOOK, CONTEXT_TOOL_GLOBE, CONTEXT_TOOL_PHOTO} from "../../../Constants/tools";
import {UPSERT_CAMERA_POINT} from "../../../Redux/Actions/Types/offlineDataActionTypes";

import PageContent from '../../Layout/Page/PageContent';
import PageFooter from '../../Layout/Page/PageFooter';
import Title from '../../Layout/TitleComponent';
import PageContainer from '../../Layout/Page/PageContainer';
import FormInput from '../../Common/FormInput';
import CircledIcon from "../../CompositeIcons/CircledIcon";

import PageHeader from "../../Layout/Page/PageHeader";
import {selectCameraPointsByProjectId} from "../../../Redux/Selectors/photoSelectors";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import GeoPositionUtils from "../../../Utils/GeoPositionUtils";
import {useFinalized} from "../../../Hooks/DataHooks";
import ConfirmSaveModal from "../../Common/ConfirmSaveModal";
import RouteLeavingGuard from "../../Common/RouteLeavingGuard";
import {useUpdateEffect} from "../../../Hooks/EffectHooks";
import {TEMP_ID_PREFIX} from "../../../Constants/misc";
import SaveButtonWithHelpIcon from "../../Common/SaveButtonWithHelpIcon";

const CameraPointDetailPage = (props) => {
    useContextTools([CONTEXT_TOOL_PHOTO, CONTEXT_TOOL_GLOBE, CONTEXT_TOOL_BOOK]);
    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();
    const nextId = useNextTempId();
    const hasInvalidField = useHasInvalidField();
    const validateInteger = useValidateInteger();
    const validatePositiveValue = useValidatePositiveValue();
    const validateDuplicate = useValidateDuplicate();
    const validateIsNotEmpty = useValidateIsNotEmpty();
    const validateLatitude = useValidateLatitude();
    const validateLongitude = useValidateLongitude();

    const event = useSelector(state => selectSampleEventById(state, params.eventId));
    const cameraPoints = useSelector(state => selectCameraPointsByProjectId(state, event.projectId));

    const cameraPoint = params.cameraPointId ? cameraPoints.find(point => point.sampleLocationId == params.cameraPointId) : null;
    const breadcrumbTitle = cameraPoint ? 'Edit Camera Point' : 'New Camera Point';
    useBreadcrumbs(breadcrumbTitle, event.projectId, event.sampleEventId);
    useContextToolsBySampleEventId(event.sampleEventId);
    const finalized = useFinalized(event.sampleEventId);
    useReadOnly(finalized);

    const [name, setName] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [physicalMarker, setPhysicalMarker] = useState('');
    const [bank, setBank] = useState('');
    const [notes, setNotes] = useState('');
    const [accuracy, setAccuracy] = useState('');

    const [showSaveWarning, setShowSaveWarning] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [loadingLocation, setLoadingLocation] = useState(false);

    useEffect(() => {
        initData();
    }, []);

    useUpdateEffect(() => {
        if (initialized) {
            setHasChanges(true);
        }

        // Ignore first iteration because data is being set by other useEffect
        setInitialized(true);
    }, [name, latitude, longitude, physicalMarker, bank, notes, accuracy]);

    const initData = () => {
        if (cameraPoint) {
            setName(cameraPoint.name);
            setLatitude(cameraPoint.latitude.toFixed(5));
            setLongitude(cameraPoint.longitude.toFixed(5));
            setAccuracy(cameraPoint.accuracy);
            setPhysicalMarker(cameraPoint.physicalMarker);
            setBank(cameraPoint.bank);
            setNotes(cameraPoint.notes);
        } else {
            setName(autoPopulateName());
        }
    };

    const autoPopulateName = () => {
        const mostRecentCameraPoint = cameraPoints
            .sort((a, b) => (a.name > b.name) ? -1 : 1)
            .find(point => !!point);

        const maxCameraPointNumber = Number(mostRecentCameraPoint?.name);

        if (isNaN(maxCameraPointNumber)) {
            return '';
        }

        return maxCameraPointNumber + 1;
    };

    const autoPopulateLocation = () => {
        if (loadingLocation) return;

        setLoadingLocation(true);
        GeoPositionUtils.getCurrentPosition(position => {
            setLatitude(position.coords.latitude.toFixed(5));
            setLongitude(position.coords.longitude.toFixed(5));
            setAccuracy(position.coords.accuracy);
            setLoadingLocation(false);
        });
    };

    const confirmSave = () => {
        if (!cameraPoint || String(cameraPoint?.sampleLocationId).startsWith(TEMP_ID_PREFIX)) {
            save();
        } else {
            if (
                cameraPoint.name === name &&
                cameraPoint.physicalMarker === physicalMarker &&
                cameraPoint.bank === bank &&
                Number(cameraPoint.longitude) === Number(longitude) &&
                Number(cameraPoint.latitude) === Number(latitude)
            ) {
                save();
            } else {
                setShowSaveWarning(true);
            }
        }
    };

    const save = () => {
        let savedCameraPoint;

        if (!cameraPoint) {
            savedCameraPoint = {
                sampleLocationId: nextId(),
                projectId: event.projectId,
                name,
                physicalMarker,
                bank,
                notes,
                latitude: Number(latitude),
                longitude: Number(longitude),
                accuracy,
                PhotoPoints: [],
            };
        } else {
            savedCameraPoint = {
                ...cameraPoint,
                name,
                physicalMarker,
                bank,
                notes,
                latitude: Number(latitude),
                longitude: Number(longitude),
                accuracy,
            };
        }

        dispatch({type: UPSERT_CAMERA_POINT, cameraPoint: savedCameraPoint});
        setHasChanges(false);
        history.goBack();
    };

    const disableSave = () => {
        const requiredFields = [name, latitude, longitude, physicalMarker];
        const validatedFields = [
            validateInteger(name, 'Name'),
            validatePositiveValue(name, 'Name'),
            validateDuplicate(name, 'name', cameraPoints, cameraPoint),
            validateLatitude(latitude),
            validateLongitude(longitude)
        ];

        return hasInvalidField(requiredFields, validatedFields);
    };

    return (
        <Fragment>
            <RouteLeavingGuard when={hasChanges} />
            <ConfirmSaveModal
                show={showSaveWarning}
                size="md"
                title="Confirm Camera Point Changes"
                message="Warning: You are about to make changes to an established Camera Point. Doing so will also change these values for all historic data. Are you sure you want to continue?"
                save={() => {
                    save();
                    setShowSaveWarning(false);
                }}
                onHide={() => {
                    initData();
                    setShowSaveWarning(false);
                }}
            />
            <PageContainer className="new-camera-point-page">
                <PageHeader>
                    <Title
                        title={cameraPoint ? `Camera Point ${cameraPoint.name}` : 'New Camera Point'}
                        readOnly={finalized}
                    />
                </PageHeader>
                <PageContent>
                    <Row xs={12}>
                        <Col xs={12}>
                            <h2>General</h2>
                        </Col>
                        <Col xs={8}>
                            <Row xs={12}>
                                <Col xs={4}>
                                    <FormInput
                                        label="Camera Point Number"
                                        type="number"
                                        value={name}
                                        onChange={value => setName(value)}
                                        validationMessage={
                                            validateInteger(name, 'Name') ||
                                            validatePositiveValue(name, 'Name') ||
                                            validateIsNotEmpty(name, 'Name') ||
                                            validateDuplicate(name, 'name', cameraPoints, cameraPoint)
                                        }
                                        autoFocus={!cameraPoint}
                                        required
                                    />
                                </Col>
                                <Col xs={4}>
                                    <FormInput
                                        label="Latitude (Datum: WGS84)"
                                        type="number"
                                        value={latitude}
                                        onChange={value => setLatitude(value)}
                                        suffix="&#176;"
                                        validationMessage={validateLatitude(latitude)}
                                        required
                                    />
                                </Col>
                                <Col xs={4}>
                                    <FormInput
                                        label="Longitude (Datum: WGS84)"
                                        type="number"
                                        value={longitude}
                                        onChange={value => setLongitude(value)}
                                        suffix="&#176;"
                                        validationMessage={validateLongitude(longitude)}
                                        required
                                    />
                                    {
                                        !finalized &&
                                            <CircledIcon
                                                icon={['fal', 'location-arrow']}
                                                className="btn-location"
                                                circleSize="12"
                                                iconTransform="shrink-2"
                                                onClick={() => autoPopulateLocation()}
                                                loading={loadingLocation}
                                            />
                                    }
                                </Col>
                                <Col xs={4}>
                                    <FormInput
                                        label="Physical Marker"
                                        type="select"
                                        options={[
                                            {id: '', name: 'Select Physical Marker'},
                                            {id: 'Rebar', name: 'Rebar'},
                                            {id: 'Orange Flagging', name: 'Orange Flagging'},
                                            {id: 'NA', name: 'NA'},
                                            {id: 'Other - See Notes', name: 'Other - See Notes'},
                                            {id: 'Wooden Stake', name: 'Wooden Stake'},
                                        ]}
                                        value={physicalMarker}
                                        onChange={value => setPhysicalMarker(value)}
                                        required
                                    />
                                </Col>
                                <Col xs={4}>
                                    <FormInput
                                        label="Bank"
                                        type="select"
                                        options={[
                                            {id: '', name: 'Select Bank'},
                                            {id: 'River Left', name: 'River Left'},
                                            {id: 'River Center', name: 'River Center'},
                                            {id: 'River Right', name: 'River Right'}
                                        ]}
                                        value={bank}
                                        onChange={value => setBank(value)}
                                    />
                                </Col>
                                <Col xs={4}>
                                    <FormInput
                                        label="GPS Accuracy (meters)"
                                        type="number"
                                        value={accuracy}
                                        onChange={value => setAccuracy(value)}
                                        suffix="m"
                                    />
                                </Col>
                                <Col xs={8}>
                                    <FormInput
                                        label="Notes"
                                        type="textarea"
                                        value={notes}
                                        onChange={value => setNotes(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </PageContent>
                <PageFooter>
                    {
                        finalized ?
                            <Button variant="secondary" onClick={() => history.goBack()}>
                                Close
                            </Button> :
                            <SaveButtonWithHelpIcon
                                type="Camera Point"
                                hasData={!!cameraPoint}
                                save={confirmSave}
                                disable={disableSave()}
                            />
                    }

                    <Button variant="link" className="ml-3" onClick={() => history.goBack()}>
                        <FontAwesomeIcon icon={['fal', 'times']} />
                        Cancel
                    </Button>
                </PageFooter>
            </PageContainer>
        </Fragment>
    );
};

export default CameraPointDetailPage;
