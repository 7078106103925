export const organizations = [
    {
        organizationId: 1,
        name: 'The Freshwater Trust'
    },
    {
        organizationId: 2,
        name: 'Idaho Power'
    }
];
