import React, {useRef} from 'react';
import {useHistory} from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TakePhotoIcon from "../../CompositeIcons/TakePhotoIcon";
import RetakePhotoIcon from "../../CompositeIcons/RetakePhotoIcon";
import FullScreenPhotoIcon from "../../CompositeIcons/FullScreenPhotoIcon";
import PhotoGalleryIcon from "../../CompositeIcons/PhotoGalleryIcon";


const ToolSidebar = (props) => {
    const {fileRef, captureRef, iOSTakePhoto, photo, retakePhoto, showGrid, takePhoto, toggleGrid} = props;
    const history = useHistory();

    return (
        <Col xs={1} className="photo-tool-sidebar">
            <div className="content">
                <div className="close-container">
                    <div className="text-center btn-close" onClick={() => history.goBack()}>
                        <FontAwesomeIcon icon={['fal', 'times']} />
                        Close
                    </div>
                </div>
                <div className="photo-tools">
                    <div className="tool-group">
                        &nbsp;
                    </div>
                    <div className={`tool-group ${photo ? 'disabled' : ''}`} onClick={() => !photo ? captureRef.current.click() : null}>
                        <FullScreenPhotoIcon />
                        <input ref={captureRef} onChange={(e) => iOSTakePhoto(e)} className="d-none" type="file" accept="image/*" capture="environment" />
                    </div>
                    {
                        photo ?
                            <div className="tool-group" onClick={() => retakePhoto()}>
                                <RetakePhotoIcon />
                            </div> :
                            <div className="tool-group" onClick={() => takePhoto()}>
                                <TakePhotoIcon />
                            </div>
                    }
                    <div className={`tool-group ${photo ? 'disabled' : ''}`} onClick={() => !photo ? fileRef.current.click() : null}>
                        <input ref={fileRef} onChange={(e) => iOSTakePhoto(e)} className="d-none" type="file" accept="image/*" />
                        <PhotoGalleryIcon/>
                    </div>
                    <div className={`tool-group ${showGrid ? 'active' : ''}`} onClick={() => toggleGrid()}>
                        <FontAwesomeIcon icon={['fal', 'th']}/>
                    </div>
                </div>
                <div className="text-center btn-close">
                    {/*  Empty space the same height as btn-close to ensure centered camera buttons */}
                    &nbsp;
                </div>
            </div>
        </Col>
    )
};

export default ToolSidebar;
