import React from 'react';
import {Col, FormGroup, FormLabel, Row} from 'react-bootstrap';
import DateUtils from "../../../Utils/DateUtils";
import {useSampleEventUsers} from "../../../Hooks/DataHooks";

const EventTileDetail = ({item}) => {
    const staff = useSampleEventUsers(item.sampleEventId);

    const getEndDate = () => {
        if (!item.endDate) {
            return 'N/A';
        }

        return DateUtils.FormatToLocalDate(item.endDate, 'MMM d, yyyy');
    };

    return (
        <div className="event-details">
            <Row xs={12}>
                <Col xs={6}>
                    <FormGroup>
                        <FormLabel>Phase</FormLabel>
                        <div>{item.sampleEventPhaseName}</div>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Purpose</FormLabel>
                        <div>{item.otherPurpose || 'N/A'}</div>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>End Date</FormLabel>
                        <div>{getEndDate()}</div>
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup>
                        <FormLabel>Field Crew</FormLabel>
                        <div className="sample-event-staff">{staff || 'N/A'}</div>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Notes</FormLabel>
                        <div className="sample-event-notes">{item.notes || 'N/A'}</div>
                    </FormGroup>
                </Col>
            </Row>
        </div>
    )
};

export default EventTileDetail;
