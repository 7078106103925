export const competitionLifeStageOptions = [
    {id: 'S', name: 'Seedling'},
    {id: 'FL', name: 'Flowering'},
    {id: 'FR', name: 'Fruiting'},
    {id: 'V', name: 'Vegetative'},
];

export const insectsImpactTypeOptions = [
    {id: 'I', name: 'Insect'},
    {id: 'P', name: 'Pest'},
    {id: 'D', name: 'Disease'},
    {id: 'Unk', name: 'Unknown'}
];

export const insectsImpactCausingOptions = [
    {label: 'Yes', value: 1},
    {label: 'No', value: 0},
]
