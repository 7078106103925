import {
    OFFLINE_STATUS_SYNC,
    OFFLINE_STATUS_OUT_OF_SYNC,
    OFFLINE_STATUS_READY_FOR_UPLOAD,
    OFFLINE_STATUS_DO_NOT_SYNC,
    STATUS_LABEL_QUEUED_DOWNLOAD,
    STATUS_LABEL_UP_TO_DATE,
    STATUS_LABEL_QUEUED_UPLOAD,
    STATUS_LABEL_READY_TO_UPLOAD,
    STATUS_LABEL_OUT_OF_DATE, STATUS_LABEL_NOT_DOWNLOADED
} from '../Constants/status';
import {HIERARCHY_TYPE_SAMPLE_EVENT_PHASE} from "../Constants/hierarchy";

export default class StatusUtils {
    static GetStatusLabel(node, isSelected) {
        switch (node.offlineState) {
            case OFFLINE_STATUS_DO_NOT_SYNC:
                return isSelected ? STATUS_LABEL_QUEUED_DOWNLOAD : STATUS_LABEL_NOT_DOWNLOADED;
            case OFFLINE_STATUS_SYNC:
                return STATUS_LABEL_UP_TO_DATE;
            case OFFLINE_STATUS_OUT_OF_SYNC:
                return isSelected ? STATUS_LABEL_QUEUED_DOWNLOAD : STATUS_LABEL_OUT_OF_DATE;
            case OFFLINE_STATUS_READY_FOR_UPLOAD:
                return isSelected ? STATUS_LABEL_QUEUED_UPLOAD : STATUS_LABEL_READY_TO_UPLOAD;
            default:
                return '';
        }
    }

    static GetStatusPriority(status) {
        switch (status) {
            case OFFLINE_STATUS_READY_FOR_UPLOAD:
                return 4;
            case OFFLINE_STATUS_OUT_OF_SYNC:
                return 3;
            case OFFLINE_STATUS_SYNC:
                return 2;
            case OFFLINE_STATUS_DO_NOT_SYNC:
                return 1;
        }
    }

    static GetComputedParentIcon(parentNode) {
        const priorities = this.GetAllPriorities(parentNode);
        let iconColor = '';

        const hasMultipleStates = priorities.size > 1;
        const maxPriority = Math.max(...priorities);

        if (maxPriority >= 3) {
            iconColor = 'danger';
        } else if (maxPriority === 2) {
            iconColor = 'success';
        }

        return {hasMultipleStates, iconColor, maxPriority}
    }

    static GetAllPriorities(node, priorities = new Set()) {
        // since Sample Event Phases don't ever need to be updated
        // and are always downloaded, ignore their "state"
        if(String(node.hierarchyTypeId) !== String(HIERARCHY_TYPE_SAMPLE_EVENT_PHASE)) {
            priorities.add(node.offlineState);
        }
        if (node.children.length) {
            node.children.forEach(child => {
                this.GetAllPriorities(child, priorities);
            })
        }

        return priorities;
    }

    static FormatLabel(label, count, suffix, noun = 'item') {
        if (count > 0) {
            let itemText = count > 1 ? `${noun}s` : noun;

            return `${label} ${count} ${itemText} ${suffix}.`;
        }

        return label;
    };
}
