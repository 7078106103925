import React from 'react';
import { ModalBody, ModalFooter, Button, Row, Col } from 'react-bootstrap';

import Modal from '../../Common/Modal';
import NotesModal from "../../Common/NotesModal";
import {UPDATE_TRANSECT_VALUE} from "../../../Redux/Actions/Types/offlineDataActionTypes";
import {useDispatch} from "react-redux";

const TransectNotesModal = props => {
    const dispatch = useDispatch();

    return (
        <NotesModal
            title="Notes"
            notes={[
                {
                    label: 'Origin Location',
                    value: props.transect.originNotes,
                    save: (value) => dispatch({
                        type: UPDATE_TRANSECT_VALUE,
                        transectId: props.transect.transectId,
                        field: 'originNotes',
                        value,
                    })
                },
                {
                    label: 'Safety',
                    value: props.transect.safetyNotes,
                    save: (value) => dispatch({
                        type: UPDATE_TRANSECT_VALUE,
                        transectId: props.transect.transectId,
                        field: 'safetyNotes',
                        value,
                    })
                },
                {
                    label: 'Transect',
                    value: props.transect.notes,
                    save: (value) => dispatch({
                        type: UPDATE_TRANSECT_VALUE,
                        transectId: props.transect.transectId,
                        field: 'notes',
                        value,
                    })
                },
            ]}
            show={props.show}
            onHide={() => props.onHide()}
            readOnly={props.readOnly}
        />
    )
};

export default TransectNotesModal;
