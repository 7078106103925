import React, {useState} from 'react';
import DiscardDeviceDataModal from "../../Layout/Modal/DiscardDeviceDataModal";
import {DELETE_EVERYTHING} from "../../../Redux/Actions/Types/offlineDataActionTypes";
import {deleteOrphanedPhotoContent} from "../../../Redux/Actions/photoActions";
import StreamBankAuthService from "../../../Services/StreamBankAuthService";
import {useDispatch} from "react-redux";
import BackgroundImage from "./BackgroundImage";

const LogoutPage = (props) => {
    const dispatch = useDispatch();
    const [showLogoutModal, setShowLogoutModal] = useState(true);

    return (
        <div className="logout-page">
            <BackgroundImage/>
            <DiscardDeviceDataModal
                backdrop={false}
                show={showLogoutModal}
                onHide={() => setShowLogoutModal(false)}
                action={() => {
                    dispatch({type: DELETE_EVERYTHING});
                    dispatch(deleteOrphanedPhotoContent());
                    StreamBankAuthService.Logout();
                }}
                offlineMessage="You are currently offline. If you log out now, you will not be able to log in again or use StreamBank Monitoring until you have an Internet connection. Are you sure you want to log out?"
                changesMessage="You have un-synced changes. Please sync or discard any on-device data before logging out."
                dataMessage="When you log out, all on-device data will be removed from the device. You will need to download this data again the next time you log in. Are you sure you want to log out?"
                logout
            />
        </div>
    );
};

export default LogoutPage;
