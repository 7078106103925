import {
    UPSERT_PROGRAM,
    UPSERT_PROJECT,
    UPSERT_SAMPLE_EVENT,
    UPSERT_CAMERA_POINT,
    UPSERT_PHOTO,
    UPSERT_TRANSECT,
    UPSERT_SURVEY,
    ADD_PHOTO_POINT,
    SET_CAMERA_POINTS,
    SET_PHOTO_POINTS,
    SET_PHOTOS,
    UPDATE_PHOTO_POINT,
    DELETE_PHOTO,
    SET_PROJECTS,
    SET_SAMPLE_EVENTS,
    SET_TRANSECTS,
    SET_PROGRAMS,
    DELETE_PROGRAMS,
    DELETE_PROJECTS,
    DELETE_SAMPLE_EVENTS,
    DELETE_CAMERA_POINTS,
    DELETE_SAMPLE_EVENT_PHASES,
    UPDATE_PROJECT_VALUE,
    UPDATE_TRANSECT_VALUE,
    UPSERT_QUAL_VEG_MONITORING,
    DELETE_TRANSECTS,
    DELETE_EVERYTHING,
    SET_PHOTO_DEPENDENCIES,
    ADD_PROJECT_SPECIES,
    RESTORE_BACKUP,
    DELETE_QUAL_VEG_MONITORING,
    DELETE_SAMPLE_EVENT_PHOTOS,
    DELETE_SAMPLE_EVENT_TRANSECTS,
    DELETE_SAMPLE_EVENT_QUALITATIVE_VEGETATION_MONITORINGS,
} from "../Actions/Types/offlineDataActionTypes";
import DateUtils from "../../Utils/DateUtils";
import EventUtils from "../../Utils/EventUtils";
import {USER_LOGOUT} from "../Actions/Types/userActionTypes";
import {sortSampleEvents} from "../../Utils/SortUtils";

const initialState = {
    programs: [],
    projects: [],
    sampleEvents: [],
    cameraPoints: [],
    photos: [],
    transects: [],
    surveys: [],
    qualitativeVegetationMonitorings: [],
    photoDependencies: {},
};

const newItem = (item, currentDateTime = null) => {
    if(currentDateTime === null) {
        return item;
    }

    return {
        ...item,
        offlineCreatedDate: currentDateTime,
        offlineModifiedDate: currentDateTime,
    };
};

const updatedItem = (item, currentDateTime = null) => {
    if(currentDateTime === null) {
        return item;
    }

    return {
        ...item,
        offlineModifiedDate: currentDateTime,
    };
};

// utility function for the logic of ADD_ and UPDATE_ actions
// test is a function that takes the element in the array and
// the item to be added/updated and returns true if the element
// should be replaced with the item
const addOrUpdateItems = (array, test, items, currentDateTime = null) => {

    let result = [...array];

    for(const item of items) {
        const existingIndex = result.findIndex(element => test(element, item) );

        if(existingIndex >= 0) {
            result[existingIndex] = updatedItem(item, currentDateTime);
        } else {
            result.push(newItem(item, currentDateTime));
        }
    }

    return result;
};

// utility function for the logic of ADD_ and UPDATE_ actions
const addOrUpdateItemsByKey = (array, key, items, currentDateTime = null) => {
    return addOrUpdateItems(
        array,
        (element, item) => {
            // Types are mismatched when creating new items (numeric ids vs tmpIds)

            // if (typeof element[key] !== typeof item?.[key] &&
            //     String(element[key]) !== String(item?.[key])
            // ) {
            //     console.log('mismatched type!');
            //     debugger
            // }

            return (String(element[key]) === String(item?.[key]));
        },
        items,
        currentDateTime
    );
};

// utility function for the logic of ADD_ and UPDATE_ actions
const updateModifiedDateByKey = (array, key, items, currentDateTime = null) => {
    let result = [...array];

    for(const item of items) {
        const existingIndex = result.findIndex(element => {
            // Types are mismatched when creating new items (numeric ids vs tmpIds)

            // if(typeof element[key] !== typeof item?.[key]) {
            //     console.log('mismatched type!');
            //     debugger
            // }

            return (String(element[key]) === String(item?.[key]));
        });

        if(existingIndex >= 0) {
            result[existingIndex] = updatedItem(result[existingIndex], currentDateTime);
        }
    }

    return result;
};

// since a user cannot select a project in the On-Device Data screen
// to upload changes without selecting a leaf (event) node
// this function is used to ensure at least one child event is marked
// as modified when changes happen to a project
const sampleEventsWithAtLeastOneOfflineModifiedDateForProjectId = (sampleEvents, projectId, currentDateTime) => {
    // the Sample Events we could use must:
    // * match the projectId
    // * be not readOnly
    const sampleEventCandidates = sampleEvents.filter(event => event.projectId === projectId && !event.readOnly)

    // if we already have a modified SampleEvent, we don't need to do anything
    if(sampleEventCandidates.find(event => !!event.offlineModifiedDate)) {
        return sampleEvents;
    }

    // otherwise find the most recent sample event to mark as modified
    const mostRecentEvent = sortSampleEvents(sampleEventCandidates).find(event => !!event);
    return [...sampleEvents].map(event => {
        if (event.sampleEventId === mostRecentEvent.sampleEventId) {
            return {
                ...event,
                offlineModifiedDate: currentDateTime ?? event?.offlineModifiedDate,
            };
        }
        return event;
    });
};

const offlineDataReducer = (state = initialState, action) => {
    const currentDateTime = !action.download ? DateUtils.GetCurrentDateTime() : undefined;

    switch (action.type) {
        case SET_PROGRAMS:
            return {
                ...state,
                programs: action.programs,
            };
        case SET_PROJECTS:
            return {
                ...state,
                projects: action.projects,
            };
        case SET_SAMPLE_EVENTS:
            return {
                ...state,
                sampleEvents: action.sampleEvents,
            };
        case SET_CAMERA_POINTS:
            return {
                ...state,
                cameraPoints: action.cameraPoints,
            };
        case SET_PHOTO_POINTS:
            return {
                ...state,
                photoPoints: action.photoPoints,
            };
        case SET_PHOTOS:
            return {
                ...state,
                photos: action.photos,
            };
        case SET_TRANSECTS:
            return {
                ...state,
                transects: action.transects,
            };
        case SET_PHOTO_DEPENDENCIES:
            return {
                ...state,
                photoDependencies: action.photoDependencies,
            };
        case UPSERT_PROGRAM:
            return {
                ...state,
                programs: addOrUpdateItemsByKey(
                    state.programs,
                    'programId',
                    [action.program]
                ),
            };
        case UPSERT_PROJECT:
            return {
                ...state,
                projects: addOrUpdateItemsByKey(
                    state.projects,
                    'projectId',
                    [action.project]
                ),
            };
        case UPSERT_SAMPLE_EVENT:
            return {
                ...state,
                sampleEvents: addOrUpdateItemsByKey(
                    state.sampleEvents,
                    'sampleEventId',
                    [action.sampleEvent],
                    currentDateTime
                ),
            };
        case UPSERT_CAMERA_POINT:
            return {
                ...state,
                cameraPoints: addOrUpdateItemsByKey(
                    state.cameraPoints,
                    'sampleLocationId',
                    [action.cameraPoint],
                    currentDateTime
                ),
                projects: updateModifiedDateByKey(
                    state.projects,
                    'projectId',
                    [action.cameraPoint],
                    currentDateTime
                ),
            };
        case UPSERT_PHOTO:
            return {
                ...state,
                photos: addOrUpdateItemsByKey(
                    state.photos,
                    'photoId',
                    [action.photo],
                    currentDateTime
                ),
                sampleEvents: updateModifiedDateByKey(
                    state.sampleEvents,
                    'sampleEventId',
                    [action.photo],
                    currentDateTime
                ),
            };
        case UPSERT_TRANSECT:
            return {
                ...state,
                transects: addOrUpdateItemsByKey(
                    state.transects,
                    'transectId',
                    [action.transect],
                    currentDateTime
                ),
                sampleEvents: updateModifiedDateByKey(
                    state.sampleEvents,
                    'sampleEventId',
                    [action.transect],
                    currentDateTime
                ),
            };
        case UPSERT_SURVEY:
            return {
                ...state,
                surveys: addOrUpdateItems(
                    state.surveys,
                    (existingSurvey, newSurvey) => (
                        existingSurvey.surveyId === newSurvey.surveyId && existingSurvey.programId === newSurvey.programId
                    ),
                    [action.survey]
                )
            };
        case UPDATE_TRANSECT_VALUE:
            const currentTransect = state.transects.find(transect => String(transect.transectId) === String(action.transectId));

            return {
                ...state,
                transects: [...state.transects].map(transect => {
                    if (String(transect.transectId) === String(action.transectId)) {
                        return {
                            ...transect,
                            [action.field]: action.value,
                            offlineModifiedDate: currentDateTime ?? transect?.offlineModifiedDate,
                        };
                    }

                    return transect;
                }),
                sampleEvents: [...state.sampleEvents].map(sampleEvent => {
                    if (sampleEvent.sampleEventId === currentTransect?.sampleEventId) {
                        return {
                            ...sampleEvent,
                            offlineModifiedDate: currentDateTime ?? sampleEvent?.offlineModifiedDate,
                        }
                    }

                    return sampleEvent;
                })
            };
        case UPSERT_QUAL_VEG_MONITORING:
            return {
                ...state,
                qualitativeVegetationMonitorings: addOrUpdateItemsByKey(
                    state.qualitativeVegetationMonitorings,
                    'sampleEventId',
                    [action.qualitativeVegetationMonitoring],
                    currentDateTime
                ),
                sampleEvents: updateModifiedDateByKey(
                    state.sampleEvents,
                    'sampleEventId',
                    [action.qualitativeVegetationMonitoring],
                    currentDateTime
                ),
            };
        case ADD_PHOTO_POINT:
            const currentPoint = state.cameraPoints.find(cameraPoint => String(cameraPoint.sampleLocationId) === String(action.photoPoint.sampleLocationId));

            return {
                ...state,
                cameraPoints: [...state.cameraPoints].map(cameraPoint => {
                    if (String(cameraPoint.sampleLocationId) === String(action.photoPoint.sampleLocationId)) {
                        return {
                            ...cameraPoint,
                            PhotoPoints: [
                                ...cameraPoint.PhotoPoints || [],
                                {
                                    ...action.photoPoint,
                                    offlineCreatedDate: currentDateTime ?? cameraPoint?.offlineCreatedDate,
                                    offlineModifiedDate: currentDateTime ?? cameraPoint?.offlineModifiedDate,
                                }
                            ]
                        };
                    }

                    return cameraPoint;
                }),
                projects: [...state.projects].map(project => {
                    if (project.projectId === currentPoint.projectId) {
                        return {
                            ...project,
                            offlineModifiedDate: currentDateTime ?? project?.offlineModifiedDate,
                        }
                    }

                    return project;
                })
            };
        case UPDATE_PHOTO_POINT:
            const currentCameraPoint = state.cameraPoints.find(cameraPoint => String(cameraPoint.sampleLocationId) === String(action.photoPoint.sampleLocationId));

            return {
                ...state,
                cameraPoints: [...state.cameraPoints].map(cameraPoint => {
                    if (cameraPoint.sampleLocationId === currentCameraPoint.sampleLocationId) {
                        return {
                            ...cameraPoint,
                            PhotoPoints: cameraPoint.PhotoPoints.map(point => {
                                if (point.photoPointId === action.photoPoint.photoPointId) {
                                    return {
                                        ...action.photoPoint,
                                        offlineModifiedDate: currentDateTime ?? action.photoPoint?.offlineModifiedDate,
                                    };
                                }

                                return point;
                            }),
                            offlineModifiedDate: currentDateTime ?? cameraPoint?.offlineModifiedDate,
                        }
                    }

                    return cameraPoint;
                }),
                projects: [...state.projects].map(project => {
                    if (project.projectId === currentCameraPoint.projectId) {
                        return {
                            ...project,
                            offlineModifiedDate: currentDateTime ?? project?.offlineModifiedDate,
                        }
                    }

                    return project;
                })
            };
        case ADD_PROJECT_SPECIES:
            return {
                ...state,
                projects: [...state.projects].map(project => {
                    if (String(project.projectId) === String(action.projectId)) {
                        return {
                            ...project,
                            Species: [...project.Species, action.newSpecies],
                        }
                    }

                    return project;
                })
            };
        case UPDATE_PROJECT_VALUE:
            return {
                ...state,
                projects: [...state.projects].map(project => {
                    if (project.projectId === action.projectId) {
                        return {
                            ...project,
                            [action.field]: action.value,
                            offlineModifiedDate: currentDateTime ?? project?.offlineModifiedDate,
                        };
                    }

                    return project;
                }),
                sampleEvents: sampleEventsWithAtLeastOneOfflineModifiedDateForProjectId(
                    state.sampleEvents,
                    action.projectId,
                    currentDateTime
                )
            };
        case DELETE_PHOTO:
            return {
                ...state,
                photos: [...state.photos].filter(photo => photo.photoId !== action.photoId),
                // We don't need to update sampleEvents here, because this action is
                // only used for deleting photos that have never been uploaded to the server
            };
        case DELETE_PROGRAMS:
            return {
                ...state,
                programs: [...state.programs].filter(program => !action.hierarchyIds.includes(program.hierarchyId))
            };
        case DELETE_PROJECTS:
            return {
                ...state,
                projects: [...state.projects].filter(project => !action.projectHierarchyIds.includes(project.hierarchyId))
            };
        case DELETE_SAMPLE_EVENT_PHOTOS:
            return {
                ...state,
                photos: [...state.photos].filter(photo => !action.sampleEventIds.includes(photo.sampleEventId)),
            };
        case DELETE_SAMPLE_EVENT_TRANSECTS:
            return {
                ...state,
                transects: [...state.transects].filter(transect => !action.sampleEventIds.includes(transect.sampleEventId)),
            };
        case DELETE_SAMPLE_EVENT_QUALITATIVE_VEGETATION_MONITORINGS:
            return {
                ...state,
                qualitativeVegetationMonitorings: [...state.qualitativeVegetationMonitorings].filter(
                    qualitativeVegetationMonitoring => !action.sampleEventIds.includes(qualitativeVegetationMonitoring.sampleEventId)
                )
            };
        case DELETE_SAMPLE_EVENTS:
            return {
                ...state,
                sampleEvents: [...state.sampleEvents].filter(event => !action.hierarchyIds.includes(event.hierarchyId))
            };
        case DELETE_CAMERA_POINTS:
            return {
                ...state,
                cameraPoints: [...state.cameraPoints].filter(point => !action.projectIds.includes(point.projectId))
            };
        case DELETE_TRANSECTS:
            return {
                ...state,
                transects: [...state.transects].filter(transect => !action.transectIds.includes(transect.transectId))
            };
        case DELETE_QUAL_VEG_MONITORING:
            return {
                ...state,
                qualitativeVegetationMonitorings: [...state.qualitativeVegetationMonitorings].filter(
                    qualitativeVegetationMonitoring => !action.qualitativeVegetationMonitoringIds.includes(qualitativeVegetationMonitoring.qualitativeVegetationMonitoringId)
                )
            };
        case USER_LOGOUT:
        case DELETE_EVERYTHING:
            return initialState;
        case RESTORE_BACKUP:
            return action.data.offlineDataState;
        default:
            return state;
    }
};

export default offlineDataReducer;
