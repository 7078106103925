import React from 'react';
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

const Breadcrumbs = props => {
    const breadcrumbs = useSelector(state => state.appStatusState.breadcrumbs);

    return (
        <div className="header-breadcrumbs">
            {
                breadcrumbs.map((breadcrumb, i) => {
                    const lastElement = i === breadcrumbs.length - 1;

                    if (lastElement) {
                        return (
                            <div className="breadcrumb-container last-breadcrumb" key={'breadcrumb-' + i}>
                                <Link to={breadcrumb.path}>
                                    {
                                        !!(breadcrumb.icon ?? false) ?
                                            <FontAwesomeIcon
                                                icon={breadcrumb.icon}
                                                className="event-icon"
                                            />
                                            : null
                                    }
                                    {breadcrumb.name}
                                </Link>
                            </div>
                        );
                    }

                    return (
                        <div className="breadcrumb-container" key={'breadcrumb-' + i}>
                            <Link to={breadcrumb.path}>
                                {
                                    !!(breadcrumb.icon ?? false) ?
                                        <FontAwesomeIcon
                                            icon={breadcrumb.icon}
                                            className="event-icon"
                                        />
                                        : null
                                }
                                {breadcrumb.name}
                            </Link>
                            <FontAwesomeIcon icon={['fal', 'angle-right']} transform="grow-4" />
                        </div>
                    )
                })
            }
        </div>
    );
};

export default Breadcrumbs;
