import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams, useLocation} from 'react-router-dom';
import storage from "localforage";
import {Row, Col, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import FileUtils from "../../../Utils/FileUtils";

import {usePreviousPhoto} from "../../../Hooks/PhotoHooks";
import {useReadOnly, useValidateRegex} from "../../../Hooks/FormHooks";
import {useFinalized} from "../../../Hooks/DataHooks";
import {
    selectSampleLocationAndPhotoPointByPhotoPointId,
    selectVisiblePhotoById
} from "../../../Redux/Selectors/photoSelectors";
import {selectProjectById, selectSampleEventById} from "../../../Redux/Selectors/nodeSelectors";

import {UPSERT_PHOTO, UPSERT_TRANSECT} from "../../../Redux/Actions/Types/offlineDataActionTypes";
import {markPhotoForDeletion} from "../../../Redux/Actions/photoActions";
import {PHOTO_POINT_INSTREAM_SHADE, PHOTO_POINT_TYPE_END, PHOTO_POINT_TYPE_ORIGIN} from "../../../Constants/photos";

import PageContent from '../../Layout/Page/PageContent';
import PageContainer from '../../Layout/Page/PageContainer';
import Title from "../../Layout/TitleComponent";
import FormInput from "../../Common/FormInput";
import ConfirmDeleteModal from "../../Common/ConfirmDeleteModal";
import {useQueryParams} from "../../../Hooks/RouteHooks";
import {markQualitativeVegetationRepeaterAsModified} from "../../../Redux/Actions/surveyActions";

const PhotoPointDetailPage = (props) => {
    const {readOnly, isPrevious} = useQueryParams();
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const photoRef = useRef(null);
    const getPreviousPhoto = usePreviousPhoto();
    const validateRegex = useValidateRegex();

    const currentPhoto = useSelector(state => selectVisiblePhotoById(state, params.photoId));
    const previousPhoto = getPreviousPhoto(currentPhoto?.sampleEventStartDate, currentPhoto?.photoPointId);

    const sampleEvent = useSelector(state => selectSampleEventById(state, currentPhoto?.sampleEventId));
    const project = useSelector(state => selectProjectById(state, sampleEvent?.projectId));

	const finalized = readOnly || useFinalized(currentPhoto?.sampleEventId);
    useReadOnly(finalized);

    let notesType = 'photo';
    let defaultNotes = '';
    const photoPointId = currentPhoto?.photoPointId;
    const {cameraPoint, transect, photoPoint} = useSelector(state => selectSampleLocationAndPhotoPointByPhotoPointId(state, photoPointId, currentPhoto?.sampleEventId));
    const photoPointType = cameraPoint ? 'cameraPoint' : (transect ? 'transect' : 'qualitative-repeater');

    if (
        photoPointType === 'transect' &&
        (photoPoint?.name === PHOTO_POINT_TYPE_ORIGIN ||
        photoPoint?.name === PHOTO_POINT_TYPE_END)
    ) {
        defaultNotes = transect?.originNotes ?? '';
        notesType = 'origin';
    } else {
        defaultNotes = currentPhoto?.notes ?? '';
    }

    const [displayPhoto, setDisplayPhoto] = useState(currentPhoto);
    const [notes, setNotes] = useState(defaultNotes);
    const [solarRadiation, setSolarRadiation] = useState(currentPhoto?.percentSolarRadiation ?? null);
    const [shade, setShade] = useState(currentPhoto?.percentShade ?? null);
    const [interpretedFor, setInterpretedFor] = useState(currentPhoto?.interpretedFor ?? null);
    const [hide, setHide] = useState(false);
    const [showDiscard, setShowDiscard] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    useEffect(() => {
        const element = photoRef.current;

        if (currentPhoto) {
            // Mobile touch events
            element.addEventListener('touchstart', displayPreviousPhoto);
            element.addEventListener('touchend', displayCurrentPhoto);

            // Web touch events
            element.addEventListener('mousedown', displayPreviousPhoto);
            element.addEventListener('mouseup', displayCurrentPhoto);
        }

        return () => {
            // Mobile touch events
            element.removeEventListener('touchstart', displayPreviousPhoto);
            element.removeEventListener('touchend', displayCurrentPhoto);

            // Web touch events
            element.removeEventListener('mousedown', displayPreviousPhoto);
            element.removeEventListener('mouseup', displayCurrentPhoto);
        }
    }, [currentPhoto]);

    useEffect(() => {
        if (solarRadiation !== null) {
            setShade(100 - solarRadiation);
        }
    }, [solarRadiation]);

    useEffect(() => {
        if (shade !== null) {
            setSolarRadiation(100 - shade)
        }
    }, [shade]);

    const photoChanged = (
        (defaultNotes ?? '') !== notes ||
        (currentPhoto?.percentSolarRadiation ?? null) !== solarRadiation ||
        (currentPhoto?.percentShade ?? null) !== shade ||
        (currentPhoto?.interpretedFor ?? null) !== interpretedFor
    );

    const displayCurrentPhoto = () => {
        setDisplayPhoto(currentPhoto);
        setHide(false);
    };

    const displayPreviousPhoto = () => {
        if (previousPhoto) {
            setDisplayPhoto(previousPhoto);
            setHide(true);
        }
    };

    const save = () => {
        const updatedPhoto = {
            ...currentPhoto,
            percentSolarRadiation: solarRadiation,
            percentShade: shade,
            interpretedFor,
        };

        if (notesType === 'origin') {
            const updatedTransect = {...transect, originNotes: notes};
            dispatch({type: UPSERT_TRANSECT, transect: updatedTransect});
        } else {
            updatedPhoto.notes = notes;
        }

        dispatch({type: UPSERT_PHOTO, photo: updatedPhoto});

        // if this is a qual veg photo, mark the survey as modified
        if(updatedPhoto?.qualitativeVegetationMonitoringRepeaterId ?? null) {
            dispatch(markQualitativeVegetationRepeaterAsModified(updatedPhoto.qualitativeVegetationMonitoringRepeaterId));
        }

        history.goBack();
    };

    const deletePhoto = () => {
        dispatch(markPhotoForDeletion(currentPhoto));
        history.goBack();
    };

    const close = () => {
        return photoChanged ? setShowDiscard(true) : history.goBack();
    };

    const shortDescription = (photoPointType === 'cameraPoint') ? `Photo Point ${cameraPoint?.name}${photoPoint?.name}` :
                             ((photoPointType === 'transect') ? `Transect ${transect?.name} ${photoPoint?.photoDirection}` :
                                'Qualitative Veg. Survey Photo'
                             );
    const longDescription = `${project?.name} - ${currentPhoto?.sampleEventName} - ${shortDescription}`;

    const saveToDevice = async () => {
        let src = currentPhoto.src;

        // attempt to save file from indexedDB
        const imageKey = FileUtils.IndexedDBKeyFromPhotoURL(src);
        const data = await storage.getItem(imageKey);
        const blob = FileUtils.EnsureBlob(data, 'image/jpeg');
        const fileExtension = currentPhoto?.fileExtension ? `.${currentPhoto?.fileExtension}` : '';
        FileUtils.DownloadBlobAsFile(blob, `${longDescription}${fileExtension}`);
    };

    const renderFields = () => {
        if (photoPointType === 'cameraPoint') {
            return (
                <Fragment>
                    <div className="photo-info">
                        <div>Photo Point</div>
                        <div className="photo-info-data">{cameraPoint?.name}{photoPoint?.name}</div>
                    </div>
                    <div className="photo-info">
                        <div>Direction/Azimuth</div>
                        <div className="photo-info-data">{photoPoint?.photoDirection} {photoPoint?.compassBearing}&#176;</div>
                    </div>
                </Fragment>
            )
        }

        switch (photoPoint?.name) {
            case PHOTO_POINT_INSTREAM_SHADE:
                const dateRegex = new RegExp(/^(0[1-9]|[1-9]|1[012])[- /.](0[1-9]|[1-9]|[12][0-9]|3[01])$/g);

                return (
                    <Fragment>
                        <div className="photo-info">
                            <div className="photo-info-data">{shortDescription}</div>
                        </div>
                        <FormInput
                            label="Solar Radiation"
                            type="number"
                            value={solarRadiation ?? ''}
                            onChange={value => value <= 100 ? setSolarRadiation(value) : null}
                            suffix="%"
                            readOnly={finalized}
                        />
                        <FormInput
                            label="Shade"
                            type="number"
                            value={shade ?? ''}
                            onChange={value => value <= 100 ? setShade(value) : null}
                            suffix="%"
                            readOnly={finalized}
                        />
                        <FormInput
                            label="Interpreted for (month / day)"
                            value={interpretedFor ?? ''}
                            placeholder="mm/dd"
                            onChange={value => setInterpretedFor(value)}
                            validationMessage={validateRegex(interpretedFor, dateRegex)}
                            readOnly={finalized}
                        />
                    </Fragment>
                );
            default:
                return <Fragment>
                    <div className="photo-info">
                        <div className="photo-info">{shortDescription}</div>
                    </div>
                </Fragment>;
        }
    };

    return (
        <Fragment>
            <ConfirmDeleteModal
                show={showDiscard}
                onHide={() => setShowDiscard(false)}
                delete={() => history.goBack()}
                itemMessage="your current changes"
                discard
            />
            <ConfirmDeleteModal
                show={showDelete}
                onHide={() => setShowDelete(false)}
                delete={() => deletePhoto()}
                itemMessage="this photo"
            />
            <PageContainer className="photo-point-detail-page" noHeader>
                <PageContent>
                    <Row xs={12}>
                        <Col xs={12} className="photo-point-detail-header">
                            <div className="close-container">
                                <div className="btn-close" onClick={() => close()}>
                                    <FontAwesomeIcon icon={['fal', 'times']} />
                                    Close
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} className="photo-detail-main">
                            <Row xs={12}>
                                <Col xs={9}>
                                    <div className="photo" ref={photoRef}>
                                        <img src={currentPhoto?.src} className={`current-photo ${hide ? 'hide' : ''}`} draggable={false} />
                                        <img src={previousPhoto?.src} className="previous-photo" draggable={false} />
                                        <div className="caption">
                                            <div className="caption-group">
                                                <span>{displayPhoto?.sampleEventName}</span>
                                                {isPrevious && <span> - Previous Monitoring Event</span>}
                                            </div>
                                            <div className="caption-group">
                                                <span>{displayPhoto?.activity}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={3} className="side-info">
                                    <Title title={currentPhoto?.sampleEventName} readOnly={finalized} />
                                    {renderFields()}
                                    <div className="photo-info photo-notes">
                                        <FormInput
                                            label={notesType === 'origin' ? ((isPrevious ? 'Previous ' : '') + 'Origin & End Notes'): 'Notes'}
                                            type="textarea"
                                            value={notes ?? ''}
                                            onChange={value => setNotes(value)}
                                        />
                                    </div>
                                    <Button onClick={() => saveToDevice()}>
                                        <FontAwesomeIcon icon={['fal', 'cloud-download']} />
                                        Save Photo to Device
                                    </Button>
                                    <Button variant="danger" onClick={() => setShowDelete(true)}>
                                        <FontAwesomeIcon icon={['fal', 'trash-alt']} />
                                        Delete
                                    </Button>
                                    {
                                        photoChanged ?
                                            <Button onClick={() => save()}>
                                                <FontAwesomeIcon icon={['fal', 'save']} />
                                                Save
                                            </Button> :
                                            <Button onClick={() => close()} disabled={false}>
                                                Close
                                            </Button>
                                    }
                                </Col>
                                <Col xs={9} className="photo-action">
                                    {
                                        previousPhoto ?
                                            <div>
                                                <FontAwesomeIcon icon={['fal', 'hand-point-up']} />
                                                Touch Above to Toggle Photo
                                            </div> :
                                        !isPrevious ?
                                            <div>Previous Photo Not Available</div> : null
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </PageContent>
            </PageContainer>
        </Fragment>
    );
};

export default PhotoPointDetailPage;
