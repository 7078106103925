import React, {useEffect, Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from 'react-router-dom';
import {SET_BREADCRUMBS, SET_CONTEXT_TABS} from "../Redux/Actions/Types/appStatusTypes";
import {selectProjectById, selectSampleEventById} from "../Redux/Selectors/nodeSelectors";
import {selectTransect} from "../Redux/Selectors/transectSelectors";
import {TYPE_LENGTH_OF_COVER, TYPE_STEM_TALLY, TYPE_TREE_DBH} from "../Constants/lengthCategories";
import LengthCategoryUtils from "../Utils/LengthCategoryUtils";
import IconUtils from "../Utils/IconUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const useBreadcrumbs = (title, projectId = null, sampleEventId = null, transectId = null) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const project = useSelector(state => selectProjectById(state, projectId));
    const sampleEvent = useSelector(state => selectSampleEventById(state, sampleEventId));
    const transect = useSelector(state => selectTransect(state, transectId));

    const breadcrumbs = [];

    if (project) {
        breadcrumbs.push(projectPath(project));
    }

    if (sampleEvent) {
        breadcrumbs.push(monitoringEventPath(sampleEvent));
    }

    if (transect) {
        breadcrumbs.push(transectPath(transect));
    }

    if(title !== null) {
        breadcrumbs.push({
            name: title,
            path: location.pathname,
        });
    }

    useEffect(() => {
        dispatch({type: SET_BREADCRUMBS, breadcrumbs});
    }, [location.pathname]);
};

export const useHydrozoneContextTabs = (transectId, plotTypeId, hydrozoneTypeId) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const types = [TYPE_STEM_TALLY, TYPE_LENGTH_OF_COVER, TYPE_TREE_DBH];

    const contextTabs = types.map(type => ({
        name: LengthCategoryUtils.GetLengthCategoryName(type),
        path: `/transect/${transectId}/plot${plotTypeId}/${type}/${hydrozoneTypeId}`,
    }));

    useEffect(() => {
        dispatch({type: SET_CONTEXT_TABS, contextTabs})
    }, [location.pathname])
};

const projectPath = (project) => {
    return {
        name: project.shortName ?? project.name,
        path: `/project/${project.projectId}`,
    };
};

const monitoringEventPath = (event) => {
    
    const procedureIcon = IconUtils.GetIcon(event.procedureCode, 'fas')
    
    return {
        icon: procedureIcon,
        name: event.sampleEventPhaseName,
        path: `/event/${event.sampleEventId}/${event.procedureCode}`,
    };
};

const transectPath = (transect) => {
    return {
        name: `Transect ${transect.name}`,
        path: `/transect/${transect.transectId}/quant-veg`,
    };
};
