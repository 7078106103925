import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faAngleLeft, faBook, faCamera, faChartBar, faClipboardList, faCloud, faFileChartLine, faFilter, faFish, faGlobe, faLayerGroup, faLocation, faMapMarkerAlt, faPlus, faSave, faSun, faTimes, faTreeAlt, faUser, faUserHardHat, faExclamationTriangle, faMinus, faCheck, faTrashAlt, faExclamation, faBorderAll, faTh, faCircle, faRectangleLandscape, faFileImage, faImage, faChevronCircleRight, faPen, faStickyNote, faCalendarTimes, faHandPointUp, faLocationArrow, faDice, faClipboardListCheck, faChartScatter, faCloudDownload, faCalendarCheck, faArrowToBottom, faArrowToTop, faBolt, faCheckCircle, faCompass, faClipboard, faExclamationCircle, faTimesCircle, faFistRaised, faCertificate } from '@fortawesome/pro-light-svg-icons';
import { faCheckSquare, faCircle as faSolidCircle, faSquare, faRectangleLandscape as faSolidRectangleLandscape, faCloud as faSolidCloud, faCamera as faSolidCamera, faRedo, faExclamationTriangle as faSolidExclamationTriangle, faCheck as faSolidCheck, faArrowUp, faArrowDown, faEllipsisH, faTree, faBars, faTreeAlt as faSolidTreeAlt, faSun as faSolidSun, faClipboardList as faSolidClipboardList, faBackspace, faInfoCircle, faArrowLeft, faArrowRight, faAngleRight as faSolidAngleRight, faQuestionCircle, faLock, faExternalLink, faCheckCircle as faSolidCheckCircle, faTimes as faSolidTimes, faExclamation as faSolidExclamation, faExclamationCircle as faSolidExclamationCircle, faEye, faEyeSlash, faArrowAltUp, faUserHardHat as faSolidUserHardHat } from '@fortawesome/pro-solid-svg-icons';

library.add(
    faAngleRight,
    faAngleLeft,
    faCloud,
    faCircle,
    faTreeAlt,
    faCamera,
    faSun,
    faLayerGroup,
    faFish,
    faClipboardList,
    faChartBar,
    faSave,
    faPlus,
    faTimes,
    faUser,
    faGlobe,
    faBook,
    faLocation,
    faFileChartLine,
    faMapMarkerAlt,
    faFilter,
    faExclamationTriangle,
    faCheckSquare,
    faSquare,
	faRectangleLandscape,
	faSolidRectangleLandscape,
    faMinus,
    faSolidCloud,
    faCheck,
    faSolidCamera,
    faTrashAlt,
    faExclamation,
    faCloudDownload,
    faBorderAll,
    faTh,
    faSolidCircle,
    faRedo,
    faFileImage,
    faImage,
    faChevronCircleRight,
    faPen,
    faStickyNote,
    faCalendarTimes,
    faHandPointUp,
    faLocationArrow,
    faDice,
    faClipboardListCheck,
    faChartScatter,
    faSolidExclamationTriangle,
    faArrowUp,
    faArrowDown,
    faEllipsisH,
    faSolidCheck,
    faCalendarCheck,
    faTree,
    faBars,
    faArrowToBottom,
    faArrowToTop,
	faBolt,
    faCheckCircle,
    faSolidSun,
    faSolidClipboardList,
    faSolidTreeAlt,
    faBackspace,
    faInfoCircle,
    faArrowLeft,
    faArrowRight,
    faCompass,
    faSolidAngleRight,
    faQuestionCircle,
	faLock,
    faExternalLink,
    faSolidCheckCircle,
    faClipboard,
    faSolidTimes,
    faSolidExclamation,
    faExclamationCircle,
    faSolidExclamationCircle,
    faEye,
    faEyeSlash,
    faArrowAltUp,
	faUserHardHat,
	faSolidUserHardHat,
	faTimesCircle,
	faFistRaised,
	faCertificate,
);
