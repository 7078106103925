import React from 'react';
import { ModalBody, ModalFooter, Button, Row, Col } from 'react-bootstrap';

import Modal from '../../Common/Modal';

const DuplicateSpeciesModal = props => {
    return (
        <Modal
            title="Species In Use"
            size="md"
            show={props.show}
            onHide={() => props.onHide()}
        >
            <ModalBody>
                <Row xs={12}>
                    <Col xs={12}>
                        <p>
                            This species has already been selected. Please use the existing row.
                        </p>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button variant="action" className="ml-3" onClick={() => props.onHide()}>
                    OK
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default DuplicateSpeciesModal;
