import React from 'react';
import {Row, Col} from 'react-bootstrap';

const SidebarWithBackdrop = props => {
    return (
        <Row xs={12} className={`backdrop-sidebar ${props.className || ''} ${props.show ? 'show' : 'hide'}`}>
            <Col xs={12}>
                <div className="backdrop-layer" onClick={() => props.hideMenu()} />
            </Col>
            <Col xs={9} />
            <Col xs={3}>
                <div className="backdrop-sidebar-container">
                    {props.children}
                </div>
            </Col>
        </Row>
    )
};

export default SidebarWithBackdrop;
