import React from 'react';

const SidebarContent = props => {
    return (
        <div className="sidebar-content">
            {props.children}
        </div>
    )
};

export default SidebarContent;