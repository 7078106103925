import { createSelector } from 'reselect';
import HydrozoneUtils from "../../Utils/HydrozoneUtils";

const transectSelector = state => state.offlineDataState.transects;

export const selectTransectsWithPlots = createSelector(
    [transectSelector],
    transects => transects.map(transect => {
        return {
            ...transect,
            Hydrozones: HydrozoneUtils.ComputeHydrozoneEnds(transect),
            HydrozonePlots: HydrozoneUtils.ComputeHydrozonePlots(transect),
        }
    })
);

export const selectTransect = (state, transectId) => {
    return selectTransectsWithPlots(state).find(transect => String(transect.transectId) === String(transectId));
};
