import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import CircledTextIcon from "../../CompositeIcons/CircledTextIcon";
import CircledIcon from "../../CompositeIcons/CircledIcon";
import {updateValue} from "../../../Redux/Actions/transectActions";
import {BACKSPACE, INCREMENT, SET, UNDO} from "../../../Redux/Actions/Types/transectActionTypes";
import {TYPE_STEM_TALLY} from "../../../Constants/lengthCategories";

const KeyboardComponent = props => {
    const dispatch = useDispatch();
    const keyboardRef = useRef(null);

    const selectedField = useSelector(state => state.transectState.selectedField);
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

    useEffect(() => {
        // Make input not lose focus when interacting with keyboard functionality
        keyboardRef.current.addEventListener('mousedown', (e) => e.preventDefault());

        return () => {
            if (keyboardRef.current) {
                keyboardRef.current.removeEventListener('mousedown', (e) => e.preventDefault());
            }
        }
    }, []);

    const disabled = !selectedField || props.readOnly;

    return (
        <div className={`keyboard-component type-${props.type} ${disabled ? 'disabled' : ''}`} ref={keyboardRef}>
            <div>
                {
                    props.type !== TYPE_STEM_TALLY &&
                    <CircledTextIcon
                        circleSize={36}
                        text="0.5+"
                        className="action"
                        onClick={() => !disabled && dispatch(updateValue(INCREMENT, props.type, 0.5))}
                    />
                }
                <CircledTextIcon
                    circleSize={36}
                    text="1+"
                    className="action"
                    onClick={() => !disabled && dispatch(updateValue(INCREMENT, props.type, 1))}
                />
                <CircledTextIcon
                    circleSize={36}
                    text="5+"
                    className="action"
                    onClick={() => !disabled && dispatch(updateValue(INCREMENT, props.type, 5))}
                />
                {
                    numbers.map(number => (
                        <CircledTextIcon
                            key={number}
                            circleSize={36}
                            onClick={() => !disabled && dispatch(updateValue(SET, props.type, number))}
                            text={number}
                        />
                    ))
                }
                <CircledIcon
                    circleSize={36}
                    icon={['fas', 'backspace']}
                    iconTransform="grow-2"
                    className="danger"
                    onClick={() => !disabled && dispatch(updateValue(BACKSPACE, props.type))}
                />
                <CircledIcon
                    circleSize={36}
                    icon={['fas', 'redo']}
                    iconTransform="flip-h"
                    className="danger"
                    onClick={() => !disabled && dispatch(updateValue(UNDO, props.type))}
                />
            </div>
        </div>
    );
};

export default KeyboardComponent;
