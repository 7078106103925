export const SET_HIERARCHY_NODES = 'hierarchy/SET_HIERARCHY_NODES';
export const SET_ORGANIZATION_ID = 'hierarchy/SET_ORGANIZATION_ID';
export const UPDATE_HIERARCHY_NODE_STATUS = 'hierarchy/UPDATE_HIERARCHY_NODE_STATUS';
export const SET_SELECTED_HIERARCHY_NODES = 'hierarchy/SET_SELECTED_HIERARCHY_NODES';
export const ADD_SELECTED_HIERARCHY_NODES = 'hierarchy/ADD_SELECTED_HIERARCHY_NODES';
export const DELETE_SELECTED_HIERARCHY_NODES = 'hierarchy/DELETE_SELECTED_HIERARCHY_NODES';
export const CLEAR_SELECTED_HIERARCHY_NODES = 'hierarchy/CLEAR_SELECTED_HIERARCHY_NODES';
export const DELETE_NODES = 'hierarchy/DELETE_NODES';
export const SET_DELETED_NODE = 'hierarchy/SET_DELETED_NODE';
export const SET_LOADING = 'hierarchy/SET_LOADING';
