export const VERIFICATION_START = 'login/VERIFICATION_START';
export const UPDATE_VERIFICATION_FIELD_VALUE = 'login/UPDATE_VERIFICATION_FIELD_VALUE';
export const TIMER_START = 'login/TIMER_START';
export const TIMER_TICK = 'login/TIMER_TICK';
export const SET_TITLE = 'login/SET_TITLE';
export const SET_PHONE_NUMBER = 'login/SET_PHONE_NUMBER';
export const VERIFICATION_SUCCESS = 'login/VERIFICATION_SUCCESS';
export const VERIFICATION_CANCEL = 'login/VERIFICATION_CANCEL';
export const SET_LOGIN_ERROR = 'login/SET_ERROR';
export const SET_SESSION_TIMEOUT = 'login/SET_SESSION_TIMEOUT';
export const SET_LOADING = 'login/SET_LOADING';
