import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import CircledIcon from '../../CompositeIcons/CircledIcon';

const CollapsibleSidebar = props => {
    const [hide, setHide] = useState(props.hide ?? false);

    return (
        <Col xs={3} className={`streambank-sidebar collapsible-sidebar ${props.className || ''} ${hide ? 'hide' : 'show'}`}>
            <CircledIcon
                icon={['fal', 'angle-right']}
                circleSize="10"
                iconTransform="grow-2 right-1"
                className="btn-collapse"
                onClick={() => setHide(!hide)}
            />
            <div className="content">
                {props.children}
            </div>
        </Col>
    )
};

export default CollapsibleSidebar;
