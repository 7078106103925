import {
    CLEAR_SELECTED_HIERARCHY_NODES,
    SET_HIERARCHY_NODES,
    SET_DELETED_NODE,
    SET_ORGANIZATION_ID,
    SET_SELECTED_HIERARCHY_NODES,
    SET_LOADING,
    ADD_SELECTED_HIERARCHY_NODES,
    DELETE_SELECTED_HIERARCHY_NODES,
    DELETE_NODES,
} from "../Actions/Types/hierarchyActionTypes";
import {USER_LOGOUT} from "../Actions/Types/userActionTypes";
import {OFFLINE_STATUS_DO_NOT_SYNC} from "../../Constants/status";
import {RESTORE_BACKUP} from "../Actions/Types/offlineDataActionTypes";

const initialState = {
    hierarchyNodes: [],
    organizationId: null,
    selected: {},
    deletedNode: null,
    loading: false,
};

const hierarchyReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_HIERARCHY_NODES:
            return {
                ...state,
                hierarchyNodes: action.hierarchyNodes,
            };
        case SET_ORGANIZATION_ID:
            return {
                ...state,
                organizationId: action.organizationId,
            };
        case SET_SELECTED_HIERARCHY_NODES:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    [action.hierarchyId]: action.selected
                },
            };
        case ADD_SELECTED_HIERARCHY_NODES:
            let selectedNodes = state.selected[action.hierarchyId] ?? [action.node];

            if (!selectedNodes.includes(action.node)) {
                selectedNodes = [...selectedNodes, action.node];
            }

            return {
                ...state,
                selected: {
                    ...state.selected,
                    [action.hierarchyId]: selectedNodes
                },
            };
        case DELETE_SELECTED_HIERARCHY_NODES:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    [action.hierarchyId]: state.selected[action.hierarchyId].filter(id => id !== action.node)
                },
            };
        case CLEAR_SELECTED_HIERARCHY_NODES:
            return {
                ...state,
                selected: {}
            };
        case SET_DELETED_NODE:
            return {
                ...state,
                deletedNode: action.deletedNode
            };
        case DELETE_NODES:
            return {
                ...state,
                hierarchyNodes: [...state.hierarchyNodes].map(node => {
                    if (action.hierarchyIds.includes(node.hierarchyId)) {
                        return {
                            ...node,
                            offlineState: OFFLINE_STATUS_DO_NOT_SYNC,
                        }
                    }

                    return node;
                })
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        case USER_LOGOUT:
            return initialState;
        case RESTORE_BACKUP:
            return action?.data?.hierarchyNodeState ?? state;
        default:
            return state;
    }
};

export default hierarchyReducer;
