import React from 'react';
import {useSelector} from "react-redux";
import { Row, Col, Container } from 'react-bootstrap';

const PageContainer = props => {
    const returnPath = useSelector(state => state.appStatusState.returnPath);

    return (
        <Row xs={12} className={`m-0 streambank-layout ${returnPath && !props.noHeader ? 'return-bar-visible' : ''} ${props.className}`}>
            <Col className="h-100">
                <Container className="streambank-page" fluid>
                    {props.children}
                </Container>
            </Col>
            {props.sidebar || null}
        </Row>
    )
};

export default PageContainer;
