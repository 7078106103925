import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import {CLOSE_QUICK_NAV} from "../../../Redux/Actions/Types/appStatusTypes";

import SidebarWithBackdrop from "../Sidebar/SidebarWithBackdrop";
import PillLink from "./PillLink";

const QuickNav = props => {
    const dispatch = useDispatch();
    const links = useSelector(state => state.appStatusState.quickNavLinks);

    return (
        <SidebarWithBackdrop
            show={props.show}
            className="quick-nav"
            hideMenu={() => dispatch({type: CLOSE_QUICK_NAV})}
        >
            {
                links.filter(link => !link.hideNav).map(link => (
                    <PillLink
                        key={link.code}
                        label={link.shortName || link.title}
                        to={link.path}
                        progress={link.progress}
                        complete={link.isCompleted}
                        child={link.child}
                        current={link.current}
                        className={link.className}
                    />
                ))
            }
        </SidebarWithBackdrop>
    )
};

export default QuickNav;
