import React from 'react';
import {useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";

const ContextTabs = props => {
    const location = useLocation();

    const contextTabs = useSelector(state => state.appStatusState.contextTabs);

    return (
        <div className="context-tabs">
            {
                contextTabs.map(tab => (
                    <div className={`context-tab ${location.pathname === tab.path ? 'active' : ''}`} key={tab.name}>
                        <Link to={tab.path}>
                            {tab.name}
                        </Link>
                    </div>
                ))
            }
        </div>
    );
};

export default ContextTabs;
