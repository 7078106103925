import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useProgressColor } from '../../Hooks/ProgressHooks';

const Tile = ({item, ellipsis, onClick, actions, noIcon, showComplete}) => {
    const progressColor = useProgressColor();
    const ellipsisClass = ellipsis ? 'ellipsis' : '';

    return (
        <div className={`tile-item ${progressColor(item.progress, item?.isCompleted)} ${ellipsisClass}`} onClick={() => onClick ? onClick() : null}>

            <div className="tile-main">
                {item.leftIcon || null}
                <div className="tile-content">
                    <div className="tile-title">
                        {showComplete && item?.isCompleted && <FontAwesomeIcon icon={['fas', 'check-circle']} className="tile-success-icon" />}
                        {item.title}
                    </div>

                    {item.subtitle && <div className="tile-subtitle">{item.subtitle}</div>}
                    {item.caption && <div className="tile-subtitle">{item.caption}</div>}
                    {
                        actions && <div className="tile-actions">{actions}</div>
                    }
                </div>
                {
                    item.icon ? <FontAwesomeIcon icon={item.icon} />
                        : !noIcon ? <FontAwesomeIcon icon={["fal", "angle-right"]} /> :
                        null
                }
            </div>
            {
                item.progress > 0 && !item?.isCompleted &&
                <div className="tile-progress">
                    <ProgressBar now={item.progress} />
                </div>
            }

        </div>
    )
};

export default Tile;
