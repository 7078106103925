import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HalfCheck from '../Components/CompositeIcons/HalfCheck';

export const checkboxTreeIcons = {
    check: <FontAwesomeIcon icon={['fas', 'check-square']} />,
    uncheck: <FontAwesomeIcon icon={['fas', 'square']} />,
    halfCheck: <HalfCheck />,
    expandClose: <FontAwesomeIcon icon={['fal', 'plus']} />,
    expandOpen: <FontAwesomeIcon icon={['fal', 'minus']} />,
};
