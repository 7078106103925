import React from 'react';

const SidebarFooter = props => {
    return (
        <div className="sidebar-footer">
            {props.children}
        </div>
    )
};

export default SidebarFooter;