import React, { useState, useEffect } from 'react';
import { FormGroup, FormLabel, FormControl, InputGroup } from 'react-bootstrap';

const CheckboxList = props => {
    const [checked, setChecked] = useState([]);
    const getCheckedValue = (value) => checked.find(check => check[props.primaryKey] === value);
    const primaryKey = props.primaryKey || 'id';

    useEffect(() => {
        setChecked(props.checked);
    }, [props.checked]);

    const toggleCheckbox = (value) => {
        const checkedValues = [...checked];
        const lastValue = props.data.find(datum => datum[primaryKey] === value);

        if (getCheckedValue(value)) {
            const index = checkedValues.indexOf(getCheckedValue(value));
            if (index > -1) {
                checkedValues.splice(index, 1);
            }
            props.onChange(checkedValues, lastValue);
        } else {
            props.onChange([...checkedValues, lastValue], lastValue);
        }
    };

    return (
        <FormGroup className={`checkbox-list ${props.required ? 'required' : ''}`}>
            <FormLabel>{props.label}</FormLabel>
            {
                props.data.map(item => (
                    <InputGroup key={item[primaryKey]}>
                        <InputGroup.Prepend>
                            <InputGroup.Checkbox
                                id={item[primaryKey]}
                                checked={getCheckedValue(item[primaryKey]) ?? false}
                                onChange={() => toggleCheckbox(item[primaryKey])}
                            />
                        </InputGroup.Prepend>
                        <FormControl onClick={() => toggleCheckbox(item[primaryKey])} value={item.name} readOnly />
                    </InputGroup>
                ))
            }
        </FormGroup>
    );
};

export default CheckboxList;
