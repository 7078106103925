import React from 'react';
import {OverlayTrigger, Popover} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function LegacyDataInfoIcon(props) {

    return <OverlayTrigger
        trigger="click"
        key="right"
        placement="right"
        overlay={
            <Popover>
                <Popover.Content>
                    This historical value was selected on a previous version of StreamBank Monitoring which provided different drop-down options and this value is no longer available.
                </Popover.Content>
            </Popover>
        }
        rootClose
        {...props}
    >
        <FontAwesomeIcon icon={['fas', 'info-circle']} className="info-popover" />
    </OverlayTrigger>;
}
