import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RetakePhotoIcon = props => {
    return (
        <span className="fa-layers fa-fw retake-photo-icon">
            <FontAwesomeIcon icon={['fal', 'circle']} />
            <FontAwesomeIcon icon={['fas', 'circle']} transform="shrink-2" inverse />
            <FontAwesomeIcon icon={['fas', 'redo']} transform="shrink-9 flip-h" />
        </span>
    );
};

export default RetakePhotoIcon;
