import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap';

const ReadOnlyHelpIcon = (props) => {

    return (
        <OverlayTrigger
            trigger="click"
            key="right"
            placement="right"
            overlay={
                <Popover>
                    <Popover.Content>
                        {
                            props.reason ? props.reason : 'This monitoring event is read-only because it has been Finalized. To make changes, uncheck the "Finalize" box on the Review and Finalize screen.'
                        }
                    </Popover.Content>
                </Popover>
            }
            rootClose
        >
            <FontAwesomeIcon icon={['fas', 'lock']} className="read-only-icon" />
        </OverlayTrigger>
    )
};

export default ReadOnlyHelpIcon;
