import {TEMP_ID_PREFIX} from "../../Constants/misc";
import {DELETE_PHOTO, SET_PHOTO_DEPENDENCIES, UPSERT_PHOTO} from "./Types/offlineDataActionTypes";
import storage from "localforage";
import {deleteQualitativeVegetationRepeaterPhoto} from "./surveyActions";
import FileUtils from "../../Utils/FileUtils";
import PhotoUtils from "../../Utils/PhotoUtils";

export const markPhotoForDeletion = (photo) => (dispatch) => {
    if(String(photo.photoId).startsWith(TEMP_ID_PREFIX)) {
        // photos that have never been uploaded can be
        // completely removed from the device
        dispatch({type: DELETE_PHOTO, photoId: photo.photoId});
    } else if(photo.qualitativeVegetationMonitoringRepeaterId != null) {
        // qualitative vegetation repeater photos have
        // special logic since we store a history of changes
        dispatch(deleteQualitativeVegetationRepeaterPhoto(photo));    
    } else {
        // all other photos get 'marked' as deleted on the server
        // during the next upload
        dispatch({type: UPSERT_PHOTO, photo: {...photo, deleted: true }});
    }
};



export const addPhotoContent = async (photoId, blob) => {
    await storage.setItem(PhotoUtils.getPhotoApiPath(photoId), blob);
    return {
        photoId,
        src: PhotoUtils.getPhotoApiUrl(photoId),
        thumbnailSrc: null,
        fileExtension: FileUtils.FileExtensionFromContentType(blob.type),
    }
}

export const movePhotoContent = (photo, newPhotoId, download = false) => async (dispatch, getState) => {
    
    if(!photo || !newPhotoId || String(newPhotoId) === String(photo.photoId)) {
        return photo;
    }
    
    const content = await PhotoUtils.getPhotoContent(photo.photoId);
    
    if(!content || content?.size <= 0) {
        // if we fail to retrieve the photo, we cannot confirm
        // that we successfully moved it, so halt and make sure
        // we don't delete the old photo
        return photo;
    }
    
    const updatedPhoto = {
        ...photo,
        ...(await addPhotoContent(newPhotoId, content)),
    };
    dispatch({type: UPSERT_PHOTO, photo: updatedPhoto, download});
    dispatch(movePhotoDependency(photo.photoId, newPhotoId));
    
    // delete the old photo only if it actually changed photoIds
    if(String(photo.photoId) !== String(updatedPhoto.photoId)) {
        dispatch({type: DELETE_PHOTO, photoId: photo.photoId, download});
        await deletePhotoContentById(photo.photoId);
    }
    
    return updatedPhoto;
};

export const deletePhotoContentById = (photoId) => {
    const largePath = PhotoUtils.getPhotoApiPath(photoId);
    const smallPath = PhotoUtils.getPhotoApiPath(photoId, true);

    return Promise.allSettled([
        storage.removeItem(largePath),
        storage.removeItem(smallPath),
    ]);
};

export const deleteOrphanedPhotoContent = () => (dispatch, getState) => {
    const { photoDependencies } = getState().offlineDataState;
    const { abort } = getState().syncState;
    
    let photoRemovalPromises = [];

    if(photoDependencies.length === 0 || abort) {
        console.log("during testing we don't expect this");
        return;
    }

    storage.iterate(function(value, key, iterationNumber) {

        // only process photos
        if(!key.startsWith('/api/photos/')) {
            return;
        }

        // don't delete photos taken by the user
        // since they might not have been backed up
        // in the cloud yet. these will get
        // deleted during the sync routine on a
        // successful upload instead.
        if(key.startsWith('/api/photos/tmp')) {
            return;
        }

        const splitKey = key.split('/');
        const photoId = splitKey[3];

        // keep any photos that still have dependencies
        if((photoDependencies?.[photoId] ?? []).length) {
            return;
        }

        if(photoRemovalPromises?.[photoId] == null) {
            console.log('PhotoId ' + photoId + ' is no longer needed. Removing.');
            photoRemovalPromises[photoId] = deletePhotoContentById(photoId).then(() => {
                console.log('PhotoId ' + photoId + ' was removed.');
            }).catch((e) => {
                console.log('Failed to remove ' + photoId + '.');
            });
        } else {
            console.log('PhotoId ' + photoId + ' is already being removed.');
        }
    }).then(function() {
        console.log('Iteration has completed');
    }).catch(function(err) {
        // This code runs if there were any errors
        console.log(err);
    });

    return Promise.all(photoRemovalPromises).then(() => {
        console.log('Orphaned photo cleanup complete.');
    });
};

export const getPhotoDependenciesExcludingHierarchyIds = (photoDependencies, hierarchyIdsToRemove) => {
    let updatedPhotoDependencies = {...photoDependencies};

    for (const [photoId, existingHierarchyIds] of Object.entries(updatedPhotoDependencies)) {
        updatedPhotoDependencies[photoId] = existingHierarchyIds.filter(existingHierarchyId => !hierarchyIdsToRemove.includes(existingHierarchyId));
    }

    return updatedPhotoDependencies;
};

// this updates the photo dependency tree for the specified
// hierarchyIds --
// specifically it set each of the photos as being dependencies
// for each of the specified hierarchyIds
// AND CLEAR any previous photo associations for the specified hierarchyIds
//
// if photos is not-empty you should only
// pass a single hierarchyId at once, otherwise all of the
// photos will become associated with all of the hierarchyIds
export const updatePhotoDependenciesForHierarchyIds = (photos, hierarchyIds) => (dispatch, getState) => {
    const {photoDependencies} = getState().offlineDataState;
    let updatedPhotoDependencies = getPhotoDependenciesExcludingHierarchyIds(photoDependencies, hierarchyIds);

    for (const photo of photos ?? []) {
        updatedPhotoDependencies[photo.photoId] = [...updatedPhotoDependencies[photo.photoId] ?? [], ...hierarchyIds];
    }

    dispatch({type: SET_PHOTO_DEPENDENCIES, photoDependencies: updatedPhotoDependencies});
};

// this is the same as calling updatePhotoDependenciesForHierarchyIds
// with an empty array of photos
export const removePhotoDependenciesForHierarchyIds = (hierarchyIds) => (dispatch, getState) => {
    dispatch(updatePhotoDependenciesForHierarchyIds([], hierarchyIds));
};

export const movePhotoDependency = (oldPhotoId, newPhotoId) => (dispatch, getState) => {
    const {photoDependencies} = getState().offlineDataState;
    let updatedPhotoDependencies = {};

    for (const [photoId, existingHierarchyIds] of Object.entries(photoDependencies)) {
        const updatedPhotoId = (String(photoId) === String(oldPhotoId)) ? newPhotoId : photoId;
        updatedPhotoDependencies[updatedPhotoId] = existingHierarchyIds;
    }
    
    dispatch({type: SET_PHOTO_DEPENDENCIES, photoDependencies: updatedPhotoDependencies});
};
