import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from 'react-router-dom'

import PageHeader from "../../Layout/Page/PageHeader";
import Title from "../../Layout/TitleComponent";
import PageContent from "../../Layout/Page/PageContent";
import {FormCheck, Table} from "react-bootstrap";
import PageFooter from "../../Layout/Page/PageFooter";
import PageContainer from "../../Layout/Page/PageContainer";
import FooterNav from "../../Layout/Page/FooterNav";
import {useTransectReviewNav} from "../../../Hooks/QuickNavHooks";
import {selectTransect} from "../../../Redux/Selectors/transectSelectors";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import {useTransectToolProgress} from "../../../Hooks/ProgressHooks";
import {updateTransectReview} from "../../../Redux/Actions/transectActions";
import {useFinalized} from "../../../Hooks/DataHooks";
import {useContextToolsBySampleEventId} from "../../../Hooks/ToolHooks";
import ReturnButton from "../../Common/ReturnButton";
import {TRANSECT_REVIEW_CODE_CANOPY} from "../../../Constants/transectReview";

const CanopyClosureReviewPage = props => {
    const dispatch = useDispatch();
    const params = useParams();
    const transectToolProgress = useTransectToolProgress();

    useTransectReviewNav(params.transectId);

    const transect = useSelector(state => selectTransect(state, params.transectId));
    const user = useSelector(state => state.userState.user);

    useBreadcrumbs('Transect Review', transect.projectId, transect.sampleEventId, transect.transectId);
    useContextToolsBySampleEventId(transect.sampleEventId);
    const finalized = useFinalized(transect.sampleEventId);

    const toolCode = TRANSECT_REVIEW_CODE_CANOPY;
    const toolProgress = transectToolProgress(transect, [toolCode])[0];

    const isConfirmed = () => {
        return transect.TransectReviews.find(review => review.code === toolCode)?.isConfirmed ?? false;
    };

    const renderTable = () => {
        if(!transect.TransectCanopyClosure) {
            return <Table borderless responsive className="streambank-table half-table">
                <tbody>
                    <tr>
                        <td>Transect Canopy Closure not started.</td>
                    </tr>
                </tbody>
            </Table>;
        }

        const {measurementLocation, canopyClosure, north, south, east, west, notes} = transect.TransectCanopyClosure;

        return <Table borderless responsive className="streambank-table half-table">
            <tbody>
                <tr>
                    <td>Measurement Location</td>
                    <td>{measurementLocation}</td>
                </tr>
                <tr>
                    <td>Canopy Closure</td>
                    <td>{canopyClosure}</td>
                </tr>
                <tr>
                    <td>Reading N</td>
                    <td>{north}</td>
                </tr>
                <tr>
                    <td>Reading E</td>
                    <td>{east}</td>
                </tr>
                <tr>
                    <td>Reading S</td>
                    <td>{south}</td>
                </tr>
                <tr>
                    <td>Reading W</td>
                    <td>{west}</td>
                </tr>
                <tr>
                    <td>Notes</td>
                    <td>{notes}</td>
                </tr>
            </tbody>
        </Table>
    };

    return (
        <PageContainer className="canopy-closure-review-page">
            <PageHeader>
                <Title
                    title="Transect Review"
                    subtitle="Canopy Closure"
                    action={<ReturnButton path={`/transect/${transect.transectId}/canopy`} isTransectReview  readOnly={finalized}  />}
                />
            </PageHeader>
            <PageContent>
                {renderTable()}
            </PageContent>
            <PageFooter>
                <FooterNav>
                    <div className="complete-checkbox">
                        <FormCheck
                            id="complete-checkbox"
                            label="Confirm"
                            type="checkbox"
                            value={isConfirmed()}
                            checked={isConfirmed()}
                            onChange={() => dispatch(updateTransectReview(transect, toolCode, !isConfirmed(), user.userId))}
                            disabled={finalized}
                        />
                    </div>
                </FooterNav>
            </PageFooter>
        </PageContainer>
    )
};

export default CanopyClosureReviewPage;
