import BaseService from './Core/BaseService';

class TwoFactorAuthService extends BaseService {
    constructor() {
        super();
    }

    get Url() { return super.Url + "/twoFactorAuth"; }

    SendVerificationCode(data) {
        return this.SendRequest(BaseService.POST, data, null, '/send');
    }

    VerifyCode(data) {
        return this.SendRequest(BaseService.POST, data, null, '/verify');
    }
}

let service = new TwoFactorAuthService();
export default service;
