import React from 'react';
import { Component } from 'react';
import {any, element} from "prop-types";

var propTypes = {
    Condition: any,
    Alternative: element
}

class Conditional extends Component {
    render() {
        const {
            props
        } = this;

        const {
            Condition,
            Alternative,
            children
        } = props;

        return Condition ? children: (Alternative || null);
    }
}

Conditional.propTypes = propTypes;

export default Conditional
