import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Spinner} from "react-bootstrap";

const CircledIcon = props => {
    return (
        <span className={`circled-icon fa-layers fa-fw ${props.className || ''} ${props.loading ? 'disabled' : ''}`} onClick={() => props.onClick ? props.onClick() : null}>
            <FontAwesomeIcon icon={['fas', 'circle']} transform={`grow-${props.circleSize}`} />
            {
                props.loading ?
                    <Spinner animation="border" size="sm" /> :
                    <FontAwesomeIcon icon={props.icon} transform={props.iconTransform} inverse />
            }
        </span>
    );
};

export default CircledIcon;
