import {useSelector} from "react-redux";
import {selectSampleEventById, selectUsers} from "../Redux/Selectors/nodeSelectors";

export const useSampleEventUsers = (sampleEventId) => {
    const event = useSelector(state => selectSampleEventById(state, sampleEventId));
    const projectUsers = useSelector(state => selectUsers(state, event.projectId));
    const sampleEventUsers = event.SampleEventStaff.map(staff => staff.userId);

    return projectUsers
        .filter(user => sampleEventUsers.includes(user.userId))
        .map(user => user.name)
        .join(', ');
};

export const useFinalized = (sampleEventId) => {
    const event = useSelector(state => selectSampleEventById(state, sampleEventId));

    return event?.isComplete ?? false;
};
