import CircledIcon from "../CompositeIcons/CircledIcon";
import {Col, Row} from "react-bootstrap";
import SurveySecondaryInput from "./Survey/SurveySecondaryInput";
import React, {useEffect, useState} from "react";
import {useFieldIsDisabled} from "../../Hooks/FormHooks";

export const RepeatableSectionItem = (props) => {

	const {primaryKey, answerId, sampleEventId, repeaterData, updateItem, fields, colSize, deleteItem, readOnly} = props;
	
	const [values, setValues] = useState(repeaterData);
	
	const fieldIsDisabled = useFieldIsDisabled();
	
	useEffect(() => {
		setValues(repeaterData);
	}, [repeaterData]);
	
	// this method writes changes to the redux store
	const commitChanges = (field, value) => {
		if(readOnly) { return null; }
		updateItem(repeaterData[primaryKey], field.code, value)
	}
	
	// this method stores changes to text fields
	// in the component state, so we don't have to do
	// a heavy redux store manipulation on each keypress
	const stageChanges = (field, value) => {
		if(readOnly) { return null; }
		
		setValues({
			...values,
			[field.code]: value
		});
		
		if(field.type !== 'number' && field.type !== 'textarea' && field.type !== 'text') {
			commitChanges(field, value);
		}
	}
	
	return <div className="repeatable-item" key={repeaterData[primaryKey]}>
		{
			!readOnly &&
			<CircledIcon
				icon={['fal', 'trash-alt']}
				circleSize="12"
				iconTransform="shrink-4"
				onClick={() => {
					return deleteItem(repeaterData[primaryKey]);
				}}
			/>
		}
		<Row xs={12}>
			{
				fields.map((field, index) => (
					<Col xs={field.colSize || colSize || 12} key={index}>
						<SurveySecondaryInput
							fieldDefinition={field}
							onClick={() => field.onClick ? field.onClick(repeaterData[primaryKey]) : null}
							onChange={value => stageChanges(field, value)}
							onBlur={() => commitChanges(field, values[field.code])}
							value={values[field.code] ?? ''}
							overlayIcon={field.overlayIcon}
							validationMessage={field.validationMessage}
							disabled={fieldIsDisabled(field, values)}
							required={!fieldIsDisabled(field, values) && field.required}

							// for species input
							answerId={answerId}
							repeaterId={repeaterData[primaryKey]}
							sampleEventId={sampleEventId}
							readOnly={readOnly}
						/>
					</Col>
				))
			}
		</Row>
	</div>
};