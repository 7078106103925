import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from 'react-router-dom'
import {Row, Col, Button, FormCheck} from 'react-bootstrap';
import {useContextTools, useContextToolsByProjectId} from '../../../Hooks/ToolHooks';
import {CONTEXT_TOOL_PHOTO, CONTEXT_TOOL_BOOK, CONTEXT_TOOL_GLOBE} from '../../../Constants/tools';

import PageContent from '../../Layout/Page/PageContent';
import Title from '../../Layout/TitleComponent';
import PageContainer from '../../Layout/Page/PageContainer';
import HydrozoneSpeciesList from "./HydrozoneSpeciesList";
import LengthCategoryUtils from "../../../Utils/LengthCategoryUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageFooter from "../../Layout/Page/PageFooter";
import SpeciesPickerModal from "../../Common/SpeciesPickerModal";
import NotesModal from "../../Common/NotesModal";
import {useQuantVegNav} from "../../../Hooks/QuickNavHooks";
import {useHydrozoneName, useHydrozoneSpeciesTypeName} from '../../../Hooks/LabelHooks';
import FooterNav from "../../Layout/Page/FooterNav";
import PageHeader from "../../Layout/Page/PageHeader";
import {selectTransect} from "../../../Redux/Selectors/transectSelectors";
import {selectSampleEventById, selectAllSpecies} from "../../../Redux/Selectors/nodeSelectors";
import {
    UPSERT_SAMPLE_EVENT,
    UPSERT_TRANSECT
} from "../../../Redux/Actions/Types/offlineDataActionTypes";
import KeyboardComponent from "./KeyboardComponent";
import {SET_CURRENT_DATA, SET_SELECTED_SPECIES} from "../../../Redux/Actions/Types/transectActionTypes";
import {OPEN_SPECIES_PICKER} from "../../../Redux/Actions/Types/transectActionTypes";
import DuplicateSpeciesModal from "./DuplicateSpeciesModal";
import {updateNoPlants} from "../../../Redux/Actions/transectActions";
import {useBreadcrumbs, useHydrozoneContextTabs} from "../../../Hooks/BreadcrumbHooks";
import {useReadOnly} from "../../../Hooks/FormHooks";
import {useFinalized} from "../../../Hooks/DataHooks";
import {LIST_NON_INVASIVE_TREES, LIST_NON_INVASIVE_WOODY} from "../../../Constants/species";
import {hasNoPlantsLabels, TYPE_TREE_DBH} from "../../../Constants/lengthCategories";

const HydrozoneSpeciesPage = (props) => {
    const params = useParams();
    const dispatch = useDispatch();
    const getHydrozoneName = useHydrozoneName();
    const getHydrozoneSpeciesTypeName = useHydrozoneSpeciesTypeName();

    useContextTools([CONTEXT_TOOL_PHOTO, CONTEXT_TOOL_GLOBE, CONTEXT_TOOL_BOOK]);
    useQuantVegNav(params.transectId);

    const transect = useSelector(state => selectTransect(state, params.transectId));
    const sampleEvent = useSelector(state => selectSampleEventById(state, transect.sampleEventId));
    const species = useSelector(state => selectAllSpecies(state, transect.projectId));

    useBreadcrumbs(`Plot ${params.plotTypeId}`, transect.projectId, transect.sampleEventId, transect.transectId);
    useHydrozoneContextTabs(params.transectId, params.plotTypeId, params.hydrozoneTypeId);
    useContextToolsByProjectId(transect.projectId);

    const finalized = useFinalized(transect.sampleEventId);
    useReadOnly(finalized);

    const [showNotes, setShowNotes] = useState(false);

    const hydrozonePlotsForThisPlotType = transect.HydrozonePlots.filter(plot => String(plot.plotTypeId) === String(params.plotTypeId)).sort((a, b) => a.hydrozoneTypeId > b.hydrozoneTypeId ? 1: -1);
    const hydrozonePlot = transect.HydrozonePlots.find(plot => String(plot.plotTypeId) === String(params.plotTypeId) && String(plot.hydrozoneTypeId) === String(params.hydrozoneTypeId));
    const hydrozone = transect.Hydrozones.find(hydrozone => String(hydrozone.hydrozoneTypeId) === String(params.hydrozoneTypeId));
    const hydrozonePlotSpecies = hydrozone.HydrozonePlotSpecies.filter(species => String(species.plotTypeId) === String(params.plotTypeId));
    const categories = LengthCategoryUtils.GetLengthCategoryByType(params.type);
    const hydrozoneName = getHydrozoneName(params.hydrozoneTypeId);
    const hydrozoneTypeClass = `type-${hydrozoneName.toLowerCase()}`;

    useEffect(() => {
        dispatch({
            type: SET_CURRENT_DATA,
            currentTransect: params.transectId,
            currentHydrozone: hydrozone.hydrozoneId,
            currentPlot: params.plotTypeId,
            currentSpeciesPage: params.type,
        });
    }, [params.transectId, hydrozone.hydrozoneId, params.plotTypeId, params.type]);

    const getNoPlantsValue = () => {
        const fieldName = LengthCategoryUtils.GetNoPlantsFieldName(params.type, params.plotTypeId)
        return hydrozone[fieldName] ?? hydrozoneName[LengthCategoryUtils.GetLegacyNoPlantsFieldName(params.plotTypeId)] ?? false;
    };

    const getHydrozoneOrder = () => {
        return hydrozonePlotsForThisPlotType.findIndex((item) => item.hydrozoneId === hydrozone.hydrozoneId) + 1;
    };

    return (
        <Fragment>
            <SpeciesPickerModal
                projectId={transect.projectId}
                listType={params.type === TYPE_TREE_DBH ? LIST_NON_INVASIVE_TREES : LIST_NON_INVASIVE_WOODY}
                type={params.type}
                hydrozoneSpecies={hydrozonePlotSpecies}
            />
            <DuplicateSpeciesModal />
            <NotesModal
                title="Notes"
                notes={[
                    {
                        label: 'Transect Notes',
                        value: transect.notes,
                        save: (value) => dispatch({
                            type: UPSERT_TRANSECT,
                            transect: {
                                ...transect,
                                notes: value,
                            }
                        })
                    },
                    {
                        label: 'Site Notes',
                        value: sampleEvent.siteNotes,
                        save: (value) => dispatch({
                            type: UPSERT_SAMPLE_EVENT,
                            sampleEvent: {
                                ...sampleEvent,
                                siteNotes: value,
                            }
                        })
                    }
                ]}
                show={showNotes}
                onHide={() => setShowNotes(false)}
                readOnly={finalized}
            />
            <PageContainer className="hydrozone-species-page">
                <PageHeader>
                    <KeyboardComponent type={params.type} readOnly={finalized} />
                    <Title
                        title={
                            <Fragment>
                                <span className="font-weight-bold">Plot {params.plotTypeId}: </span>
                                <span className="font-weight-normal">{getHydrozoneSpeciesTypeName(params.type)}</span>
                            </Fragment>
                        }
                        subtitle={
                            <Fragment>
                                <span className={`subtitle-pill ${hydrozoneTypeClass}`}>
                                    {hydrozoneName}: {hydrozonePlot.start} Ft - {hydrozonePlot.end} Ft
                                </span>
                                <span className="subtitle-desc">({getHydrozoneOrder()} of {hydrozonePlotsForThisPlotType.length})</span>
                            </Fragment>
                        }
                        action={
                            <div className="no-plants-field">
                                <FormCheck
                                    id="no-plants-checkbox"
                                    label={"No " + hasNoPlantsLabels[params.type] + " to measure in this hydrozone"}
                                    type="checkbox"
                                    value={getNoPlantsValue()}
                                    checked={getNoPlantsValue()}
                                    onChange={() => !finalized && dispatch(updateNoPlants(!getNoPlantsValue()))}
                                />
                            </div>
                        }
                        readOnly={finalized}
                    />
                </PageHeader>
                <PageContent>
                    <Row xs={12}>
                        <Col xs={12}>
                            <HydrozoneSpeciesList
                                species={species}
                                hydrozoneSpecies={!getNoPlantsValue() ? hydrozonePlotSpecies : []}
                                categories={categories}
                                type={params.type}
                                plotLength={hydrozonePlot.length}
                                disabled={finalized || getNoPlantsValue()}
                            />
                            {
                                !finalized &&
                                    <Button
                                        className="w-100"
                                        variant="secondary"
                                        onClick={() => {
                                            dispatch({type: SET_SELECTED_SPECIES, speciesId: null});
                                            dispatch({type: OPEN_SPECIES_PICKER});
                                        }}
                                        disabled={getNoPlantsValue()}
                                    >
                                        <FontAwesomeIcon icon={["fal", "plus"]} />
                                        Add Species
                                    </Button>
                            }
                        </Col>
                    </Row>
                </PageContent>
                <PageFooter>
                    <FooterNav>
                        <Button variant="primary" onClick={() => setShowNotes(true)}>
                            <FontAwesomeIcon icon={['fal', 'sticky-note']} />
                            View Notes
                        </Button>
                    </FooterNav>
                </PageFooter>
            </PageContainer>
        </Fragment>
    );
};

export default HydrozoneSpeciesPage;
