import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {Table} from "react-bootstrap";

import {
    selectQualitativeEventBySampleEventId, selectAllSpecies,
    selectSurveysBySampleEventId
} from "../../../Redux/Selectors/nodeSelectors";
import {qualTools} from "../../../Constants/tools";
import ReturnButton from "../../Common/ReturnButton";
import {useGetUserFacingSpeciesNameById} from "../../../Hooks/LabelHooks";
import {
    competitionLifeStageOptions,
    insectsImpactCausingOptions,
    insectsImpactTypeOptions
} from "../../../Constants/survey";
import LegacyDataInfoIcon from "../../Common/LegacyDataInfoIcon";
import {ReviewedPhotoThumbnail} from "./ReviewedPhotoThumbnail";
import {selectSurveyPhotos} from "../../../Redux/Selectors/photoSelectors";

const QualitativeVegMonitoringReviewTable = (props) => {
    const {event} = props;

    const surveys = useSelector(state => selectSurveysBySampleEventId(state, event.sampleEventId));
    const answers = useSelector(state => selectQualitativeEventBySampleEventId(state, event.sampleEventId));
    const speciesList = useSelector(state => selectAllSpecies(state, event.projectId));
    const surveyPhotos = useSelector(state => selectSurveyPhotos(state, event.sampleEventId));

    const getUserFacingSpeciesNameById = useGetUserFacingSpeciesNameById();

    const hasMonitoringRecommendations = (question) => {
        if (question.surveyQuestionDataTypeId === 13) {
            const values = question?.options?.values;

            if (!values) {
                return false;
            }

            return values.some(value => value?.secondaryFields && value?.secondaryFields.some(field => field.type === 'monitoring-recommendations'));
        }

        return false;
    };

    const fieldTypeFromSurveyQuestionTypeId = {
        1: 'text',
        13: 'radio',
        14: 'select',
        15: 'many-fields',
        16: 'monitoring-recommendations',
        17: 'monitoring-repeater',
    };

    // * Note: that this method mirrors the displayValueForQualitativeVegetationQuestion function
    // SurveyService.php so if you fix bugs or make changes in one you should consider parallel changes in the other.
    const displayAnswer = (fieldDefinition) => {

        let result = [];
        let secondaryFields = [];

        const answer = answers ? answers[fieldDefinition.code] : null;
        const fieldType = fieldDefinition.type ?? fieldTypeFromSurveyQuestionTypeId[fieldDefinition.surveyQuestionDataTypeId];

        const defaultValues = [{label: 'Yes', value: '1'}, {label: 'No', value: '0'}];
        const optionValues = fieldDefinition?.options?.values ?? fieldDefinition?.values ?? defaultValues;

        switch (fieldType) {
            case 'radio':
            case 'select':
                const matchedOptionValue = optionValues.find(({value: optionValue}) => {
                    return String(optionValue?.value ?? optionValue) === String(answer);
                });
                // Don't show label if value is Yes on Monitoring Recommendations
                if(!(matchedOptionValue?.label === 'Yes' && hasMonitoringRecommendations(fieldDefinition))) {
                    result.push(matchedOptionValue?.label ?? matchedOptionValue?.value ??
                        (answer != null ? <span key={answer?.surveyAnswerId} className="legacy-data-message">{String(answer)}<LegacyDataInfoIcon/></span> : null));
                }
                secondaryFields = matchedOptionValue?.secondaryFields ?? [];
                break;
            case 'many-fields':
                const checkedValues = optionValues.filter(option => !!(answers?.[option.code]));
                result = result.concat(checkedValues.map(option => option.label ?? option?.value ?? 'N/A').join(', '));
                secondaryFields = checkedValues.reduce((acc, checkedValue) => acc.concat(checkedValue?.secondaryFields ?? []), []);
                break;
            case 'monitoring-recommendations':
            case 'monitoring-repeater':
                const columns = getColumnsByCode(fieldDefinition.code, fieldDefinition.type);
                result.push(renderSubTable(columns, fieldDefinition.code, fieldDefinition.type));
                break;
            case 'text':
            default:
                result.push(answer);
                break;
        }

        // get display values for secondary fields
        let secondaryFieldResults = secondaryFields.map( (field) => {
            // the PHP version also uses implode here, but
            // .join(' ') breaks objects by turning them to [Object object]
            // and is unnecessary since React can render arrays directly
            return displayAnswer(field) 
        });

        // remove nulls
        secondaryFieldResults = secondaryFieldResults.filter(x => x !== null);
        
        if(result.length && secondaryFieldResults.length) {
            result.push(' : ');
        }

        return result.map((answer, i) => {
            if ((fieldDefinition?.isRequired ?? false) && (answer == null || answer === '')) {
                return <span key={i} className="no-data-message">No Data</span>;
            }

            return answer;
        }).concat(secondaryFieldResults);
    };

    const getColumnsByCode = (code, type = null) => {
        if (type === 'monitoring-recommendations') {
            return [{title: 'Recommendations', code: 'recommendationNotes'}]
        }

        switch (code) {
            case 'flood':
            case 'fire':
            case 'drift':
                return [
                    {title: 'Photo', code: 'photoId', isRequired: false},
                    {title: 'Latitude ° N', code: 'latitude', isRequired: true},
                    {title: 'Longitude ° E', code: 'longitude', isRequired: true},
                    {title: 'Area impacted', code: `${code}Impacted`, isRequired: true},
                ];
            case 'insects':
                return [
                    {title: 'Photo', code: 'photoId', isRequired: false},
                    {title: 'Impact type', code: 'insectsImpactType', options: insectsImpactTypeOptions, isRequired: true},
                    {title: 'Cause Identified', code: 'insectsImpactCausing', options: insectsImpactCausingOptions, isRequired: true},
                    // {title: 'Species', code: 'insectsSpeciesId', isRequired: true},
                    {title: 'Description', code: 'insectsOrDiseasesDescription', isRequired: true},
                ];
            case 'insectsii':
                return [
                    {title: 'Photo', code: 'photoId', isRequired: false},
                    {title: 'Impact type', code: 'insectsImpactType', options: insectsImpactTypeOptions, isRequired: true},
                    {title: 'Species Identified', code: 'insectsImpactCausing', options: insectsImpactCausingOptions, isRequired: true},
                    {title: 'Species', code: 'insectsSpeciesId', isRequired: true},
                    {title: 'Description', code: 'insectsOrDiseasesDescription', isRequired: true},
                ];
            case 'browse':
                return [
                    {title: 'Photo', code: 'photoId', isRequired: false},
                    {title: 'Species', code: 'browseSpeciesId', isRequired: true},
                    {title: '% of plants impacted', code: 'browseImpactedSpecies', isRequired: true},
                    {title: 'Notes', code: 'browseAnimalNote'},
                ];
            case 'soils':
                return [
                    {title: 'Latitude ° N', code: 'latitude', isRequired: true},
                    {title: 'Longitude ° E', code: 'longitude', isRequired: true},
                    {title: 'Possible Reasons', code: 'soilsReasonsNote', isRequired: false},
                ];
            case 'health':
                return [
                    {title: 'Species', code: 'healthSpeciesId', isRequired: true},
                    {title: 'Suspected Cause', code: 'healthSpeciesCause', isRequired: false},
                ];
            case 'performance':
                return [
                    {title: 'Species', code: 'performanceSpeciesId', isRequired: true},
                ];
            case 'competition':
                return [
                    {title: 'Species', code: 'competitionSpeciesId', isRequired: true},
                    {title: 'Estimated % Cover', code: 'competitionEstimatedCover', isRequired: true},
                    {title: 'Life Stage', code: 'competitionLifeStage', options: competitionLifeStageOptions, isRequired: true},
                ];
            default:
                return [];
        }
    };

    const formatSubTableValue = (repeater, column) => {
        let code = column.code;
        let value = repeater[code];

        if(code.endsWith('SpeciesId')) {
            if (value) {
                value = getUserFacingSpeciesNameById(speciesList, value);
            } else {
                // Remove "Id" from code
                code = code.slice(0, -2);
                value = repeater[code];
            }
        }
        
        if(code === 'photoId') {
            let photos = surveyPhotos
                .filter(photo => (String(photo.qualitativeVegetationMonitoringRepeaterId) === String(repeater.qualitativeVegetationMonitoringRepeaterId)));
            value = photos.length ? photos.map(photo => <ReviewedPhotoThumbnail photoId={photo.photoId} /> ) : <ReviewedPhotoThumbnail photoId={null} />;
        }

        if (column.options) {
            const optionValue = column.options.find(option => (option.id ?? option.value) === repeater[code])
            value = optionValue?.name ?? optionValue?.label ?? value;
        }

        if ((column?.isRequired ?? false) && (value == null || value === '')) {
            value = <span className="no-data-message">No Data</span>;
        }

        return <td key={code}>{value}</td>;
    };

    const renderSubTable = (columns, code, type) => {
        
        const rows = (type === 'monitoring-recommendations') ?
            answers?.QualitativeVegetationMonitoringRecommendations?.filter(recommendation => recommendation.code === code) :
            answers?.QualitativeVegetationMonitoringRepeaters?.filter(repeater => repeater.code === code);
    
        return <div className="sub-table" key={code}>
            <Table borderless responsive>
                <thead>
                    { type !== 'monitoring-recommendations' ?
                        <tr>
                            {
                                columns.map(column => (
                                    <th key={column.code}>{column.title}</th>
                                ))
                            }
                        </tr> : null
                    }
                </thead>
                <tbody>
                    {
                        !rows?.length &&
                        <tr key='no-data-row' style={{"textAlign": "center"}}>
                            <td colSpan={20} key='no-data-col'>
                                <span className="no-data-message">No Data</span>
                            </td>
                        </tr>
                    }   
                    {
                        (type === 'monitoring-recommendations') ?
                            rows?.map(recommendation => (
                                    <tr key={recommendation.qualitativeVegetationMonitoringRecommendationId}>
                                        {
                                            columns.map(column => (
                                                <td key={column.code}>{recommendation[column.code]}</td>
                                            ))
                                        }
                                    </tr>
                                )) :
                            rows?.map(repeater => (
                                    <tr key={repeater.qualitativeVegetationMonitoringRepeaterId}>
                                        {
                                            columns.map(column => formatSubTableValue(repeater, column))
                                        }
                                    </tr>
                                ))

                    }
                </tbody>
            </Table>
        </div>
    };

    return (
        <div className="mt-3">
            {
                surveys.map(survey => {
                    const tool = qualTools.find(tool => tool.id === survey.surveyTypeId);

                    return (
                        <Fragment key={survey.surveyId}>
                            {
                                survey.SurveySections
                                    .filter(section => !!section.SurveyQuestions.length)
                                    .map(section => (
                                        <Fragment key={section.surveySectionId}>
                                            <div className="review-title">
                                                <span>{tool.title} - {section.name}</span>
                                                <ReturnButton path={`/event/${event.sampleEventId}/qual-veg/${section.surveyId}/${section.code}`} readOnly={event.isComplete}/>
                                            </div>
                                            <Table borderless responsive className="streambank-table half-table">
                                                <tbody>
                                                    {section.SurveyQuestions.filter(question => !hasMonitoringRecommendations(question))
                                                        .map((question, index) => (
                                                            <tr key={question.surveyQuestionId}>
                                                                <td key={1} className={question.isRequired ? 'required' : ''}>
                                                                    {index + 1}. {question.name}
                                                                </td>
                                                                <td key={2}>
                                                                    {displayAnswer(question)}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </Table>
                                        </Fragment>
                                    ))
                            }
                        </Fragment>
                    )
                })
            }
            <Fragment>
                <div className="review-title">
                    <span>Management Recommendations</span>
                </div>
                <Table borderless responsive className="streambank-table half-table">
                    <tbody>
                        {
                            surveys.map(survey => (
                                survey.SurveySections
                                    .filter(section => !!section.SurveyQuestions.length)
                                    .map(section => (
                                        section.SurveyQuestions.filter(question => hasMonitoringRecommendations(question))
                                            .map(question => {
                                                // Monitoring Recommendations aren't required
                                                question.isRequired = false;

                                                return (<tr key={question.surveyQuestionId}>
                                                    <td key={1}>{section.name}</td>
                                                    <td key={2}>{displayAnswer(question)}</td>
                                                </tr>);
                                            })
                                    ))
                            ))
                        }
                    </tbody>
                </Table>
            </Fragment>
        </div>
    );
};

export default QualitativeVegMonitoringReviewTable;
