import React, { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Tile from './Tile';

const TileList = props => {
    const colSize = props.colSize || 4;
    const {xs, sm, md, lg, className} = props;

    const renderTile = (item) => {
        const url = props.tileUrl ? props.tileUrl(item) : null;
        const actions = props.actions ? props.actions(item) : null;

        if (url) {
            return (
                <Link to={url}>
                    <Tile
                        item={item}
                        actions={actions}
                        ellipsis={props.ellipsis}
                        noIcon={props.noIcon}
                        showComplete={props.showComplete}
                    />
                </Link>
            );
        }

        return <Tile
            item={item}
            subtitle={props.subtitle}
            caption={props.caption}
            actions={actions}
            ellipsis={props.ellipsis}
            onClick={() => props.onClick ? props.onClick(item) : null}
            noIcon={props.noIcon}
            showComplete={props.showComplete}
        />;
    };

    return (
        <Row xs={12} className={"tile-list" + (className ? ` ${className}` : '')}>
            {
                props.data.map(item => (
                    <Fragment key={item[props.primaryKey] || item.id}>
                        <Col className={props.tileDetailComponent ? 'pr-0 tile-list-column' : "tile-list-column"} xs={xs ?? colSize} sm={sm ?? xs ?? colSize} md={md ?? sm ?? colSize} lg={lg ?? md ?? colSize}>
                            {renderTile(item)}
                        </Col>
                        {
                            props.tileDetailComponent &&
                            <Col xs={12 - colSize} className="tile-details">
                                <props.tileDetailComponent item={item} />
                            </Col>
                        }
                    </Fragment>
                ))
            }
        </Row>
    )
};

export default TileList;
