import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch} from "react-redux";
import {CLOSE_ACCOUNT_MENU} from "../../../Redux/Actions/Types/appStatusTypes";

const MenuLink = props => {
    const dispatch = useDispatch();

    const onClick = () => {
        if (props.onClick) {
            props.onClick();
        }

        dispatch({type: CLOSE_ACCOUNT_MENU});
    };

    const component = () => {
        return (
            <div className="menu-link" onClick={() => onClick()}>
                <span>{props.label}</span>
                <span>
                    {props.badge || null}
                    <FontAwesomeIcon icon={["fal", "angle-right"]} />
                </span>
            </div>
        );
    };

    if (props.to) {
        return (
            <Link to={props.to}>
                {component()}
            </Link>
        )
    }

    if (props.external) {
        return (
            <a href={props.external} target="_blank">
                {component()}
            </a>
        )
    }

    return component();
};

export default MenuLink;
