import React from 'react';
import { ModalBody, ModalFooter, Button, Row, Col } from 'react-bootstrap';

import Modal from './Modal';
import {CLOSE_UPDATE_MODAL, OPEN_BACKUP} from "../../Redux/Actions/Types/appStatusTypes";
import {useDispatch} from "react-redux";
import {useForceReload, useSkipWaiting} from "../../Hooks/ServiceWorkerHooks";

const UpdateModal = props => {
    const dispatch = useDispatch();
    const skipWaiting = useSkipWaiting();
    const forceReload = useForceReload();

    const reload = () => {
        dispatch({type: CLOSE_UPDATE_MODAL});
        skipWaiting().then(forceReload);
    };

    return (
        <Modal
            title="Update Available"
            size="md"
            show={props.show}
            hideCloseButton
            static
        >
            <ModalBody>
                <Row xs={12}>
                    <Col xs={12}>
                        <p>A new version of StreamBank Monitoring is available. Click the button below to update.</p>
                        <p>If you have unsynced On-Device changes, we recommend <span className="btn-link" onClick={() => dispatch({type: OPEN_BACKUP})}>backing them up</span> before updating.</p>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button variant="complete" className="w-25" onClick={() => reload()}>
                    Update Now
                </Button>
                <Button variant="link" className="w-25" onClick={() => dispatch({type: CLOSE_UPDATE_MODAL})}>
                    Update Later
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default UpdateModal;
