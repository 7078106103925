import { createSelector } from 'reselect';
import { contextTools } from '../../Constants/tools';

const contextToolsSelector = state => state.toolState.contextTools;

export const fullContextToolsSelector = createSelector(
    [contextToolsSelector],
    tools => tools.map(tool => (
        contextTools.find(contextTool => tool === contextTool.code)
    ))
);