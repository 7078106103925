import {TEMP_ID_PREFIX} from "../Constants/misc";
import ApiUtils from "./ApiUtils";
import storage from "localforage";
import FileUtils from "./FileUtils";

export default class PhotoUtils {

	static getPhotoPath(photoId, thumbnail = false, photoQuality = '') {
		const params = photoQuality !== '' ? `?size=${photoQuality}` : '';
		return `photos/${photoId}/${thumbnail ? 'small' : 'download'}${params}`;
	}

	static getPhotoApiPath(photoId, thumbnail = false, photoQuality = '') {
		const path = this.getPhotoPath(photoId, thumbnail, photoQuality);
		return `/api/${path}`;
	}

	static getPhotoApiUrl(photoId, thumbnail = false, photoQuality = '') {
		return ApiUtils.GetApiUrl(this.getPhotoPath(photoId, thumbnail, photoQuality));
	}

	static async getPhotoContent(photoId) {
		const photoData = await storage.getItem(`/api/photos/${photoId}/download`);
		return FileUtils.EnsureBlob(photoData, 'image/jpeg');
	}

	static photoIsNew(photo) {
		return String(photo.photoId).startsWith(TEMP_ID_PREFIX);
	}
	
	static photoHasChanges(photo) {
		return !!photo.offlineCreatedDate || !!photo.offlineModifiedDate;
	};
	
	static photoIsMissingThumbnail(photo) {
		return !photo.fileSizeLarge ||         // any of these missing indicates we
			!photo.fileSizeMedium ||        // have not finished uploading the
			!photo.fileSizeSmall;
	}
	
	static photoShouldBeUploaded(photo) {
		return this.photoHasChanges(photo) ||
			this.photoIsMissingThumbnail(photo);
	};
	
}
