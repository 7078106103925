import React, {Fragment, useState} from 'react';
import {useSelector} from "react-redux";
import { useHistory } from 'react-router-dom';
import { ModalBody, ModalFooter, Button, Row, Col } from 'react-bootstrap';

import Modal from '../../Common/Modal';
import {useHasChanges} from "../../../Hooks/AuthHooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DiscardDeviceDataModal = props => {
    const history = useHistory();
    const hasChanges = useHasChanges();

    const isOnline = useSelector(state => state.appStatusState.online);
    const offlineMode = useSelector(state => state.appStatusState.offlineMode);
    const projects = useSelector(state => state.offlineDataState.projects);

    // this gets set to true if the user attempts to perform the 'action'
    // while they are offline. They are then shown the 'offlineMessage'
    // as a double confirmation. If they click the 'action' again they
    // may proceed.
    const [showOfflineDoubleConfirmation, setShowOfflineDoubleConfirmation] = useState(false);

    const goToOfflineData = () => {
        props.onHide();
        history.push('/offline');
    };

    // before performing the 'discard data and logout' action
    // we check to confirm the user is not offline. If they are
    // offline then show them an extra warning.
    const checkOffline = () => {
        if (!isOnline || offlineMode) {
            setShowOfflineDoubleConfirmation(true);
        } else {
            action();
        }
    };

    const cancel = () => {
        setShowOfflineDoubleConfirmation(false);
        if (props.logout) {
            history.goBack();
        }
        props.onHide();
    };

    const action = () => {
        props.action();
        props.onHide();
    };

    const renderTitle = () => {
        if (showOfflineDoubleConfirmation) {
            return 'No Internet Connection';
        }

        if (hasChanges) {
            return 'Un-synced Changes';
        }

        return 'Discard On-Device Data?';
    };

    const renderContent = () => {
        if (showOfflineDoubleConfirmation) {
            return <span>{props.offlineMessage}</span>;
        }

        if (hasChanges) {
            return <span>{props.changesMessage}</span>;
        }

        // We assume this dialog is only shown now
        // if the user has projects
        return <span>{props.dataMessage}</span>;
    };

    const renderButtons = () => {
        if (showOfflineDoubleConfirmation) {
            return <Fragment>
                {
                    props.logout &&
                        <Button variant="danger" onClick={() => action()}>
                            Log Out
                        </Button>
                }
                <Button variant="secondary" className="ml-3" onClick={() => cancel()}>
                    Cancel
                </Button>
            </Fragment>;
        }

        if (hasChanges) {
            return <Button variant="complete" onClick={() => props.logout ? goToOfflineData() : props.onHide()}>
                OK
            </Button>;
        }

        return <Fragment>
            <Button variant="danger" onClick={() => props.logout ? checkOffline() : props.action()}>
                <FontAwesomeIcon icon={['fal', 'trash-alt']} />
                Discard Data{props.logout ? ' and Log Out' : ''}
            </Button>
            <Button variant="secondary" className="ml-3" onClick={() => cancel()}>
                Cancel
            </Button>
        </Fragment>;
    };

    return (
        <Modal
            backdrop={props.backdrop}
            title={renderTitle()}
            size="md"
            show={props.show}
            onHide={() => props.onHide()}
        >
            <ModalBody>
                <Row xs={12}>
                    <Col xs={12}>
                        {renderContent()}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {renderButtons()}
            </ModalFooter>
        </Modal>
    )
};

export default DiscardDeviceDataModal;
