import {format, isAfter, isBefore, isEqual, compareDesc, parseISO, isValid, compareAsc} from 'date-fns';

export default class DateUtils {

    // UTC DateTime String -> Date object
    static ParseUTCDateTime(dateString) {
        if (dateString.slice(-1).toUpperCase() === 'Z') {
            return parseISO(dateString);
        }

        return parseISO(dateString + 'Z');
    }

    // Local DateTime String -> UTC DateTime String
    static ToUTC(dateString) {
        if (!dateString) {
            return null;
        }

        const date = this.ReplaceDashes(dateString);
        const isoDate = date.toISOString();

        // removes the 'T' part and TimeZone if it is there
        return `${isoDate.substr(0, 10)} ${isoDate.substr(11, 8)}`;
    }

    static GetCurrentDate(pattern = 'yyyy-MM-dd') {
        return format(new Date, pattern);
    }

    // Get current UTC DateTime String
    static GetCurrentDateTime(pattern = 'yyyy-MM-dd HH:mm:ss') {
        const currentDateTime = format(new Date, 'yyyy-MM-dd HH:mm:ss');
        return this.FormatToUTCDateTime(currentDateTime, pattern);
    }

    // Local Date String -> Local Date String
    // this method is useful for ensuring the date
    // is formatted in a specific format without
    // changing the timezone
    static FormatDate(date, pattern = 'yyyy/MM/dd') {
        if (!date) {
            return '';
        }

        const result = this.ReplaceDashes(date);
        
        return !isNaN(+result) ? format(result, pattern) : null;
    }

    // UTC DateTime String -> (Local) Date String
    static FormatToLocalDate(date, pattern = 'yyyy/MM/dd') {
        if (!date) {
            return '';
        }

        const localDate = this.ParseUTCDateTime(date.replace(/\//g, '-'));

        return format(localDate, pattern);
    }

    // UTC DateTime String -> Local DateTime String
    static FormatToLocalDateTime(datetime, pattern = "yyyy-MM-dd'T'HH:mm") {
        if (!datetime) {
            return '';
        }

        const localDateTime = this.ParseUTCDateTime(datetime.replace(/\//g, '-'));

        return format(localDateTime, pattern);
    }

    // Local DateTime String -> UTC Date String
    // There should be no reason to use this
    static FormatToUTCDate(dateString, pattern = 'yyyy-MM-dd') {
        if (!dateString) {
            return '';
        }

        const utcDate = this.ToUTC(dateString);

        return format(this.ReplaceDashes(utcDate), pattern);
    }

    // Local DateTime String -> UTC DateTime String
    static FormatToUTCDateTime(dateTime, pattern = 'yyyy-MM-dd HH:mm:ss') {
        if (!dateTime) {
            return '';
        }

        const utcDateTime = this.ToUTC(dateTime);

        return format(this.ReplaceDashes(utcDateTime), pattern);
    }

    // Local Date String (with dashes or slashes) -> Date Object
    // (this method is necessary since Safari doesn't accept `yyyy-MM-dd [HH:mm:ss]`
    // formatted date strings in the Date constructor)
    // But Safari also doesn't accept `yyyy/MM/dd'T'[HH:mm:dd]` so we need to switch
    // between either slashes or dashes depending on if it the DateTime has a T in it
    static ReplaceDashes(date, pattern = null) {
        if(String(date).indexOf('/') !== -1) {
            date = String(date).replace(/\//g, '-');
        }
        return parseISO(date);
    }

    static IsAfter(firstDate, secondDate) {
        if((firstDate == null) || (secondDate == null)) { return false; }
        return isAfter(this.ReplaceDashes(firstDate), this.ReplaceDashes(secondDate));
    }

    static IsBefore(firstDate, secondDate) {
        if((firstDate == null) || (secondDate == null)) { return false; }
        return isBefore(this.ReplaceDashes(firstDate), this.ReplaceDashes(secondDate));
    }

    static IsEqual(firstDate, secondDate) {
        if((firstDate == null) || (secondDate == null)) { return false; }
        return isEqual(this.ReplaceDashes(firstDate), this.ReplaceDashes(secondDate));
    }

    static CompareAsc(firstDate, secondDate) {
        if((firstDate == null) || (secondDate == null)) { return 0; }
        return compareAsc(this.ReplaceDashes(firstDate), this.ReplaceDashes(secondDate));
    }

    static CompareDesc(firstDate, secondDate) {
        if((firstDate == null) || (secondDate == null)) { return 0; }
        return compareDesc(this.ReplaceDashes(firstDate), this.ReplaceDashes(secondDate));
    }
    
    static GreatestDate(firstDate, secondDate) {
        if(firstDate == null) { return secondDate; }
        if(secondDate == null) { return firstDate; }
        if(compareDesc(this.ReplaceDashes(firstDate), this.ReplaceDashes(secondDate))) {
            return secondDate;
        } else {
            return firstDate;
        }
    }
}
