import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TakePhotoIcon = props => {
    return (
        <span className="fa-layers fa-fw take-photo-icon">
            <FontAwesomeIcon icon={['fal', 'circle']} />
            <FontAwesomeIcon icon={['fas', 'circle']} transform="shrink-5" inverse />
        </span>
    );
};

export default TakePhotoIcon;
