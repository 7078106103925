import React, {Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, ModalBody, ModalFooter, Row} from "react-bootstrap";
import Modal from "../../Common/Modal";
import {ACCEPT_TERMS, CLOSE_TERMS} from "../../../Redux/Actions/Types/appStatusTypes";
import StreamBankAuthService from "../../../Services/StreamBankAuthService";

const TermsOfServiceModal = props => {
    const dispatch = useDispatch();

    const termsAccepted = useSelector(state => state.appStatusState.termsAccepted);

    const iframe = () => {
        return {
            __html: '<iframe src="/templates/terms.html"></iframe>'
        }
    };

    return (
        <Modal
            title="Terms Of Service"
            size="lg"
            show={props.show}
            onHide={() => dispatch({type: CLOSE_TERMS})}
            hideCloseButton
            static
        >
            <ModalBody>
                <div dangerouslySetInnerHTML={iframe()} />
            </ModalBody>
            <ModalFooter>
                {
                    termsAccepted ?
                        <Button variant="danger" className="ml-3" onClick={() => {
                            dispatch({type: CLOSE_TERMS});
                        }}>
                            Close
                        </Button> :
                        <Fragment>
                            <Button variant="complete" className="ml-3" onClick={() => {
                                dispatch({type: ACCEPT_TERMS});
                                dispatch({type: CLOSE_TERMS});
                            }}>
                                Agree
                            </Button>
                            <Button variant="danger" className="ml-3" onClick={() => {
                                StreamBankAuthService.Logout();
                                dispatch({type: CLOSE_TERMS});
                            }}>
                                Disagree
                            </Button>
                        </Fragment>
                }
            </ModalFooter>
        </Modal>
    )
};

export default TermsOfServiceModal;
