import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Link, useParams } from 'react-router-dom'
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {selectSampleEventById} from "../../../Redux/Selectors/nodeSelectors";
import {useContextTools, useContextToolsBySampleEventId} from '../../../Hooks/ToolHooks';
import {useTransectsWithProgress} from "../../../Hooks/ProgressHooks";
import {CONTEXT_TOOL_PHOTO, CONTEXT_TOOL_BOOK, CONTEXT_TOOL_GLOBE} from '../../../Constants/tools';

import {selectTransectsWithPlots} from "../../../Redux/Selectors/transectSelectors";

import PageContent from '../../Layout/Page/PageContent';
import Title from '../../Layout/TitleComponent';
import TileList from '../../Common/TileList';
import PageContainer from '../../Layout/Page/PageContainer';

import TransectRow from "./TransectRow";
import CircledIcon from "../../CompositeIcons/CircledIcon";
import PageHeader from "../../Layout/Page/PageHeader";
import TransectNotesModal from "./TransectNotesModal";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import {useReadOnly} from "../../../Hooks/FormHooks";
import {useFinalized} from "../../../Hooks/DataHooks";
import SafetyNoteIcon from "../../CompositeIcons/SafetyNoteIcon";

const TransectsPage = (props) => {
    useContextTools([CONTEXT_TOOL_PHOTO, CONTEXT_TOOL_GLOBE, CONTEXT_TOOL_BOOK]);

    const params = useParams();
    const transectsWithProgress = useTransectsWithProgress();

    const transects = useSelector(state => selectTransectsWithPlots(state)) ?? [];
    const event = useSelector(state => selectSampleEventById(state, params.eventId));

    useBreadcrumbs(null, event.projectId, event.sampleEventId);
    useContextToolsBySampleEventId(event.sampleEventId);

    const finalized = useFinalized(event.sampleEventId);
    useReadOnly(finalized);

    const [data, setData] = useState([]);
    const [deactivatedData, setDeactivatedData] = useState([]);
    const [showNotes, setShowNotes] = useState(false);
    const [currentTransect, setCurrentTransect] = useState(false);

    useEffect(() => {
        const eventTransects = transects.filter(transect => transect.sampleEventId === event.sampleEventId)
            .map(transect => ({
                ...transect,
                title: `Transect ${transect.name}`,
            }))
            .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }));

        const deactivatedTransects = eventTransects.filter(transect => !transect.isActive).map(transect => ({
            ...transect,
            subtitle: <span className="danger">- Deactivated -</span>,
            caption: <span className="font-italic">Data will not be collected</span>
        }));

        setData(transectsWithProgress(eventTransects.filter(transect => transect.isActive)));
        setDeactivatedData(deactivatedTransects);
    }, [params.eventId, transects]);

    return (
        <PageContainer className="transects-page">
            <TransectNotesModal
                show={showNotes}
                transect={currentTransect}
                onHide={() => setShowNotes(false)}
                readOnly={finalized}
            />
            <PageHeader>
                <Title
                    title="Transect List"
                    subtitle={`${event?.sampleEventPhaseName}`}
                    action={
                        <Link to={`/event/${event?.sampleEventId}/review`}>
                            <Button variant={event.isComplete ? "complete" : "primary"}>
                                {
                                    event.isComplete ?
                                        <FontAwesomeIcon icon={['fas', 'check-circle']} /> :
                                        <FontAwesomeIcon icon={['fal', 'check-circle']} />
                                }
                                Review & Finalize
                            </Button>
                        </Link>
                    }
                    readOnly={finalized}
                />
                {
                    !finalized &&
                        <Link to={`/event/${event?.sampleEventId}/transect/create`}>
                            <Button className="w-100" variant="secondary">
                                <FontAwesomeIcon icon={["fal", "plus"]} />
                                Add Transect
                            </Button>
                        </Link>
                }
            </PageHeader>
            <PageContent>
                <Row xs={12}>
                    <Col xs={12}>
                        <TileList
                            className="tile-list-transects"
                            colSize={3}
                            data={data}
                            tileDetailComponent={TransectRow}
                            primaryKey="transectId"
                            actions={(item) => (
                                <Fragment>
                                    <Link to={`/transect/${item.transectId}/quant-veg`}>
                                        <CircledIcon
                                            icon={['fal', 'tree-alt']}
                                            circleSize="12"
                                            iconTransform="shrink-2"
                                        />
                                    </Link>
                                    <Link to={`/transect/${item.transectId}`}>
                                        <CircledIcon
                                            icon={['fal', 'pen']}
                                            circleSize="12"
                                            iconTransform="shrink-4"
                                        />
                                    </Link>
                                    {
                                        item.safetyNotes ?
                                            <SafetyNoteIcon
                                                icon={['fal', 'sticky-note']}
                                                circleSize="12"
                                                onClick={() => {
                                                    setCurrentTransect(item);
                                                    setShowNotes(true);
                                                }}
                                            /> :
                                            <CircledIcon
                                                icon={['fal', 'sticky-note']}
                                                circleSize="12"
                                                iconTransform="shrink-3"
                                                className={item.safetyNotes ? 'danger' : ''}
                                                onClick={() => {
                                                    setCurrentTransect(item);
                                                    setShowNotes(true);
                                                }}
                                            />
                                    }
                                </Fragment>
                            )}
                            showComplete
                            noIcon
                        />
                    </Col>
                    {
                        ((deactivatedData ?? []).length > 0) && 
                        <Fragment>
                            <Col xs={12}>
                                <h2>Deactivated</h2>
                            </Col>
                            <Col xs={12}>
                                <TileList
                                    className="tile-list-transects"
                                    colSize={3}
                                    data={deactivatedData}
                                    tileDetailComponent={TransectRow}
                                    primaryKey="transectId"
                                    actions={(item) => (
                                        <Fragment>
                                            <CircledIcon
                                                icon={['fal', 'tree-alt']}
                                                circleSize="12"
                                                iconTransform="shrink-2"
                                                className="disabled"
                                            />
                                            <Link to={`/transect/${item.transectId}`}>
                                                <CircledIcon
                                                    icon={['fal', 'pen']}
                                                    circleSize="12"
                                                    iconTransform="shrink-4"
                                                />
                                            </Link>
                                            {
                                                item.safetyNotes ?
                                                    <SafetyNoteIcon
                                                        icon={['fal', 'sticky-note']}
                                                        circleSize="12"
                                                        onClick={() => {
                                                            setCurrentTransect(item);
                                                            setShowNotes(true);
                                                        }}
                                                    /> :
                                                    <CircledIcon
                                                        icon={['fal', 'sticky-note']}
                                                        circleSize="12"
                                                        iconTransform="shrink-3"
                                                        className={item.safetyNotes ? 'danger' : ''}
                                                        onClick={() => {
                                                            setCurrentTransect(item);
                                                            setShowNotes(true);
                                                        }}
                                                    />
                                            }
                                        </Fragment>
                                    )}
                                    showComplete
                                    noIcon
                                />
                            </Col>
                        </Fragment>
                    }
                </Row>
            </PageContent>
        </PageContainer>
    );
};

export default TransectsPage;
