import {
    CLEAR_SELECTED_INPUT,
    SET_CURRENT_DATA, SET_REPLACE,
    SET_SELECTED_INPUT
} from "../Actions/Types/transectActionTypes";
import {
    CLOSE_SPECIES_PICKER,
    OPEN_SPECIES_PICKER,
    SET_SELECTED_SPECIES
} from "../Actions/Types/transectActionTypes";
import {USER_LOGOUT} from "../Actions/Types/userActionTypes";
import {RESTORE_BACKUP} from "../Actions/Types/offlineDataActionTypes";

const initialState = {
    selectedInput: null,
    selectedField: null,
    currentTransect: null,
    currentHydrozone: null,
    currentPlot: null,
    currentSpeciesPage: null,
    setReplace: false,

    showSpeciesPicker: false,
    selectedSpecies: undefined,
};

const transectReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_DATA:
            return {
                ...state,
                currentTransect: action.currentTransect,
                currentHydrozone: action.currentHydrozone,
                currentPlot: action.currentPlot,
                currentSpeciesPage: action.currentSpeciesPage,
            };
        case SET_SELECTED_INPUT:
            return {
                ...state,
                selectedInput: action.selectedInput,
                selectedField: action.selectedField,
            };
        case CLEAR_SELECTED_INPUT:
            return {
                ...state,
                selectedInput: null,
                selectedField: null,
            };
        case OPEN_SPECIES_PICKER:
            return {
                ...state,
                showSpeciesPicker: true,
                // If action.species is null the page's
                // SurveySpeciesInput.props.species
                // will be used instead
                species: action?.species || null,
                // qual repeaters
                answerId: action?.answerId ?? null,
                repeaterId: action?.repeaterId ?? null,
                idColumn: action?.idColumn ?? null,
                nameColumn: action?.nameColumn ?? null,
            };
        case CLOSE_SPECIES_PICKER:
            return {
                ...state,
                showSpeciesPicker: false,
            };
        case SET_SELECTED_SPECIES:
            return {
                ...state,
                selectedSpecies: action.speciesId,
            };
        // Replaces keyboard value instead of appending
        case SET_REPLACE:
            return {
                ...state,
                setReplace: action.setReplace,
            };
        case USER_LOGOUT:
            return initialState;
        case RESTORE_BACKUP:
            return action.data.transectState;
        default:
            return state;
    }
};

export default transectReducer;
