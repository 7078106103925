import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import RepeatableSection from "../RepeatableSection";
import {
    addManagementRecommendation,
    deleteManagementRecommendation,
    updateManagementRecommendation
} from "../../../Redux/Actions/surveyActions";
import {useNextTempId} from "../../../Hooks/TempIdHooks";
import FormInput from "../FormInput";

const MonitoringRecommendationComponent = props => {
    const dispatch = useDispatch();
    const nextId = useNextTempId();
    const user = useSelector(state => state.userState.user);

    return (
        <RepeatableSection
            name="Recommendation"
            data={props.data}
            primaryKey="qualitativeVegetationMonitoringRecommendationId"
            answerId={props.answerId}
            sampleEventId={props.sampleEventId}
            fields={[{
                code: 'recommendationNotes',
                type: 'text',
            }]}
            colSize={12}
            addItem={() => dispatch(addManagementRecommendation(props.answerId, props.code, user.userId, nextId()))}
            updateItem={(index, column, value) => dispatch(updateManagementRecommendation(props.answerId, index, value))}
            deleteItem={(index) => dispatch(deleteManagementRecommendation(props.answerId, index))}
            readOnly={props.readOnly}
        />
    )
};

export default MonitoringRecommendationComponent;
