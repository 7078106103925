import React, {useEffect, useState} from 'react';
import { ModalBody, ModalFooter, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Modal from './Modal';
import FormInput from "./FormInput";

const NotesModal = props => {
    const [noteValues, setNoteValues] = useState({});

    useEffect(() => {
        const values = {};
        props.notes.forEach(note => {
            values[note.label] = note.value || '';
        });

        setNoteValues(values);
    }, [props.notes]);

    const save = () => {
        props.notes.forEach(note => {
            note.save(noteValues[note.label]);
        });

        props.onHide();
    };

    return (
        <Modal
            title={props.title}
            size="md"
            className="notes-modal"
            show={props.show}
            onHide={() => props.onHide()}
        >
            <ModalBody>
                <Row xs={12}>
                    <Col xs={12}>
                        {
                            props.notes.map(note => (
                                <FormInput
                                    label={note.label}
                                    type="textarea"
                                    value={noteValues[note.label]}
                                    onChange={value => setNoteValues({
                                        ...noteValues,
                                        [note.label]: value
                                    })}
                                    key={note.label}
                                    readOnly={props.readOnly}
                                />
                            ))
                        }
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {
                    !props.readOnly ?
                        <Button variant="complete" className="w-25" onClick={() => save()}>
                            <FontAwesomeIcon icon={['fal', 'save']} />
                            Save
                        </Button> :
                        <Button variant="secondary" className="w-25" onClick={() => props.onHide()}>
                            Close
                        </Button>
                }
            </ModalFooter>
        </Modal>
    )
};

export default NotesModal;
