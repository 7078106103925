import {
    TYPE_STEM_TALLY,
    TYPE_LENGTH_OF_COVER,
    TYPE_TREE_DBH,
    TYPE_INVASIVE_COVER,
    stemTallyCategories,
    lengthOfCoverCategories,
    treeDBHCategories,
    invasiveCoverCategories,
    hasNoPlantsFieldNames
} from '../Constants/lengthCategories';

export default class LengthCategoryUtils {
    // This doesn't work for our current structure
    static GetLengthCategories(species, type) {
        const categories = this.GetLengthCategoryByType(type);

        if (!species.length) {
            return categories.filter(category => category.active);
        }

        const data = species.map(item => item[type]);
        const uniqueKeys = Object.keys(
            data.reduce((result, obj) => ({...result, ...obj}))
        );

        return uniqueKeys.map(key => {
            return categories.find(category => category.code === key);
        }).filter(category => category !== undefined);
    }

    static GetLengthCategoryByType(type) {
        switch (type) {
            case TYPE_STEM_TALLY:
                return stemTallyCategories;
            case TYPE_LENGTH_OF_COVER:
                return lengthOfCoverCategories;
            case TYPE_TREE_DBH:
                return treeDBHCategories;
            case TYPE_INVASIVE_COVER:
                return invasiveCoverCategories;
            default:
                return [];
        }
    }

    static GetLengthCategoryName(type) {
        switch (type) {
            case TYPE_STEM_TALLY:
                return 'Stem Tally';
            case TYPE_LENGTH_OF_COVER:
                return 'Length of Cover';
            case TYPE_TREE_DBH:
                return 'Tree DBH';
            case TYPE_INVASIVE_COVER:
                return 'Invasive / Ground';
        }
    }
    
    static GetNoPlantsFieldName(type, plotNumber) {
        const fieldNamePrefix = hasNoPlantsFieldNames[type] ?? null;
        if(fieldNamePrefix === null) { return null; }
        return fieldNamePrefix + (Number(plotNumber) === 2 ? '2' : '1');
    }

    static GetLegacyNoPlantsFieldName(plotNumber) {
        return 'hasNoPlantsPlot' + (Number(plotNumber) === 2 ? '2' : '1');
    }
}
