import {useSelector} from "react-redux";
import DateUtils from "../Utils/DateUtils";
import {selectVisiblePhotos} from "../Redux/Selectors/photoSelectors";
import {useNextTempId} from "./TempIdHooks";
import {addPhotoContent} from "../Redux/Actions/photoActions";

export const usePreviousPhoto = () => {
    const photos = useSelector(state => selectVisiblePhotos(state));

    return (sampleEventStartDate, photoPointId) => {
        // photos that are not associated with a photoPoint (e.g. Qualitative Survey photos)
        // do not have a 'previous photo'
        if(photoPointId == null) {
            return null;
        }
        
        // Previous sample event sorted by sampleEventStartDate in descending order
        return photos.filter(photo => photo.photoPointId === photoPointId)
            .sort((a, b) => DateUtils.CompareDesc(a.sampleEventStartDate, b.sampleEventStartDate))
            .find(photo => DateUtils.IsBefore(photo.sampleEventStartDate, sampleEventStartDate));
    }
};

export const photoPointWasActiveDuringSampleEvent = (photoPoint, sampleEvent) => {
    return photoPoint.PhotoPointHistory.some( photoPointHistory => {
            return (
                (DateUtils.IsAfter(sampleEvent.startDate, photoPointHistory.activeFrom) ||
                    DateUtils.IsEqual(sampleEvent.startDate, photoPointHistory.activeFrom))
                &&
                (DateUtils.IsBefore(sampleEvent.startDate, photoPointHistory.activeTo) ||
                    photoPointHistory.activeTo == null)
            )

        }
    );
}

export const useStoreNewPhoto = () => {
    const nextId = useNextTempId()
    return async (photo) => {
        return addPhotoContent(nextId(), photo);
    }
}
