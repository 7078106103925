import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {Col, OverlayTrigger, Row, Popover} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import SurveyRadioInput from "./SurveyRadioInput";
import SurveySelectInput from "./SurveySelectInput";
import CompetitionComponent from "./CompetitionComponent";
import SurveyTextInput from "./SurveyTextInput";
import SurveyCheckboxListInput from "./SurveyCheckboxListInput";
import SurveySecondaryInput from "./SurveySecondaryInput";
import {updateSurveyValue} from "../../../Redux/Actions/surveyActions";

const SurveyField = props => {
    const dispatch = useDispatch();

    const {name, order, options, code, helpText, surveyQuestionId, surveyQuestionDataTypeId, isRequired} = props.question;
    const {answers} = props;

    const [secondaryFields, setSecondaryFields] = useState([]);

    const answerId = answers?.qualitativeVegetationMonitoringId;
    const answer = answers ? answers[code] : null;

    // hide or show the appropriate secondary fields
    // based on the current radio selection
    useEffect(() => {
        options?.values?.forEach?.((value) => {
            if (value.value !== undefined && value.value === answer) {
                setSecondaryFields(value.secondaryFields ?? []);
            } else if (value.type === "checkbox" && !!answers?.[value.code]) {
                setSecondaryFields(value.secondaryFields ?? []);
            }
        });
    }, [answers]);

    const secondaryFieldComponents = secondaryFields.map((secondaryField, i) => {

        let data = null;
        if(secondaryField.type === 'monitoring-repeater') {
            data = props?.answers?.QualitativeVegetationMonitoringRepeaters ?? [];
        } else if(secondaryField.type === 'monitoring-recommendations') {
            data = props?.answers?.QualitativeVegetationMonitoringRecommendations ?? [];
        }

        return <SurveySecondaryInput
            fieldDefinition={secondaryField}
            value={answers?.[secondaryField.code] ?? ''}
            key={i}

            /* used by primary fields only */
            onChange={(value) => dispatch(updateSurveyValue(answerId, secondaryField.code, value))}

            /* used by species fields */
            sampleEventId={answers.sampleEventId}

            /* used by repeater and recommendation fields */
            data={data}
            answerId={answerId}
            readOnly={props.readOnly}
        />
    });

    const renderComponent = () => {
        const commonSurveyInputProps = {
            options: options,
            answerId: answerId,
            value: answer,
            children: secondaryFieldComponents
        };

        switch (surveyQuestionDataTypeId) {
            case 1:
                return <SurveyTextInput id={surveyQuestionId} code={code} {...commonSurveyInputProps} readOnly={props.readOnly} />;
            case 13:
                return <SurveyRadioInput id={surveyQuestionId} code={code} {...commonSurveyInputProps} readOnly={props.readOnly} />;
            case 14:
                return <SurveySelectInput id={surveyQuestionId} code={code} {...commonSurveyInputProps} readOnly={props.readOnly} />;
            case 15:
                return <SurveyCheckboxListInput id={surveyQuestionId} code={code} {...commonSurveyInputProps} answers={answers} readOnly={props.readOnly} />;
            case 17:
                switch (code) {
                    case 'competition':
                        return <CompetitionComponent id={surveyQuestionId} code={code} answers={answers} readOnly={props.readOnly} />
                }
        }
    };

    return (
        <div className="survey-field">
            <Row xs={12}>
                <Col xs={5}>
                    <div className={`survey-question ${(isRequired ?? true) ? 'required' : ''}`}>
                        {order}. {name}
                        {
                            helpText &&
                            <OverlayTrigger
                                trigger="click"
                                key="right"
                                placement="right"
                                overlay={
                                    <Popover>
                                        <Popover.Content>
                                            {helpText}
                                        </Popover.Content>
                                    </Popover>
                                }
                                rootClose
                            >
                                <FontAwesomeIcon icon={['fas', 'info-circle']} className="info-popover" />
                            </OverlayTrigger>
                        }
                    </div>
                </Col>
                <Col xs={7}>
                    <div className="survey-answer">
                        {renderComponent()}
                    </div>
                </Col>
            </Row>
            <hr />
        </div>
    )
};

export default SurveyField;
