import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useUpdateEffect} from "../../Hooks/EffectHooks";
import {SBM_VERSION} from "../../Constants";

const ErrorComponent = props => {
    const history = useHistory();

    useUpdateEffect(() => {
        location.reload();
    }, [location.pathname]);

    console.log(props.error.stack);

    return (
        <div className="error-page">
            <div className="error-container">
                <div className="error-text">Something went wrong :(</div>
                <div>If you continue to have problems, backup your data and then try reinstalling the app.</div>
                <div className="error-message">{props.error?.stack}</div>
                <div>Version: {SBM_VERSION}</div>
                <div className="actions">
                    <Link to="/dashboard">
                        <Button variant="complete">Dashboard</Button>
                    </Link>
                    <Button variant="action" onClick={() => {
                        history.goBack();
                    }}>
                        <FontAwesomeIcon icon={['fas', 'redo']} transform="flip-h" />
                        Go Back
                    </Button>
                </div>
            </div>
        </div>
    )
};

export default ErrorComponent;
