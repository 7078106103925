import React, {useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UPSERT_PHOTO} from "../../../Redux/Actions/Types/offlineDataActionTypes";
import {selectSampleEventById} from "../../../Redux/Selectors/nodeSelectors";
import {selectSurveyPhotos} from "../../../Redux/Selectors/photoSelectors";
import {Link} from "react-router-dom";
import CircledIcon from "../../CompositeIcons/CircledIcon";
import {markPhotoForDeletion} from "../../../Redux/Actions/photoActions";
import {useStoreNewPhoto} from "../../../Hooks/PhotoHooks";
import {markQualitativeVegetationRepeaterAsModified} from "../../../Redux/Actions/surveyActions";

const SurveyPhotoInput = (props) => {
    const {answerId, repeaterId, sampleEventId, label, required} = props;
    
    const dispatch = useDispatch();
    const fileRef = useRef(null);
    const storeNewPhoto = useStoreNewPhoto();

    const user = useSelector(state => state.userState.user);
    const event = useSelector(state => selectSampleEventById(state, sampleEventId));
    const surveyPhotos = useSelector(state => selectSurveyPhotos(state, sampleEventId, repeaterId));

    const takePhoto = (e) => {
        if(e?.target?.files) {
            savePhotos(e?.target?.files);
        }
    };
    
    const savePhotos = async (photos) => {
        for (const photo of photos) {
            if (photo instanceof Blob) {
                await savePhoto(photo);
            }
        }
    }

    const savePhoto = async (photo) => {
        const newPhoto = {
            ...(await storeNewPhoto(photo)),
            qualitativeVegetationMonitoringRepeaterId: repeaterId,
            organizationId: user.organizationId,
            photoPointId: null,
            sampleEventId: event.sampleEventId,
            sampleEventName: event.sampleEventPhaseName,
            sampleEventStartDate: event.startDate,
            notes: null,
            description: null,
        };

        dispatch({type: UPSERT_PHOTO, photo: newPhoto});
        dispatch(markQualitativeVegetationRepeaterAsModified(repeaterId));
    };

    return (
        <div className="survey-photo-input photo-point-row">
            <div className={`form-group ${required ? 'required' : ''}`}>
                <label className="form-label">{label}</label>
                <input ref={fileRef} onChange={takePhoto} className="d-none" type="file" accept="image/*" multiple />
            </div>

            <Row xs={12} className="photo-point-content">
                {
                    surveyPhotos.map(photo => (
                        <Col xs={4} key={photo.photoId}>
                            <div className="photo">
                                <CircledIcon
                                    icon={['fal', 'trash-alt']}
                                    circleSize="12"
                                    iconTransform="shrink-4"
                                    onClick={() => dispatch(markPhotoForDeletion(photo))}
                                />
                                <Link to={`/photo/${photo.photoId}`}>
                                    <img src={photo.thumbnailSrc ?? photo.src} />
                                </Link>
                            </div>
                        </Col>
                    ))
                }
                <Col xs={4}>
                    <div className="photo">
                        <div className="no-image">
                            <FontAwesomeIcon icon={['fal', 'camera']} />
                            <Button variant="action" onClick={() => fileRef.current.click()}>
                                Choose Photo
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default SurveyPhotoInput;
