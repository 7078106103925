import React from 'react';
import {useDispatch} from "react-redux";

import {FormCheck} from "react-bootstrap";
import {updateSurveyValue} from "../../../Redux/Actions/surveyActions";


const SurveyRadioInput = props => {
    const dispatch = useDispatch();

    const { value: selectedValue, answerId, children } = props;

    let optionValues = props.options?.values;
    if(!optionValues || optionValues?.length < 1) {
        optionValues = [
            {value: 1, label: "Yes"},
            {value: 0, label: "No"}
        ]
    }

    return (
        <div className="survey-radio-input">
            {
                optionValues.map(({value, label}, i) => (
                        <FormCheck
                            key={`${props.code}-${i}`}
                            id={`${props.code}-${i}`}
                            name={props.id}
                            label={label}
                            type="radio"
                            value={value}
                            checked={value === selectedValue}
                            onChange={() => !props.readOnly ? dispatch(updateSurveyValue(answerId, props.code, value)) : null}
                            inline
                        />
                    ))
            }
            {
                children
            }
        </div>
    );
};

export default SurveyRadioInput;
