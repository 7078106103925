import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Button, OverlayTrigger, Popover} from "react-bootstrap";

const SaveButtonWithHelpIcon = (props) => {
    return (
        <div className="button-overlay-hack">
            {
                props.disable &&
                <OverlayTrigger
                    trigger="click"
                    key="top"
                    placement="top"
                    overlay={
                        <Popover>
                            <Popover.Content>
                                Please complete all required fields (marked with red asterisks) and check for validation errors.
                            </Popover.Content>
                        </Popover>
                    }
                    rootClose
                >
                    <div className="hidden-div" />
                </OverlayTrigger>
            }
            <Button variant="complete" onClick={() => props.save()} disabled={props.disable}>
                <FontAwesomeIcon icon={['fal', 'save']} />
                {props.hasData ? `Save ${props.type}` : `Create ${props.type}`}
            </Button>
        </div>
    )
};

export default SaveButtonWithHelpIcon;
