import React, {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, FormLabel, FormGroup, Row} from "react-bootstrap";
import MaskedFormControl from "../Common/MaskedInputControl";

import {sendCode} from "../../Redux/Actions/loginActions";
import {UPDATE_VERIFICATION_FIELD_VALUE, VERIFICATION_CANCEL} from "../../Redux/Actions/Types/loginActionTypes";
import KeyboardUtils from "../../Utils/KeyboardUtils";
import {SB_ENTER_KEY_CODE} from "../../Constants";

const PhoneNumberForm = props => {
    const {fields, errors} = useSelector(state => state.loginState);
    const dispatch = useDispatch();

    const updateField = (field, value) => {
        dispatch({type: UPDATE_VERIFICATION_FIELD_VALUE, field, value})
    };

    const isValidMobileNumber = (number) => {
        return number && number.replace(/[^0-9]/g, '').length === 10;
    };

    return (
        <Fragment>
            <Row key={1} className="top10">
                <Col xs={12}>
                    <p>Your organization requires that you use two-factor authentication. A 6-digit code will be sent via text message to your mobile phone number.</p>
                </Col>
            </Row>
            <Row key={2} className="top10">
                <Col md={3}>
                    <span className="login-form-label">Phone Number</span>
                </Col>
                <Col md={9}>
                    <FormGroup validationState={errors.sendCode ? 'error' : null}>
                        <MaskedFormControl
                            mask={`(111) 111-1111`}
                            type="text"
                            value={fields.mobileNumber ?? ''}
                            onKeyUp={(x) => KeyboardUtils.handleKeyPress(SB_ENTER_KEY_CODE, x, () => dispatch(sendCode()))}
                            onChange={(event) => updateField('mobileNumber', event.target.value)}
                            autoFocus
                        />
                        {errors.sendCode && <FormLabel>{errors.sendCode}</FormLabel>}
                    </FormGroup>
                </Col>
            </Row>
            {
                !props.HideButtons &&
                <Row key={3} className="top10 login-btn">
                    <Col md={3} />
                    <Col md={9}>
                        <Button
                            onClick={() => dispatch(sendCode())}
                            variant="primary"
                            disabled={!isValidMobileNumber(fields.mobileNumber)}
                            className="send-btn"
                        >
                            Send Code
                        </Button>
                    </Col>
                    <Col md={12} className="cancel-btn">
                        <a onClick={() => dispatch({type: VERIFICATION_CANCEL})}>Cancel</a>
                    </Col>
                </Row>
            }
        </Fragment>
    )
};

export default PhoneNumberForm;
