import axios from 'axios';
import Config from "../Config"
import {SET_PARTIAL_LOGIN} from "../Redux/Actions/Types/appStatusTypes";
import {SBM_VERSION} from "../Constants";
import {ABORT_SYNC} from "../Redux/Actions/Types/syncStatusTypes";
import BaseService from "../Services/Core/BaseService";

export default class ApiUtils {
    static CreateInstance(options) {
        return axios.create(options);
    }

    static CreateSBInstance(options = {}) {
        const instance = this.CreateInstance({
            withCredentials: true,
            baseURL: this.GetApiUrl(),
            ...options,
        });

        instance.interceptors.response.use(
            (response) => {
                if(response?.data) {
                    BaseService.refreshAuthTokenFromAPIResponseData(response.data);
                }
                return response;
            },
            (error) => {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            Config.getStore().dispatch({type: ABORT_SYNC});
                            Config.getStore().dispatch({type: SET_PARTIAL_LOGIN, partialLogin: true});
                            break;
                    }
                    return Promise.reject(error.response);
                }
                return Promise.reject(error);
            }
        );

        return instance;
    }

    static CreateSBCancellableInstance(options = {}) {
        let requestSerial = 0;

        const instance = this.CreateSBInstance({
            requestSerial: 0,
        });

        instance.interceptors.request.use(
            (config) => {
                config.requestSerial = ++requestSerial;

                return config
            }
        );

        instance.interceptors.response.use(
            (response) => {
                if(response?.config?.requestSerial < requestSerial) {
                    throw new axios.Cancel('Rejecting out-of-sequence Axios response')
                } else {
                    return response
                }
            }
        );

        return instance;
    }

    static Axios() {
        return Config.getAxiosInstances().external;
    }

    static SBCancellableAxios() {
        return this.GetInstanceWithHeaders('cancellable');
    }

    static SBAxios() {
        return this.GetInstanceWithHeaders('default');
    }

    static GetInstanceWithHeaders(type) {
        const authToken = localStorage.getItem('storedAuthToken');
        const instance = Config.getAxiosInstances()[type];
        instance.defaults.headers.common['Authorization'] = authToken;
        instance.defaults.headers.common['SBM-Version'] = SBM_VERSION;
        instance.defaults.withCredentials = true;

        return instance;
    }

    static GetApiUrl(path = '') {
        return `${Config.getApiUrl()}/api/${path}`;
    }
}
