import React from 'react';
import { ModalBody, ModalFooter, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Modal from './Modal';

const ConfirmDeleteModal = props => {
    const verb = props.discard ? 'Discard' : 'Delete';

    return (
        <Modal
            title={`Confirm ${verb}`}
            size="md"
            show={props.show}
            onHide={() => props.onHide()}
        >
            <ModalBody>
                <Row xs={12}>
                    <Col xs={12}>
                        <span>
                            {
                                props.children ?
                                    props.children :
                                    props.itemMessage ?
                                        `Are you sure you want to ${verb.toLowerCase()} ${props.itemMessage}?` :
                                        `Are you sure you want to ${verb.toLowerCase()} the current item?`
                            }
                        </span>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button variant="link" className="ml-3" onClick={() => props.onHide()}>
                    <FontAwesomeIcon icon={['fal', 'times']} />
                    Cancel
                </Button>
                <Button variant="danger" className="w-25" onClick={() => props.delete()}>
                    {
                        !props.discard &&
                        <FontAwesomeIcon icon={['fal', 'trash-alt']} />
                    }
                    {verb}
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default ConfirmDeleteModal;
