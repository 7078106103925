import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FullScreenPhotoIcon = props => {
    return (
        <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={['fal', 'camera']} transform="grow-10" />
            <FontAwesomeIcon icon={['fas', 'arrow-alt-up']} transform="shrink-5 rotate-45 right-18 up-12" />
            <FontAwesomeIcon icon={['fas', 'arrow-alt-up']} transform="shrink-5 rotate-225 left-18 down-17" />
        </span>
    );
};

export default FullScreenPhotoIcon;
