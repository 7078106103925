import React, {useEffect, useRef, useState} from 'react';
import {Table} from "react-bootstrap";

import {useHydrozoneName, useGetUserFacingSpeciesName} from "../../../Hooks/LabelHooks";
import LengthCategoryUtils from "../../../Utils/LengthCategoryUtils";
import {TYPE_INVASIVE_COVER, TYPE_STEM_TALLY} from "../../../Constants/lengthCategories";
import ReturnLink from "../../Common/ReturnLink";


const SpeciesTable = props => {
    const getHydrozoneName = useHydrozoneName();
    const getUserFacingSpeciesName = useGetUserFacingSpeciesName();

    const categories = LengthCategoryUtils.GetLengthCategoryByType(props.type);
    const [speciesIds, setSpeciesIds] = useState([]);

    const isInvasive = props.type === TYPE_INVASIVE_COVER;
    const hydrozoneSpeciesType = isInvasive ? 'HydrozoneGroundCoverSpecies' : 'HydrozonePlotSpecies';

    useEffect(() => {
        const speciesList = new Set();

        props.data.forEach(datum => {
            datum[hydrozoneSpeciesType].forEach(species => {
                speciesList.add(species.speciesId);
            })
        });

        setSpeciesIds([...speciesList]);
    }, [props.data]);

    const getSpeciesIds = () => {
        return speciesIds.filter(speciesId => props.data.find(datum => (
            datum[hydrozoneSpeciesType].find(species => speciesId === species.speciesId)
        )));
    };

    const getHydrozoneLink = (hydrozone = null) => {
        if (isInvasive) {
            return `/transect/${props.transectId}/invasive-cover/${hydrozone.hydrozoneTypeId}`;
        }

        if (!hydrozone) {
            hydrozone = props.data[0];
        }

        const plotTypeId = hydrozone[hydrozoneSpeciesType].find(species => !!species)?.plotTypeId;

        return `/transect/${props.transectId}/plot${plotTypeId}/${props.type}/${hydrozone.hydrozoneTypeId}`
    };

    const renderSpeciesData = () => {
        return getSpeciesIds()
            .map((speciesId) => {
                const currentSpecies = props.species.find(species => species.speciesId === speciesId);

                return (
                    <tr key={speciesId}>
                        <td className="species-cell">
                            <div className="rounded-cell-container fixed-column">
                                <span className="species-name">{getUserFacingSpeciesName(currentSpecies)}</span>
                            </div>
                        </td>
                        {
                            props.group ?
                                categories.map((category, i) => {
                                    const borderClass = i === 0 ? 'left-border' : i === categories.length - 1 ? 'right-border' : '';

                                    // Group hydrozone data by category
                                    const value = props.data.reduce((acc, curr) => {
                                        return acc + curr[hydrozoneSpeciesType].filter(species => speciesId === species.speciesId)
                                            .reduce((acc, curr) => acc + Number(curr[category.code]) || 0, 0)
                                    }, 0);

                                    return <td key={category.id} className={`type-normal ${borderClass}`}>{value}</td>;
                                }) :
                                props.data.map(datum => (
                                    categories.map((category, i) => {
                                        const borderClass = i === 0 ? 'left-border' : i === categories.length - 1 ? 'right-border' : '';

                                        const species = datum[hydrozoneSpeciesType]
                                            .find(species => speciesId === species.speciesId);

                                        return <td key={category.id} className={`type-${getHydrozoneName(datum.hydrozoneTypeId).toLowerCase()} ${borderClass}`}>
                                            {species && species[category.code] ? species[category.code] : 0}
                                        </td>;
                                    })
                                ))
                        }
                    </tr>
                )
            });
    };

    if (!getSpeciesIds().length) {
        return null;
    }

    return (
        <Table borderless className={`species-table type-${props.type}`}>
            <tbody>
                <tr key="title">
                    <th className="table-title">
                        {
                            props.group && props.title ?
                                <ReturnLink path={getHydrozoneLink()} isTransectReview>
                                    {props.title}
                                </ReturnLink> :
                                props.title
                        }
                    </th>
                </tr>
                {
                    !props.group &&
                        <tr key="group-header">
                            <th className="type-blank">
                                <div className="rounded-cell-container" />
                            </th>
                            {
                                props.data.map(datum => (
                                    <th
                                        key={datum.hydrozoneId}
                                        colSpan={categories.length}
                                        className={`type-${getHydrozoneName(datum.hydrozoneTypeId).toLowerCase()}`}
                                    >
                                        <ReturnLink path={getHydrozoneLink(datum)} isTransectReview>
                                            {getHydrozoneName(datum.hydrozoneTypeId)}
                                        </ReturnLink>
                                    </th>
                                ))
                            }
                        </tr>
                }
                <tr className="sub-header" key="sub-header">
                    <td className="fixed-column">
                        <span>Species</span>
                    </td>
                    {
                        props.group ?
                            categories.map(category => (
                                <td key={category.id}>{category.shortName || category.name}</td>
                            )) :
                            props.data.map((datum, i) => (
                                categories.map(category => (
                                    <td key={category.id + "." + i}>{category.shortName || category.name}</td>
                                ))
                            ))
                    }
                </tr>
                {renderSpeciesData()}
            </tbody>
        </Table>
    )
};

export default SpeciesTable;
