import {useDispatch} from "react-redux";
import {incrementTempId} from "../Redux/Actions/tempIdActions";

export const useNextTempId = () => {
    const dispatch = useDispatch();
    return () => {
        return dispatch(incrementTempId());
    }
};

export const useNextAlphaId = () => {
    const minCharCode = 65; // A
    const maxCharCode = 90; // Z

    return (alphaId) => {
        if (!alphaId) {
            return String.fromCharCode(minCharCode);
        }

        const currentCharCode = alphaId.charCodeAt(0);

        if (currentCharCode === maxCharCode) {
            return null;
        }

        return String.fromCharCode(currentCharCode + 1);
    }
};
