import React from 'react';

import {Col, ModalBody, Row} from "react-bootstrap";
import Modal from "../../Common/Modal";
import FormInput from "../../Common/FormInput";
import {useReturnToReview} from "../../../Hooks/RouteHooks";
import {TYPE_INVASIVE_COVER} from "../../../Constants/lengthCategories";

const TransectModal = props => {
    const returnToReview = useReturnToReview();

    const goToLink = (transectId) => {
        const currentTransect = props.transects.find(transect => String(transect.transectId) === String(transectId));
        if (props.type === TYPE_INVASIVE_COVER) {
            returnToReview(`/transect/${transectId}/invasive-cover/${currentTransect.hydrozoneTypeId}`);
        } else {
            returnToReview(`/transect/${transectId}/plot${currentTransect.plotTypeId}/${props.type}/${currentTransect.hydrozoneTypeId}`);
        }

        props.onHide();
    };

    return (
        <Modal
            title="Transect List"
            className="transect-modal"
            size="md"
            show={props.show}
            onHide={() => props.onHide()}
        >
            <ModalBody>
                <Row xs={12}>
                    <Col xs={12}>
                        <FormInput
                            type="select"
                            placeholder="Select Transect"
                            value=""
                            options={props.transects.map(({transectId, transectName}) => ({id: transectId, name: `Transect ${transectName}`}))}
                            onChange={(value) => goToLink(value)}
                        />
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
};

export default TransectModal;
