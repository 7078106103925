import { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { SET_CONTEXT_TOOLS } from '../Redux/Actions/Types/toolActionTypes';
import {selectProcedures, selectProjectById, selectSampleEventById} from "../Redux/Selectors/nodeSelectors";
import {SET_PROCEDURES, SET_PROJECT_MAP} from "../Redux/Actions/Types/appStatusTypes";
import ApiUtils from "../Utils/ApiUtils";

export const useContextTools = (tools) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({type: SET_CONTEXT_TOOLS, tools});
    }, [])
};

export const useContextToolsByProjectId = (projectId) => {
    const dispatch = useDispatch();

    const project = useSelector(state => selectProjectById(state, projectId));
    const procedures = useSelector(state => selectProcedures(state, project.parentHierarchyId));
    const mapId = project.projectMapFileUploadId;
    const projectMap = mapId ? ApiUtils.GetApiUrl(`fileUploads/${mapId}/download`) : null;

    useEffect(() => {
        dispatch({type: SET_PROCEDURES, procedures: procedures.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)});
        dispatch({type: SET_PROJECT_MAP, projectMap});
    }, [])
};

export const useContextToolsBySampleEventId = (sampleEventId) => {
    const dispatch = useDispatch();

    const sampleEvent = useSelector(state => selectSampleEventById(state, sampleEventId));
    const project = useSelector(state => selectProjectById(state, sampleEvent.projectId));
    const procedures = useSelector(state => selectProcedures(state, project.parentHierarchyId));
    const mapId = project.projectMapFileUploadId;
    const projectMap = mapId ? ApiUtils.GetApiUrl(`fileUploads/${mapId}/download`) : null;

    useEffect(() => {
        const procedureList = procedures.map(procedure => {
            if (procedure.code === sampleEvent.procedureCode) {
                return {...procedure, selected: true};
            }

            return procedure;
        }).sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1);

        dispatch({type: SET_PROCEDURES, procedures: procedureList});
        dispatch({type: SET_PROJECT_MAP, projectMap});
    }, [])
};
