import React, {useEffect, useState} from 'react';
import { ModalBody, ModalFooter, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Modal from '../../Common/Modal';
import FormInput from "../../Common/FormInput";
import {useDispatch} from "react-redux";
import {UPSERT_TRANSECT} from "../../../Redux/Actions/Types/offlineDataActionTypes";

const DeactivateTransectModal = props => {
    const [notes, setNotes] = useState(props.transect?.notes ?? '');

    const dispatch = useDispatch();
    
    useEffect(() => {
        setNotes(props.transect?.notes ?? '');
    }, [props.show]);

    const deactivate = () => {
        const transect = {
            ...props.transect,
            notes,
            isActive: false,
        };

        dispatch({type: UPSERT_TRANSECT, transect});
        onHide(false);
    };

    const onHide = (cancelled = true) => {
        props.onHide(cancelled);
    };

    return (
        <Modal
            title={`Deactivate Transect ${props.transect?.name}`}
            size="md"
            show={props.show}
            className="deactivate-transect-modal"
            onHide={() => onHide()}
        >
            <ModalBody>
                <Row xs={12}>
                    <Col xs={12}>
                        Please note the reason for deactivation of Transect {props.transect?.name}
                    </Col>
                    <Col xs={12} className="mt-3">
                        <FormInput
                            label="Transect Notes"
                            type="textarea"
                            value={notes}
                            onChange={value => setNotes(value)}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button variant="danger" onClick={() => deactivate()}>
                    <FontAwesomeIcon icon={['fal', 'times-circle']} />
                    Confirm Deactivation
                </Button>
                <Button variant="link" className="mt-2" onClick={() => onHide()}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default DeactivateTransectModal;

