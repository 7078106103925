import React from "react";

import {useFieldIsDisabled, useHasInvalidField, useValidateLatitude, useValidateLongitude} from "./FormHooks";

import {LIST_NATIVE_OR_UNKNOWN_WOODY, LIST_NATIVE_WOODY, LIST_PROBLEMATIC} from "../Constants/species";
import {competitionLifeStageOptions, insectsImpactCausingOptions, insectsImpactTypeOptions} from "../Constants/survey";


export const useRepeaterParameters = () => {
    const browseFields = useBrowseFields();
    const competitionFields = useCompetitionFields();
    const conditionFields = useConditionFields();
    const healthFields = useHealthFields();
    const insectFields = useInsectFields();
    const performanceFields = usePerformanceFields();
    const soilFields = useSoilFields();

    return (code) => {
        switch (code) {
            case 'flood':
            case 'fire':
            case 'drift':
                return {
                    name: 'Area',
                    fields: conditionFields(code),
                };
            case 'insects':
            case 'insectsii':
                return {
                    name: 'Species',
                    fields: insectFields(code),
                    colSize: 6,
                };
            case 'browse':
                return {
                    name: 'Species',
                    fields: browseFields,
                };
            case 'soils':
                return {
                    name: 'Observation',
                    fields: soilFields,
                };
            case 'health':
                return {
                    name: 'Species',
                    fields: healthFields,
                    colSize: 6,
                };
            case 'performance':
                return {
                    name: 'Species',
                    fields: performanceFields,
                    colSize: 12,
                };
            case 'competition':
                return {
                    name: 'Species',
                    fields: competitionFields,
                };
            default:
                return null;
        }
    }
};

export const useValidAnswer = () => {
    const hasInvalidField = useHasInvalidField();
    const fieldIsDisabled = useFieldIsDisabled();

    return (answers, fields) => {
        if (!answers.length) {
            return false;
        }

        for (const answer of answers) {
            for (const field of fields) {
                // don't validate disabled fields
                if(fieldIsDisabled(field, answer)) {
                    continue;
                }
                
                const validationMessage = field.validationMessage ? field.validationMessage(answer[field.code]) : null;
                const requiredFields = field.required ? [answer[field.code]] : [];

                // note hasInvalidField doesn't work for 'required' photo fields and
                // I didn't implement it yet because we don't use it anywhere right now
                if (hasInvalidField(requiredFields, [validationMessage])) {
                    return false;
                }
            }
        }

        return true;
    }
};

export const useBrowseFields = () => {
    return [
        {
            label: 'Photograph the Species:',
            code: 'photoId',
            type: 'photo',
            colSize: 12,
        },
        {
            label: 'What is the species?',
            code: 'browseSpeciesId',
            type: 'species',
            listType: LIST_NATIVE_WOODY,
            required: true,
        },
        {
            label: `% of plants impacted`,
            code: 'browseImpactedSpecies',
            type: 'select',
            options: [
                {id: '<1%', name: '<1%'},
                {id: '1-<10%', name: '1-<10%'},
                {id: '10-<30%', name: '10-<30%'},
                {id: '30+%', name: '30+%'},
            ],
            required: true,
        },
        {
            label: 'Note browsing animals',
            code: 'browseAnimalNote',
            type: 'text',
        },
    ];
};

export const useCompetitionFields = () => {
    return [
        {
            label: 'Species',
            code: 'competitionSpeciesId',
            type: 'species',
            listType: LIST_PROBLEMATIC,
            required: true,
        },
        {
            label: 'Estimated % Cover',
            code: 'competitionEstimatedCover',
            type: 'select',
            options: [
                {id: '<1%', name: '<1%'},
                {id: '1-<10%', name: '1-<10%'},
                {id: '10-<30%', name: '10-<30%'},
                {id: '30+%', name: '30+%'},
            ],
            required: true,
        },
        {
            label: 'Life Stage',
            code: 'competitionLifeStage',
            type: 'select',
            options: competitionLifeStageOptions,
            required: true,
        },
    ];
};

export const useConditionFields = () => {
    const validateLatitude = useValidateLatitude();
    const validateLongitude = useValidateLongitude();

    return (code) => {
        return [
            {
                label: 'Photograph the area:',
                code: 'photoId',
                type: 'photo',
                colSize: 12,
            },
            {
                label: <span>Latitude &#176; N</span>,
                code: 'latitude',
                type: 'number',
                validationMessage: (value) => validateLatitude(value),
                required: true,
            },
            {
                label: <span>Longitude &#176; E</span>,
                code: 'longitude',
                type: 'number',
                validationMessage: (value) => validateLongitude(value),
                required: true,
            },
            {
                label: `Estimated area impacted`,
                code: `${code}Impacted`,
                type: 'select',
                options: [
                    {id: '<1%', name: '<1%'},
                    {id: '1-<10%', name: '1-<10%'},
                    {id: '10-<25%', name: '10-<25%'},
                    {id: '25-<50%', name: '25-<50%'},
                    {id: '50-<75%', name: '50-<75%'},
                    {id: '75+%', name: '75+%'},
                ],
                required: true,
            },
        ];
    };
};

export const useHealthFields = () => {
    return [
        {
            label: 'What is the species?',
            code: 'healthSpeciesId',
            type: 'species',
            listType: LIST_NATIVE_WOODY,
            required: true,
        },
        {
            label: 'Describe the suspected cause',
            code: 'healthSpeciesCause',
            type: 'text',
        },
    ];
};

export const useInsectFields = () => {
    return (code) => {
        const insectsII = (code !== 'insects');

        let fields = [
            {
                label: 'Photograph the insect, disease, or evidence thereof:',
                code: 'photoId',
                type: 'photo',
                colSize: 12,
            },
            {
                label: 'Impact type',
                code: 'insectsImpactType',
                type: 'select',
                options: insectsImpactTypeOptions,
                required: true,
            },
            {
                label: 'Was the ' + (insectsII ? 'affected species' : 'species of insect or disease') + ' identified?',
                code: 'insectsImpactCausing',
                type: 'radio',
                options: insectsImpactCausingOptions,
                required: true,
            },
            // insectsII question gets spliced here (see below)
            {
                label: 'Describe the insect or disease',
                code: 'insectsOrDiseasesDescription',
                enabled: (insectsII ? null : 'insectsImpactCausing'),
                type: 'text',
                required: true,
            },
        ];

        if(insectsII) {
            fields[0].label = 'Photograph the impacted plant species:';
            fields.splice(3, 0, {
                label: 'What is the species?',
                code: 'insectsSpeciesId',
                type: 'species',
                listType: LIST_NATIVE_WOODY,
                enabled: 'insectsImpactCausing',
                required: true,
            });
        }

        return fields;
    }
};

export const usePerformanceFields = () => {
    return [
        {
            label: 'What is the species?',
            code: 'performanceSpeciesId',
            type: 'species',
            listType: LIST_NATIVE_OR_UNKNOWN_WOODY,
        },
    ];
};

export const useSoilFields = () => {
    const validateLatitude = useValidateLatitude();
    const validateLongitude = useValidateLongitude();

    return [
        {
            label: <span>Latitude &#176; N</span>,
            code: 'latitude',
            type: 'number',
            validationMessage: (value) => validateLatitude(value),
            required: true,
        },
        {
            label: <span>Longitude &#176; E</span>,
            code: 'longitude',
            type: 'number',
            validationMessage: (value) => validateLongitude(value),
            required: true,
        },
        {
            label: 'Possible Reasons',
            code: 'soilsReasonsNote',
            type: 'text',
            required: true,
        },
    ];
};
