export const CONTEXT_TOOL_ACCOUNT = 'account';
export const CONTEXT_TOOL_PHOTO = 'photo';
export const CONTEXT_TOOL_GLOBE = 'globe';
export const CONTEXT_TOOL_BOOK = 'book';

export const contextTools = [
    {
        "id": 1,
        "code": CONTEXT_TOOL_ACCOUNT,
        "icon": [
            "fal",
            "user"
        ],
        "action": () => console.log('tool clicked')
    },
    {
        "id": 2,
        "code": CONTEXT_TOOL_PHOTO,
        "icon": [
            "fal",
            "camera"
        ],
        "action": () => console.log('tool clicked')
    },
    {
        "id": 3,
        "code": CONTEXT_TOOL_GLOBE,
        "icon": [
            "fal",
            "globe"
        ],
        "action": () => console.log('tool clicked')
    },
    {
        "id": 4,
        "code": CONTEXT_TOOL_BOOK,
        "icon": [
            "fal",
            "book"
        ],
        "action": () => console.log('tool clicked')
    },
];

export const eventTools = [
    {
        "id": 1,
        "title": "Quantitative Riparian Vegetation",
        "progress": 100,
        "code": "transect",
        "icon": [
            "fal",
            "tree-alt"
        ]
    },
    {
        "id": 2,
        "title": "Photo Point",
        "progress": 75,
        "code": "photo",
        "icon": [
            "fal",
            "camera"
        ]
    },
    {
        "id": 3,
        "title": "Instream Shade",
        "code": "instream-shade",
        "icon": [
            "fal",
            "sun"
        ]
    },
    {
        "id": 4,
        "title": "Qualitative Riparian Vegetation",
        "code": "qual",
        "icon": [
            "fal",
            "clipboard-list"
        ]
    },
];

export const quantTools = [
    {
        "id": 1,
        "title": "Transect Narrative",
        "code": "transect-narrative",
        "icon": [
            "fal",
            "clipboard-list-check"
        ],
    },
    {
        "id": 2,
        "title": "Plot 1",
        "code": "plot1",
        "icon": [
            "fal",
            "chart-scatter"
        ],
        "required": true,
        "hideFooter": true,
    },
    {
        "id": 3,
        "title": "Plot 2",
        "code": "plot2",
        "icon": [
            "fal",
            "chart-scatter"
        ],
        "required": true,
        "hideFooter": true,
    },
    {
        "id": 4,
        "title": "Invasive / Ground Cover",
        "shortName": "Invasive / Ground",
        "code": "invasive-cover",
        "icon": [
            "fal",
            "chart-scatter"
        ],
        "required": true,
        "hideFooter": true,
    },
    {
        "id": 5,
        "title": "Canopy Closure",
        "code": "canopy",
        "icon": [
            "fal",
            "globe"
        ],
        "required": true,
    },
    {
        "id": 6,
        "title": "Origin & End Photos",
        "code": "origin-end-photo",
        "icon": [
            "fal",
            "camera"
        ],
    },
    {
        "id": 7,
        "title": "Instream Shade",
        "code": "instream-shade",
        "icon": [
            "fal",
            "sun"
        ],
    },
    {
        "id": 10,
        "title": "Transect Review",
        "code": "transect-review",
        "hide": true,
        "className": "rust"
    },
];

export const qualTools = [
    {
        "id": 4,
        "title": "Previous",
        "code": "qual-veg",
        "hide": true,
        "hideNav": true,
    },
    {
        "id": 5,
        "title": "Vegetation",
        "code": "vegetation",
    },
    {
        "id": 6,
        "title": "Wildlife & Environment",
        "code": "wildlife-environment",
    },
    {
        "id": 7,
        "title": "Management Survey",
        "code": "management-survey",
    },
    {
        "id": 8,
        "title": "Review and Finalize",
        "code": "review",
        "hide": true,
    },
];
