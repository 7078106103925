import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom'
import {Row, Col, Button, FormCheck} from 'react-bootstrap';

import PageContent from '../../Layout/Page/PageContent';
import Title from '../../Layout/TitleComponent';
import PageContainer from '../../Layout/Page/PageContainer';
import HydrozoneSpeciesList from "./HydrozoneSpeciesList";
import LengthCategoryUtils from "../../../Utils/LengthCategoryUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageFooter from "../../Layout/Page/PageFooter";
import SpeciesPickerModal from "../../Common/SpeciesPickerModal";
import NotesModal from "../../Common/NotesModal";
import {useQuantVegNav} from "../../../Hooks/QuickNavHooks";
import {useHydrozoneName} from '../../../Hooks/LabelHooks';
import FooterNav from "../../Layout/Page/FooterNav";
import PageHeader from "../../Layout/Page/PageHeader";
import {selectTransect} from "../../../Redux/Selectors/transectSelectors";
import {selectSampleEventById, selectAllSpecies} from "../../../Redux/Selectors/nodeSelectors";
import {
    UPSERT_TRANSECT,
    UPSERT_SAMPLE_EVENT
} from "../../../Redux/Actions/Types/offlineDataActionTypes";
import KeyboardComponent from "./KeyboardComponent";
import {
    CLEAR_SELECTED_INPUT,
    SET_CURRENT_DATA,
    SET_REPLACE,
    SET_SELECTED_INPUT, SET_SELECTED_SPECIES
} from "../../../Redux/Actions/Types/transectActionTypes";
import {OPEN_SPECIES_PICKER} from "../../../Redux/Actions/Types/transectActionTypes";
import DuplicateSpeciesModal from "./DuplicateSpeciesModal";
import {updateHydrozone} from "../../../Redux/Actions/transectActions";
import {TYPE_INVASIVE_COVER} from "../../../Constants/lengthCategories";
import FormInput from "../../Common/FormInput";
import CircledIcon from "../../CompositeIcons/CircledIcon";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import {useInvasiveCoverValidationErrors} from "../../../Hooks/ValidationHooks";
import {useContextToolsBySampleEventId} from "../../../Hooks/ToolHooks";
import {useReadOnly} from "../../../Hooks/FormHooks";
import {useFinalized} from "../../../Hooks/DataHooks";
import ConfirmSaveModal from "../../Common/ConfirmSaveModal";
import {LIST_INVASIVE_GROUND_COVER} from "../../../Constants/species";

const InvasiveGroundCoverPage = (props) => {
    const params = useParams();
    const dispatch = useDispatch();
    const getHydrozoneName = useHydrozoneName();
    const validationErrors = useInvasiveCoverValidationErrors();

    useQuantVegNav(params.transectId);

    const transect = useSelector(state => selectTransect(state, params.transectId));
    const sampleEvent = useSelector(state => selectSampleEventById(state, transect.sampleEventId));
    const species = useSelector(state => selectAllSpecies(state, transect.projectId));

    useBreadcrumbs('Invasive / Ground', transect.projectId, transect.sampleEventId, transect.transectId);
    useContextToolsBySampleEventId(transect.sampleEventId);

    const finalized = useFinalized(transect.sampleEventId);
    useReadOnly(finalized);

    const [showNotes, setShowNotes] = useState(false);
    const [showRandomizeWarning, setShowRandomizeWarning] = useState(false);

    const hydrozone = transect.Hydrozones.find(hydrozone => String(hydrozone.hydrozoneTypeId) === String(params.hydrozoneTypeId));
    const invasiveGroundSpecies = hydrozone.HydrozoneGroundCoverSpecies;
    const hydrozoneName = getHydrozoneName(params.hydrozoneTypeId);
    const hydrozoneTypeClass = `type-${hydrozoneName.toLowerCase()}`;
    const categories = LengthCategoryUtils.GetLengthCategoryByType(TYPE_INVASIVE_COVER);
    // Invasive species or herbaceous type

    useEffect(() => {
        dispatch({
            type: SET_CURRENT_DATA,
            currentTransect: params.transectId,
            currentHydrozone: hydrozone.hydrozoneId,
        });

        initializeNoRoom();
    }, [params.transectId, hydrozone.hydrozoneId]);

    const initializeNoRoom = () => {
        if (
            hydrozone.hasNoRoomForQuadrat === null &&
            (hydrozone.end - hydrozone.start) < 3
        ) {
            dispatch(updateHydrozone('hasNoRoomForQuadrat', true));
        }
    };

    const randomize = (randomizeAnyways = false) => {
        if (!randomizeAnyways) {
            if (hydrozone.leftQuadratStart || hydrozone.rightQuadratStart) {
                setShowRandomizeWarning(true);
                return;
            }
        }

        if (!hydrozone.hasNoRoomForQuadrat && !finalized) {
            dispatch(updateHydrozone('leftQuadratStart', getRandomQuadratNumber()));
            dispatch(updateHydrozone('rightQuadratStart', getRandomQuadratNumber()));
        }
    };

    const selectQuadratField = (code) => {
        dispatch({
            type: SET_SELECTED_INPUT,
            selectedInput: hydrozone,
            selectedField: code,
        });
        dispatch({type: SET_REPLACE, setReplace: true});
    };

    const getRandomQuadratNumber = () => {
        const range = (Number(hydrozone.end) - (Number(hydrozone.start) + 3));
        if(range < 1) {
            dispatch(updateHydrozone('hasNoRoomForQuadrat', true));
        }
        return Math.round(Math.random() * range) + Number(hydrozone.start);
    };

    return (
        <Fragment>
            <SpeciesPickerModal
                projectId={transect.projectId}
                listType={LIST_INVASIVE_GROUND_COVER}
                type={TYPE_INVASIVE_COVER}
                hydrozoneSpecies={invasiveGroundSpecies}
            />
            <ConfirmSaveModal
                show={showRandomizeWarning}
                title="Confirm Randomize"
                message="Quadrat locations for this Hydrozone have already been set. Are you sure you want overwrite the existing locations with new random values?"
                save={() => {
                    randomize(true);
                    setShowRandomizeWarning(false);
                }}
                hideTitle="Cancel"
                saveTitle="Confirm"
                onHide={() => setShowRandomizeWarning(false)}
            />
            <DuplicateSpeciesModal />
            <NotesModal
                title="Notes"
                notes={[
                    {
                        label: 'Transect Notes',
                        value: transect.notes,
                        save: (value) => dispatch({
                            type: UPSERT_TRANSECT,
                            transect: {
                                ...transect,
                                notes: value,
                            }
                        })
                    },
                    {
                        label: 'Site Notes',
                        value: sampleEvent.siteNotes,
                        save: (value) => dispatch({
                            type: UPSERT_SAMPLE_EVENT,
                            sampleEvent: {
                                ...sampleEvent,
                                siteNotes: value,
                            }
                        })
                    }
                ]}
                show={showNotes}
                onHide={() => setShowNotes(false)}
                readOnly={finalized}
            />
            <PageContainer className="invasive-cover-page">
                <PageHeader>
                    <KeyboardComponent type={TYPE_INVASIVE_COVER} readOnly={finalized} />
                    <Title
                        title={`Invasive + Ground Cover`}
                        subtitle={
                            <span className={`subtitle-pill ${hydrozoneTypeClass}`}>
                                {hydrozoneName}: {hydrozone.start} Ft - {hydrozone.end} Ft
                            </span>
                        }
                        action={
                            <div className="no-plants-field">
                                <FormCheck
                                    id="no-plants-checkbox"
                                    label="Not enough room for quadrat in this hydrozone"
                                    type="checkbox"
                                    value={hydrozone.hasNoRoomForQuadrat ?? false}
                                    checked={hydrozone.hasNoRoomForQuadrat}
                                    onChange={() => !finalized && dispatch(updateHydrozone('hasNoRoomForQuadrat', !hydrozone.hasNoRoomForQuadrat))}
                                />
                            </div>
                        }
                        readOnly={finalized}
                    />
                </PageHeader>
                <PageContent>
                    <Row xs={12}>
                        <Col xs={3}>
                            <FormInput
                                label="Left Quadrat Start"
                                type="text"
                                className="quadrat-field"
                                value={hydrozone.hasNoRoomForQuadrat ? "N/A" : hydrozone.leftQuadratStart ?? ''}
                                onFocus={() => selectQuadratField('leftQuadratStart')}
                                onBlur={() => dispatch({type: CLEAR_SELECTED_INPUT})}
                                disabled={hydrozone.hasNoRoomForQuadrat}
                                readOnly
                            />
                        </Col>
                        <Col xs={3}>
                            <FormInput
                                label="Right Quadrat Start"
                                type="text"
                                className="quadrat-field"
                                value={hydrozone.hasNoRoomForQuadrat ? "N/A" : hydrozone.rightQuadratStart ?? ''}
                                onFocus={() => selectQuadratField('rightQuadratStart')}
                                onBlur={() => dispatch({type: CLEAR_SELECTED_INPUT})}
                                disabled={hydrozone.hasNoRoomForQuadrat}
                                readOnly
                            />
                            <CircledIcon
                                icon={['fal', 'dice']}
                                className="btn-random"
                                circleSize="12"
                                iconTransform="shrink-2"
                                onClick={() => randomize()}
                            />
                        </Col>
                        <Col xs={6} className="validation-errors">
                            {
                                validationErrors(categories, invasiveGroundSpecies, hydrozone).map((error,i) => (
                                    <span key={i}>{error}</span>
                                ))
                            }
                        </Col>
                        <Col xs={12}>
                            <HydrozoneSpeciesList
                                species={species}
                                hydrozoneSpecies={!hydrozone.hasNoRoomForQuadrat ? invasiveGroundSpecies : []}
                                categories={categories}
                                type={TYPE_INVASIVE_COVER}
                                disabled={hydrozone.hasNoRoomForQuadrat || finalized}
                            />
                            {
                                !finalized &&
                                    <Button
                                        className="w-100"
                                        variant="secondary"
                                        onClick={() => {
                                            dispatch({type: SET_SELECTED_SPECIES, speciesId: null});
                                            dispatch({type: OPEN_SPECIES_PICKER});
                                        }}
                                        disabled={hydrozone.hasNoRoomForQuadrat}
                            >
                                        <FontAwesomeIcon icon={["fal", "plus"]} />
                                        Add Species
                                    </Button>
                            }
                        </Col>
                    </Row>
                </PageContent>
                <PageFooter>
                    <FooterNav>
                        <Button variant="primary" onClick={() => setShowNotes(true)}>
                            <FontAwesomeIcon icon={['fal', 'sticky-note']} />
                            View Notes
                        </Button>
                    </FooterNav>
                </PageFooter>
            </PageContainer>
        </Fragment>
    );
};

export default InvasiveGroundCoverPage;
