import AuthService from "./Core/AuthService";

import Config from "../Config";

class StreamBankAuthService {
    Logout() {
        return AuthService.Logout();
    }
}

const service = new StreamBankAuthService();
export default service;
