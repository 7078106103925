import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { ModalBody, ModalFooter, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {useHasChanges} from "../../../Hooks/AuthHooks";
import {
    OFFLINE_STATUS_DO_NOT_SYNC,
    OFFLINE_STATUS_READY_FOR_UPLOAD
} from "../../../Constants/status";
import {deleteHierarchyNode} from "../../../Redux/Actions/downloadActions";

import Modal from '../../Common/Modal';
import {SET_DELETED_NODE} from "../../../Redux/Actions/Types/hierarchyActionTypes";

const DeleteNodeModal = props => {
    const dispatch = useDispatch();
    const hasChanges = useHasChanges();

    const deletedNode = useSelector(state => state.hierarchyNodeState.deletedNode);
    const isOnline = useSelector(state => state.appStatusState.online);
    const offlineMode = useSelector(state => state.appStatusState.offlineMode);

    const showModal = (deletedNode != null)
    const [showUnSyncedChangesDoubleConfirmation, setShowUnSyncedChangesDoubleConfirmation] = useState(false);

    useEffect(() => {
        setShowUnSyncedChangesDoubleConfirmation(false);
    }, [deletedNode?.hierarchyId]);

    const getTitle = () => {
         if(showUnSyncedChangesDoubleConfirmation) {
             return "Discard Collected Data"
         }
         return "Remove On-Device Data";
    };

    const getMessage = () => {
        if (showUnSyncedChangesDoubleConfirmation) {
            return (
                <Fragment>
                    <span>The following items have changes that have not been synced. Are you sure you want to permanently discard all of these changes?</span>
                    <div className="node-list">{getHierarchyList([deletedNode], true)}</div>
                </Fragment>
            )
        }

        if (!isOnline || offlineMode) {
            return (
                <Fragment>
                    <span>You are currently offline. If you remove on-device data, you will not be able to download it again until you have an Internet connection.<br/><br/>Are you sure you want to remove on-device data for the following items?</span>
                    <div className="node-list">{getHierarchyList([deletedNode])}</div>
                </Fragment>
            )
        }

        return (
            <Fragment>
                <span>Are you sure you want to remove on-device data for the following items?</span>
                <div className="node-list"><ol>{getHierarchyList([deletedNode])}</ol></div>
            </Fragment>
        );
    };

    const getHierarchyList = (nodes, onlyChanges = false) => {
        if (!nodes.length || !deletedNode) {
            return [];
        }

        return nodes.filter(node => node.offlineState !== OFFLINE_STATUS_DO_NOT_SYNC)
            .map(node => {
                const childHierarchyList = node?.children?.length ? getHierarchyList(node.children, onlyChanges) : [];

                if(onlyChanges && (childHierarchyList.length === 0) && (node.offlineState !== OFFLINE_STATUS_READY_FOR_UPLOAD)) {
                    return null;
                }

                return <li key={node.hierarchyId}>
                    {node.fullName ?? node.name}
                    {
                        childHierarchyList.length ? <ol>{childHierarchyList}</ol> : null
                    }
                </li>
            }).filter(node => !!node);
    };

    const onDeleteClick = () => {
        const requiresDoubleConfirmation = hasChanges && getHierarchyList([deletedNode], true).length;
        if (!showUnSyncedChangesDoubleConfirmation && requiresDoubleConfirmation) {
            setShowUnSyncedChangesDoubleConfirmation(true);
        } else {
            deleteNode();
        }
    };

    const deleteNode = () => {
        dispatch(deleteHierarchyNode(deletedNode));
        onHide();
    };

    const onHide = () => {
        dispatch({type: SET_DELETED_NODE, deletedNode: null});
    };

    return (
        <Modal
            title={getTitle()}
            size="md"
            show={showModal}
            onHide={() => onHide()}
        >
            <ModalBody>
                <Row xs={12}>
                    <Col xs={12}>
                        <span>
                            {getMessage()}
                        </span>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button variant="link" className="ml-3" onClick={() => onHide()}>
                    <FontAwesomeIcon icon={['fal', 'times']} />
                    Cancel
                </Button>
                <Button variant="danger" className={showUnSyncedChangesDoubleConfirmation ? "w-50" : "w-25"} onClick={() => onDeleteClick()}>
                    <FontAwesomeIcon icon={['fal', 'trash-alt']} />
                    {showUnSyncedChangesDoubleConfirmation ? "Permanently Delete Data" : "Remove"}
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default DeleteNodeModal;
