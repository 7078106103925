import {ENVIRONMENT} from "../../Constants";
import {RESTORE_BACKUP, UPSERT_PHOTO} from "./Types/offlineDataActionTypes";
import {TEMP_ID_PREFIX} from "../../Constants/misc";
import HistoryUtils from "../../Utils/HistoryUtils";
import Config from "../../Config";
import {addPhotoContent} from "./photoActions";
import Base64Utils from "../../Utils/Base64Utils";
import userReducer from "../Reducers/userReducer";
import loginReducer from "../Reducers/loginReducer";
import contextReducer from "../Reducers/contextReducer";
import ajaxReducer from "../Reducers/ajaxReducer";
import errorReducer from "../Reducers/errorReducer";
import toolReducer from "../Reducers/toolReducer";
import hierarchyNodeReducer from "../Reducers/hierarchyReducer";
import appStatusReducer from "../Reducers/appStatusReducer";
import offlineDataReducer from "../Reducers/offlineDataReducer";
import transectReducer from "../Reducers/transectReducer";
import syncReducer from "../Reducers/syncReducer";
import {SET_CONNECTION} from "./Types/appStatusTypes";

// this hook restores a backups/restore.json if the
// file exists on the local server.
export const restoreBackup = (filename = '/backups/restore.json') => async (dispatch, getState) => {
	if (ENVIRONMENT === 'local') {
		try {
			const response = await fetch(filename, {cache: "no-store"});
			console.log(`found backup JSON file: ${filename}`);
			try {
				const pinkImage = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+H/y1H8ACO8Dkks3LTIAAAAASUVORK5CYII=";
				const json = await response.json();
				let data = {};
				const reduxStoreKeys = [
					'userState',
					'loginState',
					'contextState',
					'ajaxState',
					'errorState',
					'toolState',
					'hierarchyNodeState',
					'appStatusState',
					'offlineDataState',
					'transectState',
					'syncState',
				];
				
				for(const key of reduxStoreKeys) {
					if(json.hasOwnProperty(key)) {
						data[key] = JSON.parse(json[key]);
					}
				}

				// convert photo urls and base64 data to
				// appropriate urls for the local client
				if(data?.offlineDataState?.photos ?? null) {
					for (const photo of data?.offlineDataState?.photos) {
						console.log('loading', photo.photoId);						

						if (String(photo.photoId).startsWith(TEMP_ID_PREFIX) && String(photo.src).startsWith('http')) {
							console.log('no backup for', photo.photoId);
							// this means we didn't get the photo backup
							// so let's install a fake pink one
							photo.src = pinkImage;
							photo.thumbnailSrc = null;
						}

						// replace the domain for the current environment since
						// CORS doesn't allow cross origin requests and auth tokens differ
						if (String(photo.src).startsWith('https://api.') || String(photo.thumbnailSrc).startsWith('https://api.')) {
							const newSource = HistoryUtils.ReplaceHostName(String(photo.src), 'api.' + Config.getDomain());
							const newThumbSource = HistoryUtils.ReplaceHostName(String(photo.thumbnailSrc), 'api.' + Config.getDomain());
							console.log('updated url for', photo.photoId, newSource);
							photo.src = newSource;
							photo.thumbnailSrc = newThumbSource;
						}

						// this means we got a backed up photo as a data: format,
						// so we need to convert it and store it
						if (String(photo.src).substring(0, 5) === 'data:') {
							let arrayBuffer = Base64Utils.Base64ToArrayBuffer(photo.src);
							let contentType = Base64Utils.GetBase64ContentType(photo.src);
							let blob = new Blob([arrayBuffer], {type: contentType ?? 'image/jpeg'});
							console.log(`converted ${contentType} blob for`, photo.photoId);
							try {
								let newPhoto = await addPhotoContent(photo.photoId, blob);
								photo.photoId = newPhoto.photoId;
								photo.src = newPhoto.src;
								photo.thumbnailSrc = newPhoto.thumbnailSrc;
								photo.fileExtension = newPhoto.fileExtension;
							} catch (x) {
								console.log(`Failed to restore photoId ${photo.photoId} from base64 data.`, x);
								photo.src = photo.thumbnailSrc = null;
							}
						}
					}
				}

				dispatch({type: RESTORE_BACKUP, data});
				dispatch({type: SET_CONNECTION, online: true});
				
				console.log(`loaded backup JSON file: ${filename}`);
			} catch (e) {
				console.log(`failed to parse ${filename}`, e);
			}
						
		} catch (e) {
			console.log(`failed to load ${filename}`, e);
		}
	}
};
