import React from 'react';
import FormInput from "../FormInput";
import {updateSurveyValue} from "../../../Redux/Actions/surveyActions";
import {useDispatch} from "react-redux";

const SurveySelectInput = props => {
    const dispatch = useDispatch();

    const { value: selectedValue, answerId, children } = props;

    return (
        <div className="survey-select-input">
            <FormInput
                type="select"
                placeholder=""
                value={selectedValue ?? ''}
                options={props.options.values.map(({label, value}) => ({id: value, name: label ?? value}))}
                onChange={(value) => !props.readOnly ? dispatch(updateSurveyValue(answerId, props.code, value)) : null}
                readOnly={props.readOnly}
            />
            { children }
        </div>
    )
};

export default SurveySelectInput;
