import React, {useEffect, useState} from 'react';
import storage from "localforage";

import {Button, Col, ModalBody, ModalFooter, Row} from "react-bootstrap";
import Modal from "../../Common/Modal";
import FormInput from "../../Common/FormInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Base64Utils from "../../../Utils/Base64Utils";
import FileUtils from "../../../Utils/FileUtils";

const MapModal = props => {
    const download = async () => {
        let src = props.src;
        const path = src.split('/api/').pop();
        const blob = await storage.getItem(`/api/${path}`);
        FileUtils.DownloadBlobAsFile(blob, `quick-map`);
    };

    return (
        <Modal
            size="lg"
            title="Quick Map"
            show={props.show}
            onHide={() => props.onHide()}
            className="map-modal"
        >
            <ModalBody>
                <Row xs={12}>
                    <img src={props.src} className="w-100" />
                </Row>
            </ModalBody>
            <ModalFooter>
                {
                    <Button variant="complete" onClick={() => download()}>
                        <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']}/>
                        Save to Device
                    </Button>
                }
            </ModalFooter>
        </Modal>
    )
};

export default MapModal;
