import React, {Fragment, useEffect, useRef, useState} from 'react';
import {ModalBody, Row, Col, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Modal from './Modal';
import FormInput from "./FormInput";
import {useGetUserFacingSpeciesName} from "../../Hooks/LabelHooks";
import {useDispatch, useSelector} from "react-redux";
import {addSpecies, updateSpecies} from "../../Redux/Actions/transectActions";
import {
    CLOSE_SPECIES_PICKER, OPEN_SPECIES_PICKER,
    SET_SELECTED_SPECIES
} from "../../Redux/Actions/Types/transectActionTypes";
import DuplicateSpeciesModal from "../Pages/QuantVegTools/DuplicateSpeciesModal";
import {updateRepeaterItem} from "../../Redux/Actions/surveyActions";
import SpeciesTypeIcon from "../CustomIcons/SpeciesTypeIcon";
import {useNextTempId} from "../../Hooks/TempIdHooks";
import {ADD_PROJECT_SPECIES} from "../../Redux/Actions/Types/offlineDataActionTypes";
import {selectAllSpecies, selectSelectableSpecies} from "../../Redux/Selectors/nodeSelectors";
import {
    LIST_INVASIVE_GROUND_COVER, LIST_NATIVE_OR_UNKNOWN_WOODY,
    LIST_NATIVE_WOODY,
    LIST_PROBLEMATIC,
    LIST_NON_INVASIVE_TREES, LIST_NON_INVASIVE_WOODY
} from "../../Constants/species";

const SpeciesPickerModal = props => {
    const dispatch = useDispatch();
    const nextId = useNextTempId();
    const getUserFacingSpeciesName = useGetUserFacingSpeciesName();

    const allSpecies = useSelector(state => selectAllSpecies(state, props.projectId));
    const selectableSpecies = useSelector(state => selectSelectableSpecies(state, props.projectId));
    
    const {showSpeciesPicker, selectedSpecies, answerId, repeaterId, idColumn, nameColumn, species: speciesListType } = useSelector(state => state.transectState);

    const [search, setSearch] = useState('');
    const [filteredSpecies, setFilteredSpecies] = useState([]);
    const [otherSpecies, setOtherSpecies] = useState('');
    const [otherDuplicate, setOtherDuplicate] = useState(null);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);

    const listType = speciesListType ?? props.listType;

    useEffect(() => {
        setSearch('');
        setOtherSpecies('');
        setOtherDuplicate(null);
        setShowDuplicateModal(false);
        if(!showSpeciesPicker) {
            dispatch({type: SET_SELECTED_SPECIES, speciesId: undefined});
        }
    }, [showSpeciesPicker]);

    useEffect(() => {
        const speciesList = getSpeciesList();
        if (search) {
            setFilteredSpecies(speciesList.filter(item => {
                const term = search.toLowerCase();
                const commonName = item.commonName?.toLowerCase() ?? '';
                const latinName = item.latinName?.toLowerCase() ?? '';
                const alternativeCommonName = item.alternativeCommonName?.toLowerCase() ?? '';
                const alternativeLatinName = item.alternativeLatinName?.toLowerCase() ?? '';
                const otherSearchTerms = item.otherSearchTerms?.toLowerCase() ?? '';

                return commonName.includes(term) || latinName.includes(term)
                    || alternativeLatinName.includes(term) || alternativeCommonName.includes(term)
                    || otherSearchTerms.includes(term);
            }));
        } else {
            setFilteredSpecies(speciesList);
        }
    }, [search, speciesListType, props.listType, props.projectId]);

    useEffect(() => {
        const duplicateSpecies = allSpecies.find(item => 
            item.latinName === otherSpecies || item.commonName === otherSpecies ||
            item?.alternativeLatinName === otherSpecies || item?.alternativeCommonName === otherSpecies
        );
        if (otherSpecies && duplicateSpecies) {
            setOtherDuplicate(duplicateSpecies);
        } else {
            setOtherDuplicate(null);
        }
    }, [otherSpecies]);

    const getSpeciesList = () => {
        const defaultFilter = (item) => (
            (item.speciesTypeId === 5 || item.speciesId === selectedSpecies)
            && item.isHidden !== 1
        );

        switch (listType) {
            case LIST_NON_INVASIVE_WOODY:
                return selectableSpecies.filter(item =>
                    (item.speciesInvasivityId === 0 && item.speciesTypeId < 4) || defaultFilter(item)
                );
            case LIST_NON_INVASIVE_TREES:
                return selectableSpecies.filter(item =>
                    (item.speciesInvasivityId === 0 && item.speciesTypeId === 1) || defaultFilter(item)
                );
            case LIST_INVASIVE_GROUND_COVER:
                return selectableSpecies.filter(item =>
                    // include invasive species herbaceous species
                    // Monique mentioned that we don't include non-invasive
                    // herbaceous species because we won't want people
                    // to spend time documenting that detail.
                    (item.speciesInvasivityId !== 0 && item.speciesTypeId <= 4) ||
                    // include all species that begin with a number for
                    // the generic, native ground cover categories
                    !isNaN(Number(item.latinName.charAt(0))) ||
                    defaultFilter(item)
                );
            case LIST_PROBLEMATIC:
                return selectableSpecies.filter(item => 
                    item.speciesInvasivityId !== 0 ||
                    defaultFilter(item)
                );
            case LIST_NATIVE_OR_UNKNOWN_WOODY:
                return selectableSpecies.filter(item =>
                    ([1, 3].includes(item.speciesNativityId) && item.speciesTypeId < 4) ||
                    defaultFilter(item)
                );
            case LIST_NATIVE_WOODY:
                return selectableSpecies.filter(item =>
                    (item.speciesNativityId === 1 && item.speciesTypeId < 4) ||
                    defaultFilter(item)
                );
            default:
                return [];
        }
    };

    const pickSpecies = (chosenSpecies) => {

        // qualitative vegetation repeaters
        if (answerId && repeaterId) {
            if (idColumn) {
                dispatch(updateRepeaterItem(answerId, repeaterId, idColumn, chosenSpecies.speciesId));
            }
            if (nameColumn) {
                dispatch(updateRepeaterItem(answerId, repeaterId, nameColumn, getUserFacingSpeciesName(chosenSpecies)));
            }
        }

        // todo: refactor this to state
        if (props.hydrozoneSpecies) {
            upsertHydrozoneSpecies(chosenSpecies);
        } else {
            props?.onSelect?.(chosenSpecies);
            onHide();
        }
    };

    const upsertHydrozoneSpecies = (chosenSpecies) => {
        const speciesInUse = props.hydrozoneSpecies
            .filter(species => species.speciesId !== selectedSpecies)
            .map(species => species.speciesId);
        if (!speciesInUse.includes(chosenSpecies.speciesId)) {
            if (selectedSpecies) {
                dispatch(updateSpecies(props.type, chosenSpecies.speciesId, getUserFacingSpeciesName(chosenSpecies)));
            } else {
                dispatch(addSpecies(chosenSpecies.speciesId, getUserFacingSpeciesName(chosenSpecies), props.type));
            }
            onHide();
        } else {
            dispatch({type: CLOSE_SPECIES_PICKER});
            setShowDuplicateModal(true);
        }
    };

    const addOtherSpecies = () => {
        if (otherDuplicate) {
            pickSpecies(otherDuplicate);
        } else {
            const newSpecies = {
                speciesId: nextId(),
                commonName: otherSpecies,
                latinName: otherSpecies,
                speciesName: otherSpecies,
                speciesInvasivityId: 0,
                speciesTypeId: 5,
            };

            dispatch({type: ADD_PROJECT_SPECIES, projectId: props.projectId, newSpecies});
            pickSpecies(newSpecies);
        }
    };

    const onHide = () => {
        dispatch({type: CLOSE_SPECIES_PICKER});
    };

    return (
        <Fragment>
            <DuplicateSpeciesModal
                show={showDuplicateModal}
                onHide={() => {
                    setShowDuplicateModal(false);
                    dispatch({type: OPEN_SPECIES_PICKER});
                }}
            />
            <Modal
                title={`Choose ${listType} Species`}
                size="md"
                show={showSpeciesPicker}
                className="species-picker"
                onHide={() => onHide()}
                centered={false}
            >
                <ModalBody>
                    <Row xs={12}>
                        <Col xs={12}>
                            <FormInput
                                label="Search"
                                value={search}
                                onChange={value => setSearch(value)}
                                suffix={<FontAwesomeIcon icon={['fal', 'times']} onClick={() => {
                                    setSearch('');
                                    setOtherSpecies('');
                                }} />}
                                placeholder="Search Species"
                            />
                        </Col>
                        <Col xs={12} className="species-list">
                            {
                                !!filteredSpecies.length ?
                                    filteredSpecies.map(item => (
                                        <div className="species-item" key={item.speciesId} onClick={() => pickSpecies(item)}>
                                        <span>
                                            <SpeciesTypeIcon className="species-icon" speciesTypeId={item.speciesTypeId} />
                                            {getUserFacingSpeciesName(item)}
                                        </span>
                                            {
                                                item.speciesId === selectedSpecies &&
                                                <span>Selected</span>
                                            }
                                        </div>
                                    )) :
                                    <div className="no-species-container">
                                        <span className="no-species-message pb-2">
                                            No {listType} species found matching '{search}'.
                                        </span>
                                        <FormInput
                                            value={otherSpecies}
                                            onChange={value => setOtherSpecies(value)}
                                            placeholder="Other Species"
                                        />
                                        {
                                            otherDuplicate &&
                                                <div className="duplicate-warning">
                                                    This species already exists, but is not listed as the appropriate species type for this field. Use it anyway?
                                                </div>
                                        }
                                        <div>
                                            <Button variant="complete" onClick={() => addOtherSpecies()} disabled={!otherSpecies}>
                                                
                                                { otherDuplicate ? 
                                                    <Fragment>
                                                        <FontAwesomeIcon icon={['fal', 'check']} />
                                                        Confirm Species
                                                    </Fragment> :
                                                    <Fragment>
                                                        <FontAwesomeIcon icon={['fal', 'plus']} />    
                                                        Confirm New Species
                                                    </Fragment>
                                                }
                                            </Button>
                                        </div>
                                    </div>
                            }
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
};

export default SpeciesPickerModal;
