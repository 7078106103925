import React, {useEffect, useRef} from 'react';
import {FormGroup, FormLabel, FormControl, InputGroup, FormCheck, Popover, OverlayTrigger} from 'react-bootstrap';

import GuidUtils from "../../Utils/GuidUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Select from "react-select";

const FormInput = props => {

    const innerRef = props.forwardedRef ?? useRef();
    useEffect(() => {
        if(props.autoFocus) {
            innerRef.current?.focus();
        }
    }, [innerRef]);
    
    const onChange = (value) => {
        props.onChange ? props.onChange(value) : null;
    };

    const onClick = () => {
        props.onClick ? props.onClick() : null;
    };

    const onFocus = () => {
        props.onFocus ? props.onFocus() : null;
    };

    const onBlur = () => {
        props.onBlur ? props.onBlur() : null;
    };
    

    const renderFormControl = () => {
        let input;

        switch (props.type) {
            case 'select':
                const primaryKey = props.primaryKey || 'id';
                const currentValue = String(props.value ?? ''); // normal the current value to a string
                
                // ensure there is an option for the placeholder
                let options = props.placeholder !== null && props.placeholder !== undefined ? [
                    {[primaryKey]: '', name: props.placeholder},
                    ...props.options
                ] : props.options;

                // ensure there is an option for the currently selected value
                if(!options.find(o => String(o[primaryKey] ?? '') === currentValue)) {
                    options = [...options, {[primaryKey]: props.value, name: currentValue, disabled:true}];
                }
                
                // restructure options for react-select component
                options = options.map( o => ({
                   "value": String(o[primaryKey] ?? ''),
                   "label": o.name,
                   "disabled": o.disabled,
                }));
                
                // style react-select component to better match our app
                const styles = {
                    container: (provided) => ({
                        ...provided, 
                    }),
                    control: (provided) => {
                        return {
                            ...provided,
                            '&:hover': null,
                            height: '32px',
                            minHeight: '32px',
                            borderRadius: '5px',
                            boxShadow: null, //$input-border-color;
                            borderColor: '#ced4da', //$input-border-color;
                        };
                    },
                    valueContainer: (provided) => {
                        return {
                            ...provided,
                            padding: '2px 6px',
                        };
                    },
                    dropdownIndicator: (provided) => ({
                        ...provided,
                        padding: '4px 4px 4px 0px',
                        "svg": {
                            width: 14,
                            height: 14,
                        }
                    }),
                    menu: (provided) => ({
                        ...provided,
                        marginTop: '0px',
                        marginBottom: '2px',
                    }),
                    menuList: (provided) => ({
                        ...provided,
                        borderRadius: '5px',
                        paddingTop: '0',
                        paddingBottom: '0',
                    }),
                    indicatorSeparator: () => ({display: "none"}),
                    ...(props.readOnly ? {
                            dropdownIndicator: () => ({display: "none"})
                        } : {})
                };
                
                // react-select expects an option object, not the raw value
                const selectedOption = options.find(o => o.value === currentValue);
                
                input = (
                    <Select
                        className={props.readOnly ? "read-only react-select" : "react-select"}
                        ref={innerRef}
                        as={props.type}

                        value={selectedOption}
                        onChange={(v) => onChange(v?.value)}
                        onClick={() => onClick()}
                        onFocus={() => onFocus()}
                        onBlur={(e) => onBlur(e)}
                        isDisabled={props.disabled}
                        
                        // prevent onBlue from firing before onChange (which breaks commitChanges call)
                        blurInputOnSelect={false}
                        
                        // prevent keyboard from popping up (on iPad)
                        isSearchable={false}
                        
                        // fix for line-height of empty options (make it same as other options)
                        getOptionLabel={(option) => option.label === '' ? <span>&nbsp;</span> : option.label}
                        
                        // hack readOnly behavior
                        styles={styles}
                        menuIsOpen={props.readOnly ? false : undefined }
                        onMenuOpen={props.readOnly ? () => { innerRef.current?.blur(); } : undefined }
                        
                        options={options}
                    />
                )
                
                // TODO: This is the old code, which we should use again if Apple Safari
                // ever fixes the issue with their select drop-downs on iPadOS (See: SB-2309)
                // input = (
                //     <FormControl
                //         ref={innerRef}
                //         as={props.type}
                //         value={props.value}
                //         onChange={e => onChange(e.target.value)}
                //         onClick={() => onClick()}
                //         onFocus={() => onFocus()}
                //         onBlur={() => onBlur()}
                //         disabled={props.disabled}
                //         readOnly={props.readOnly}
                //         custom
                //     >
                //         {options.map(option => (
                //             <option key={option[primaryKey]} value={option[primaryKey]} disabled={option.disabled ?? null}>{option.name}</option>
                //         ))}
                //     </FormControl>
                // );
                break;
            case 'textarea':
                input = (
                    <FormControl
                        ref={innerRef}
                        as={props.type}
                        rows={props.rows || 5}
                        value={props.value ?? ''}
                        onChange={e => onChange(e.target.value)}
                        onClick={() => onClick()}
                        onFocus={() => onFocus()}
                        onBlur={() => onBlur()}
                        placeholder={props.placeholder}
                        disabled={props.disabled}
                        readOnly={props.readOnly}
                        autoFocus={props.autoFocus}
                    />
                );
                break;
            case 'radio':
                input = (
                    <div>
                        {
                            props.options.map(option => (
                                <FormCheck
                                    id={((props?.id + '_') ??'')+option.value}
                                    key={option.value}
                                    label={option.name ?? option.label}
                                    type="radio"
                                    checked={option.value === props.value}
                                    onChange={() => onChange(option.value)}
                                    inline
                                />
                            ))
                        }
                    </div>
                );
                break;
            case 'number':
                input = (
                    <FormControl
                        ref={innerRef}
                        type={props.type}
                        value={props.value}
                        onChange={e => onChange(e.target.value === '' ? '' : Number(e.target.value))}
                        onClick={() => onClick()}
                        onFocus={() => onFocus()}
                        onBlur={() => onBlur()}
                        placeholder={props.placeholder}
                        disabled={props.disabled}
                        readOnly={props.readOnly}
                        autoFocus={props.autoFocus}
                    />
                );
                break;
            default:
                input = (
                    <FormControl
                        ref={innerRef}
                        type={props.type}
                        value={props.value}
                        onChange={e => onChange(e.target.value)}
                        onClick={() => onClick()}
                        onFocus={() => onFocus()}
                        onBlur={() => onBlur()}
                        placeholder={props.placeholder}
                        disabled={props.disabled}
                        readOnly={props.readOnly}
                        autoFocus={props.autoFocus}
                    />
                );
        }

        if (props.prefix || props.suffix) {
            return (
                <InputGroup className={`${props.prefix ? 'has-prefix' : ''} ${props.suffix ? 'has-suffix' : ''}`}>
                    {
                        props.prefix &&
                        <InputGroup.Prepend>
                            <InputGroup.Text>{props.prefix}</InputGroup.Text>
                        </InputGroup.Prepend>
                    }
                    {input}
                    {
                        props.suffix &&
                        <InputGroup.Append>
                            <InputGroup.Text>{props.suffix}</InputGroup.Text>
                        </InputGroup.Append>
                    }
                </InputGroup>
            )
        }

        return input;
    };

    const renderErrorMessage = () => {
        if (!props.validationMessage) {
            return null;
        }

        return (
            <FormControl.Feedback type="invalid">
                <span className="font-weight-bold mr-1">
                    <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className="mr-1" />
                    Error:
                </span>
                <span>{props.validationMessage}</span>
            </FormControl.Feedback>
        )
    };

    return (
        <FormGroup
            controlId={GuidUtils.Generate()}
            className={`${props.required ? 'required' : ''} ${props.className || ''} ${props.validationMessage ? 'danger' : ''}`}
        >
            {props.label && <FormLabel>{props.label}</FormLabel>}
            {props.overlayIcon ? props.overlayIcon : null}
            {renderFormControl()}
            {renderErrorMessage()}
        </FormGroup>
    )
};

export default FormInput;
