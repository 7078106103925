import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import BaseService from "../Services/Core/BaseService";

export const useCheckAuth = () => {
    const user = useSelector(state => state.userState.user);
    const {partialLogin, offlineMode} = useSelector(state => state.appStatusState);
    const history = useHistory();

    const loginPath = '/login';

    useEffect(() => {
        if((user === null || user?.twoFactorRequired) && !offlineMode) {
            if(window.location.pathname.toLowerCase() !== loginPath) {
                history.push(loginPath);
            }
        }
    }, [user, offlineMode]);
    
    useEffect(() => {
        if(partialLogin) {
            if(window.location.pathname.toLowerCase() !== loginPath) {
                history.push(loginPath);
            }
        }
    }, [partialLogin])
    
};

export const useHasChanges = () => {
    const {projects, sampleEvents} = useSelector(state => state.offlineDataState);
    const modifiedProject = projects.find(project => project.offlineModifiedDate);
    const modifiedEvent = sampleEvents.find(sampleEvent => sampleEvent.offlineModifiedDate);
    
    // TODO: should we check for modified photos?
    // might it be possible that we made changes to photos
    // without modifying an event or project?
    // probably not, but it might be possible that we synced
    // the project and event and failed to sync the photos
    // let's verify that the project/event would retain it's
    // offlineModifiedData in this case -- I kind of think it doesn't...
    // we could check with the photoShouldBeUploaded method here?
    
    const hasChanges = modifiedProject || modifiedEvent;

    return !!hasChanges;
};
