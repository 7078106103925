/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import DateUtils from "./DateUtils";
import {HIERARCHY_TYPE_PROGRAM, HIERARCHY_TYPE_SAMPLE_EVENT} from "../Constants/hierarchy";
import ArrayUtils from "./ArrayUtils";

export const sortByProperty = (propertyName, defaultValue = 0) =>
	(a, b) => (a?.[propertyName] ?? defaultValue) - (b?.[propertyName] ?? defaultValue);

export const sortByHierarchyTypeId = sortByProperty('hierarchyTypeId');
// these compute to 0 if the hierarchyNode doesn't have
// a sampleEventPhaseId, startDate or procedureId
export const sortBySampleEventPhaseId = sortByProperty('sampleEventPhaseId');
export const sortByStartDateDesc = (a, b) => {
	if(a?.hierarchyTypeId !== HIERARCHY_TYPE_SAMPLE_EVENT) {
		return 0;
	}
	return DateUtils.CompareDesc(a?.sampleEventStartDate, b?.sampleEventStartDate);
}

export const sortByStartDateAsc = (a, b) => {
	if(a?.hierarchyTypeId !== HIERARCHY_TYPE_SAMPLE_EVENT) {
		return 0;
	}
	return DateUtils.CompareAsc(a?.sampleEventStartDate, b?.sampleEventStartDate);
}

export const sortByProcedureId = sortByProperty('procedureId');

export const sortByHierarchyNodeName = (a, b) => {
	let userFacingNameA = a?.shortName, userFacingNameB = b?.shortName;

	// Programs are sorted by name, everything under programs sorted by shortName
	// This is corresponding to how they are displayed on the offline data page
	if (a.hierarchyTypeId === HIERARCHY_TYPE_PROGRAM) {
		userFacingNameA = a?.name ?? '';
		userFacingNameB = b?.name ?? '';
	}
	return userFacingNameA?.localeCompare(userFacingNameB, 'en', {numeric: true});
}

const sampleEventSortCriteria = [
	sortBySampleEventPhaseId,
	sortByStartDateDesc,
	sortByProcedureId
];
export const sortSampleEvents = (sampleEvents) =>
	ArrayUtils.SortByMultipleCriteria(sampleEvents, sampleEventSortCriteria);

const hierarchyNodeSortCriteria = [
	sortByHierarchyTypeId,
	sortBySampleEventPhaseId,
	sortByStartDateAsc,
	sortByProcedureId,
	sortByHierarchyNodeName
];
export const sortHierarchyNodes = (hierarchyNodes) =>
	ArrayUtils.SortByMultipleCriteria(hierarchyNodes ?? [], hierarchyNodeSortCriteria);