import {SET_BREADCRUMBS, SET_CONTEXT, SET_TOOLBARS, SET_URL} from "../Actions/Types/contextActionTypes";
import {USER_LOGOUT} from "../Actions/Types/userActionTypes";
import {RESTORE_BACKUP} from "../Actions/Types/offlineDataActionTypes";

const initialState = {
    context: {},
    breadcrumbs: [],
    toolbars: [],
    url: null,
};

const contextReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONTEXT:
            return {
                ...state,
                context: action.context,
            };
        case SET_BREADCRUMBS:
            return {
                ...state,
                breadcrumbs: action.breadcrumbs,
            };
        case SET_TOOLBARS:
            return {
                ...state,
                toolbars: action.toolbars,
            };
        case SET_URL:
            return {
                ...state,
                url: action.url,
            };
        case USER_LOGOUT:
            return initialState;
        case RESTORE_BACKUP:
            return action.data.contextState;
        default:
            return state;
    }
};

export default contextReducer;
