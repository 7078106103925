import React from 'react';
import {updateSurveyValue} from "../../../Redux/Actions/surveyActions";
import {useDispatch} from "react-redux";
import CheckboxList from "../CheckboxList";

const SurveyCheckboxListInput = props => {
    const dispatch = useDispatch();

    const { answers, answerId, children, options, readOnly } = props;

    const checkboxOptions = options?.values ?? [];
    const checkboxListData = checkboxOptions.map(option => ({...option, name: option.label}));
    const selectedValues = checkboxListData.filter(option => !!(answers?.[option.code]) );

    const onChange = (newValues, lastValue) => {
        if (readOnly) {
            return;
        }

        const clearingOptions = ['None', 'Other'];

        checkboxOptions.forEach((option) => {
            let isChecked = !!newValues.find(newValue => String(newValue.code) === String(option.code));

            // If clearing options are checked, un-select others,
            // when others are checked, un-select clearing options
            if (
                (clearingOptions.includes(lastValue.name) && option.label !== lastValue.name) ||
                (clearingOptions.includes(option.label) && option.label !== lastValue.name)
            ) {
                isChecked = false;
            }

            dispatch(updateSurveyValue(answerId, option.code, isChecked));
        });
    };

    return (
        <div className="survey-checkbox-list-input">
            <CheckboxList
                data={checkboxListData}
                primaryKey="code"
                checked={selectedValues ?? []}
                onChange={onChange}
            />
            {
                children
            }
        </div>
    )
};

export default SurveyCheckboxListInput;
