import {useValidateGreaterThanOrEquals, useValidateLessThan} from "./FormHooks";

export const useInvasiveCoverValidationErrors = () => {
    const validateGreaterThanOrEquals = useValidateGreaterThanOrEquals();
    const validateLessThan = useValidateLessThan();

    return (categories, species, hydrozone) => {
        if(hydrozone.hasNoRoomForQuadrat) {
            return [];
        }
        
        return [
                validateGreaterThanOrEquals(hydrozone.leftQuadratStart, hydrozone.start, "Left Quadrat Start"),
                validateLessThan(hydrozone.leftQuadratStart, hydrozone.end-2, "Left Quadrat Start"),
                validateGreaterThanOrEquals(hydrozone.rightQuadratStart, hydrozone.start, "Right Quadrat Start"),
                validateLessThan(hydrozone.rightQuadratStart, hydrozone.end-2, "Right Quadrat Start")
            ]
            .concat(categories.map(category => {
                const value = species.reduce((acc, curr) => acc + curr[category.code], 0);
    
                return validateGreaterThanOrEquals(value, 100, category.name);
            })
                .filter(validation => !!validation))
    }
};
