import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PhotoGalleryIcon = props => {
    let {color = "#33425a"} = props;
    return (
        <span className="fa-layers fa-fw fa-2x">
            <FontAwesomeIcon icon={['fal', 'rectangle-landscape']} transform="left-1 up-1" />
            <FontAwesomeIcon icon={['fas', 'rectangle-landscape']} transform="right-1 down-1" color={color} />
            <FontAwesomeIcon icon={['fal', 'image']} transform="right-1 down-1" />
        </span>
    );
};

export default PhotoGalleryIcon;
