import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom'
import {Col, Row} from "react-bootstrap";

import PageHeader from "../../Layout/Page/PageHeader";
import Title from "../../Layout/TitleComponent";
import PageContent from "../../Layout/Page/PageContent";
import PageFooter from "../../Layout/Page/PageFooter";
import PageContainer from "../../Layout/Page/PageContainer";
import FooterNav from "../../Layout/Page/FooterNav";
import {useQuantVegNav} from "../../../Hooks/QuickNavHooks";
import {selectTransect} from "../../../Redux/Selectors/transectSelectors";
import FormInput from "../../Common/FormInput";
import {updateCanopyClosureValue} from "../../../Redux/Actions/transectActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import {useReadOnly, useValidateBetween} from "../../../Hooks/FormHooks";
import {useFinalized} from "../../../Hooks/DataHooks";
import {useContextToolsBySampleEventId} from "../../../Hooks/ToolHooks";
import {
    calculateCanopyClosureMeasurementLocationFromTransect
} from "../../../Hooks/DataCopyHooks";


const DensiometerPage = props => {
    const params = useParams();
    const dispatch = useDispatch();
    const validateBetween = useValidateBetween();

    useQuantVegNav(params.transectId);

    const transect = useSelector(state => selectTransect(state, params.transectId));
    const transectCanopyClosure = transect.TransectCanopyClosure ?? {};

    useBreadcrumbs('Canopy Closure', transect.projectId, transect.sampleEventId, transect.transectId);
    useContextToolsBySampleEventId(transect.sampleEventId);

    const finalized = useFinalized(transect.sampleEventId);
    useReadOnly(finalized);

    const updateValue = (field, value, download = false) => {
        dispatch(updateCanopyClosureValue(transect.transectId, field, value, download));
    };
    
    // Some old migrated events might have null TransectCanopyClosure objects
    // so this ensures that we try to compute a default measurementLocation
    useEffect(() => {
        const measurementLocation = calculateCanopyClosureMeasurementLocationFromTransect(transect, false);
        if(measurementLocation !== null && measurementLocation !== transect.TransectCanopyClosure?.measurementLocation) {
            // we pass download=true on the action
            // so that this automated update doesn't
            // trigger the Transect to get an offlineModifiedDate
            updateValue('measurementLocation', measurementLocation, true);
        }
    });

    return (
        <PageContainer className="canopy-page">
            <PageHeader>
                <Title title="Canopy Closure" readOnly={finalized} />
            </PageHeader>
            <PageContent>
                <Row xs={12}>
                    <Col xs={3}>
                        <FormInput
                            label="Measurement Location (ft)"
                            type="number"
                            value={transectCanopyClosure?.measurementLocation ?? ''}
                            onChange={value => updateValue('measurementLocation', value)}
                        />
                    </Col>
                    <Col xs={3}>
                        <FormInput
                            label="% Canopy Closure"
                            type="number"
                            value={transectCanopyClosure?.canopyClosure ?? ''}
                            readOnly
                        />
                    </Col>
                    <Col xs={6} />
                    <Col xs={3}>
                        <FormInput
                            label="Reading N (open points)"
                            type="number"
                            value={transectCanopyClosure?.north ?? ''}
                            onChange={value => updateValue('north', value)}
                            validationMessage={validateBetween(transectCanopyClosure?.north, 0, 96)}
                            suffix={<FontAwesomeIcon icon={['fas', 'arrow-up']} />}
                        />
                    </Col>
                    <Col xs={3}>
                        <FormInput
                            label="Reading E (open points)"
                            type="number"
                            value={transectCanopyClosure?.east ?? ''}
                            onChange={value => updateValue('east', value)}
                            validationMessage={validateBetween(transectCanopyClosure?.east, 0, 96)}
                            suffix={<FontAwesomeIcon icon={['fas', 'arrow-right']} />}
                        />
                    </Col>
                    <Col xs={3}>
                        <FormInput
                            label="Reading S (open points)"
                            type="number"
                            value={transectCanopyClosure?.south ?? ''}
                            onChange={value => updateValue('south', value)}
                            validationMessage={validateBetween(transectCanopyClosure?.south, 0, 96)}
                            suffix={<FontAwesomeIcon icon={['fas', 'arrow-down']} />}
                        />
                    </Col>
                    <Col xs={3}>
                        <FormInput
                            label="Reading W (open points)"
                            type="number"
                            value={transectCanopyClosure?.west ?? ''}
                            onChange={value => updateValue('west', value)}
                            validationMessage={validateBetween(transectCanopyClosure?.west, 0, 96)}
                            suffix={<FontAwesomeIcon icon={['fas', 'arrow-left']} />}
                        />
                    </Col>
                    <Col xs={6}>
                        <FormInput
                            label="Notes"
                            type="textarea"
                            value={transectCanopyClosure?.notes ?? ''}
                            onChange={value => updateValue('notes', value)}
                        />
                    </Col>
                </Row>
            </PageContent>
            <PageFooter>
                <FooterNav />
            </PageFooter>
        </PageContainer>
    )
};

export default DensiometerPage;
