export const TYPE_STEM_TALLY = 'stem-tally';
export const TYPE_LENGTH_OF_COVER = 'length-of-cover';
export const TYPE_TREE_DBH = 'dbh';
export const TYPE_INVASIVE_COVER = 'invasive-cover';

export const hasNoPlantsFieldNames = {
    [TYPE_STEM_TALLY]: 'hasNoStemsPlot',
    [TYPE_LENGTH_OF_COVER]: 'hasNoLengthOfCoverPlot',
    [TYPE_TREE_DBH]: 'hasNoDBHPlot',
};

export const hasNoPlantsLabels = {
    [TYPE_STEM_TALLY]: 'stems',
    [TYPE_LENGTH_OF_COVER]: 'cover', // "length of cover" won't fit on iPad portrait
    [TYPE_TREE_DBH]: 'DBH',
};

export const speciesNativityTypes = [
    {speciesNativityId: 1, name: 'Native'},
    {speciesNativityId: 2, name: 'Introduced'},
    {speciesNativityId: 3, name: 'Unknown'},
];

export const speciesTypes = [
    {speciesTypeId: 1, name: 'Tree'},
    {speciesTypeId: 2, name: 'Shrub'},
    {speciesTypeId: 3, name: 'Vine'},
    {speciesTypeId: 4, name: 'Herbaceous'},
    {speciesTypeId: 5, name: 'Other', icon: ['fal', 'exclamation-circle']}
];

export const stemTallyCategories = [
    {
        id: 1,
        code: 'count6inTo3ft',
        name: '6 in - < 3 ft',
        shortName: '6\" to 3\'',
        active: true,
    },
    {
        id: 2,
        code: 'count3ftTo7ft',
        name: '3 ft - < 7 ft',
        shortName: '3\' to 7\'',
        active: true,
    },
    {
        id: 3,
        code: 'count7ftTo10ft',
        name: '7 ft - < 10 ft',
        shortName: '7\' to 10\'',
        active: true,
    },
    {
        id: 4,
        code: 'count10ftTo15ft',
        name: '10 ft - < 15 ft',
        shortName: '10\' to 15\'',
        active: true,
    },
    {
        id: 5,
        code: 'count15ftTo25ft',
        name: '15 ft - < 25 ft',
        shortName: '15\' to 25\'',
        active: true,
    },
    {
        id: 6,
        code: 'count25ftOrGreater',
        name: '25 ft +',
        shortName: '> 25\'',
        active: true,
    },
];

export const lengthOfCoverCategories = [
    {
        id: 1,
        code: 'lengthOfCover6inTo3ft',
        name: '6 in - < 3 ft',
        shortName: '6\" to 3\'',
        active: true,
    },
    {
        id: 2,
        code: 'lengthOfCover3ftTo7ft',
        name: '3 ft - < 7 ft',
        shortName: '3\' to 7\'',
        active: true,
    },
    {
        id: 3,
        code: 'lengthOfCover7ftTo10ft',
        name: '7 ft - < 10 ft',
        shortName: '7\' to 10\'',
        active: true,
    },
    {
        id: 4,
        code: 'lengthOfCover10ftTo15ft',
        name: '10 ft - < 15 ft',
        shortName: '10\' to 15\'',
        active: true,
    },
    {
        id: 5,
        code: 'lengthOfCover15ftTo25ft',
        name: '15 ft - < 25 ft',
        shortName: '15\' to 25\'',
        active: true,
    },
    {
        id: 6,
        code: 'lengthOfCover25ftOrGreater',
        name: '25 ft +',
        shortName: '> 25\'',
        active: true,
    },
];

export const treeDBHCategories = [
    {
        id: 1,
        code: 'dbh5inTo10in',
        name: '5 in - < 10 in',
        shortName: '5\" to 10\"',
        active: true,
    },
    {
        id: 2,
        code: 'dbh10inTo15in',
        name: '10 in - < 15 in',
        shortName: '10\" to 15\"',
        active: true,
    },
    {
        id: 3,
        code: 'dbh15inTo21in',
        name: '15 in - < 21 in',
        shortName: '15\" to 21\"',
        active: true,
    },
    {
        id: 4,
        code: 'dbh21inOrGreater',
        name: '21 in +',
        shortName: '> 21\"',
        active: true,
    },
];

export const invasiveCoverCategories = [
    {
        id: 1,
        code: 'leftCover',
        name: 'Left % Cover',
        active: true,
    },
    {
        id: 2,
        code: 'rightCover',
        name: 'Right % Cover',
        active: true,
    },
];
